export var EmbedTemplate;
(function (EmbedTemplate) {
    EmbedTemplate["Default"] = "default";
    EmbedTemplate["NoArt"] = "no-art";
    EmbedTemplate["ArtOnly"] = "art-only";
    EmbedTemplate["Compact"] = "compact";
    EmbedTemplate["Strabismus"] = "strabismus";
    EmbedTemplate["V1"] = "v1";
    EmbedTemplate["ButtonOnly"] = "button-only";
})(EmbedTemplate || (EmbedTemplate = {}));

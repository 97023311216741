import { useEffect, useRef, useState } from "react";
const useDetectSticky = (ref, observerSettings = { threshold: [1] }) => {
    const [isSticky, setIsSticky] = useState(false);
    const newRef = useRef(null);
    ref || (ref = newRef);
    // mount
    useEffect(() => {
        const cachedRef = ref === null || ref === void 0 ? void 0 : ref.current, observer = new IntersectionObserver(([e]) => setIsSticky(e.intersectionRatio < 1), observerSettings);
        if (cachedRef) {
            observer.observe(cachedRef);
        }
        // unmount
        return () => {
            if (cachedRef) {
                observer.unobserve(cachedRef);
            }
        };
    }, [observerSettings]);
    return { isSticky, ref, setIsSticky };
};
export default useDetectSticky;

import { FC, useCallback, useEffect, useState } from "react";

import { TransactionType } from "@hl/shared-features/lib/apollo/graphql.generated";
import useAnalytics from "@hl/shared-features/lib/features/analytics/useAnalytics";
import {
  FEATURE_FLAGS,
  useEmbedMode,
  useFeatureFlags,
} from "@hl/shared-features/lib/features/auth/hooks";
import {
  TransactionStateType,
  useTransactionState,
} from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import {
  buildOpenSeaSearchUrl,
  networkLookup,
} from "@hl/shared-features/lib/utils/blockExplorer";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import { getEventSelector, TransactionReceipt } from "viem";

import { getMintPageUrl } from "~config";
import useMintState from "~hooks/useMintState";

import { _CollectionType } from "../../apollo/graphql.generated";
import { SuccessModalData, useTokenReveal } from "../MintPage/SuccessModal";
import { useEmbedFlags } from "../MintPage/embed/customize";
import { useEmbedEvents } from "../MintPage/embed/embed-events";
import { ModalType, updateModalVar } from "../layout/Modal/modal";

import { MintingToken } from "./vars";

export const TRANSFER_721_EVENT_TOPIC_ID = getEventSelector(
  "Transfer(address,address,uint256)"
);

type SuccessProps = {
  editionId: number;
  collectionId: string;
  numTokensMinted: number;
  mintingToken: MintingToken | null;
};
type ClaimMinterProps = {
  navigateOnMint?: (input: NavigateOnMintInput) => void;
};

export const useSuccessModal = ({ collectionId, editionId }: SuccessProps) => {
  const {
    collection,
    mintingSeriesToken: mintingToken,
    numTokensToMint: numTokensMinted,
  } = useMintState();

  const showSuccessModal = useCallback(
    ({
      tokenIds,
      txHash,
      transactionType,
    }: {
      tokenIds: string[];
      txHash: string;
      transactionType?: string;
    }) => {
      if (!collection) {
        return;
      }
      const network = networkLookup(collection.chainId);
      const openSeaUrl =
        collection.openSeaCollectionUrl ??
        buildOpenSeaSearchUrl(collection.address, network.type);

      const editions = collection.editions ?? [];
      let imageUrl = editions.length ? editions[editionId].image : "";
      const isSeries = collection.collectionType === _CollectionType.Series;
      const isGenSeries =
        collection.collectionType === _CollectionType.GenerativeSeries;
      const isCollectorChoice = isSeries && !collection.reveal;
      if (isCollectorChoice) {
        imageUrl = mintingToken?.imageUrl || "";
      }

      updateModalVar<SuccessModalData>({
        showModal: ModalType.MINT_SUCCESS,
        data: {
          imageUrl,
          editionSize: editions.length ? editions[editionId].size : 0,
          transactionType,
          collectionName: collection.name,
          creatorAddresses: collection.creatorAddresses,
          creatorEns: collection.creatorEns,
          collectionId: collectionId ?? "",
          onchainId: collection.onchainId,
          contractAddress: collection.address,
          txHash,
          chainId: collection.chainId,
          editionTransferability: editions.length
            ? editions[editionId].nonTransferable
            : null,
          numTokensMinted,
          tokensIds:
            isCollectorChoice && mintingToken
              ? [mintingToken.id.toString()]
              : tokenIds,
          collectionOnChainBaseUri: collection.onChainBaseUri,
          isSeries,
          isGenSeries,
          openSeaUrl,
          reveal: collection.reveal,
        },
      });
    },
    [numTokensMinted, collection]
  );

  return {
    showSuccessModal,
  };
};

const SPONSORED_MINT_CLAIM_EVENT_TOPIC_ID = ethers.utils.id(
  "SponsoredMint(bytes32,address,uint64,address,address,uint256,uint32)"
);

const getSponsoredMintOrderIdFromLogs = (
  receipt: TransactionReceipt
): string | undefined => {
  let mintedOrderId: string | undefined = undefined;
  for (const log of receipt.logs) {
    if (log.topics[0] === SPONSORED_MINT_CLAIM_EVENT_TOPIC_ID) {
      mintedOrderId = log.topics[3];
    }
  }
  return mintedOrderId;
};

/**
 * Logic-only component that manages one mint token lifecycle. Should
 * only ever be used by the ClaimMintManager. To interact with a
 * token mint, use the `useClaimMintLifecycle` hook.
 */
export const ClaimMinter: FC<ClaimMinterProps> = ({ navigateOnMint }) => {
  const {
    collection,
    txnId,
    mintVector,
    mintingSeriesToken: mintingToken,
    numTokensToMint: numTokensMinted,
    referrer,
    sponsorVector,
  } = useMintState();
  const collectionId = collection?.id ?? "";
  const mintVectorId = mintVector?.id ?? "";
  const transactionState = useTransactionState(txnId);
  const editionId = 0;
  const ids = {
    mintVectorId,
    editionId,
    collectionId,
    mintingToken,
    numTokens: numTokensMinted,
  };
  const { trackEvent, EventType } = useAnalytics();
  const enableRevealPage = useFeatureFlags(
    FEATURE_FLAGS.ENABLE_MINT_REVEAL_PAGE
  );
  const { isEmbedMode } = useEmbedMode();

  useEffect(() => {
    setHasModalShown(false);
  }, [txnId]);

  const getTokenIds = (receipt: TransactionReceipt) => {
    const mintedTokenIds: string[] = [];
    for (const log of receipt.logs) {
      if (log.topics[0] === TRANSFER_721_EVENT_TOPIC_ID) {
        const from = log.topics[1];
        if (from === ethers.constants.HashZero) {
          mintedTokenIds.push(parseInt(log.topics[3]!, 16).toString());
        }
      }
      if (log.topics[3] === TRANSFER_721_EVENT_TOPIC_ID) {
        const from = log.topics[1];
        if (from === ethers.constants.HashZero) {
          mintedTokenIds.push(parseInt(log.topics[3]!, 16).toString());
        }
      }
    }
    return mintedTokenIds;
  };

  const [hasModalShown, setHasModalShown] = useState(false);
  const { showSuccessModal } = useSuccessModal({
    collectionId,
    editionId,
    mintingToken,
    numTokensMinted,
  });
  const [tokenIds, setTokenIds] = useState<string[]>([]);
  const embedFlags = useEmbedFlags();

  useEffect(() => {
    if (transactionState) {
      const state = transactionState?.type;
      const txHash: string | null | undefined = transactionState.hash;
      if (
        transactionState.transactionType ===
          TransactionType.EVM_CROSSCHAIN_BURN &&
        !transactionState.redeemReceipt
      ) {
        return;
      }
      const receipt =
        transactionState.transactionType === TransactionType.EVM_CROSSCHAIN_BURN
          ? transactionState.redeemReceipt
          : transactionState.receipt;
      if (
        state === TransactionStateType.WaitingConfirmedTx &&
        transactionState.hash
      ) {
        trackEvent(EventType.TokenSalePage_TransactionSigned, {
          txHash: transactionState.hash,
          ...ids,
        });
      } else if (state === "DONE" && txHash && !hasModalShown) {
        trackEvent(EventType.TokenSalePage_TransactionSucceeded, {
          txHash,
          ...ids,
        });

        const tokenIds = receipt ? getTokenIds(receipt) : [];

        const sponsorMintOrderId = receipt
          ? getSponsoredMintOrderIdFromLogs(receipt)
          : undefined;

        setTokenIds(tokenIds);

        if (!enableRevealPage || !navigateOnMint) {
          if (!embedFlags.hideSuccessModal) {
            showSuccessModal({
              tokenIds,
              txHash,
              transactionType: transactionState.transactionType,
            });
          }
        } else {
          navigateOnMint({
            mintingToken,
            numTokensMinted,
            tokenIds,
            collectionId,
            onchainId: collection?.onchainId ?? "",
            editionId,
            txHash,
            referrer,
            sponsorMintOrderId,
            sponsoredVectorId: sponsorVector?.id,
          });
        }

        setHasModalShown(true);
      }
    }
  }, [
    collectionId,
    transactionState,
    hasModalShown,
    mintingToken,
    showSuccessModal,
  ]);

  if (collection && isEmbedMode) {
    return (
      <MintEventsEmitter
        collectionId={collectionId}
        contractAddress={collection.address}
        chainId={collection.chainId}
        txHash={transactionState?.hash}
        tokenIds={tokenIds}
      />
    );
  }

  return <></>;
};

const MintEventsEmitter = ({
  collectionId,
  contractAddress,
  chainId,
  txHash,
  tokenIds,
}: {
  collectionId: string;
  contractAddress: string;
  chainId: number;
  txHash?: string;
  tokenIds: string[];
}) => {
  const { emitMintFinished, emitTokenRevealed } = useEmbedEvents({
    collectionId,
    contractAddress,
    chainId,
  });
  const embedFlags = useEmbedFlags();

  useEffect(() => {
    if (!txHash || !tokenIds.length) {
      return;
    }
    emitMintFinished({ txHash, tokenIds });
  }, [tokenIds, txHash, emitMintFinished, embedFlags.hideSuccessModal]);

  if (embedFlags.hideSuccessModal) {
    // success modal listens for reveals, without it, doing it separately
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useTokenReveal(collectionId, tokenIds, emitTokenRevealed);
  }
  return <></>;
};

export const ClaimMinterWithNavigate: FC<ClaimMinterProps> = ({ ...rest }) => {
  const navigate = useNavigate();
  const { clearModals } = useModalStack();

  const navigateOnMint = ({
    collectionId: id,
    onchainId,
    ...rest
  }: NavigateOnMintInput) => {
    clearModals();
    return navigate(getMintPageUrl({ id, onchainId }, { reveal: true }), {
      state: {
        ...rest,
      },
    });
  };
  return <ClaimMinter navigateOnMint={navigateOnMint} {...rest} />;
};

export type NavigateOnMintInput = {
  editionId: number;
  collectionId: string;
  onchainId: string;
  numTokensMinted: number;
  mintingToken: MintingToken | null;
  tokenIds: string[];
  txHash: string;
  referrer?: string;
  sponsorMintOrderId?: string;
  sponsoredVectorId?: string | null;
};

import { jsxs as _jsxs, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { TEXT_COLOR, UTILITY_COLOR, } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Box, Card, Divider, Flex, Group, Stack, Text } from "@mantine/core";
import { useCollectionActivity } from "@reservoir0x/reservoir-kit-ui";
import { formatEther, parseEther } from "ethers/lib/utils";
import ReactTimeAgo from "react-time-ago";
import { reactTimeAgoStyle } from "../../../utils/format";
import { ResizedImage } from "../../image";
const RecentSales = ({ isCollectionBid = false, address, name, }) => {
    const { data: activity } = useCollectionActivity({
        collection: address,
        types: ["sale"],
        limit: 5,
    });
    return !isCollectionBid ? (_jsxs(Stack, { children: [_jsxs(Text, { size: "sm", fw: WEIGHT_BOLD, children: ["Recent sales from ", name] }), _jsx(Card, { px: 16, py: 12, children: activity.length ? (activity === null || activity === void 0 ? void 0 : activity.map((sale, index) => {
                    var _a, _b, _c, _d, _e, _f, _g;
                    return (_jsxs(Box, { children: [_jsxs(Group, { noWrap: true, children: [_jsx(Box, { p: 8, bg: UTILITY_COLOR.TABLE_ROW_BACKGROUND, children: _jsx(ResizedImage, { src: (_a = sale.token) === null || _a === void 0 ? void 0 : _a.tokenImage, width: 40 }) }), _jsxs(Flex, { justify: "space-between", align: "center", sx: { flexGrow: 1 }, children: [_jsx(Stack, { spacing: 0, children: _jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: `#${(_b = sale.token) === null || _b === void 0 ? void 0 : _b.tokenId}` }) }), _jsxs(Stack, { spacing: 0, align: "flex-end", children: [_jsxs(Text, { size: "sm", fw: WEIGHT_BOLD, children: [formatEther(parseEther(String((_e = (_d = (_c = sale.price) === null || _c === void 0 ? void 0 : _c.amount) === null || _d === void 0 ? void 0 : _d.native) !== null && _e !== void 0 ? _e : 0))), " ", (_g = (_f = sale.price) === null || _f === void 0 ? void 0 : _f.currency) === null || _g === void 0 ? void 0 : _g.symbol] }), _jsx(Text, { size: "sm", color: TEXT_COLOR.SECONDARY, children: _jsx(ReactTimeAgo, { date: Date.parse(sale.createdAt || "0"), timeStyle: "round", locale: "en-US" }) })] })] })] }), index !== activity.length - 1 && _jsx(Divider, { my: "sm" })] }, sale.txHash));
                })) : (_jsx(Text, { size: "sm", children: "No sales yet" })) })] })) : (_jsxs(Stack, { children: [_jsxs(Text, { size: "sm", fw: WEIGHT_BOLD, children: ["Last 5 sales for ", name] }), _jsx(Card, { px: 16, py: 12, children: (activity === null || activity === void 0 ? void 0 : activity.length) ? (activity.map((sale, index) => {
                    var _a, _b, _c, _d, _e;
                    return (_jsxs(Box, { children: [_jsxs(Flex, { justify: "space-between", children: [_jsxs(Text, { size: "sm", fw: WEIGHT_BOLD, children: [formatEther(parseEther(String((_c = (_b = (_a = sale.price) === null || _a === void 0 ? void 0 : _a.amount) === null || _b === void 0 ? void 0 : _b.native) !== null && _c !== void 0 ? _c : 0))), " ", (_e = (_d = sale.price) === null || _d === void 0 ? void 0 : _d.currency) === null || _e === void 0 ? void 0 : _e.symbol] }), _jsx(Text, { size: "sm", color: TEXT_COLOR.SECONDARY, children: _jsx(ReactTimeAgo, { date: Date.parse(sale.createdAt || "0"), timeStyle: reactTimeAgoStyle(), locale: "en-US" }) })] }), index !== activity.length - 1 && _jsx(Divider, { my: "sm" })] }, sale.txHash));
                })) : (_jsx(Text, { size: "sm", children: "No offers accepted yet" })) })] }));
};
export default RecentSales;

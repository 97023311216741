import { makeVar } from "@apollo/client";

export enum ModalType {
  MINT_SUCCESS = "mint-success",
  GATED_ENTITY_ALL_PREREQS = "gated-entity-all-prereqs",
}

export type Modal<T> = {
  showModal: ModalType | null;
  showView: string | null;
  data: T;
  onClose?: () => void;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
const emptyModal: Modal<any> = {
  showModal: null,
  showView: null,
  data: null,
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const modalVar = makeVar<Modal<any>>(emptyModal);

export const resetModalVar = () => {
  modalVar(emptyModal);
};

export const setModalVar = <T>(data: Partial<Modal<T>>) => {
  modalVar({ ...emptyModal, ...data });
};

export const updateModalVar = <T>(data: Partial<Modal<T>>) => {
  if (modalVar().showModal != data.showModal) {
    modalVar({ ...modalVar(), ...data });
  }
};

import { useEffect, useRef, useState } from "react";

import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { Video } from "@hl/base-components/lib/video";
import { ResizedImage } from "@hl/shared-features/lib/features/image";
import { Carousel } from "@mantine/carousel";
import { Center, Flex, Loader } from "@mantine/core";
import {
  useDebouncedState,
  useDisclosure,
  useElementSize,
} from "@mantine/hooks";

import { ImageFullscreenModal } from "~components/ImageFullscreenModal";
import { SlideProps } from "~components/carousel";
import useMintState from "~hooks/useMintState";

import { StrabismusSlideMenu } from "../custom/Strabismus";
import { useEmbedFlags } from "../embed/customize";

import { getSlideBoxShadow, useSlideStyles } from "./MintCarouselCommon";
import { isVideoUrl } from "./MintSeriesHeader";
import MintedBadge from "./MintedBadge";

const MintCarouselSlide: React.FC<SlideProps> = ({
  imageUrl,
  animationUrl,
  selected,
  imagePlaceholder,
  onImageError,
  minted = null,
  index,
  fullHeight,
  slideSize,
  onAspectRatio,
}) => {
  const [isArtworkLoaded, setIsArtworkLoaded] = useDebouncedState(false, 100);
  const [imageExpanded, { open: expandImage, close: closeImageExpanded }] =
    useDisclosure(false);
  const { isMedium } = useMantineMediaQueries();
  const { isCollectorChoiceMint, collection } = useMintState();
  const { ref, width, height } = useElementSize();
  const [aspectRatio, setAspectRatio] = useState<number>(1);
  const isVertical = isMedium
    ? aspectRatio <= width / height
    : aspectRatio > width / height;

  const { classes, cx } = useSlideStyles({
    selected,
    isMobile: false,
    imagesCount: 1,
    fullHeight,
    slideSize,
  });

  const isInitialAspect = useRef(true);

  useEffect(() => {
    if (isInitialAspect.current && !isArtworkLoaded) {
      return;
    }
    onAspectRatio?.(aspectRatio);
  }, [aspectRatio, isArtworkLoaded]);

  const flags = useEmbedFlags();
  if (!imageUrl) {
    return null;
  }
  const animation = isVideoUrl(animationUrl ?? "")
    ? animationUrl
    : isVideoUrl(imageUrl)
    ? imageUrl
    : null;

  if (imageExpanded) {
    return (
      <ImageFullscreenModal
        imageUrl={imageUrl}
        close={closeImageExpanded}
        shloms404UI={collection?.flagVariations.shloms404UI}
      />
    );
  }

  return (
    <Carousel.Slide className={classes.staticSlide} ref={ref}>
      <Flex
        pos="relative"
        w={isVertical ? "auto" : "100%"}
        h={isVertical ? "100%" : "auto"}
        align="center"
        justify="center"
      >
        {!isArtworkLoaded && (
          <Center h="100%" w="100%" pos="absolute">
            <Loader />
          </Center>
        )}
        {animation ? (
          <Video
            className={cx([
              classes.video,
              classes.mediaItemNoBorder,
              classes.fullHeight,
              isArtworkLoaded ? classes.mediaItemBorderOnly : undefined,
            ])}
            containerStyles={{ height: "100%" }}
            autoPlay
            loop
            muted
            playsInline
            onLoadedMetadata={(event) => {
              setIsArtworkLoaded(true);
              const target = event.target as HTMLVideoElement;
              const ar = target.videoWidth / target.videoHeight;
              if (ar === aspectRatio) {
                return;
              }
              isInitialAspect.current = false;
              setAspectRatio(ar);
            }}
          >
            <source src={animation} />
          </Video>
        ) : (
          <ResizedImage
            fit="contain"
            onLoad={(event) => {
              setIsArtworkLoaded(true);
              const target = event.target as HTMLImageElement;
              const ar = target.naturalWidth / target.naturalHeight;
              if (ar === aspectRatio) {
                return;
              }
              isInitialAspect.current = false;
              setAspectRatio(ar);
            }}
            styles={{
              image: {
                cursor: "pointer",
                border: "0.5px solid transparent",
                transition:
                  "border 0.5s ease-in-out, box-shadow 0.5s ease-in-out",
                ...(isArtworkLoaded && {
                  borderColor: selected ? "rgba(0, 0, 0, 0.1)" : "transparent",
                  boxShadow: getSlideBoxShadow(selected),
                }),
              },
            }}
            classNames={{
              root: fullHeight ? classes.fullHeight : undefined,
              image: fullHeight ? classes.fullHeightImage : undefined,
              imageWrapper: fullHeight
                ? classes.fullHeightImageWrapper
                : undefined,
              figure: fullHeight ? classes.fullHeight : undefined,
            }}
            width={"100%"}
            fitSize={480}
            src={imageUrl}
            onClick={expandImage}
            placeholder={imagePlaceholder}
            withPlaceholder={!!imagePlaceholder}
            onError={onImageError}
          />
        )}

        {(isCollectorChoiceMint || !!onImageError) &&
          minted &&
          isArtworkLoaded && <MintedBadge />}
        {flags.strabismusTemplate && <StrabismusSlideMenu index={index!} />}
      </Flex>
    </Carousel.Slide>
  );
};

export default MintCarouselSlide;

import { memo, useState } from "react";

import { InfoCircleFill } from "@hl/base-components/lib/assets/icons.generated";
import { CLEAR_COLOR } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { EXTERNAL_URL } from "@hl/shared-features/lib/config";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import {
  Anchor,
  CloseButton,
  Group,
  Popover,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { formatEther, parseEther } from "ethers/lib/utils";

import { formatPrice } from "~features/MintPage/utils/price";
import useMintState from "~hooks/useMintState";

import {
  NftContractStandard,
  PriceType,
} from "../../../apollo/graphql.generated";

const MintFee = ({
  numTokensToMint,
  infoView,
  small = true,
}: {
  numTokensToMint?: number;
  infoView?: boolean;
  small?: boolean;
}) => {
  const { collection } = useMintState();
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  const { mintVector, chainId, isFreeMint, isERC1155 } = useMintState();

  const currency = getCurrencySymbol(
    chainId,
    mintVector?.paymentCurrency?.symbol
  );
  const mintFee = mintVector?.paymentCurrency?.mintFee;

  if (
    mintVector?.priceType &&
    [PriceType.DutchAuction, PriceType.RankedAuction].includes(
      mintVector?.priceType
    )
  ) {
    return null;
  }

  if (!mintFee) return <></>;

  const is1155 = collection?.standard === NftContractStandard.ERC1155;
  const mintFeeDivider = is1155 ? 2 : 1;
  const mintFeeAdjusted = parseEther(mintFee).div(mintFeeDivider);

  return (
    <Popover
      position="top"
      withArrow
      shadow="md"
      arrowSize={12}
      opened={opened}
      onChange={setOpened}
      width={287}
    >
      <Popover.Target>
        <UnstyledButton onClick={() => setOpened((o) => !o)}>
          <Group
            sx={{
              whiteSpace: small ? "nowrap" : "unset",
            }}
            spacing={4}
            position="left"
            noWrap={small}
          >
            {infoView ? (
              <>
                <Text size="xs" color={TEXT_COLOR.SECONDARY} span>
                  {formatEther(mintFeeAdjusted)}
                </Text>{" "}
                <Text size="xs" color={TEXT_COLOR.SECONDARY} span>
                  {currency} mint fee per token
                </Text>
                <InfoCircleFill
                  width={12}
                  height={12}
                  color={theme.fn.themeColor(TEXT_COLOR.SECONDARY)}
                />
              </>
            ) : (
              <>
                {" "}
                {!isFreeMint && (
                  <Text size="xs" color={TEXT_COLOR.SECONDARY} span>
                    +{" "}
                  </Text>
                )}
                <Text size="xs" color={TEXT_COLOR.SECONDARY} span>
                  {formatPrice(
                    formatEther(mintFeeAdjusted.mul(numTokensToMint || 1))
                  )}{" "}
                </Text>
                <Text size="xs" color={TEXT_COLOR.SECONDARY} span>
                  {currency}
                </Text>
                <Group align="center" spacing={4}>
                  {isFreeMint && !small && (
                    <Text size="xs" color={TEXT_COLOR.SECONDARY} span>
                      ({is1155 ? 80 : 50}% to creator)
                    </Text>
                  )}
                  <InfoCircleFill
                    width={12}
                    height={12}
                    color={theme.fn.themeColor(TEXT_COLOR.SECONDARY)}
                  />
                </Group>
              </>
            )}
          </Group>
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown p={20} bg="strongOverlay.0" sx={{ border: "unset" }}>
        <Text size="xs" color={TEXT_COLOR.INVERTED_PRIMARY} align="left">
          <CloseButton
            //@ts-ignore
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
            size="sm"
            color={CLEAR_COLOR}
            onClick={() => setOpened((o) => !o)}
          />
          <Stack spacing={6} align="flex-start">
            <div>
              {isERC1155
                ? "80% of this fee goes directly to the creator. The rest helps us to run our service."
                : isFreeMint
                ? "Half of this fee goes directly to the creator. The other half helps us run our service."
                : "Highlight charges a small fee for each token minted in order to run our service."}
            </div>
            <Anchor
              size="xs"
              weight={500}
              color={TEXT_COLOR.INVERTED_PRIMARY}
              href={EXTERNAL_URL.MINT_FEE_HELP_ARTICLE}
              target="_blank"
              onClick={() => setOpened(false)}
            >
              Learn more →
            </Anchor>
          </Stack>
        </Text>
      </Popover.Dropdown>
    </Popover>
  );
};

export default memo(MintFee);

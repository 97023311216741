import axios from "axios";
import { EXTERNAL_URL } from "../config";
const IPFS_PREFIX = "ipfs://";
const ARWEAVE_PREFIX = "ar://";
export const constructDFSGatewayUrl = (uri, hasDefault) => {
    if (uri.indexOf(IPFS_PREFIX) === 0) {
        return `${EXTERNAL_URL.IPFS_GATEWAY}/${uri.slice(7)}`;
    }
    else if (uri.indexOf(ARWEAVE_PREFIX) === 0) {
        return `${EXTERNAL_URL.ARWEAVE_GATEWAY}/${uri.slice(5)}`;
    }
    else {
        return hasDefault ? uri : null;
    }
};
export const isDFSUri = (dfsUri) => {
    return (dfsUri.indexOf(IPFS_PREFIX) === 0 || dfsUri.indexOf(ARWEAVE_PREFIX) === 0);
};
export const getDFSContent = async (dfsUri) => {
    const url = constructDFSGatewayUrl(dfsUri);
    if (!url)
        return null;
    return new Promise((resolve) => {
        // eslint-disable-next-line import/no-named-as-default-member
        const source = axios.CancelToken.source();
        const timeoutId = setTimeout(() => {
            source.cancel("too long to get ipfs content");
            resolve(null);
        }, 1000);
        axios
            .get(url, { cancelToken: source.token })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((response) => {
            if (response.status === 200) {
                resolve(response.data);
            }
            else {
                resolve(null);
            }
        })
            .catch(() => {
            resolve(null);
        })
            .finally(() => {
            clearTimeout(timeoutId);
        });
    });
};
export const isDFSContentAvailable = async (dfsUri) => {
    const url = constructDFSGatewayUrl(dfsUri);
    if (!url)
        return false;
    return new Promise((resolve) => {
        // eslint-disable-next-line import/no-named-as-default-member
        const source = axios.CancelToken.source();
        const timeoutId = setTimeout(() => {
            source.cancel("too long to get ipfs content");
            resolve(false);
        }, 2000);
        axios
            .get(url, { cancelToken: source.token })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((response) => {
            if (response.status === 200) {
                resolve(true);
            }
            else {
                resolve(false);
            }
        })
            .catch(() => {
            resolve(false);
        })
            .finally(() => {
            clearTimeout(timeoutId);
        });
    });
};

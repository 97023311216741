import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { forwardRef, useEffect, useMemo, useState, } from "react";
import { Settings04 } from "@hl/base-components/lib/assets/icons.generated/HDS/Duotone icons/General";
import { OUTLINE_COLOR } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Button, createStyles, Flex, Group, Select, Text, TextInput, } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons";
import useDetectSticky from "../../../hooks/useDetectSticky";
const useStyles = createStyles({
    input: { height: "40px", marginTop: 0, fontWeight: WEIGHT_BOLD },
    root: { flexGrow: 1, width: "100%" },
});
const MarketplaceFilterSortBar = ({ setShowFilters, searchString, hasFilterToggle = false, setSearchString, searchPlaceholder, setSorting, sorting, sortingOptions, containerConfig = {
    top: "calc(var(--mantine-header-height, 0px) + 1px)",
    zIndex: 99,
}, customFilterElement, customViewElement, isCollectorsChoice, attributesVar, }) => {
    const { classes, theme } = useStyles();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
    const [headerHeight, setHeaderHeight] = useState("0px");
    const { isSticky, ref } = useDetectSticky(undefined, {
        threshold: [1],
        rootMargin: `-${headerHeight} 0px 0px 0px`,
    });
    const selectedSort = useMemo(() => sortingOptions.find((sortingOption) => sortingOption.value === sorting), [sorting]);
    const numberOfTraitsSelected = (attributesVar === null || attributesVar === void 0 ? void 0 : attributesVar.reduce((acc, item) => item.values.length + acc, 0)) ||
        0;
    useEffect(() => {
        const height = getComputedStyle(document.documentElement).getPropertyValue("--mantine-header-height");
        const adjustedHeight = parseInt(height.split("px")[0]) + 2;
        if (height) {
            setHeaderHeight(String(adjustedHeight) + "px");
        }
    }, []);
    const filterElement = hasFilterToggle &&
        (customFilterElement ? (customFilterElement) : (_jsx(Button, { size: "lg", color: OUTLINE_COLOR, onClick: () => setShowFilters === null || setShowFilters === void 0 ? void 0 : setShowFilters((prev) => !prev), leftIcon: _jsx(Settings04, {}), rightIcon: numberOfTraitsSelected ? (_jsx(Text, { px: 6, fw: WEIGHT_BOLD, bg: theme.colors.secondaryBackground[0], sx: { borderRadius: 999, display: "flex", alignItems: "center" }, h: 20, color: TEXT_COLOR.PLACEHOLDER, size: 12, children: numberOfTraitsSelected })) : undefined, children: "Traits" })));
    return (_jsxs(Flex, { py: 16, direction: isMobile ? "column" : "row", gap: "sm", wrap: "wrap", justify: "space-between", sx: (theme) => ({
            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                paddingLeft: isCollectorsChoice ? 40 : 20,
                paddingRight: isCollectorsChoice ? 40 : 20,
                marginLeft: isCollectorsChoice ? -40 : -20,
                marginRight: isCollectorsChoice ? -40 : -20,
            },
            marginLeft: -40,
            marginRight: -40,
            paddingLeft: 40,
            paddingRight: 40,
            position: "sticky",
            background: theme.colors.baseBackground[0],
            borderBottom: `1px solid ${isSticky || isCollectorsChoice
                ? theme.colors.divider[0]
                : "transparent"}`,
            ...containerConfig,
        }), ref: ref, children: [_jsxs(Group, { spacing: isMobile ? "sm" : "lg", children: [!isMobile && filterElement, _jsx(TextInput, { w: { base: "100%", sm: 260, md: 460 }, sx: { flexGrow: 1 }, placeholder: searchPlaceholder !== null && searchPlaceholder !== void 0 ? searchPlaceholder : "Filter tokens by name or ID", value: searchString, onChange: (e) => setSearchString(e.target.value), classNames: { input: classes.input }, icon: _jsx(IconSearch, { width: 16, height: 16, color: theme.colors.primaryText[0] }) })] }), _jsxs(Group, { noWrap: true, spacing: isMobile ? "sm" : "lg", children: [isMobile && filterElement, customViewElement, _jsx(Select, { icon: selectedSort ? adjustIconSize(selectedSort.icon) : null, value: sorting, onChange: setSorting, data: sortingOptions, itemComponent: (props) => (_jsx(SelectItem, { ...props, selected: sorting })), classNames: { input: classes.input, root: classes.root } })] })] }));
};
export default MarketplaceFilterSortBar;
const adjustIconSize = (IconComponent) => {
    return _jsx(IconComponent, { width: 16, height: 16 });
};
const SelectItem = forwardRef(({ icon, label, value, ...others }, ref) => (_jsxs(Group, { noWrap: true, ref: ref, ...others, spacing: 8, children: [adjustIconSize(icon), _jsx(Text, { size: "sm", sx: {
                flexGrow: 1,
                fontWeight: others.selected === value ? WEIGHT_BOLD : undefined,
            }, children: label })] })));

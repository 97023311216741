import { useEffect, useRef } from "react";
import { GOOGLE_ANALYTICS_ID } from "../../../config";
import useGaTracker from "../../../hooks/useGaTracker";
import { setLastRefresh } from "../../../utils/clientVersionCookie";
import { setDeviceFingerprint } from "../../../utils/deviceFingerprint";
import { useAmpliSetup } from "../../analytics/use-ampli";
import { useGlobalEmbedEvents } from "../../embed/events";
import { useAuth } from "../AuthContext";
import { useGetIdentityQuery } from "../queries/auth.graphql.generated";
import { identityVar, initVar, isEmbedVar } from "../vars";
const useIdentity = () => {
    var _a;
    const { authenticated } = useAuth();
    const { data: identityData, loading: identityLoading, refetch, } = useGetIdentityQuery();
    const { emitSignInEvent, emitSignOutEvent } = useGlobalEmbedEvents();
    useEffect(() => {
        var _a;
        if (identityLoading) {
            return;
        }
        if (!identityData) {
            console.error("identityData didn't load");
            return;
        }
        identityVar(identityData.identity);
        initVar(true);
        const account = identityData.identity.account;
        if (account) {
            emitSignInEvent({
                walletAddress: account === null || account === void 0 ? void 0 : account.evmAddresses[0],
                ensName: (_a = account === null || account === void 0 ? void 0 : account.ensName) !== null && _a !== void 0 ? _a : null,
            });
        }
    }, [identityData, identityLoading]);
    const account = (_a = identityData === null || identityData === void 0 ? void 0 : identityData.identity) === null || _a === void 0 ? void 0 : _a.account;
    const wasAuthenticatedRef = useRef(false);
    useEffect(() => {
        if (authenticated) {
            wasAuthenticatedRef.current = true;
        }
    }, [authenticated]);
    useEffect(() => {
        if (authenticated && !account) {
            refetch();
        }
        if (!authenticated && wasAuthenticatedRef.current && account) {
            // this executed when user is logged out
            wasAuthenticatedRef.current = false;
            refetch();
            emitSignOutEvent();
        }
    }, [authenticated, account, emitSignOutEvent]);
    return { identityData, identityLoading };
};
export const useBootstrap = () => {
    useIdentity();
    const { startGoogleAnalytics } = useGaTracker(GOOGLE_ANALYTICS_ID !== null && GOOGLE_ANALYTICS_ID !== void 0 ? GOOGLE_ANALYTICS_ID : "");
    useAmpliSetup();
    useEffect(() => {
        startGoogleAnalytics();
        setDeviceFingerprint();
        // track the last time we fetched the static `main.js` bundled file
        // so we can force refresh of cached browser client code
        setLastRefresh();
    }, []);
    return {};
};
export const useEmbedBootstrap = () => {
    useIdentity();
    useAmpliSetup();
    isEmbedVar(true);
};
export const useNextBootstrap = () => {
    useIdentity();
    useEffect(() => {
        setDeviceFingerprint();
    }, []);
    useAmpliSetup();
    return {};
};

import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useState } from "react";
import { Modal } from "@mantine/core";
import { uniqueId } from "lodash";
import { ModalStackContext } from "./context";
export const ModalStack = ({ children, size, }) => {
    var _a, _b;
    const [modals, setModals] = useState([]);
    const [id] = useState(uniqueId("modal-stack"));
    const clearModals = () => {
        setModals([]);
    };
    const popModal = () => {
        setModals((prevModals) => prevModals.slice(0, -1));
    };
    const pushModal = (node, props) => {
        const modal = {
            node,
            props: props !== null && props !== void 0 ? props : {},
        };
        setModals((prevModals) => [...prevModals, modal]);
    };
    const setModalProps = (props) => {
        const lastModal = modals.at(-1);
        if (!lastModal)
            return;
        setModals((prevModals) => {
            var _a;
            return [
                ...prevModals.slice(0, -1),
                {
                    node: (_a = prevModals === null || prevModals === void 0 ? void 0 : prevModals.at(-1)) === null || _a === void 0 ? void 0 : _a.node,
                    props: Object.assign(lastModal.props, props),
                },
            ];
        });
    };
    const lastModalProps = (_b = (_a = modals.at(-1)) === null || _a === void 0 ? void 0 : _a.props) !== null && _b !== void 0 ? _b : { onClose: () => null };
    return (_jsxs(ModalStackContext.Provider, { value: {
            modals,
            pushModal,
            popModal,
            clearModals,
            setModalProps,
            id,
            bodyId: `${id}-body`,
        }, children: [children, _jsx(Modal, { onClose: () => popModal(), size: size, ...lastModalProps, opened: modals.length > 0, id: id, children: modals.map(({ node }, i) => (_jsx("div", { style: i + 1 < modals.length ? { display: "none" } : {}, children: node }, i))) })] }));
};

import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { memo, useReducer } from "react";
import { TransactionContext, TransactionDispatchContext, transactionReducer, } from "./TransactionContext";
import TransactionsManager from "./TransactionsManager";
/* eslint-disable  @typescript-eslint/no-explicit-any */
BigInt.prototype.toJSON = function () {
    return this.toString();
};
const TransactionProvider = ({ children }) => {
    const [transactions, dispatch] = useReducer(transactionReducer, {});
    return (_jsx(TransactionDispatchContext.Provider, { value: dispatch, children: _jsxs(TransactionContext.Provider, { value: transactions, children: [_jsx(TransactionsManager, {}), children] }) }));
};
export default memo(TransactionProvider);

import { useCallback } from "react";

import { TransactionType } from "@hl/shared-features/lib/apollo/graphql.generated";
import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import { useOnChainTransaction } from "@hl/shared-features/lib/hooks/useOnChainTransaction";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import { BigNumber } from "ethers";

import { MintVector } from "~features/MintPage";
import { useGetApproveCurrencyTxArgsLazyQuery } from "~features/MintPage/queries.graphql.generated";

import { _CollectionType } from "../apollo/graphql.generated";

export const useApproveErc20 = (
  collectionId: string,
  collectionType: _CollectionType,
  mintVector: MintVector,
  valueToAllow: BigNumber,
  onMintClick: () => void
) => {
  const { walletAddress } = useAuth();
  const [generateTxArgs, { loading: loadingArgs, error: errorArgs }] =
    useGetApproveCurrencyTxArgsLazyQuery();

  const currencySymbol = getCurrencySymbol(
    mintVector?.chainId || 0,
    mintVector?.paymentCurrency?.symbol,
    true
  );

  const { error, txnId, startTransaction, buttonLabel, buttonLoading } =
    useOnChainTransaction({
      collectionType,
      entityId: collectionId,
      onFinish: onMintClick,
      refetchQueries: [],
      transactionType: TransactionType.EVM_CURRENCY_ALLOWANCE,
      actionLabel: `Approve ${currencySymbol}`,
    });

  const approve = useCallback(async () => {
    if (!mintVector?.chainId) throw new Error("mintVector is required");

    const metadata = {
      chainId: mintVector.chainId.toString(),
      currency: currencySymbol,
    };
    const result = await generateTxArgs({
      variables: {
        chainId: mintVector.chainId,
        currency: mintVector.currency,
        value: valueToAllow.toString(),
      },
    });
    const args = result?.data?.approveCurrencyTxArgs;
    return startTransaction({ args, metadata });
  }, [
    generateTxArgs,
    collectionId,
    mintVector,
    walletAddress,
    valueToAllow,
    currencySymbol,
  ]);

  return {
    txnId,
    error: error || (errorArgs ? "Error preparing transaction" : ""),
    approve,
    buttonLabel: buttonLabel,
    buttonLoading: buttonLoading || loadingArgs,
  };
};

export const createFontFaces = (family) => {
    let styles = ``;
    const face = (weight, formats, style) => {
        const woff = formats[0];
        const woff2 = formats[1];
        return `
      @font-face {
        font-family: '${family.name}';
        src: url(${woff2}) format('woff2'),
            url(${woff}) format('woff');
        font-weight: ${weight};
        font-style: ${style};
        font-display: auto;
      }
    `;
    };
    for (const [weight, formats] of Object.entries(family.normal)) {
        styles += face(weight, formats, "normal");
    }
    if (family === null || family === void 0 ? void 0 : family.italic) {
        for (const [weight, formats] of Object.entries(family.italic)) {
            styles += face(weight, formats, "italic");
        }
    }
    return styles;
};

import { ApolloClient, InMemoryCache, from, } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getAccessToken } from "@privy-io/react-auth";
import { createUploadLink } from "apollo-upload-client";
import { ApolloClientErrors, envConfig } from "../config";
import { getLastRefresh, setLastRefresh } from "../utils/clientVersionCookie";
import { getDeviceFingerprint } from "../utils/deviceFingerprint";
import { InvalidAccessTokenErrorCode } from "./auth";
import { logApolloError } from "./logger";
const IN_DEVELOPMENT = envConfig.isDevelopment;
let customerEmbedId;
const createApolloClient = ({ initialState, clientVersionId = Date.now(), }) => {
    IN_DEVELOPMENT && console.log(`🟢 Apollo Client Initialized.`);
    const isSSR = typeof window === "undefined";
    const cache = new InMemoryCache({
        typePolicies: {
            WalletNft: {
                // WalletNft has an id field that represents the id of an NFT as
                // stored on-chain. ApolloCache uses id by default as a unique key
                // but we return tokens across multiple contracts so the ids overlap.
                // This is needed so that Apollo client doesn't think they are the same
                // object and overwrite them in the InMemoryCache.
                keyFields: ["chain", "contract", "id"],
            },
            OrderToken: {
                // Same as WalletNft
                keyFields: ["id", "imageUrl"],
            },
            Collection: {
                fields: {
                    seriesDetails: {
                        merge: true,
                    },
                    collectorsChoiceTokens: {
                        keyArgs: [
                            "minted",
                            "attributes",
                            "first",
                            "sortBy",
                            "sortDirection",
                            "tokenNameOrId",
                        ],
                        merge(existing, incoming) {
                            var _a, _b;
                            return {
                                ...incoming,
                                edges: [...((_a = existing === null || existing === void 0 ? void 0 : existing.edges) !== null && _a !== void 0 ? _a : []), ...((_b = incoming === null || incoming === void 0 ? void 0 : incoming.edges) !== null && _b !== void 0 ? _b : [])],
                            };
                        },
                    },
                    seriesTokens: {
                        keyArgs: ["minted", "attributes", "first"],
                        merge(existing, incoming) {
                            var _a, _b;
                            return {
                                ...incoming,
                                edges: [...((_a = existing === null || existing === void 0 ? void 0 : existing.edges) !== null && _a !== void 0 ? _a : []), ...((_b = incoming === null || incoming === void 0 ? void 0 : incoming.edges) !== null && _b !== void 0 ? _b : [])],
                            };
                        },
                    },
                },
            },
        },
    });
    if (initialState) {
        cache.restore(initialState);
    }
    const authLink = setContext(async (graphQLRequest, { headers }) => {
        const newHeaders = {
            headers: {
                ...headers,
                "X-Client-Version": clientVersionId,
                Accept: "application/json",
            },
        };
        // https://www.apollographql.com/docs/react/api/link/introduction/
        // evaluated each time, so cookies (source of auth / jwt) isn't cached
        const accessToken = await getAccessToken();
        if (accessToken &&
            graphQLRequest.operationName !== "CompleteSignInWithJWT") {
            newHeaders.headers["X-Access-Token"] = accessToken;
        }
        if (customerEmbedId) {
            newHeaders.headers["X-Customer-Embed-Id"] = customerEmbedId;
        }
        newHeaders.headers["X-Analytics-Device-Id"] = getDeviceFingerprint();
        if (IN_DEVELOPMENT) {
            [...Array(10).keys()].map((num) => {
                const monitor = process.env[`REACT_APP_MONITOR_REQ_${num}`];
                const forward = process.env[`REACT_APP_FORWARD_REQ_${num}`];
                if (monitor && forward) {
                    newHeaders.headers[`x-forward-dev-request-${num}`] = forward;
                    newHeaders.headers[`x-monitor-dev-request-${num}`] = monitor;
                }
            });
        }
        return newHeaders;
    });
    const getHighlightTraceId = (operation) => {
        var _a;
        const context = operation.getContext();
        const headers = (_a = context.response) === null || _a === void 0 ? void 0 : _a.headers;
        return headers === null || headers === void 0 ? void 0 : headers.get("X-Highlight-Traceid");
    };
    // https://www.apollographql.com/docs/react/networking/advanced-http-networking/
    // https://www.apollographql.com/docs/react/data/error-handling/
    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
        var _a;
        const highlightTraceId = (_a = getHighlightTraceId(operation)) !== null && _a !== void 0 ? _a : "no-trace-id";
        logApolloError({
            graphQLErrors,
            networkError,
            operation,
            highlightTraceId,
        });
        if (graphQLErrors) {
            graphQLErrors.forEach((error) => {
                const { extensions } = error;
                switch (extensions.code) {
                    case InvalidAccessTokenErrorCode:
                        // TODO: refactor 401 handling
                        // resetSession();
                        break;
                    case ApolloClientErrors.CLIENT_VERSION_DONT_MATCH:
                        console.error("OUTDATED_CLIENT_VERSION");
                        // limit to once every minute
                        if (Date.now() - getLastRefresh() > 60000) {
                            setLastRefresh();
                            window.location.reload();
                        }
                        break;
                    default:
                    // Nothing to do here...
                }
            });
        }
    });
    const httpLink = createUploadLink({
        uri: envConfig.graphql.httpUrl,
        credentials: "include",
    });
    const additiveLinks = from([authLink, errorLink, httpLink]);
    return new ApolloClient({
        ssrMode: isSSR,
        credentials: "same-origin",
        link: additiveLinks,
        connectToDevTools: IN_DEVELOPMENT,
        cache,
    });
};
export const apolloClient = createApolloClient({});
export const createEmbedApolloClient = (embedId) => {
    customerEmbedId = embedId;
    // it's important to use single instance of the client
    // as different features use default client (e.g. to reset cache)
    return apolloClient;
};

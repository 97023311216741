import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { reactTimeAgoStyle } from "@hl/shared-features/lib/utils/format";
import {
  Box,
  Center,
  Divider,
  Group,
  Space,
  Text,
  useMantineTheme,
} from "@mantine/core";
import ReactTimeAgo from "react-time-ago";

import { ReactComponent as AnalogClock } from "~assets/icons/analog-clock.svg";
import useMintState, { MintStateRequired } from "~hooks/useMintState";

import { SaleStatus } from "../utils/types";

type MintCardDateProps = {
  showDivider?: boolean;
  smallSize?: boolean;
  showBackground?: boolean;
  type?: "mint" | "auction";
};

const MintCardDate = ({
  showDivider,
  smallSize,
  showBackground = false,
  type = "mint",
}: MintCardDateProps) => {
  const { mintVector, saleStatus, refetch } =
    useMintState() as MintStateRequired;
  const theme = useMantineTheme();

  if ([SaleStatus.ACTIVE_WITH_END, SaleStatus.NOT_STARTED].includes(saleStatus))
    return (
      <Box
        sx={(theme) => ({
          ...(showBackground && {
            backgroundColor: theme.colors.secondaryBackground[0],
            padding: "6px",
            borderRadius: theme.radius.xl,
          }),
        })}
      >
        <Group spacing={smallSize ? 5 : 8}>
          <Center>
            <AnalogClock
              height={smallSize ? 12 : 16}
              width={smallSize ? 12 : 16}
              color={theme.fn.themeColor(TEXT_COLOR.PRIMARY)}
            />
          </Center>
          <Text size={smallSize ? 12 : 14}>
            {type === "auction" ? "Auction" : "Mint"}{" "}
            {SaleStatus.NOT_STARTED === saleStatus ? (
              <>
                {type === "auction" ? "starts" : "opens"} in{" "}
                <ReactTimeAgo
                  date={Date.parse(mintVector.start)}
                  timeStyle={reactTimeAgoStyle(refetch)}
                  future={true}
                  locale="en-US"
                />
              </>
            ) : (
              <>
                {type === "auction" ? "ends" : "closes"} in{" "}
                <ReactTimeAgo
                  date={Date.parse(mintVector.end!)}
                  future={true}
                  timeStyle={reactTimeAgoStyle(refetch)}
                  locale="en-US"
                />
              </>
            )}
          </Text>
        </Group>

        {showDivider && (
          <>
            <Space h="sm" />
            <Divider mx="-md" />
            <Space h="sm" />
          </>
        )}
      </Box>
    );
  return null;
};

export default MintCardDate;

import { useSearchParams } from "react-router-dom";

import { getMintPageUrl } from "~config";

const useReferral = () => {
  const [searchParams] = useSearchParams();

  const referrerParam = (referrer?: string | null) =>
    referrer ? `?referrer=${referrer}` : "";

  const mintUrlWithReferrer = (
    collection: { id: string; onchainId: string },
    referrer?: string | null
  ) => {
    const mintUrl = getMintPageUrl(collection, { withBaseUrl: true });
    return `${mintUrl}${referrerParam(referrer)}`;
  };

  const referrerQueryParamValue = searchParams.get("referrer") || undefined;

  return {
    referrerQueryParamValue,
    mintUrlWithReferrer,
  };
};

export default useReferral;

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { memo, useEffect, useState } from "react";
import { maskAddress } from "../../../utils/content";
import ChainName from "../../chain/ChainName";
import { useCollectionForHistoryDropdownQuery } from "../queries.graphql.generated";
const MintDescription = ({ collectionId, status, chainId }) => {
    const { data } = useCollectionForHistoryDropdownQuery({
        variables: { collectionId },
    });
    const [displayName, setDisplayName] = useState("token");
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.getPublicCollection) {
            const { name, address: rawAddress } = data.getPublicCollection;
            const address = maskAddress(rawAddress, 6, 4);
            setDisplayName(`${name} (${address})`);
        }
    }, [data === null || data === void 0 ? void 0 : data.getPublicCollection]);
    if (status === "PENDING") {
        return (_jsxs(_Fragment, { children: ["Minting ", displayName, " on ", _jsx(ChainName, { chainId: chainId }), "."] }));
    }
    if (status === "MINED") {
        return (_jsxs(_Fragment, { children: ["Minted ", displayName, " on ", _jsx(ChainName, { chainId: chainId }), "."] }));
    }
    if (status === "REJECTED") {
        return (_jsxs(_Fragment, { children: ["Failed to mint ", displayName, " on ", _jsx(ChainName, { chainId: chainId }), "."] }));
    }
    return _jsx(_Fragment, { children: "Unknown transaction status." });
};
export default memo(MintDescription);

import { NetworkType } from "../../apollo/graphql.generated";
export const BlockchainMainnets = [
    NetworkType.ETHEREUM,
    NetworkType.POLYGON,
    NetworkType.ARBITRUM,
    NetworkType.OPTIMISM,
    NetworkType.BASE,
    NetworkType.ZORA,
    NetworkType.FORMA,
    NetworkType.MANTLE,
    NetworkType.SCROLL,
    NetworkType.ZK_SYNC_ERA,
    NetworkType.SHAPE,
];
export const SupportedCrossChainMainnets = [
    NetworkType.ETHEREUM,
    NetworkType.ARBITRUM,
    NetworkType.OPTIMISM,
    NetworkType.BASE,
    NetworkType.ZORA,
    NetworkType.SCROLL,
    NetworkType.FORMA,
    NetworkType.ZK_SYNC_ERA,
    NetworkType.SHAPE,
];
export const SupportedCrossChainTestnets = [
    NetworkType.SEPOLIA,
    NetworkType.BASE_SEPOLIA,
];

import { useCallback } from "react";

import { useEmbedMode } from "@hl/shared-features/lib/features/auth/hooks";
import {
  EMBED_EVENT_PREFIX as EVENT_PREFIX,
  EmbedEvent,
} from "@hl/shared-features/lib/features/embed/events";
import { Metadata } from "@hl/shared-features/lib/features/gen-art/preview";

interface MintEvent extends CustomEvent {
  detail: {
    collectionId: string;
    contractAddress: string;
    chainId: number;
    txHash: string;
    tokenIds: string[];
  };
}
interface TokenRevealEvent extends CustomEvent {
  detail: {
    collectionId: string;
    contractAddress: string;
    chainId: number;
    tokenId: string;

    metadata: Metadata;
  };
}
export const useEmbedEvents = ({
  collectionId,
  contractAddress,
  chainId,
}: {
  collectionId: string;
  contractAddress: string;
  chainId: number;
}) => {
  const { isEmbedMode } = useEmbedMode();
  const emitTokenRevealed = useCallback(
    (tokenId: string, metadata: Metadata) => {
      if (!isEmbedMode) return;
      const event: TokenRevealEvent = new CustomEvent(
        `${EVENT_PREFIX}:${EmbedEvent.TokenRevealed}`,
        {
          detail: {
            collectionId,
            contractAddress,
            chainId,
            tokenId,
            metadata,
          },
        }
      );
      window.dispatchEvent(event);
    },
    [isEmbedMode, collectionId, contractAddress, chainId]
  );

  const emitMintFinished = useCallback(
    (data: { txHash: string; tokenIds: string[] }) => {
      if (!isEmbedMode) return;
      const event: MintEvent = new CustomEvent(
        `${EVENT_PREFIX}:${EmbedEvent.MintFinished}`,
        {
          detail: {
            collectionId,
            contractAddress,
            chainId,
            ...data,
          },
        }
      );
      window.dispatchEvent(event);
    },
    [isEmbedMode, collectionId, contractAddress, chainId]
  );

  return {
    emitTokenRevealed,
    emitMintFinished,
  };
};

import { createStyles } from "@mantine/core";

export const getSlideBoxShadow = (selected: boolean) =>
  selected
    ? "0px 8px 80px -8px rgba(0, 0, 0, 0.2), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);"
    : "0px 2px 20px -2px rgba(0, 0, 0, 0.1), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);";

type Props = {
  selected: boolean;
  isMobile: boolean;
  imagesCount: number;
  fullHeight?: boolean;
  isVertical?: boolean;
  slideSize: number;
};
export const useSlideStyles = createStyles(
  (theme, { selected, isMobile, fullHeight, slideSize }: Props) => {
    return {
      staticSlide: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: slideSize,
        width: "100vw",
        height: fullHeight ? "100%" : "auto",
        transform: selected ? "scale(1)" : "scale(0.9)",
        transition: "transform .2s ease-in-out",
      },
      mediaItem: {
        flex: "1 1",
        border: "0.5px solid rgba(0, 0, 0, 0.1)",
        boxShadow: getSlideBoxShadow(selected),
        height: fullHeight ? "100%" : "auto",
      },
      mediaItemNoBorder: {
        flex: "1 1",
        height: fullHeight ? "100%" : "auto",
        border: "0.5px solid transparent",
      },
      mediaItemBorderOnly: {
        border: "0.5px solid rgba(0, 0, 0, 0.1)",
        boxShadow: getSlideBoxShadow(selected),
      },
      fullHeight: {
        [theme.fn.largerThan("sm")]: {
          height: "100% !important",
          maxHeight: "calc(100vh - 129px)",
        },
      },
      fullHeightImage: {
        [theme.fn.largerThan("sm")]: {
          height: "100% !important",
          maxHeight: "calc(100vh - 129px)",
        },
      },
      fullHeightImageWrapper: {
        display: "flex",
        alignItems: "center",
        height: "100% !important",
      },
      video: {
        display: "block",
        objectFit: "contain",
        width: "100%",
      },
      // dynamic
      imageHolder: {
        position: "relative",
        width: selected ? "100%" : "90%",
        transition: "width 450ms ease-in-out",
        opacity: selected ? 1 : 0.5,
      },
      root: {
        display: "flex",
        justifyContent: "center",
        height: fullHeight ? "100%" : "auto",
        overflow: "visible !important",
      },
      rootInfinite: {
        display: "flex",
        justifyContent: "center",
        height: fullHeight ? "calc(100% - 91px)" : "auto",
        overflow: "visible !important",
      },
      slide: {
        alignSelf: "center",
        height: fullHeight ? "100%" : "auto",
      },
      container: {
        gap: isMobile ? 10 : 20,
        height: fullHeight ? "100%" : "auto",
      },
      viewport: {
        overflow: "visible",
        width: slideSize,
        height: fullHeight ? "100%" : "auto",
      },
      controls: {
        "[data-inactive]": {
          cursor: "not-allowed",
          opacity: 0.5,
        },
      },
    };
  }
);

import { memo } from "react";

import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { Loader, Text } from "@mantine/core";
import { formatEther, parseEther } from "ethers/lib/utils";

import { useGetUsdPriceRateQuery } from "../queries.graphql.generated";

import { MintVector } from "./MintCard";

const UsdPrice = ({
  mintVector,
  numTokensToMint,
}: {
  mintVector: MintVector;
  numTokensToMint: number;
}) => {
  const { data, loading } = useGetUsdPriceRateQuery({
    variables: {
      chainId: mintVector.chainId || 0,
    },
    fetchPolicy: "network-only",
    skip: !mintVector.chainId,
  });

  if (loading) return <Loader />;

  const usdPriceRate = data?.usdPriceRate;
  if (!usdPriceRate) return <></>;

  const totalPrice = parseEther(mintVector.price)
    .add(parseEther(mintVector.chain?.mintFee || "0"))
    .mul(numTokensToMint || 1);

  const usdPrice =
    +formatEther(totalPrice) * parseFloat(mintVector.ethRate) * usdPriceRate;
  return (
    <Text size="xs" color={TEXT_COLOR.SECONDARY}>
      ≈ ${(+usdPrice).toFixed(2)} USD
    </Text>
  );
};

export default memo(UsdPrice);

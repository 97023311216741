import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { PlusCircle, RightCaret, } from "@hl/base-components/lib/assets/icons.generated";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Box, Button, Group, Menu, Stack, Text, UnstyledButton, } from "@mantine/core";
import { Link } from "react-router-dom";
// import { TOOLS_URL } from "config";
import { _CollectionType } from "../../apollo/graphql.generated";
const isInToolsApp = () => window.location.pathname.indexOf("/tools") > -1;
const CollectionMenuItem = ({ title, description, collectionType, href, }) => {
    const isToolsApp = isInToolsApp();
    const finalLink = href !== null && href !== void 0 ? href : "/collections/create/type/:collectionType".replace(":collectionType", collectionType === _CollectionType.OpenEdition
        ? "Edition"
        : collectionType);
    return (_jsx(Menu.Item, { p: "sm", 
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        component: (isToolsApp ? Link : "a"), 
        // used for "a" component
        href: (isToolsApp ? "" : "/tools") + finalLink, 
        // used for Link component
        to: (isToolsApp ? "" : "/tools") + finalLink, sx: {
            "&:hover": {
                textDecoration: "none",
            },
        }, children: _jsxs(Group, { spacing: "lg", noWrap: true, children: [_jsxs(Stack, { spacing: 8, sx: { flex: 1 }, children: [_jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: title }), _jsx(Text, { size: "xs", children: description })] }), _jsx(Box, { component: "span", miw: 20, mih: 20, children: _jsx(RightCaret, { width: 20, height: 20 }) })] }) }));
};
const CreateCollectionButton = ({ isMobile }) => {
    const isToolsApp = isInToolsApp();
    return (_jsxs(Menu, { width: isMobile ? "100%" : 400, position: "bottom-end", children: [_jsx(Menu.Target, { children: _jsx(UnstyledButton, { children: _jsx(Button, { component: "div", leftIcon: isMobile ? null : _jsx(PlusCircle, {}), sx: { paddingLeft: 12, paddingRight: 12 }, children: "Create" }) }) }), _jsxs(Menu.Dropdown, { children: [_jsx(CollectionMenuItem, { title: "Edition", description: "Open edition, limited edition, or one of one", collectionType: _CollectionType.OpenEdition }), _jsx(CollectionMenuItem, { title: "Generative", description: "Generated by code", collectionType: _CollectionType.GenerativeSeries }), _jsx(CollectionMenuItem, { title: "Series", description: "Unique artwork and metadata for each token", collectionType: _CollectionType.Series }), !isToolsApp && (_jsxs(_Fragment, { children: [_jsx(Menu.Divider, { sx: { marginLeft: 4, marginRight: 4 } }), _jsx(CollectionMenuItem, { title: "Manage collections", description: "View your collection management dashboard", href: "/" })] }))] })] }));
};
export default CreateCollectionButton;

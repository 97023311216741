import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { forwardRef, useEffect, useState } from "react";
import Calendar from "@hl/base-components/lib/assets/icons.generated/Calendar";
import { CLEAR_COLOR } from "@hl/base-components/lib/theme/button";
import { STATUS_COLOR } from "@hl/base-components/lib/theme/colors";
import { Box, Button, createStyles, Image, Title, Text, useMantineTheme, Group, Modal, Flex, Stack, } from "@mantine/core";
import { useLocalStorage, useMediaQuery } from "@mantine/hooks";
import { usePrivy } from "@privy-io/react-auth";
import { STORAGE_KEYS } from "../../config";
import { useAuth } from "../auth";
const useStyles = createStyles({
    modalHeader: {
        position: "absolute",
        right: 16,
        border: "none",
    },
    fullHeight: {
        height: "100%",
    },
    wrapper: {
        height: "100%",
        display: "flex",
    },
    image: {
        maxHeight: "100%",
        width: "unset !important",
        maxWidth: "100%",
        margin: "auto",
    },
});
const EmailUpdates = forwardRef(({ description, name, imageUrl, visualElement, autoOpen = false, ...btnProps }, ref) => {
    const { walletAddress } = useAuth();
    const [emailPrompted, setEmailPrompted] = useLocalStorage({
        key: `${STORAGE_KEYS.emailUpdates}${walletAddress}`,
        getInitialValueInEffect: false,
    });
    const spacing = btnProps.size === "xl" ? 10 : 8;
    const [opened, setOpened] = useState(false);
    const [error, setError] = useState("");
    const theme = useMantineTheme();
    const isSmallAndUp = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px`);
    const { classes } = useStyles();
    const { linkEmail, user } = usePrivy();
    useEffect(() => {
        if (
        // Triggers only if modal hasn't been prompted yet and user hasn't linked their email before.
        autoOpen &&
            !(user === null || user === void 0 ? void 0 : user.email) &&
            !emailPrompted) {
            setOpened(true);
        }
        else {
            setOpened(false);
        }
    }, [autoOpen, emailPrompted, user]);
    if (!walletAddress)
        return null;
    return (_jsxs(_Fragment, { children: [!autoOpen && (_jsx(Button, { ref: ref, ...btnProps, onClick: () => setOpened(true), children: _jsxs(Group, { spacing: spacing, children: [_jsx(Calendar, {}), " Subscribe for notifications"] }) })), _jsx(Modal, { opened: opened, onClose: () => {
                    setOpened(false);
                    setEmailPrompted(true);
                }, zIndex: 1001, size: isSmallAndUp ? 800 : "100%", classNames: { header: classes.modalHeader }, children: _jsxs(Flex, { align: "center", gap: 80, p: isSmallAndUp ? 24 : 4, pt: 24, h: isSmallAndUp ? 480 : "auto", children: [isSmallAndUp && (_jsx(Box, { w: "50%", h: "100%", children: visualElement ? (visualElement) : (_jsx(Image, { src: imageUrl, alt: `Preview image for collection ${name}`, classNames: {
                                    imageWrapper: classes.wrapper,
                                    figure: classes.fullHeight,
                                    root: classes.fullHeight,
                                    image: classes.image,
                                } })) })), _jsxs(Stack, { w: isSmallAndUp ? "50%" : "100%", spacing: 0, children: [_jsx(Title, { size: "x-large", mb: "sm", weight: 500, align: "center", children: "Unlock exclusive updates" }), _jsx(Text, { size: "sm", align: "center", mb: 32, children: description }), _jsxs(Stack, { spacing: 8, children: [_jsx(Button, { size: "xl", w: "100%", onClick: () => {
                                                try {
                                                    setError("");
                                                    linkEmail();
                                                }
                                                catch (err) {
                                                    setError(err instanceof Error
                                                        ? err.message
                                                        : "Please try again later.");
                                                    console.error("Error linking email", err);
                                                }
                                            }, children: "Subscribe" }), _jsx(Button, { size: "xl", color: CLEAR_COLOR, w: "100%", onClick: () => {
                                                setOpened(false);
                                                setEmailPrompted(true);
                                            }, children: "No thanks" })] }), error && (_jsxs(Text, { size: "sm", align: "center", color: STATUS_COLOR.ERROR, children: ["Error submitting email.", _jsx("br", {}), error] }))] })] }) })] }));
});
export default EmailUpdates;

import { useCallback } from "react";
import { usePrivy } from "@privy-io/react-auth";
import { useAuthDispatch, userSignOut } from "../AuthContext";
export const useSignOut = () => {
    const dispatch = useAuthDispatch();
    const { logout } = usePrivy();
    const signOut = useCallback(async () => {
        await logout();
        userSignOut(dispatch);
    }, [dispatch]);
    return signOut;
};

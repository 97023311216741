import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const PublicUserProfileDocument = gql `
  query PublicUserProfile($slug: String!) {
    getPublicAccountSettings(slug: $slug) {
      imported
      id
      displayAvatar
      displayName
      walletAddresses
      verified
    }
  }
`;
/**
 * __usePublicUserProfileQuery__
 *
 * To run a query within a React component, call `usePublicUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicUserProfileQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicUserProfileQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(PublicUserProfileDocument, options);
}
export function usePublicUserProfileLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(PublicUserProfileDocument, options);
}

import { abi as Erc20ABI } from "@hl/shared-features/lib/assets/web3/ERC20.json";
import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import { useContractRead } from "wagmi";

import { MintVector } from "~features/MintPage";

import { CurrencyType } from "../apollo/graphql.generated";

export const useBalanceErc20 = (mintVector?: MintVector) => {
  const { walletAddress } = useAuth();

  const { data, isLoading, error } = useContractRead({
    address: mintVector?.currency as `0x${string}`,
    abi: Erc20ABI,
    functionName: "balanceOf",
    chainId: mintVector?.chainId || 0,
    args: [walletAddress],
    cacheTime: 60000,
    enabled:
      mintVector?.paymentCurrency?.type === CurrencyType.ERC20 &&
      !!mintVector?.currency &&
      !!walletAddress,
  });
  // @ts-ignore
  const userBalance = data as bigint;
  return {
    userBalance,
    loading: isLoading,
    error: error,
  };
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
import { Flex } from "@mantine/core";
import { User } from "../../auth/User";
import { useCollectionForHistoryDropdownQuery } from "../queries.graphql.generated";
const SaleDescription = ({ collectionId, status, fromAddress, toAddress, tokenId, }) => {
    const { data } = useCollectionForHistoryDropdownQuery({
        variables: { collectionId },
        skip: !collectionId,
    });
    const [displayName, setDisplayName] = useState("token");
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.getPublicCollection) {
            const { name } = data.getPublicCollection;
            const tokenText = tokenId ? ` #${tokenId}` : "";
            setDisplayName(`${name}${tokenText}`);
        }
    }, [data === null || data === void 0 ? void 0 : data.getPublicCollection]);
    const fromTo = (_jsxs(Flex, { sx: { whiteSpace: "pre" }, children: ["from ", _jsx(User, { small: true, bold: true, enableLink: true, walletAddresses: [fromAddress] }), " to", " ", _jsx(User, { small: true, bold: true, enableLink: true, walletAddresses: [toAddress] }), "."] }));
    if (status === "PENDING") {
        return (_jsxs(_Fragment, { children: ["Transferring ", displayName, " ", fromTo] }));
    }
    if (status === "MINED") {
        return (_jsxs(_Fragment, { children: ["Transferred ", displayName, " ", fromTo] }));
    }
    if (status === "REJECTED") {
        return (_jsxs(_Fragment, { children: ["Failed to transfer ", displayName, " ", fromTo] }));
    }
    return _jsx(_Fragment, { children: "Unknown transaction status." });
};
export default SaleDescription;

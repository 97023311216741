import { STATUS_COLOR } from "@hl/base-components/lib/theme/colors";
import { Card, Group, useMantineTheme, Text } from "@mantine/core";

import { ReactComponent as ErrorIcon } from "../../../assets/icons/error.svg";

const ErrorBox = ({
  message,
  small = false,
}: {
  message: string;
  small?: boolean;
}) => {
  const theme = useMantineTheme();

  if (small) {
    return (
      <Text size="xs" color={STATUS_COLOR.ERROR}>
        {message}
      </Text>
    );
  }

  return (
    <Card
      py={13}
      px={8}
      sx={(theme) => ({
        border: `1px solid ${theme.fn.themeColor(STATUS_COLOR.ERROR)}`,
        borderRadius: 6,
      })}
    >
      <Group spacing={10} align="center" position="center" noWrap>
        <ErrorIcon
          height={20}
          width={20}
          color={theme.fn.themeColor(STATUS_COLOR.ERROR)}
        />
        <Text size="sm" color={STATUS_COLOR.ERROR}>
          {message}
        </Text>
      </Group>
    </Card>
  );
};

export default ErrorBox;

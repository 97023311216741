export const WEIGHT_BOLD = 500;
export const WEIGHT_NORMAL = 400;
export const TEXT_STYLE_ELLIPSIS = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
};
export const fontFeatureSettings = "'ss02' on, 'ss07' on, 'ss09' on, 'ss11' on, 'cv10' on, 'case' on";
export const labelStyles = (theme) => ({
    color: theme.colors.primaryText[0],
    fontWeight: WEIGHT_BOLD,
    fontFeatureSettings,
});
export const fontSizes = {
    // Font sizes in px, other units are not supported
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 20,
};
// Custom non-Mantine component
export const labelSizes = {
    xs: 12,
    sm: 14,
};
export const headings = {
    fontWeight: WEIGHT_NORMAL,
    fontFamily: "'Aeonik Pro', sans-serif",
    sizes: {
        h1: { fontSize: 60 },
        h2: { fontSize: 50 },
        h3: { fontSize: 41 },
        h4: { fontSize: 35 },
        h5: { fontSize: 29 },
        h6: { fontSize: 24 },
    },
};

import { ColorSchemeMode } from "./ColorSchemeContext";
import { buttonsTheme } from "./button";
import { inputsTheme } from "./input";
import { fontSizes, headings, WEIGHT_BOLD } from "./typography";
export const Z_INDEX = Object.freeze({
    editorToolbar: 1,
    sidebar: 101,
    modalOverlay: 200,
});
// overrides default theme
// https://github.com/mantinedev/mantine/blob/5.10.5/src/mantine-styles/src/theme/default-theme.ts
export const defaultTheme = {
    colorScheme: "light",
    primaryColor: "black",
    primaryShade: 5,
    fontFamily: "'Aeonik Pro', sans-serif",
    lineHeight: 1.4,
    // TODO: customize to match designs
    spacing: {
        xs: 10,
        sm: 12,
        md: 16,
        lg: 20,
        xl: 24,
    },
    fontSizes,
    radius: {
        xs: 2,
        sm: 6,
        md: 8,
        lg: 16,
        xl: 32,
    },
    headings,
    activeStyles: { transform: "scale(0.975)" },
    shadows: {
        xs: "0px 1px 10px -1px rgba(0, 0, 0, 0.08), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
        sm: "0px 2px 20px -2px rgba(0, 0, 0, 0.1), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
        md: "0px 4px 40px -4px rgba(0, 0, 0, 0.14), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
        lg: "0px 8px 80px -8px rgba(0, 0, 0, 0.2), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)",
    },
    dateFormat: "MMMM D, YYYY",
    globalStyles: (theme) => ({
        body: {
            backgroundColor: theme.colors.baseBackground[0],
            color: theme.colors.primaryText[0],
        },
    }),
    components: {
        ...buttonsTheme,
        ...inputsTheme,
        Card: {
            defaultProps: {
                withBorder: true,
                shadow: 0,
            },
            styles: (theme) => ({
                root: {
                    backgroundColor: "inherit",
                    borderColor: theme.colors.divider[0],
                },
            }),
        },
        Header: {
            styles: (theme) => ({
                root: {
                    backgroundColor: theme.colors.baseBackground[0],
                },
            }),
        },
        Navbar: {
            styles: (theme) => ({
                root: {
                    backgroundColor: theme.colors.baseBackground[0],
                    borderRight: `1px solid ${theme.colors.divider[0]}`,
                    zIndex: Z_INDEX.sidebar,
                },
                header: {
                    paddingBottom: theme.spacing.md,
                    marginBottom: theme.spacing.md * 1.5,
                    borderBottom: `1px solid ${theme.colors.divider[0]}`,
                },
                footer: {
                    paddingTop: theme.spacing.md,
                    marginTop: theme.spacing.md,
                    borderTop: `1px solid ${theme.colors.divider[0]}`,
                },
            }),
        },
        AppShell: {
            defaultProps: {
                padding: "lg",
            },
            styles: (theme) => ({
                main: {
                    [theme.fn.smallerThan("sm")]: {
                        paddingLeft: 16,
                        paddingRight: 16,
                    },
                },
            }),
        },
        Anchor: {
            styles: {
                root: {
                    color: "inherit",
                    "&:hover": {
                        textDecorationColor: "inherit",
                    },
                },
            },
        },
        Container: {
            // the size is the content width + the 2 default paddings. e.g. 720 + 16 + 16 = 752px
            defaultProps: {
                sizes: {
                    xs: 540,
                    sm: 752,
                    md: 992,
                    lg: 1216,
                    xl: 1512,
                },
            },
            styles: (theme, { size }) => ({
                root: {
                    ...(size === "xl" && {
                        paddingLeft: "80px",
                        paddingRight: "80px",
                    }),
                    ...(size === "lg" && {
                        paddingLeft: "40px",
                        paddingRight: "40px",
                    }),
                    [theme.fn.smallerThan("sm")]: {
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                },
            }),
        },
        Footer: {
            styles: (theme) => ({
                root: {
                    backgroundColor: theme.colors.baseBackground[0],
                    borderTop: `1px solid ${theme.colors.divider[0]}`,
                },
            }),
        },
        DateTimePicker: {
            styles: {
                calendarBase: {
                    minHeight: 311,
                },
                rightSection: {
                    paddingRight: 16,
                    pointerEvents: "none",
                },
            },
        },
        DatePicker: {
            defaultProps: (theme) => ({
                dayStyle: () => ({
                    color: theme.colors.primaryText[0],
                }),
            }),
        },
        Divider: {
            defaultProps: {
                color: "divider.0",
            },
        },
        Select: {
            defaultProps: (theme) => ({
                rightSection: " ",
                iconWidth: 46,
                rightSectionProps: {
                    style: {
                        pointerEvents: "none",
                        width: "36px",
                        height: "100%",
                        backgroundImage: "url('data:image/svg+xml;utf8," +
                            chevronDownSvg(theme.colors.primaryText[0]) +
                            "')",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    },
                },
            }),
            styles: (theme) => {
                const backgroundColor = theme.colorScheme === ColorSchemeMode.DARK
                    ? theme.colors.primaryBackground[0]
                    : theme.colors.secondaryBackground[0];
                return {
                    rightSection: {
                        "&>svg": {
                            color: theme.colors.primaryText[0],
                        },
                    },
                    icon: {
                        color: theme.colors.primaryText[0],
                        paddingLeft: 16,
                        paddingRight: 10,
                    },
                    item: {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingRight: 4,
                        "&[data-selected]": {
                            backgroundColor,
                            color: theme.colors.primaryText[0],
                            "&:hover, &[data-hovered]": {
                                backgroundColor,
                            },
                            // Becomes an element after the selected item that reacts to the parent flex display
                            "&::after": {
                                content: '""',
                                width: "20px",
                                height: "20px",
                                backgroundImage: 
                                // Hack to get the check icon to show up through CSS only
                                "url('data:image/svg+xml;utf8," +
                                    checkIconSvg(theme.colors.primaryText[0]) +
                                    "')",
                            },
                        },
                        "&:hover, &[data-hovered]": {
                            backgroundColor,
                        },
                    },
                };
            },
        },
        NavLink: {
            styles: (theme, params) => ({
                root: {
                    borderRadius: theme.radius.sm,
                    fontWeight: 500,
                    "&[data-active]": {
                        color: theme.colors.invertedPrimaryText[0],
                        backgroundColor: theme.colors.primaryButton[0],
                    },
                    "&:hover": {
                        backgroundColor: theme.colorScheme === ColorSchemeMode.DARK
                            ? theme.colors.primaryButton[1]
                            : theme.colors.secondaryButton[0],
                    },
                    "&[data-active]:hover": {
                        backgroundColor: theme.colors.primaryButton[0],
                    },
                    color: params.color
                        ? theme.fn.themeColor(params.color)
                        : theme.colors.primaryText[0],
                },
                label: {
                    color: "unset",
                    fontWeight: 500,
                },
            }),
        },
        Tabs: {
            styles: (theme, { variant, color }) => ({
                tabsList: {
                    borderWidth: 1,
                    borderBottomColor: theme.colors.divider[0],
                    backgroundColor: theme.colors.baseBackground[0],
                    gap: 32,
                },
                tab: {
                    paddingRight: 0,
                    paddingLeft: 0,
                    ...(!color && { color: theme.colors.placeholderText[0] }),
                    "&[data-active]": {
                        ...(!color && { color: theme.colors.primaryText[0] }),
                    },
                    "@media (hover: hover)": {
                        "&:hover": {
                            backgroundColor: "unset",
                        },
                        "&[data-active]:hover": {
                            ...(!color && { borderBottomColor: theme.colors.primaryText[0] }),
                        },
                    },
                    display: "flex",
                    ...(variant === "default" && {
                        marginBottom: -1,
                        "&[data-active]": {
                            borderBottomWidth: 2,
                            borderBottomStyle: "solid",
                            ...(!color && { borderBottomColor: theme.colors.primaryText[0] }),
                        },
                    }),
                },
            }),
        },
        Title: {
            styles: {
                root: {
                    overflowWrap: "break-word",
                },
            },
        },
        Text: {
            styles: {
                root: {
                    overflowWrap: "break-word",
                },
            },
        },
        LoadingOverlay: {
            defaultProps: (theme) => ({
                overlayColor: theme.colors.invertedStrongOverlay[0],
                overlayOpacity: 0.75,
            }),
        },
        Modal: {
            defaultProps: {
                centered: true,
                overlayOpacity: 0.6,
                withCloseButton: true,
                shadow: "lg",
                overflow: "inside",
            },
            styles: (theme, params) => ({
                header: {
                    padding: `0 ${theme.spacing.sm}px ${theme.spacing.sm}px ${theme.spacing.md}px`,
                    borderBottom: `1px solid ${theme.colors.divider[0]}`,
                    marginRight: `-${theme.spacing.md}px`,
                    marginLeft: `-${theme.spacing.md}px`,
                    "& .mantine-Modal-Close": {
                        backgroundColor: "transparent",
                        color: "inherit",
                    },
                },
                title: {
                    fontSize: 18,
                    fontWeight: 500,
                },
                modal: {
                    // There is a precedence issue in Mantine V5 where modals default 'padding' value always override this custom one.
                    // The '!important' here fixes it, but will make custom modal paddings more difficult (would need to tweak modal contents).
                    padding: `${theme.spacing.sm}px ${theme.spacing.md}px ${theme.spacing.md}px ${theme.spacing.md}px !important`,
                    backgroundColor: theme.colors.primaryBackground[0],
                    width: (params.fullScreen
                        ? "100%"
                        : ModalSizes[params.size]) ||
                        params.size,
                },
                body: {
                    backgroundColor: theme.colors.primaryBackground[0],
                    overflowX: "hidden",
                    padding: `0 ${theme.spacing.md}px`,
                    marginRight: `-${theme.spacing.md}px`,
                    marginLeft: `-${theme.spacing.md}px`,
                },
            }),
        },
        Menu: {
            defaultProps: (theme) => ({
                shadow: "sm",
                offset: 4,
                color: theme.colors.primaryText[0],
            }),
            styles: (theme) => ({
                itemIcon: {
                    marginRight: "8px",
                    color: "inherit",
                },
                dropdown: {
                    backgroundColor: theme.colors.primaryBackground[0],
                    padding: "4px",
                    border: "none",
                    borderRadius: "10px",
                },
                item: {
                    borderRadius: theme.radius.sm,
                    fontWeight: 500,
                },
            }),
        },
        Dropzone: {
            styles: (theme) => ({
                root: {
                    borderWidth: "1px",
                    borderColor: theme.colors.divider[0],
                    borderStyle: "solid",
                    backgroundColor: theme.colors.outlineButton[0],
                    "&:hover": {
                        backgroundColor: theme.colors.outlineButton[1],
                    },
                },
            }),
        },
        Loader: {
            defaultProps: {
                color: "secondaryText.0",
                size: 20,
            },
        },
        Pagination: {
            styles: (theme) => ({
                item: {
                    "&[data-active]": {
                        backgroundColor: theme.colors.secondaryBackground[0],
                        color: theme.colors.primaryText[0],
                    },
                    backgroundColor: theme.colors.baseBackground[0],
                    borderStyle: "none",
                    [theme.fn.smallerThan(theme.breakpoints.sm - 1)]: {
                        "&[tabindex]": {
                            display: "none",
                        },
                        "&>svg": {
                            height: "32px",
                            width: "32px",
                        },
                    },
                },
            }),
        },
        Switch: {
            defaultProps: {
                size: "sm",
            },
            styles: (theme) => ({
                thumb: {
                    backgroundColor: theme.colors.baseBackground[0],
                    "input:checked+*>&": {
                        left: "calc(100% - 18px)",
                    },
                    borderWidth: 0,
                    width: 16,
                    height: 16,
                },
                track: {
                    width: 36,
                    minWidth: 36,
                    cursor: "pointer",
                    borderColor: theme.colors.tertiaryBackground[0],
                    backgroundColor: theme.colors.tertiaryBackground[0],
                    "input:checked+&": {
                        borderColor: theme.colors.primaryButton[0],
                        backgroundColor: theme.colors.primaryButton[0],
                    },
                },
                label: {
                    cursor: "pointer",
                },
            }),
        },
        Table: {
            styles: (theme) => ({
                root: {
                    "tbody tr td": {
                        borderTop: `1px solid ${theme.colors.divider[0]}`,
                        padding: "16px",
                    },
                    "thead tr th": {
                        borderBottom: `1px solid ${theme.colors.divider[0]}`,
                        padding: "16px",
                    },
                    "&[data-striped] tbody tr:nth-of-type(odd)": {
                        backgroundColor: theme.colors.tableRowBackground[0],
                    },
                },
            }),
        },
        Tooltip: {
            defaultProps: {
                withArrow: true,
                arrowSize: 8,
            },
        },
        Radio: {
            styles: (theme) => ({
                radio: {
                    cursor: "pointer",
                    "&:checked": {
                        backgroundColor: theme.colors.divider[0],
                        border: `1.66667px solid ${theme.colors.primaryText[0]}`,
                    },
                    "&:not(checked)": {
                        border: `1.66667px solid ${theme.colors.divider[0]}`,
                    },
                },
                icon: {
                    color: theme.colors.primaryText[0],
                },
            }),
        },
        Checkbox: {
            defaultProps: {
                radius: "xl",
            },
            styles: (theme) => ({
                label: {
                    cursor: "pointer",
                },
                input: {
                    cursor: "pointer",
                    "&:checked": {
                        backgroundColor: theme.colors.divider[0],
                        border: `1.66667px solid ${theme.colors.primaryText[0]}`,
                    },
                    "&:not(checked)": {
                        border: `1.66667px solid ${theme.colors.divider[0]}`,
                    },
                    "&:checked+.mantine-Checkbox-icon": {
                        color: theme.colors.primaryText[0],
                    },
                },
            }),
        },
        Prism: {
            styles: (theme) => ({
                root: {
                    border: `1px solid ${theme.colors.divider[0]}`,
                    borderRadius: theme.radius.sm,
                    "& .mantine-Tooltip-tooltip": {
                        backgroundColor: theme.colors.invertedBaseBackground[0],
                        color: theme.colors.invertedPrimaryText[0],
                    },
                },
                lineNumber: { color: "rgba(144, 164, 174, 0.3)" },
            }),
        },
        SegmentedControl: {
            styles: (theme) => ({
                root: {
                    backgroundColor: "inherit",
                    "& .mantine-SegmentedControl-active": {
                        boxShadow: "none",
                    },
                },
                control: {
                    paddingTop: 2,
                    paddingBottom: 2,
                },
                controlActive: {
                    backgroundColor: theme.colors.secondaryButton[0],
                },
            }),
        },
        Progress: {
            defaultProps: {
                size: 6,
            },
        },
        Chip: {
            defaultProps: {
                variant: "filled",
            },
            styles: (theme) => ({
                label: {
                    fontSize: theme.fontSizes.xs,
                    fontWeight: WEIGHT_BOLD,
                    borderRadius: theme.radius.sm,
                    "&:hover[data-variant=filled]": {
                        backgroundColor: theme.colors.secondaryButton[1],
                    },
                    "&[data-checked]": {
                        color: theme.colors.invertedPrimaryText[0],
                        "&:hover[data-variant=filled]": {
                            backgroundColor: theme.colors.primaryButton[1],
                        },
                    },
                },
                iconWrapper: {
                    display: "none",
                },
            }),
        },
    },
};
const ModalSizes = {
    sm: 400,
    md: 600,
    lg: 600,
    xl: 1000,
};
const checkIconSvg = (color) => `<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3334 4L6.00008 11.3333L2.66675 8" stroke="${color}" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
const chevronDownSvg = (color) => `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 6L8 10L12 6" stroke="${color}" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/></svg>`;

import ERC1155 from "@hl/shared-features/lib/assets/web3/ERC1155.json";
import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import { useContractRead } from "wagmi";

import { MintVector } from "~features/MintPage";

export const useBalance1155 = (
  enableBurnAndRedeem?: boolean,
  mintVector?: MintVector
) => {
  const { walletAddress } = useAuth();
  const burnRedeem = mintVector?.customProjectData?.burnRedeem;
  const address = burnRedeem?.burn1155Address as `0x${string}`;

  const { data, isLoading, error } = useContractRead({
    address,
    abi: ERC1155,
    functionName: "balanceOf",
    chainId: burnRedeem?.crosschain?.burnChainId || mintVector?.chainId || 0,
    args: [walletAddress, burnRedeem?.burnTokenId || 1],
    cacheTime: 60000,
    enabled:
      enableBurnAndRedeem &&
      !!mintVector?.chainId &&
      !!walletAddress &&
      !!address,
  });

  // @ts-ignore
  const userBurnBalance1155 = data as bigint;
  const hasEnoughToBurnAndRedeem = burnRedeem?.numToBurnPerToken
    ? userBurnBalance1155 >= burnRedeem?.numToBurnPerToken
    : false;
  return {
    userBurnBalance1155,
    hasEnoughToBurnAndRedeem,
    loading: isLoading,
    error: error,
  };
};

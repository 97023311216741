import { SwitchVertical01, SwitchVertical02, } from "@hl/base-components/lib/assets/icons.generated/HDS/Duotone icons/Arrows";
import { Cryptocurrency02 } from "@hl/base-components/lib/assets/icons.generated/HDS/Duotone icons/Finance & eCommerce";
import { Hourglass01 } from "@hl/base-components/lib/assets/icons.generated/HDS/Duotone icons/Time";
import { SortDirection, TokenSortOptions } from "../apollo/graphql.generated";
export const sortingOptionsDefault = [
    {
        value: "date_desc",
        label: "Newest first",
        sortBy: TokenSortOptions.DATE_CREATED,
        sortDirection: SortDirection.DESC,
        icon: Cryptocurrency02,
    },
    {
        value: "date_asc",
        label: "Oldest first",
        sortBy: TokenSortOptions.DATE_CREATED,
        sortDirection: SortDirection.ASC,
        icon: Hourglass01,
    },
];
export const sortingOptionsWithMarketplace = [
    {
        value: "price_asc",
        label: "Price low to high",
        sortBy: TokenSortOptions.PRICE,
        sortDirection: SortDirection.ASC,
        icon: SwitchVertical02,
    },
    {
        value: "price_desc",
        label: "Price high to low",
        sortBy: TokenSortOptions.PRICE,
        sortDirection: SortDirection.DESC,
        icon: SwitchVertical01,
    },
    ...sortingOptionsDefault,
];

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Text } from "@mantine/core";
import { useCollectionForHistoryDropdownQuery } from "../queries.graphql.generated";
const ApproveTransferDescription = ({ collectionId, status }) => {
    const { data } = useCollectionForHistoryDropdownQuery({
        variables: { collectionId },
        skip: !collectionId,
    });
    const [displayName, setDisplayName] = useState("token");
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.getPublicCollection) {
            const { name } = data.getPublicCollection;
            setDisplayName(`${name}`);
        }
    }, [data === null || data === void 0 ? void 0 : data.getPublicCollection]);
    const formatted = (_jsx(Text, { size: "sm", weight: WEIGHT_BOLD, children: displayName }));
    if (status === "PENDING") {
        return _jsxs(_Fragment, { children: ["Approving transfer of tokens for ", formatted] });
    }
    if (status === "MINED") {
        return _jsxs(_Fragment, { children: ["Approved transfer of tokens for ", formatted] });
    }
    if (status === "REJECTED") {
        return _jsxs(_Fragment, { children: ["Failed to approve transfer of tokens for ", formatted] });
    }
    return _jsx(_Fragment, { children: "Unknown transaction status." });
};
export default ApproveTransferDescription;

import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useCallback } from "react";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { NumberInput, Text } from "@mantine/core";
import { parseEther } from "viem";
import { getCurrencySymbol } from "../../utils/currency";
const SAFE_FLOAT_ROUNDING_PRECISION = 6;
const CryptoCurrencyInput = ({ chainId, missingChainIdPlaceholder, customCurrencySymbol, currencySymbol, onChange, ...props }) => {
    const hanleChange = useCallback((val) => {
        if (!val) {
            return;
        }
        try {
            // make sure it can be parsed
            parseEther(val.toString());
        }
        catch (err) {
            return;
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(val);
    }, [onChange]);
    if (!chainId) {
        return (_jsx(NumberInput, { ...props, onChange: hanleChange, inputMode: "decimal", disabled: true, placeholder: missingChainIdPlaceholder }));
    }
    return (_jsx(NumberInput, { ...props, onChange: hanleChange, inputMode: "decimal", precision: SAFE_FLOAT_ROUNDING_PRECISION, rightSection: _jsx(Text, { size: "sm", color: TEXT_COLOR.SECONDARY, children: customCurrencySymbol ||
                getCurrencySymbol(chainId, currencySymbol, false) }), rightSectionWidth: "unset", min: 0, step: 0, removeTrailingZeros: true }));
};
export default CryptoCurrencyInput;

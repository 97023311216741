import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useBootstrap, useEmbedBootstrap } from "./hooks";
export const AppBootstrap = ({ children }) => {
    useBootstrap();
    return _jsx(_Fragment, { children: children });
};
export const EmbedAppBootstrap = ({ children }) => {
    useEmbedBootstrap();
    return _jsx(_Fragment, { children: children });
};

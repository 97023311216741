import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { UTILITY_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Box, Card, Center, Divider, Flex, Group, Loader, Radio, ScrollArea, Stack, Text, } from "@mantine/core";
import { useUserTokens } from "@reservoir0x/reservoir-kit-ui";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth } from "../../auth/AuthContext";
import { ResizedImage } from "../../image";
const SelectToken = ({ address, selectToken, tokenId, chainId, }) => {
    const { walletAddress } = useAuth();
    const { data: tokens, hasNextPage, fetchNextPage, isLoading, } = useUserTokens(walletAddress !== null && walletAddress !== void 0 ? walletAddress : undefined, {
        contract: address,
        limit: 10,
    }, undefined, chainId);
    return (_jsxs(Stack, { children: [_jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: "Your tokens" }), _jsx(Card, { px: 16, py: 12, children: _jsx(ScrollArea.Autosize, { maxHeight: 375, offsetScrollbars: true, children: _jsx(InfiniteScroll, { scrollThreshold: -20, dataLength: tokens.length, next: () => hasNextPage && fetchNextPage(), hasMore: hasNextPage, loader: _jsx(Loading, {}), children: tokens.length ? (tokens === null || tokens === void 0 ? void 0 : tokens.map((token, index) => {
                            var _a, _b, _c, _d;
                            return (_jsxs(Box, { children: [_jsxs(Group, { noWrap: true, children: [_jsx(Box, { p: 8, bg: UTILITY_COLOR.TABLE_ROW_BACKGROUND, children: _jsx(ResizedImage, { src: (_a = token.token) === null || _a === void 0 ? void 0 : _a.imageSmall, width: 40 }) }), _jsx(Flex, { justify: "space-between", align: "center", sx: { flexGrow: 1 }, children: _jsx(Radio, { labelPosition: "left", w: "100%", label: _jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: `#${(_b = token.token) === null || _b === void 0 ? void 0 : _b.tokenId}` }), styles: {
                                                        body: { width: "100%" },
                                                        labelWrapper: { width: "100%" },
                                                    }, checked: ((_c = token.token) === null || _c === void 0 ? void 0 : _c.tokenId) === tokenId, onChange: (_) => {
                                                        var _a, _b, _c;
                                                        return selectToken({
                                                            tokenId: (_b = (_a = token.token) === null || _a === void 0 ? void 0 : _a.tokenId) !== null && _b !== void 0 ? _b : "",
                                                            imageSrc: (_c = token.token) === null || _c === void 0 ? void 0 : _c.imageSmall,
                                                        });
                                                    } }) })] }), index !== tokens.length - 1 && _jsx(Divider, { my: "sm" })] }, (_d = token.token) === null || _d === void 0 ? void 0 : _d.tokenId));
                        })) : (_jsx(Text, { size: "sm", align: "center", fw: WEIGHT_BOLD, children: isLoading ? _jsx(Loading, {}) : "No tokens could be fetched" })) }) }) })] }));
};
const Loading = () => (_jsx(Center, { p: "lg", w: "100%", children: _jsx(Loader, {}) }));
export default SelectToken;

import { ReactNode } from "react";

import MultilineText from "@hl/base-components/lib/MultilineText";
import {
  BACKGROUND_COLOR,
  TEXT_COLOR,
} from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Video } from "@hl/base-components/lib/video";
import { ResizedImage } from "@hl/shared-features/lib/features/image";
import { Props1155 } from "@hl/shared-features/lib/features/marketplace/components/BidBuyButtons";
import {
  MarketplaceToken,
  TokenV2,
  TokenV3,
} from "@hl/shared-features/lib/features/marketplace/utils";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import {
  Badge,
  Box,
  Center,
  createStyles,
  Flex,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";

import { getDetailPageUrl } from "~config";
import { InternalLink } from "~features/MintPage/components/InternalLink";
import useMintState, { MintStateRequired } from "~hooks/useMintState";
import useMintTokensState from "~hooks/useMintTokensState";

import { NftContractStandard } from "../../apollo/graphql.generated";

import MarketplaceTokenButton from "./MarketplaceTokenButton";

// card size * number of columns
const IMAGE_FIT_SIZE = 284 * 4;

const useTokenCardStyles = createStyles((theme) => ({
  mediaItem: {
    flex: "1 1",
    border: `20px solid ${theme.colors.tableRowBackground[0]}`,
    aspectRatio: "1",
    background: theme.colors.tableRowBackground[0],
  },
  video: {
    display: "block",
    objectFit: "contain",
    width: "100%",
  },
  link: {
    textDecoration: "none",
  },
}));

export const TokenCardCollectorsChoice = ({
  token,
  onCompleted = () => null,
  imagePlaceholder,
  onImageError,
}: {
  token: TokenV3;
  onCompleted?: () => void;
  imagePlaceholder?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onImageError?: (object: any) => void;
}) => {
  return (
    <Box pos="relative">
      <TokenCardBase
        token={{ ...token, ...props1155 }}
        onCompleted={onCompleted}
        imagePlaceholder={imagePlaceholder}
        onImageError={onImageError}
        isCollectorsChoice
      />
    </Box>
  );
};

type InternalLinkProps = React.ComponentProps<typeof InternalLink>;

const TokenCardBase = ({
  token,
  onCompleted = () => null,
  imagePlaceholder,
  onImageError,
  isCollectorsChoice,
}: {
  token: MarketplaceToken & Props1155;
  onCompleted?: () => void;
  imagePlaceholder?: ReactNode;
  isCollectorsChoice?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onImageError?: (object: any) => void;
}) => {
  const { cx, classes } = useTokenCardStyles();
  const {
    isCodeGenMint,
    isSeriesMint,
    isCollectorChoiceMint,
    collection,
    isImported,
  } = useMintState() as MintStateRequired;
  const { popModal } = useModalStack();

  const InternalLinkProxy = ({ children, ...props }: InternalLinkProps) => {
    if (isCollectorsChoice && !token.minted) {
      return <>{children}</>;
    }
    return <InternalLink {...props}>{children}</InternalLink>;
  };

  const { cols } = useMintTokensState();

  return (
    <>
      {(isCodeGenMint || (isSeriesMint && !isCollectorChoiceMint)) &&
      !token.imageUrl ? (
        <PlaceholderMetadataPreview isCodeGenMint={isCodeGenMint} />
      ) : (
        <InternalLinkProxy
          className={classes.link}
          to={getDetailPageUrl(collection, token.tokenId)}
          onClick={popModal}
        >
          {token.animationUrl && !isCodeGenMint && !isImported ? (
            <Video
              className={cx([classes.video, classes.mediaItem])}
              autoPlay
              loop
              muted
              controls={false}
              playsInline
            >
              <source src={token.animationUrl} />
            </Video>
          ) : (
            <ResizedImage
              className={classes.mediaItem}
              src={token.imageUrl}
              fit={token.imageUrl.includes(".svg") ? "fill" : "contain"}
              aspectRatio="1"
              fitSize={IMAGE_FIT_SIZE / cols}
              placeholder={imagePlaceholder}
              withPlaceholder={!!imagePlaceholder}
              onError={onImageError}
            />
          )}
        </InternalLinkProxy>
      )}
      <Flex
        mt={16}
        justify="space-between"
        align="center"
        columnGap={8}
        rowGap={4}
        wrap="nowrap"
      >
        <Stack spacing={6}>
          <InternalLinkProxy
            style={{ textDecoration: "none" }}
            to={getDetailPageUrl(collection, token.tokenId)}
            onClick={popModal}
          >
            <MultilineText size="sm" weight={WEIGHT_BOLD} numLines="2">
              {token.name}
            </MultilineText>
          </InternalLinkProxy>
        </Stack>
        <MarketplaceTokenButton token={token} onCompleted={onCompleted} />
      </Flex>
    </>
  );
};

const props1155 = {
  balance: null,
  userBalance: null,
};

const TokenCard = ({
  token,
  onCompleted = () => null,
  imagePlaceholder,
  onImageError,
}: {
  token: TokenV2;
  onCompleted?: () => void;
  imagePlaceholder?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onImageError?: (object: any) => void;
}) => {
  const { collection, totalSoldCount } = useMintState() as MintStateRequired;

  return (
    <Box pos="relative">
      {collection.standard === NftContractStandard.ERC1155 && (
        <Flex
          gap="xs"
          px={30}
          justify="space-between"
          sx={{
            position: "absolute",
            top: 32,
            width: "100%",
            zIndex: 1,
          }}
        >
          <TokenCardBadge
            text={`${totalSoldCount} tokens`}
            empty={!totalSoldCount}
          />
          <TokenCardBadge
            text={`${token?.userBalance} owned`}
            empty={!token.userBalance}
          />
        </Flex>
      )}
      <TokenCardBase
        token={token}
        onCompleted={onCompleted}
        imagePlaceholder={imagePlaceholder}
        onImageError={onImageError}
      />
    </Box>
  );
};

const PlaceholderMetadataPreview = ({
  isCodeGenMint,
}: {
  isCodeGenMint: boolean;
}) => (
  <Center
    sx={(theme) => ({
      padding: 20,
      minHeight: 220,
      aspectRatio: "1",
      height: "auto",
      border: `0.5px solid ${theme.colors.divider[0]}`,
    })}
  >
    <Stack spacing={8} align="center">
      <Text size="sm" fw={WEIGHT_BOLD} align="center">
        {isCodeGenMint ? "Capturing metadata..." : "Processing metadata..."}
      </Text>
      <Text size="xs" color={TEXT_COLOR.SECONDARY} align="center">
        Metadata for this token will be available soon
      </Text>
    </Stack>
  </Center>
);

const TokenCardBadge = ({ text, empty }: { text: string; empty: boolean }) => {
  const theme = useMantineTheme();

  if (empty) {
    return <Box />;
  }
  return (
    <Badge
      color={BACKGROUND_COLOR.QUATERNARY}
      size="xs"
      styles={{
        root: {
          borderRadius: "999px",
          height: "24px",
          padding: "4px 4px",
          backgroundColor: theme.colors.baseBackground[0],
          outline: `1px solid ${theme.colors.divider[0]}`,
        },
      }}
    >
      <Text size="xs" tt="lowercase">
        {text}
      </Text>
    </Badge>
  );
};

export default TokenCard;

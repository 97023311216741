import { useCallback, useEffect, useState } from "react";

import AlertBox, { AlertBoxType } from "@hl/base-components/lib/alert/AlertBox";
import { LIFECYCLE_ERROR_CODES } from "@hl/shared-features/lib/features/evm-tx";
import {
  TransactionStateType,
  useTransactionsDispatch,
  useTransactionState,
} from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { useModalStack } from "@hl/shared-features/lib/features/modal";
import { Button } from "@mantine/core";

import { UserStatus } from "./utils/types";

const INSUFFICIENT_FUNDS_MESSAGE = "Insufficient funds";
const GENERIC_ERROR_MESSAGE = "There was an error, please try again later";
const CONFIRM_WALLET_MESSAGE = "Please confirm in your wallet";
const MAX_TOKENS_MINTED_MESSAGE =
  "You've minted the max number of tokens allowed";

interface UseLifecycleWarningsButtonHandlerProps {
  txnId: string;
  hasEnoughMoney: boolean;
  userStatus?: UserStatus;
  showWarningModals: boolean;
  onClick(chainId: number, isRetry?: boolean): void;
}

const useLifecycleWarningsButtonHandler = ({
  txnId,
  hasEnoughMoney,
  userStatus,
  showWarningModals,
  onClick,
}: UseLifecycleWarningsButtonHandlerProps) => {
  const transactionState = useTransactionState(txnId);
  const transactionDispatch = useTransactionsDispatch();
  const { pushModal, popModal } = useModalStack();
  // Guarantee only the clicked component spawns a modal, in case many mint buttons exist simultaneously and modal
  // is triggered in an effect.
  const [clickedMintButton, setClickedMintButton] = useState(false);

  const onClickHandler = useCallback(
    (chainId: number, isRetry?: boolean) => {
      if (showWarningModals) {
        if (userStatus === UserStatus.LIMIT_REACHED) {
          pushModalMessage(MAX_TOKENS_MINTED_MESSAGE, "success");
          return;
        }

        if (!hasEnoughMoney) {
          pushModalMessage(INSUFFICIENT_FUNDS_MESSAGE);
          return;
        }

        setClickedMintButton(true);
      }

      if (isRetry) {
        if (transactionState)
          transactionDispatch?.({
            type: "UPDATE_TX_STATE",
            payload: {
              id: txnId,
              type: TransactionStateType.WaitingSignedTx,
            },
          });
      } else {
        onClick(chainId);
      }
    },
    [hasEnoughMoney, transactionState, onClick, showWarningModals]
  );

  const pushModalMessage = (message: string, type?: AlertBoxType) => {
    pushModal(
      <AlertBox
        type={type}
        title={message}
        footer={
          <Button
            miw={180}
            onClick={() => {
              popModal();
              setClickedMintButton(false);
            }}
          >
            OK
          </Button>
        }
      />,
      {
        withCloseButton: false,
      }
    );
  };

  useEffect(() => {
    if (!clickedMintButton || !showWarningModals) {
      return;
    }

    if (transactionState?.error && transactionState.error.cause) {
      if (
        transactionState.error.cause.code ===
        LIFECYCLE_ERROR_CODES.USER_DENIED_TX
      ) {
        pushModalMessage(CONFIRM_WALLET_MESSAGE);
      } else {
        pushModalMessage(GENERIC_ERROR_MESSAGE);
      }
    }
  }, [transactionState, showWarningModals]);

  return {
    onClickHandler,
  };
};

export default useLifecycleWarningsButtonHandler;

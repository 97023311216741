import { getDetailPageUrl, getMintPageUrl } from "~config";

const isTokenIdValid = (id: number) => !isNaN(id) && id > 0;

const getFullTokenTitle = (collectionName?: string, tokenTitle?: string) => {
  if (!tokenTitle) {
    return collectionName;
  }
  if (!collectionName || tokenTitle.startsWith(collectionName)) {
    return tokenTitle;
  }
  return `${collectionName} ${tokenTitle}`;
};

const getTokenTitle = (
  collectionName: string,
  tokenName: string,
  tokenId?: string | null
) =>
  // The token name is a number (e.g. 1 or #1)
  tokenName.match(/^(#?\d+)$/)
    ? `${collectionName} ${tokenName}`
    : // Token id exists, and token name is the same as the collection name (Open and Limited editions)
    tokenId && collectionName === tokenName
    ? `${collectionName} #${tokenId}`
    : `${tokenName}`;

export const buildTokenNavigationUrl = (
  collection: { id: string; onchainId: string },
  tokenId?: string | null
) =>
  tokenId ? getDetailPageUrl(collection, tokenId) : getMintPageUrl(collection);

export { isTokenIdValid, getFullTokenTitle, getTokenTitle };

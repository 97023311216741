import { InfoCircleFill } from "@hl/base-components/lib/assets/icons.generated";
import ModalSection from "@hl/base-components/lib/modal/ModalSection";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import CryptoCurrencyInput from "@hl/shared-features/lib/features/input/CryptoCurrencyInput";
import {
  Anchor,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { EligibleRebate, MintState } from "~hooks/useMintPage";
import useMintState, { MintStateRequired } from "~hooks/useMintState";
import useRebateCollector from "~hooks/useRebateCollector";

import ErrorBox from "../MintVector/ErrorBox";

const RebateCollectorModal = ({
  eligibleRebate,
  isOpened,
  onClose,
}: {
  eligibleRebate: EligibleRebate | null;
  isOpened: boolean;
  onClose: () => void;
}) => {
  const { collection, refetch } = useMintState() as MintStateRequired;

  const { startRebate, buttonLabel, buttonDisabled, error } =
    useRebateCollector({
      mintVectorId: eligibleRebate?.vectorId ?? "",
      collectionId: collection.id,
      collectionType: collection.collectionType,
      refetch,
      onFinish: onClose,
    });

  const rebateAmount = eligibleRebate?.rebateAmount
    ? +eligibleRebate.rebateAmount
    : 0;

  return (
    <Modal opened={isOpened} onClose={onClose} size="sm" title="Auction rebate">
      <Stack>
        <CryptoCurrencyInput
          label={
            <Group spacing={4}>
              <Text component="label" size="sm">
                Rebate amount
              </Text>
              <Tooltip
                closeDelay={0}
                sx={{
                  pointerEvents: "all",
                }}
                label={
                  <Stack spacing="sm" p="sm">
                    <Text size="xs" color={TEXT_COLOR.INVERTED_PRIMARY}>
                      Any paid amount above the final price can be claimed as a
                      rebate
                    </Text>
                    <Anchor
                      hidden={true}
                      size="xs"
                      href={""}
                      target="_blank"
                      underline={false}
                    >
                      <Text
                        size="xs"
                        color={TEXT_COLOR.INVERTED_PRIMARY}
                        weight={400}
                      >
                        Learn more →
                      </Text>
                    </Anchor>
                  </Stack>
                }
              >
                <InfoCircleFill width={14} height={14} cursor="pointer" />
              </Tooltip>
            </Group>
          }
          missingChainIdPlaceholder=""
          chainId={collection.chainId}
          disabled
          value={rebateAmount}
        />
      </Stack>
      <ModalSection>
        <Stack>
          <Button
            type="submit"
            size="xl"
            fullWidth
            disabled={buttonDisabled || rebateAmount === 0}
            onClick={startRebate}
          >
            {buttonLabel}
          </Button>
          {error && <ErrorBox message={error} />}
        </Stack>
      </ModalSection>
    </Modal>
  );
};

export const RebateCollectorButton = ({
  eligibleRebate,
}: Pick<MintState, "eligibleRebate">) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button mt={8} size="xl" fullWidth color={SECONDARY_COLOR} onClick={open}>
        Claim rebate
      </Button>
      {opened && (
        <RebateCollectorModal
          eligibleRebate={eligibleRebate}
          isOpened={opened}
          onClose={close}
        />
      )}
    </>
  );
};

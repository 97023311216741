import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import ScheduledPricesList from "@hl/shared-features/lib/features/mint/ScheduledPricesList";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import { reactTimeAgoStyle } from "@hl/shared-features/lib/utils/format";
import { MantineNumberSize, Space, Text } from "@mantine/core";
import ReactTimeAgo from "react-time-ago";

import useMintState from "~hooks/useMintState";

import { PriceType } from "../../../apollo/graphql.generated";
import { SaleStatus } from "../utils/types";

import { MintVector } from "./MintCard";

const ScheduledPrices = ({
  priceType,
  priceDrops,
  priceDropInterval,
  onTimesUp,
  saleStatus,
  mt,
}: {
  priceType?: MintVector["priceType"];
  priceDrops?: MintVector["priceDrops"];
  priceDropInterval?: MintVector["priceDropInterval"];
  onTimesUp?: () => void;
  saleStatus: SaleStatus;
  mt?: MantineNumberSize;
}) => {
  const isFixedPrice = !priceType || priceType === PriceType.Fixed;
  if (isFixedPrice) return <></>;
  const isLinearContinuous =
    !!priceDrops && !!priceDrops.length && priceDrops.length > 10;
  const difference = isLinearContinuous
    ? Number(priceDrops[0].price) - Number(priceDrops[1].price)
    : 0;
  const activePriceDrop = priceDrops?.filter((drop) => drop.active).pop();
  const endTime = activePriceDrop?.end;
  const lastActiveIndex =
    priceDrops?.findIndex((drop) => drop === activePriceDrop) ?? -1;
  const showCountdown =
    endTime &&
    priceDrops &&
    lastActiveIndex >= 0 &&
    lastActiveIndex + 1 < priceDrops.length;
  const restingPriceReached =
    isLinearContinuous &&
    !!priceDrops &&
    priceDrops[priceDrops.length - 1].active;

  return (
    <>
      {mt && <Space h={mt} />}
      {!isLinearContinuous && <ScheduledPricesList priceDrops={priceDrops} />}
      {SaleStatus.NOT_STARTED === saleStatus && (
        <PriceDropNotStarted
          difference={difference}
          isLinear={isLinearContinuous}
          priceDropInterval={priceDropInterval}
        />
      )}
      {SaleStatus.NOT_STARTED !== saleStatus && restingPriceReached && (
        <Text size="sm" color={TEXT_COLOR.SECONDARY}>
          Resting price reached
        </Text>
      )}
      {SaleStatus.NOT_STARTED !== saleStatus && showCountdown && (
        <Text size="sm" color={TEXT_COLOR.SECONDARY}>
          Price drops{" "}
          {isLinearContinuous && (
            <LinearPriceDrop
              difference={difference}
              onTimesUp={onTimesUp}
              end={endTime}
            />
          )}
          {!isLinearContinuous && (
            <RegularPriceDropActive endTime={endTime} onTimesUp={onTimesUp} />
          )}
        </Text>
      )}
    </>
  );
};

type LinearPriceDropProps = {
  difference: number;
  onTimesUp?: () => void;
  end?: string;
};

const LinearPriceDrop = ({
  difference,
  onTimesUp,
  end = "",
}: LinearPriceDropProps) => {
  const { mintVector } = useMintState();

  return (
    <>
      by {difference.toFixed(4)} {getCurrencySymbol(mintVector?.chainId || "1")}{" "}
      in{" "}
      <ReactTimeAgo
        date={Date.parse(end)}
        timeStyle={reactTimeAgoStyle(onTimesUp)}
        future={true}
        locale="en-US"
      />
    </>
  );
};

type PriceDropNotStartedProps = {
  difference: number;
  isLinear: boolean;
  priceDropInterval?: MintVector["priceDropInterval"];
};

const PriceDropNotStarted = ({
  difference,
  isLinear,
  priceDropInterval,
}: PriceDropNotStartedProps) => {
  const { mintVector } = useMintState();

  return (
    <Text size="sm" color={TEXT_COLOR.SECONDARY}>
      Price drops
      {isLinear &&
        ` by ${difference.toFixed(4)} ${getCurrencySymbol(
          mintVector?.chainId || "1"
        )}`}{" "}
      every{" "}
      {priceDropInterval
        ?.filter((period) => period.value > 0)
        .map(
          (period) => `${period.value}${period.unit.charAt(0).toLowerCase()}`
        )
        .join(" ")}
    </Text>
  );
};

type RegularPriceDropActiveProps = {
  endTime?: string;
  onTimesUp?: () => void;
};
const RegularPriceDropActive = ({
  onTimesUp,
  endTime,
}: RegularPriceDropActiveProps) => {
  return (
    <>
      in{" "}
      <ReactTimeAgo
        date={Date.parse(endTime || "")}
        future={true}
        timeStyle={reactTimeAgoStyle(onTimesUp, 500)}
        locale="en-US"
      />
    </>
  );
};

export default ScheduledPrices;

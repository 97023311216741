import { useCallback } from "react";

import { TransactionType } from "@hl/shared-features/lib/apollo/graphql.generated";
import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import {
  TransactionState,
  TransactionStateType,
} from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { useOnChainTransaction } from "@hl/shared-features/lib/hooks/useOnChainTransaction";

import { MintVector } from "~features/MintPage";
import { useGetApproveNftBurnTxArgsLazyQuery } from "~features/MintPage/queries.graphql.generated";

import { _CollectionType } from "../apollo/graphql.generated";

export const useApprove1155 = (
  collectionId: string,
  collectionType: _CollectionType,
  mintVector: MintVector,
  onMintClick: () => void
) => {
  const { walletAddress } = useAuth();
  const [generateTxArgs, { loading: loadingArgs, error: errorArgs }] =
    useGetApproveNftBurnTxArgsLazyQuery();
  const burnRedeem = mintVector?.customProjectData?.burnRedeem;

  const { error, startTransaction, buttonLabel, buttonLoading, isDone } =
    useOnChainTransaction({
      collectionType,
      entityId: collectionId,
      onFinish: (state: TransactionState) => {
        if (state.type === TransactionStateType.Done) {
          setTimeout(() => onMintClick(), 1000);
        }
      },
      refetchQueries: [],
      transactionType: TransactionType.EVM_CURRENCY_ALLOWANCE,
      actionLabel: "Approve tokens",
    });

  const approve = useCallback(async () => {
    if (!mintVector?.chainId) throw new Error("mintVector is required");
    const chainId =
      mintVector.customProjectData?.burnRedeem?.crosschain?.burnChainId ||
      mintVector.chainId;
    const metadata = {
      chainId: chainId.toString(),
      currency: mintVector.currency,
    };
    const result = await generateTxArgs({
      variables: {
        chainId,
        contractAddress: burnRedeem?.burn1155Address || "",
      },
    });
    const args = result?.data?.approveNFTBurnTxArgs;
    return startTransaction({ args, metadata });
  }, [generateTxArgs, collectionId, mintVector, walletAddress]);

  return {
    error: error || (errorArgs ? "Error preparing transaction" : ""),
    approve,
    buttonLabel: buttonLabel,
    buttonLoading: buttonLoading || loadingArgs,
    isDone,
  };
};

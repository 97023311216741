import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import React from "react";
export const SKELETON_TEXT_HEIGHT = 20;
export const SKELETON_INPUT_HEIGHT = 40;
export const SkeletonList = ({ count, skeleton, }) => {
    const list = [];
    for (let i = 0; i < count; i++) {
        list.push(_jsx(React.Fragment, { children: skeleton }, i));
    }
    return _jsx(_Fragment, { children: list });
};

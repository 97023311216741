import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { CLEAR_COLOR } from "@hl/base-components/lib/theme/button";
import { ActionIcon, CopyButton as MantineCopyButton, Tooltip, } from "@mantine/core";
import CopyIcon from "../../assets/icons.generated/Copy";
const CopyButton = ({ copyValue, preventDefault, Icon }) => (_jsx(MantineCopyButton, { value: copyValue, children: ({ copied, copy }) => (_jsx(Tooltip, { label: copied ? "Copied!" : "Copy", withArrow: true, children: _jsx(ActionIcon, { color: CLEAR_COLOR, size: 16, onClick: (e) => {
                copy();
                if (preventDefault) {
                    e.preventDefault();
                }
            }, children: Icon !== null && Icon !== void 0 ? Icon : _jsx(CopyIcon, {}) }) })) }));
export default memo(CopyButton);

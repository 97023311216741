import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import {
  Box,
  createStyles,
  DefaultProps,
  Flex,
  Selectors,
  Space,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { formatEther, parseEther } from "ethers/lib/utils";

import useMintState, { MintStateRequired } from "~hooks/useMintState";

import { ConsumerMintVectorType } from "../../../apollo/graphql.generated";
import { useEmbedFlags } from "../embed/customize";
import { SaleStatus } from "../utils/types";

import MintFee from "./MintFee";
import NumMintsInput from "./NumMintsInput";
import ScheduledPrices from "./ScheduledPrices";
import UsdPrice from "./UsdPrice";

type StatsStylesParams = {
  smallSize?: boolean;
  isMobile?: boolean;
};
const useStatsStyle = createStyles(
  (theme, { smallSize, isMobile }: StatsStylesParams) => ({
    value: {
      fontSize: smallSize && !isMobile ? 18 : 29,
      letterSpacing: "-0.05em",
    },
  })
);

type StatsStylesNames = Selectors<typeof useStatsStyle>;

export interface MintCardStatsProps
  extends DefaultProps<StatsStylesNames, StatsStylesParams> {
  smallSize?: boolean;
  onTimesUp?: () => void;
  showUsdPrice?: boolean;
  hideNumInput?: boolean;
}

const CardStats = ({
  smallSize = false,
  styles,
  onTimesUp,
  showUsdPrice = false,
  hideNumInput = false,
}: MintCardStatsProps) => {
  const {
    mintVector,
    numTokensToMint,
    saleStatus,
    price,
    isCollectorChoiceMint,
    isFreeMint,
  } = useMintState() as MintStateRequired;
  const currency = getCurrencySymbol(
    mintVector?.chainId || "",
    mintVector.paymentCurrency?.symbol
  );
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
  const mintFee =
    mintVector.consumerData?.type === ConsumerMintVectorType.HL_GASLESS ||
    mintVector.sponsored
      ? undefined
      : mintVector?.chain?.mintFee;
  const { classes } = useStatsStyle(
    { smallSize, isMobile },
    { name: "CardStats", styles }
  );
  const flags = useEmbedFlags();

  if (flags.strabismusTemplate && SaleStatus.NOT_STARTED) {
    return null;
  }

  if (
    [
      SaleStatus.ACTIVE_WITH_END,
      SaleStatus.ACTIVE_WITH_NO_END,
      SaleStatus.NOT_STARTED,
    ].includes(saleStatus)
  )
    return (
      <>
        <Flex
          direction="row"
          justify="space-between"
          columnGap={16}
          align="center"
        >
          <Flex
            columnGap={0}
            align={smallSize && !isMobile ? "flex-end" : "flex-start"}
            wrap="wrap"
            direction="column"
          >
            {!isFreeMint && (
              <Box>
                <Text span fw={WEIGHT_BOLD} className={classes.value}>
                  {formatEther(parseEther(price).mul(numTokensToMint || 1))}{" "}
                </Text>
                <Text span weight={500} size={smallSize && !isMobile ? 14 : 16}>
                  {currency}
                </Text>
              </Box>
            )}
            {mintFee && (
              <MintFee numTokensToMint={numTokensToMint} small={smallSize} />
            )}
            {showUsdPrice && (
              <UsdPrice
                mintVector={mintVector}
                numTokensToMint={numTokensToMint}
              />
            )}
          </Flex>
          {!hideNumInput &&
            !isCollectorChoiceMint &&
            (mintVector.sponsored ? (
              <Text size="xs">Gas and mint fee sponsored</Text>
            ) : (
              <NumMintsInput
                size={smallSize ? "xs" : "sm"}
                height={smallSize ? 40 : undefined}
              />
            ))}
        </Flex>
        {!smallSize && (
          <>
            <ScheduledPrices
              priceType={mintVector.priceType}
              priceDrops={mintVector.priceDrops}
              priceDropInterval={mintVector.priceDropInterval}
              onTimesUp={onTimesUp}
              saleStatus={saleStatus}
              mt="xs"
            />
            <Space h="sm" />
          </>
        )}
      </>
    );
  return null;
};

export default CardStats;

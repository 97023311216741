import ERC1155ABI from "@hl/shared-features/lib/assets/web3/ERC1155.json";
import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import { useContractRead } from "wagmi";

import { MintVector } from "~features/MintPage";
import { useGetCheckBurnNftAllowanceTxArgsQuery } from "~features/MintPage/queries.graphql.generated";

export const useAllowance1155 = (
  enableBurnAndRedeem?: boolean,
  mintVector?: MintVector
) => {
  const { walletAddress } = useAuth();
  const contractAddress =
    mintVector?.customProjectData?.burnRedeem?.burn1155Address || "";
  const chainId =
    mintVector?.customProjectData?.burnRedeem?.crosschain?.burnChainId ||
    mintVector?.chainId ||
    0;

  const {
    data: argsData,
    loading: loadingArgs,
    error: errorArgs,
  } = useGetCheckBurnNftAllowanceTxArgsQuery({
    variables: {
      chainId,
      contractAddress,
    },
    skip: !enableBurnAndRedeem || !mintVector?.chainId || !walletAddress,
  });

  const args = argsData?.checkBurnNFTAllowanceTxArgs;

  const { data, isLoading, error } = useContractRead({
    address: args?.address as `0x${string}`,
    abi: ERC1155ABI,
    chainId,
    functionName: args?.method || "",
    args: JSON.parse(args?.args || "[]"),
    enabled: !!args?.address && !errorArgs,
  });

  return {
    userAllowed1155: data as boolean,
    loading: isLoading || loadingArgs,
    error: errorArgs || error,
  };
};

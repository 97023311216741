import { createContext, useContext } from "react";
export var TransactionStateType;
(function (TransactionStateType) {
    TransactionStateType["WaitingWeb2Update"] = "WAITING_WEB2_UPDATE";
    TransactionStateType["WaitingPrepareTx"] = "WAITING_PREPARE_TX";
    TransactionStateType["WaitingSignedTx"] = "WAITING_SIGNED_TX";
    TransactionStateType["WaitingConfirmedTx"] = "WAITING_CONFIRMED_TX";
    TransactionStateType["Done"] = "DONE";
    TransactionStateType["Web2UpdateError"] = "WEB2_UPDATE_ERROR";
    TransactionStateType["ChainSwitchError"] = "CHAIN_SWITCH_ERROR";
    TransactionStateType["SignTxRejected"] = "SIGN_TX_REJECTED";
    TransactionStateType["ConfirmedTxError"] = "CONFIRMED_TX_ERROR";
    TransactionStateType["Web2TxCreateError"] = "WEB2_TX_CREATE_ERROR";
    TransactionStateType["Web2TxUpdateError"] = "WEB2_TX_UPDATE_ERROR";
})(TransactionStateType || (TransactionStateType = {}));
export const pendingStates = [
    TransactionStateType.WaitingWeb2Update,
    TransactionStateType.WaitingPrepareTx,
    TransactionStateType.WaitingSignedTx,
    TransactionStateType.WaitingConfirmedTx,
];
export const TransactionContext = createContext(null);
export const TransactionDispatchContext = createContext(null);
export const useTransactions = () => {
    return useContext(TransactionContext);
};
export const useTransactionState = (id) => {
    const transactions = useContext(TransactionContext);
    return transactions === null || transactions === void 0 ? void 0 : transactions[id];
};
export const useTransactionsDispatch = () => {
    return useContext(TransactionDispatchContext);
};
export const transactionReducer = (transactions, action) => {
    var _a;
    switch (action.type) {
        case "START_TRANSACTION":
            const id = action.payload.id;
            transactions[id] = {
                ...action.payload,
                type: action.payload.args && action.payload.metadata
                    ? TransactionStateType.WaitingPrepareTx
                    : TransactionStateType.WaitingWeb2Update,
            };
            return { ...transactions };
        case "UPDATE_TX_ARGS":
            const transaction = transactions[action.payload.id];
            transactions[action.payload.id] = {
                ...transaction,
                args: action.payload.args,
                metadata: action.payload.metadata,
                contractType: action.payload.contractType,
                relayerTaskId: action.payload.relayerTaskId,
                type: TransactionStateType.WaitingPrepareTx,
            };
            return { ...transactions };
        case "UPDATE_TX_STATE":
            const signTx = transactions[action.payload.id];
            transactions[action.payload.id] = {
                ...signTx,
                type: action.payload.type,
                receipt: (_a = signTx.receipt) !== null && _a !== void 0 ? _a : action.payload.receipt,
                redeemReceipt: action.payload.redeemReceipt,
            };
            return { ...transactions };
        case "UPDATE_TX_STATUS":
            const txStatus = transactions[action.payload.id];
            transactions[action.payload.id] = {
                ...txStatus,
                type: action.payload.hash
                    ? TransactionStateType.WaitingConfirmedTx
                    : TransactionStateType.SignTxRejected,
                ...action.payload,
            };
            return { ...transactions };
        case "CHAIN_SWITCH_ERROR":
            transactions[action.payload.id] = {
                ...transactions[action.payload.id],
                type: TransactionStateType.ChainSwitchError,
                ...action.payload,
            };
            return { ...transactions };
        case "UPDATE_WEB2_TX_STATUS":
            const web2TxStatus = transactions[action.payload.id];
            transactions[action.payload.id] = {
                ...web2TxStatus,
                createdEvmTx: web2TxStatus.createdEvmTx || action.payload.createdEvmTx,
                updatedEvmTx: web2TxStatus.updatedEvmTx || action.payload.updatedEvmTx,
            };
            return { ...transactions };
        case "UPDATE_RELAYER_TASK_ID":
            transactions[action.payload.id] = {
                ...transactions[action.payload.id],
                relayerTaskId: action.payload.relayerTaskId,
            };
            return { ...transactions };
        default:
            throw Error("Unknown action");
    }
};

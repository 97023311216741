import { abi as MintManagerABI } from "../../assets/web3/MintManager.json";
import { abi as ReferralManagerABI } from "../../assets/web3/ReferralManager.json";
export var LIFECYCLE_ERROR_CODES;
(function (LIFECYCLE_ERROR_CODES) {
    LIFECYCLE_ERROR_CODES[LIFECYCLE_ERROR_CODES["USER_DENIED_TX"] = 4001] = "USER_DENIED_TX";
})(LIFECYCLE_ERROR_CODES || (LIFECYCLE_ERROR_CODES = {}));
export const getMintingAbiFromMethodName = (methodName) => {
    return [
        "vectorMint721WithReferral",
        "gatedNumMint721WithReferral",
        "gatedChooseMint721WithReferral",
    ].includes(methodName || "")
        ? ReferralManagerABI
        : MintManagerABI;
};

import { useEffect, useRef } from "react";
import { useAuth } from "../AuthContext";
export const useAuthChangedCallback = (callback) => {
    const { loading: authLoading, authenticated } = useAuth();
    // trigger callback when authenticated state changes
    const authRef = useRef(null);
    useEffect(() => {
        if (authLoading) {
            return;
        }
        if (authRef.current === null) {
            authRef.current = authenticated;
            return;
        }
        else if (authRef.current === authenticated) {
            return;
        }
        authRef.current = authenticated;
        callback(authenticated);
    }, [authLoading, authenticated, callback]);
};

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const EmitAnalyticsEventDocument = gql `
  mutation EmitAnalyticsEvent($event: AnalyticsInput!) {
    emitAnalyticsEvent(event: $event) {
      ok
    }
  }
`;
/**
 * __useEmitAnalyticsEventMutation__
 *
 * To run a mutation, you first call `useEmitAnalyticsEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmitAnalyticsEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emitAnalyticsEventMutation, { data, loading, error }] = useEmitAnalyticsEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useEmitAnalyticsEventMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(EmitAnalyticsEventDocument, options);
}

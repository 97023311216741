import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useEffect, useRef } from "react";
import { Box } from "@mantine/core";
import { useIntersection } from "@mantine/hooks";
export default function SimpleIntersectionObserver(props) {
    var _a;
    const containerRef = useRef();
    const { ref, entry } = useIntersection({
        root: containerRef.current,
        threshold: (_a = props.rootMargin) !== null && _a !== void 0 ? _a : 1,
    });
    useEffect(() => {
        if (entry === null || entry === void 0 ? void 0 : entry.isIntersecting) {
            props.onIntersection(entry);
        }
    }, [entry]);
    return _jsx(Box, { ref: ref, children: props.children });
}

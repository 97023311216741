import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo, useEffect } from "react";
import { _CollectionType, } from "../../apollo/graphql.generated";
import { abi as GenerativeSeriesABI } from "../../assets/web3/GenerativeSeries.json";
import { abi as MultipleEditionsDFSABI } from "../../assets/web3/MultipleEditionsDFS.json";
import { abi as SeriesABI } from "../../assets/web3/Series.json";
import { abi as SingleEditionDFSABI } from "../../assets/web3/SingleEditionDFS.json";
import { TransactionStateType, useTransactionsDispatch, } from "./TransactionContext";
const TransactionDeployExecution = ({ id, type, collectionType, walletClient, address, args, }) => {
    const dispatch = useTransactionsDispatch();
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    let abi;
    if (collectionType === _CollectionType.LimitedEdition ||
        collectionType === _CollectionType.OneOfOne) {
        abi = MultipleEditionsDFSABI;
    }
    else if (collectionType === _CollectionType.OpenEdition) {
        abi = SingleEditionDFSABI;
    }
    else if (collectionType === _CollectionType.Series) {
        abi = SeriesABI;
    }
    else if (collectionType === _CollectionType.GenerativeSeries) {
        abi = GenerativeSeriesABI;
    }
    useEffect(() => {
        if (dispatch && type === TransactionStateType.WaitingPrepareTx) {
            walletClient
                .deployContract({
                account: address,
                chain: walletClient.chain,
                abi,
                args: JSON.parse(args.args),
                bytecode: args.bytecode,
                gas: args.gasLimit ? BigInt(args.gasLimit) : undefined,
            })
                .then((hash) => {
                dispatch({
                    type: "UPDATE_TX_STATUS",
                    payload: {
                        id,
                        hash,
                    },
                });
            })
                .catch((error) => {
                console.log("TRANSACTION CANCELLED");
                dispatch({
                    type: "UPDATE_TX_STATUS",
                    payload: {
                        id,
                        error,
                    },
                });
            });
            dispatch({
                type: "UPDATE_TX_STATE",
                payload: {
                    id,
                    type: TransactionStateType.WaitingSignedTx,
                },
            });
        }
    }, [type]);
    return _jsx(_Fragment, {});
};
export default memo(TransactionDeployExecution);

import * as Types from "../../../apollo/graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type GetRankedSponsorsByMintVectorQueryVariables = Types.Exact<{
  mintVectorId: Types.Scalars["String"];
  page: Types.Scalars["Float"];
  limit: Types.Scalars["Float"];
}>;

export type GetRankedSponsorsByMintVectorQuery = {
  getRankedSponsorsByMintVector: {
    __typename: "RankedSponsors";
    sponsors: Array<{
      __typename: "Sponsor";
      ranking: number | null;
      sponsorUserAddress: string;
      totalNumSponsored: number;
      user: {
        __typename: "PublicAccountSettings";
        id: string | null;
        accountId: string;
        walletAddresses: Array<string> | null;
        displayName: string | null;
        avatarUrl: string | null;
        ensNames: Array<string> | null;
      } | null;
    }>;
    pageInfo: {
      __typename: "PagePaginatedInfo";
      page: number;
      totalCount: number;
    };
  };
};

export type GetSponsoRankingByAmountQueryVariables = Types.Exact<{
  mintVectorId: Types.Scalars["String"];
  amount: Types.Scalars["Float"];
}>;

export type GetSponsoRankingByAmountQuery = {
  getSponsoRankingByAmount: number;
};

export type GetSponsorMintTxArgsQueryVariables = Types.Exact<{
  sponsorVectorId: Types.Scalars["String"];
  numToMint: Types.Scalars["Float"];
}>;

export type GetSponsorMintTxArgsQuery = {
  sponsorMintTxArgs: {
    __typename: "TransactionArgs";
    chainId: number;
    address: string | null;
    args: string;
    method: string | null;
    value: string | null;
    bytecode: string | null;
    gasLimit: string | null;
  };
};

export type GetMintSponsorQueryVariables = Types.Exact<{
  sponsoredVectorId: Types.Scalars["String"];
  mintedOrderId: Types.Scalars["String"];
}>;

export type GetMintSponsorQuery = {
  getMintSponsor: {
    __typename: "Sponsor";
    sponsorUserAddress: string;
    user: {
      __typename: "PublicAccountSettings";
      id: string | null;
      accountId: string;
      walletAddresses: Array<string> | null;
      displayName: string | null;
      ensNames: Array<string> | null;
    } | null;
  };
};

export const GetRankedSponsorsByMintVectorDocument = gql`
  query GetRankedSponsorsByMintVector(
    $mintVectorId: String!
    $page: Float!
    $limit: Float!
  ) {
    getRankedSponsorsByMintVector(
      mintVectorId: $mintVectorId
      page: $page
      limit: $limit
    ) {
      sponsors {
        ranking
        sponsorUserAddress
        totalNumSponsored
        user {
          id
          accountId
          walletAddresses
          displayName
          avatarUrl
          ensNames
        }
      }
      pageInfo {
        page
        totalCount
      }
    }
  }
`;

/**
 * __useGetRankedSponsorsByMintVectorQuery__
 *
 * To run a query within a React component, call `useGetRankedSponsorsByMintVectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRankedSponsorsByMintVectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRankedSponsorsByMintVectorQuery({
 *   variables: {
 *      mintVectorId: // value for 'mintVectorId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetRankedSponsorsByMintVectorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRankedSponsorsByMintVectorQuery,
    GetRankedSponsorsByMintVectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRankedSponsorsByMintVectorQuery,
    GetRankedSponsorsByMintVectorQueryVariables
  >(GetRankedSponsorsByMintVectorDocument, options);
}
export function useGetRankedSponsorsByMintVectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRankedSponsorsByMintVectorQuery,
    GetRankedSponsorsByMintVectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRankedSponsorsByMintVectorQuery,
    GetRankedSponsorsByMintVectorQueryVariables
  >(GetRankedSponsorsByMintVectorDocument, options);
}
export type GetRankedSponsorsByMintVectorQueryHookResult = ReturnType<
  typeof useGetRankedSponsorsByMintVectorQuery
>;
export type GetRankedSponsorsByMintVectorLazyQueryHookResult = ReturnType<
  typeof useGetRankedSponsorsByMintVectorLazyQuery
>;
export type GetRankedSponsorsByMintVectorQueryResult = Apollo.QueryResult<
  GetRankedSponsorsByMintVectorQuery,
  GetRankedSponsorsByMintVectorQueryVariables
>;
export const GetSponsoRankingByAmountDocument = gql`
  query GetSponsoRankingByAmount($mintVectorId: String!, $amount: Float!) {
    getSponsoRankingByAmount(mintVectorId: $mintVectorId, amount: $amount)
  }
`;

/**
 * __useGetSponsoRankingByAmountQuery__
 *
 * To run a query within a React component, call `useGetSponsoRankingByAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSponsoRankingByAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSponsoRankingByAmountQuery({
 *   variables: {
 *      mintVectorId: // value for 'mintVectorId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useGetSponsoRankingByAmountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSponsoRankingByAmountQuery,
    GetSponsoRankingByAmountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSponsoRankingByAmountQuery,
    GetSponsoRankingByAmountQueryVariables
  >(GetSponsoRankingByAmountDocument, options);
}
export function useGetSponsoRankingByAmountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSponsoRankingByAmountQuery,
    GetSponsoRankingByAmountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSponsoRankingByAmountQuery,
    GetSponsoRankingByAmountQueryVariables
  >(GetSponsoRankingByAmountDocument, options);
}
export type GetSponsoRankingByAmountQueryHookResult = ReturnType<
  typeof useGetSponsoRankingByAmountQuery
>;
export type GetSponsoRankingByAmountLazyQueryHookResult = ReturnType<
  typeof useGetSponsoRankingByAmountLazyQuery
>;
export type GetSponsoRankingByAmountQueryResult = Apollo.QueryResult<
  GetSponsoRankingByAmountQuery,
  GetSponsoRankingByAmountQueryVariables
>;
export const GetSponsorMintTxArgsDocument = gql`
  query GetSponsorMintTxArgs($sponsorVectorId: String!, $numToMint: Float!) {
    sponsorMintTxArgs(
      sponsorVectorId: $sponsorVectorId
      numToMint: $numToMint
    ) {
      chainId
      address
      args
      method
      value
      bytecode
      gasLimit
    }
  }
`;

/**
 * __useGetSponsorMintTxArgsQuery__
 *
 * To run a query within a React component, call `useGetSponsorMintTxArgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSponsorMintTxArgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSponsorMintTxArgsQuery({
 *   variables: {
 *      sponsorVectorId: // value for 'sponsorVectorId'
 *      numToMint: // value for 'numToMint'
 *   },
 * });
 */
export function useGetSponsorMintTxArgsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSponsorMintTxArgsQuery,
    GetSponsorMintTxArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSponsorMintTxArgsQuery,
    GetSponsorMintTxArgsQueryVariables
  >(GetSponsorMintTxArgsDocument, options);
}
export function useGetSponsorMintTxArgsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSponsorMintTxArgsQuery,
    GetSponsorMintTxArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSponsorMintTxArgsQuery,
    GetSponsorMintTxArgsQueryVariables
  >(GetSponsorMintTxArgsDocument, options);
}
export type GetSponsorMintTxArgsQueryHookResult = ReturnType<
  typeof useGetSponsorMintTxArgsQuery
>;
export type GetSponsorMintTxArgsLazyQueryHookResult = ReturnType<
  typeof useGetSponsorMintTxArgsLazyQuery
>;
export type GetSponsorMintTxArgsQueryResult = Apollo.QueryResult<
  GetSponsorMintTxArgsQuery,
  GetSponsorMintTxArgsQueryVariables
>;
export const GetMintSponsorDocument = gql`
  query GetMintSponsor($sponsoredVectorId: String!, $mintedOrderId: String!) {
    getMintSponsor(
      sponsoredVectorId: $sponsoredVectorId
      mintedOrderId: $mintedOrderId
    ) {
      sponsorUserAddress
      user {
        id
        accountId
        walletAddresses
        displayName
        ensNames
      }
    }
  }
`;

/**
 * __useGetMintSponsorQuery__
 *
 * To run a query within a React component, call `useGetMintSponsorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMintSponsorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMintSponsorQuery({
 *   variables: {
 *      sponsoredVectorId: // value for 'sponsoredVectorId'
 *      mintedOrderId: // value for 'mintedOrderId'
 *   },
 * });
 */
export function useGetMintSponsorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMintSponsorQuery,
    GetMintSponsorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMintSponsorQuery, GetMintSponsorQueryVariables>(
    GetMintSponsorDocument,
    options
  );
}
export function useGetMintSponsorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMintSponsorQuery,
    GetMintSponsorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMintSponsorQuery, GetMintSponsorQueryVariables>(
    GetMintSponsorDocument,
    options
  );
}
export type GetMintSponsorQueryHookResult = ReturnType<
  typeof useGetMintSponsorQuery
>;
export type GetMintSponsorLazyQueryHookResult = ReturnType<
  typeof useGetMintSponsorLazyQuery
>;
export type GetMintSponsorQueryResult = Apollo.QueryResult<
  GetMintSponsorQuery,
  GetMintSponsorQueryVariables
>;

import { Badge, createStyles } from "@mantine/core";

const useBadgeStyles = createStyles((theme) => ({
  redCircle: {
    display: "inline-block",
    width: 8,
    height: 8,
    borderRadius: 8,
    backgroundColor: theme.colors.errorStatus[0],
    marginRight: 8,
  },
}));

const MintedBadge = () => {
  const { classes } = useBadgeStyles();
  return (
    <Badge
      color="primaryBackground.0"
      size="lg"
      styles={(theme) => ({
        root: {
          position: "absolute",
          top: 8,
          right: 8,
          fontSize: 12,
          fontWeight: 500,
          backgroundColor: theme.colors.primaryBackground[0],
          color: theme.colors.primaryText[0],
          textTransform: "none",
          border: `1px solid ${theme.colors.divider[0]}`,
        },
      })}
    >
      <span className={classes.redCircle} />
      Minted
    </Badge>
  );
};

export default MintedBadge;

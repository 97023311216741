import { InfoCircleFill } from "@hl/base-components/lib/assets/icons.generated";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { EXTERNAL_URL } from "@hl/shared-features/lib/config";
import { Anchor, Box, Popover, Text } from "@mantine/core";

export const RebatesTooltip = ({
  notAvailable,
}: {
  notAvailable?: boolean;
}) => (
  <Popover width={300} withArrow shadow="md">
    <Popover.Target>
      <InfoCircleFill width={12} height={12} style={{ flexShrink: 0 }} />
    </Popover.Target>
    <Popover.Dropdown bg="strongOverlay.0">
      <Text size="xs" color={TEXT_COLOR.INVERTED_PRIMARY}>
        <Box mb={12}>
          {notAvailable
            ? "Rebates are only available for public Dutch auctions"
            : "Any amount paid above the final price can be claimed as a rebate."}
        </Box>
        <Anchor
          size="xs"
          weight={500}
          href={EXTERNAL_URL.AUCTIONS}
          target="_blank"
          color={TEXT_COLOR.INVERTED_PRIMARY}
        >
          Learn more →
        </Anchor>
      </Text>
    </Popover.Dropdown>
  </Popover>
);

import { useCallback, useState } from "react";

import { Embla, useAnimationOffsetEffect } from "@mantine/carousel";
import {
  Center,
  Flex,
  Loader,
  UnstyledButton,
  Text,
  Stack,
} from "@mantine/core";
import { useHotkeys } from "@mantine/hooks";

import { ReactComponent as ArrowRightIcon } from "~assets/icons/arrow-right.svg";
import { InfiniteCarousel } from "~components/carousel";

import { SeriesToken } from "../../../apollo/graphql.generated";

const ArrowLeftIcon = () => (
  <ArrowRightIcon style={{ transform: "rotate(180deg)" }} />
);

export const SHLOMS_404_FALLBACK_IMG_URL =
  "https://highlight-creator-assets-prod.s3.amazonaws.com/main/image/404.png";
export const SHLOMS_404_FALLBACK_IMG = (
  <Center>
    <Loader />
  </Center>
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const shloms404OnImageError = (object: any) => {
  object.target.onerror = null;
  object.target.src = SHLOMS_404_FALLBACK_IMG_URL;
  object.target.srcset = SHLOMS_404_FALLBACK_IMG_URL;
};

type Shloms404CarouselProps = {
  selectedTokenIndex: number;
  setSelectedTokenIndex: React.Dispatch<React.SetStateAction<number>>;
  tokens: SeriesToken[];
  nextCursor: string | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchMore: any;
  loading: boolean;
};

const Shloms404Carousel = ({
  selectedTokenIndex,
  setSelectedTokenIndex,
  tokens,
  nextCursor,
  fetchMore,
  loading,
}: Shloms404CarouselProps) => {
  const [emblaApi, setEmbla] = useState<Embla | null>(null);
  useAnimationOffsetEffect(emblaApi, 200);

  const handleImageSelected = useCallback(
    (index: number) => {
      setSelectedTokenIndex(index);
    },
    [setSelectedTokenIndex, tokens]
  );

  const loadMore = useCallback(
    () =>
      fetchMore({
        variables: {
          cursor: nextCursor,
        },
      }),
    [nextCursor]
  );

  if (!tokens.length) {
    return (
      <Center h={520}>
        <Loader />
      </Center>
    );
  }

  const disabledSx = {
    opacity: 0.3,
    cursor: "not-allowed",
  };

  return (
    <>
      <InfiniteCarousel
        isLoading={loading}
        tokens={tokens}
        getEmblaApi={setEmbla}
        onFetchMore={loadMore}
        hasMoreToLoad={!!nextCursor}
        onSlideChange={handleImageSelected}
        onImageError={shloms404OnImageError}
      />

      <Stack spacing={8} align="center">
        <Flex w="100%" maw={440} gap="md" mt="xl">
          <UnstyledButton
            onClick={() => emblaApi?.scrollPrev()}
            p="sm"
            sx={selectedTokenIndex ? undefined : disabledSx}
          >
            <ArrowLeftIcon />
          </UnstyledButton>
          <Center w="100%">
            <Text size={25} weight={500} align="center">
              {tokens[selectedTokenIndex]?.name}
            </Text>
          </Center>
          <UnstyledButton
            onClick={() => emblaApi?.scrollNext()}
            p="sm"
            sx={
              selectedTokenIndex !== tokens.length - 1 ? undefined : disabledSx
            }
          >
            <ArrowRightIcon />
          </UnstyledButton>
        </Flex>
      </Stack>
      <CarouselHotkeys emblaApi={emblaApi} />
    </>
  );
};

const CarouselHotkeys = ({ emblaApi }: { emblaApi: Embla | null }) => {
  useHotkeys([
    ["ArrowLeft", () => emblaApi?.scrollPrev()],
    ["ArrowRight", () => emblaApi?.scrollNext()],
  ]);

  return null;
};

export default Shloms404Carousel;

import { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import { chainIdNetworkMap } from "../../utils/opensea";
export const DEFAULT_MAX_EDITION_SIZE = 10000;
export const MAX_BLOCK_NUMBER = 100000000;
export const MIN_GAS_PRICE = 10;
export const MAX_GAS_PRICE = 200;
export const MIN_GAS_USED = 10;
export const MAX_GAS_USED = 100;
export const MAX_MINT_BATCH_SIZE = 1000;
function randNumber(min = 1, max = 10000) {
    if (min > max) {
        throw new Error("Invalid range: min should be less than or equal to max.");
    }
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
function randomHash(len = 64) {
    let result = "";
    const hexChars = "0123456789abcdef";
    for (let i = 0; i < len; i++) {
        result += hexChars[Math.floor(Math.random() * hexChars.length)];
    }
    return "0x" + result;
}
export const generator = {
    randTxHash() {
        return randomHash();
    },
    randWalletAddress() {
        return randomHash(40);
    },
    randTokenId(max) {
        return randNumber(1, max || DEFAULT_MAX_EDITION_SIZE);
    },
    randBlockHash() {
        return randomHash();
    },
    randTimestamp() {
        const now = dayjs();
        const nowUnix = dayjs().unix();
        const futureDateUnix = now.add(2, "year").unix();
        return Math.floor(Math.random() * (futureDateUnix - nowUnix) + nowUnix);
    },
    randEditionSize(max) {
        return randNumber(1, max || DEFAULT_MAX_EDITION_SIZE);
    },
    randAddress() {
        return randomHash(40);
    },
    randBlockNumber() {
        return randNumber(1, MAX_BLOCK_NUMBER);
    },
    randChainId() {
        const keys = Object.keys(chainIdNetworkMap);
        return +keys[Math.floor(Math.random() * keys.length)];
    },
    randGasPrice() {
        return randNumber(MIN_GAS_PRICE, MAX_GAS_PRICE);
    },
    randGasUsed() {
        return randNumber(MIN_GAS_USED, MAX_GAS_USED);
    },
    randMintSize(max) {
        return randNumber(1, Math.min(max || Number.MAX_VALUE, MAX_MINT_BATCH_SIZE));
    },
    randMintIteration(max) {
        return randNumber(1, Math.min(max || Number.MAX_VALUE, MAX_MINT_BATCH_SIZE));
    },
};
const randListItem = (items) => {
    return items[Math.floor(Math.random() * items.length)];
};
export const useGenParams = ({ allowedHashes, isCurated: isCurateParam, size, fixEditionSize, fixedChainId, fixedContractAddress, } = {}) => {
    var _a;
    const limitHashes = ((_a = allowedHashes === null || allowedHashes === void 0 ? void 0 : allowedHashes.length) !== null && _a !== void 0 ? _a : 0) !== 0;
    const maxEditionSize = size || (allowedHashes === null || allowedHashes === void 0 ? void 0 : allowedHashes.length);
    const nextTxHash = useCallback((currentHash) => {
        var _a;
        if (!limitHashes) {
            return generator.randTxHash();
        }
        let newHash;
        do {
            newHash = randListItem(allowedHashes !== null && allowedHashes !== void 0 ? allowedHashes : []);
        } while (currentHash &&
            ((_a = allowedHashes === null || allowedHashes === void 0 ? void 0 : allowedHashes.length) !== null && _a !== void 0 ? _a : 0) > 1 &&
            // ensure that new value is different
            currentHash === newHash);
        return newHash;
    }, [limitHashes, allowedHashes]);
    const isCurated = isCurateParam || limitHashes;
    const [loadVer, setLoadVer] = useState(1);
    const [txHash, setTxHash] = useState(nextTxHash());
    const [timestamp, setTimestamp] = useState(generator.randTimestamp());
    const [walletAddress, setMintingWallet] = useState(generator.randWalletAddress());
    const [editionSize, setEditionSize] = useState(fixEditionSize
        ? maxEditionSize || DEFAULT_MAX_EDITION_SIZE
        : generator.randEditionSize(maxEditionSize));
    const [tokenId, setTokenId] = useState(generator.randTokenId(editionSize));
    const [address, setAddress] = useState(fixedContractAddress || generator.randAddress());
    const [blockHash, setBlockHash] = useState(generator.randBlockHash());
    const [blockNumber, setBlockNumber] = useState(generator.randBlockNumber());
    const [chainId, setChainId] = useState(fixedChainId || generator.randChainId());
    const [gasPrice, setGasPrice] = useState(generator.randGasPrice());
    const [gasUsed, setGasUsed] = useState(generator.randGasUsed());
    const [mintSize, setMintSize] = useState(generator.randMintSize(editionSize));
    const [mintIteration, setMintIteration] = useState(generator.randMintIteration(mintSize));
    const params = useMemo(() => ({
        isCurated,
        loadVer,
        txHash,
        timestamp,
        walletAddress,
        tokenId,
        editionSize,
        address,
        blockHash,
        blockNumber,
        chainId,
        gasPrice,
        gasUsed,
        mintSize,
        mintIteration,
    }), [
        isCurated,
        loadVer,
        txHash,
        timestamp,
        walletAddress,
        tokenId,
        editionSize,
        address,
        blockHash,
        blockNumber,
        chainId,
        gasPrice,
        gasUsed,
        mintSize,
        mintIteration,
    ]);
    const randomizeTxHash = useCallback(() => setTxHash((hash) => nextTxHash(hash)), []);
    const randomizeTimestamp = useCallback(() => setTimestamp(generator.randTimestamp()), []);
    const randomizeMintingWallet = useCallback(() => setMintingWallet(generator.randWalletAddress()), []);
    const randomizeTokenId = useCallback(() => setTokenId(generator.randTokenId(editionSize)), [editionSize]);
    const randomizeEditionSize = useCallback(() => {
        if (!fixEditionSize) {
            setEditionSize(generator.randEditionSize(maxEditionSize));
        }
    }, [fixEditionSize, maxEditionSize]);
    const randomizeAddress = useCallback(() => {
        if (!fixedContractAddress) {
            setAddress(generator.randAddress());
        }
    }, [fixedContractAddress]);
    const randomizeBlockHash = useCallback(() => setBlockHash(generator.randBlockHash()), []);
    const randomizeBlockNumber = useCallback(() => setBlockNumber(generator.randBlockNumber()), []);
    const randomizeChainId = useCallback(() => {
        if (!fixedChainId) {
            setChainId(generator.randChainId());
        }
    }, [fixedChainId]);
    const randomizeGasPrice = useCallback(() => setGasPrice(generator.randGasPrice()), []);
    const randomizeGasUsed = useCallback(() => setGasUsed(generator.randGasUsed()), []);
    const randomizeMintSize = useCallback(() => {
        setMintSize(generator.randMintSize(editionSize));
    }, [editionSize]);
    const randomizeMintIteration = useCallback(() => {
        setMintIteration(generator.randMintIteration(mintSize));
    }, [mintSize]);
    // Change dependencies directly without an effect to avoid changing params multiple times, and consequently triggering multiple renders
    // and other possible side effects like API calls.
    const setEditionSizeWithDeps = (editionSize) => {
        setEditionSize(editionSize);
        setTokenId(generator.randTokenId(editionSize));
        setMintSize(generator.randMintSize(editionSize));
    };
    const setMintSizeWithDeps = (mintSize) => {
        setMintSize(mintSize);
        setMintIteration(generator.randMintIteration(mintSize));
    };
    const randomizeAll = useCallback(() => {
        randomizeTxHash();
        randomizeTimestamp();
        randomizeMintingWallet();
        randomizeTokenId();
        randomizeEditionSize();
        randomizeAddress();
        randomizeBlockHash();
        randomizeBlockNumber();
        randomizeChainId();
        randomizeGasPrice();
        randomizeGasUsed();
        randomizeMintSize();
        randomizeMintIteration();
    }, []);
    const refreshIframe = useCallback(() => setLoadVer((ver) => ver + 1), []);
    return {
        params,
        setLoadVer,
        randomizeAll,
        randomizeTxHash,
        setTxHash,
        randomizeTimestamp,
        setTimestamp,
        randomizeMintingWallet,
        setMintingWallet,
        randomizeTokenId,
        setTokenId,
        randomizeEditionSize,
        setEditionSize: setEditionSizeWithDeps,
        randomizeAddress,
        setAddress,
        randomizeBlockHash,
        setBlockHash,
        randomizeBlockNumber,
        setBlockNumber,
        randomizeChainId,
        setChainId,
        randomizeGasPrice,
        setGasPrice,
        randomizeGasUsed,
        setGasUsed,
        randomizeMintSize,
        setMintSize: setMintSizeWithDeps,
        randomizeMintIteration,
        setMintIteration,
        refreshIframe,
    };
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Text } from "@mantine/core";
const CurrencyAllowanceDescription = ({ currency, status }) => {
    const formatted = (_jsx(Text, { size: "sm", display: "inline", weight: WEIGHT_BOLD, children: currency }));
    if (status === "PENDING") {
        return _jsxs(_Fragment, { children: ["Approving ", formatted, " tokens to be transferred"] });
    }
    if (status === "MINED") {
        return _jsxs(_Fragment, { children: ["Approved ", formatted, " tokens to be transferred"] });
    }
    if (status === "REJECTED") {
        return _jsxs(_Fragment, { children: ["Failed to approve ", formatted, " tokens to be transferred"] });
    }
    return _jsx(_Fragment, { children: "Unknown transaction status." });
};
export default CurrencyAllowanceDescription;

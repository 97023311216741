/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 2
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/implementation/web)
 */
import * as amplitude from '@amplitude/analytics-browser';
export const ApiKey = {
    default: '3c73cb385741bb407e4855039c49e914'
};
/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
    plan: {
        version: '2',
        branch: 'main',
        source: 'web',
        versionId: 'fedb4954-b669-47bc-be55-98085cdee8d4'
    },
    ...{
        ingestionMetadata: {
            sourceName: 'browser-typescript-ampli',
            sourceVersion: '2.0.0'
        }
    }
};
export class Identify {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = amplitude.Types.SpecialEventType.IDENTIFY;
        this.event_properties = event_properties;
    }
}
export class AddToCalendar {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Add to Calendar';
        this.event_properties = event_properties;
    }
}
export class ArtworkInteraction {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Artwork Interaction';
        this.event_properties = event_properties;
    }
}
export class CalendarViewClick {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Calendar View Click';
        this.event_properties = event_properties;
    }
}
export class ConnectWallet {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Connect Wallet';
        this.event_properties = event_properties;
    }
}
export class DeployCollectionFinish {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Deploy Collection Finish';
        this.event_properties = event_properties;
    }
}
export class DeployCollectionStep {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Deploy Collection Step';
        this.event_properties = event_properties;
    }
}
export class EmailSignup {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Email Signup';
        this.event_properties = event_properties;
    }
}
export class ExploreCardClick {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Explore Card Click';
        this.event_properties = event_properties;
    }
}
export class FarcasterExploreLogin {
    constructor() {
        this.event_type = 'Farcaster Explore Login';
    }
}
export class FilterExplore {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Filter Explore';
        this.event_properties = event_properties;
    }
}
export class FilterTokens {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Filter Tokens';
        this.event_properties = event_properties;
    }
}
export class FooterClick {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Footer Click';
        this.event_properties = event_properties;
    }
}
export class HomepageCarouselClick {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Homepage Carousel Click';
        this.event_properties = event_properties;
    }
}
export class HomepageCarouselSwipe {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Homepage Carousel Swipe';
        this.event_properties = event_properties;
    }
}
export class HomepageProjectClick {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Homepage Project Click';
        this.event_properties = event_properties;
    }
}
export class LearnItemClick {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Learn Item Click';
        this.event_properties = event_properties;
    }
}
export class LiveViewClick {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Live View Click';
        this.event_properties = event_properties;
    }
}
export class MarketplacePurchase {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Marketplace Purchase';
        this.event_properties = event_properties;
    }
}
export class MintFinish {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Mint Finish';
        this.event_properties = event_properties;
    }
}
export class MintStart {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Mint Start';
        this.event_properties = event_properties;
    }
}
export class PopoverClick {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Popover Click';
        this.event_properties = event_properties;
    }
}
export class ReadMoreHighlighted {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Read More Highlighted';
        this.event_properties = event_properties;
    }
}
export class ReferEarn {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Refer Earn';
        this.event_properties = event_properties;
    }
}
export class RelatedProjectClick {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Related Project Click';
        this.event_properties = event_properties;
    }
}
export class ResourcesClick {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Resources Click';
        this.event_properties = event_properties;
    }
}
export class Search {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search';
        this.event_properties = event_properties;
    }
}
export class SearchSelect {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search Select';
        this.event_properties = event_properties;
    }
}
export class SponsorMints {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Sponsor Mints';
        this.event_properties = event_properties;
    }
}
export class ViewActivity {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'View Activity';
        this.event_properties = event_properties;
    }
}
export class ViewOnMarketplace {
    constructor(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'View On Marketplace';
        this.event_properties = event_properties;
    }
}
const getVoidPromiseResult = () => ({ promise: Promise.resolve() });
// prettier-ignore
export class Ampli {
    constructor() {
        this.disabled = false;
    }
    get client() {
        this.isInitializedAndEnabled();
        return this.amplitude;
    }
    get isLoaded() {
        return this.amplitude != null;
    }
    isInitializedAndEnabled() {
        if (!this.amplitude) {
            console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
            return false;
        }
        return !this.disabled;
    }
    /**
     * Initialize the Ampli SDK. Call once when your application starts.
     *
     * @param options Configuration options to initialize the Ampli SDK with.
     */
    load(options) {
        var _a;
        this.disabled = (_a = options.disabled) !== null && _a !== void 0 ? _a : false;
        if (this.amplitude) {
            console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
            return getVoidPromiseResult();
        }
        let apiKey = null;
        if (options.client && 'apiKey' in options.client) {
            apiKey = options.client.apiKey;
        }
        else if ('environment' in options) {
            apiKey = ApiKey[options.environment];
        }
        if (options.client && 'instance' in options.client) {
            this.amplitude = options.client.instance;
        }
        else if (apiKey) {
            this.amplitude = amplitude.createInstance();
            const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
            return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
        }
        else {
            console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
        }
        return getVoidPromiseResult();
    }
    /**
     * Identify a user and set user properties.
     *
     * @param userId The user's id.
     * @param properties The user properties.
     * @param options Optional event options.
     */
    identify(userId, properties, options) {
        if (!this.isInitializedAndEnabled()) {
            return getVoidPromiseResult();
        }
        if (userId) {
            options = { ...options, user_id: userId };
        }
        const amplitudeIdentify = new amplitude.Identify();
        const eventProperties = properties;
        if (eventProperties != null) {
            for (const [key, value] of Object.entries(eventProperties)) {
                amplitudeIdentify.set(key, value);
            }
        }
        return this.amplitude.identify(amplitudeIdentify, options);
    }
    /**
     * Flush the event.
     */
    flush() {
        if (!this.isInitializedAndEnabled()) {
            return getVoidPromiseResult();
        }
        return this.amplitude.flush();
    }
    /**
     * Track event
     *
     * @param event The event to track.
     * @param options Optional event options.
     */
    track(event, options) {
        if (!this.isInitializedAndEnabled()) {
            return getVoidPromiseResult();
        }
        return this.amplitude.track(event, undefined, options);
    }
    /**
     * Add to Calendar
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Add%20to%20Calendar)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. artist)
     * @param options Amplitude event options.
     */
    addToCalendar(properties, options) {
        return this.track(new AddToCalendar(properties), options);
    }
    /**
     * Artwork Interaction
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Artwork%20Interaction)
     *
     * Fires when a user interacts with a generative artwork (e.g. generate a random preview).
     *
     * @param properties The event's properties (e.g. artworkInteractionType)
     * @param options Amplitude event options.
     */
    artworkInteraction(properties, options) {
        return this.track(new ArtworkInteraction(properties), options);
    }
    /**
     * Calendar View Click
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Calendar%20View%20Click)
     *
     * Only to be used when user is on the dedicated calendar page URL.
     *
     * @param properties The event's properties (e.g. clickType)
     * @param options Amplitude event options.
     */
    calendarViewClick(properties, options) {
        return this.track(new CalendarViewClick(properties), options);
    }
    /**
     * Connect Wallet
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Connect%20Wallet)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. pagePath)
     * @param options Amplitude event options.
     */
    connectWallet(properties, options) {
        return this.track(new ConnectWallet(properties), options);
    }
    /**
     * Deploy Collection Finish
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Deploy%20Collection%20Finish)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. blockchain)
     * @param options Amplitude event options.
     */
    deployCollectionFinish(properties, options) {
        return this.track(new DeployCollectionFinish(properties), options);
    }
    /**
     * Deploy Collection Step
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Deploy%20Collection%20Step)
     *
     * Fires when a user clicks from one step to another in any creation flow.
     *
     * @param properties The event's properties (e.g. blockchain)
     * @param options Amplitude event options.
     */
    deployCollectionStep(properties, options) {
        return this.track(new DeployCollectionStep(properties), options);
    }
    /**
     * Email Signup
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Email%20Signup)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. emailAddress)
     * @param options Amplitude event options.
     */
    emailSignup(properties, options) {
        return this.track(new EmailSignup(properties), options);
    }
    /**
     * Explore Card Click
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Explore%20Card%20Click)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. projectName)
     * @param options Amplitude event options.
     */
    exploreCardClick(properties, options) {
        return this.track(new ExploreCardClick(properties), options);
    }
    /**
     * Farcaster Explore Login
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Farcaster%20Explore%20Login)
     *
     * Event has no description in tracking plan.
     *
     * @param options Amplitude event options.
     */
    farcasterExploreLogin(options) {
        return this.track(new FarcasterExploreLogin(), options);
    }
    /**
     * Filter Explore
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Filter%20Explore)
     *
     * Fires whenever a user interacts with any filters on the Explore page
     *
     * @param properties The event's properties (e.g. filterAdjusted)
     * @param options Amplitude event options.
     */
    filterExplore(properties, options) {
        return this.track(new FilterExplore(properties), options);
    }
    /**
     * Filter Tokens
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Filter%20Tokens)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. filterType)
     * @param options Amplitude event options.
     */
    filterTokens(properties, options) {
        return this.track(new FilterTokens(properties), options);
    }
    /**
     * Footer Click
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Footer%20Click)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. linkText)
     * @param options Amplitude event options.
     */
    footerClick(properties, options) {
        return this.track(new FooterClick(properties), options);
    }
    /**
     * Homepage Carousel Click
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Homepage%20Carousel%20Click)
     *
     * The carousel that currently says "Best in class tools"
     *
     * @param properties The event's properties (e.g. headline)
     * @param options Amplitude event options.
     */
    homepageCarouselClick(properties, options) {
        return this.track(new HomepageCarouselClick(properties), options);
    }
    /**
     * Homepage Carousel Swipe
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Homepage%20Carousel%20Swipe)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. headline)
     * @param options Amplitude event options.
     */
    homepageCarouselSwipe(properties, options) {
        return this.track(new HomepageCarouselSwipe(properties), options);
    }
    /**
     * Homepage Project Click
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Homepage%20Project%20Click)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. artist)
     * @param options Amplitude event options.
     */
    homepageProjectClick(properties, options) {
        return this.track(new HomepageProjectClick(properties), options);
    }
    /**
     * Learn Item Click
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Learn%20Item%20Click)
     *
     * When a user clicks an item in the Learn dropdown in the nav bar
     *
     * @param properties The event's properties (e.g. itemClicked)
     * @param options Amplitude event options.
     */
    learnItemClick(properties, options) {
        return this.track(new LearnItemClick(properties), options);
    }
    /**
     * Live View Click
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Live%20View%20Click)
     *
     * Fires whenever a user clicks an item in the Live view.Event that captures when a user clicks on a live view within the application
     *
     * @param properties The event's properties (e.g. clickTarget)
     * @param options Amplitude event options.
     */
    liveViewClick(properties, options) {
        return this.track(new LiveViewClick(properties), options);
    }
    /**
     * Marketplace Purchase
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Marketplace%20Purchase)
     *
     * When a user purchases a work on secondary
     *
     * @param properties The event's properties (e.g. contractAddress)
     * @param options Amplitude event options.
     */
    marketplacePurchase(properties, options) {
        return this.track(new MarketplacePurchase(properties), options);
    }
    /**
     * Mint Finish
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Mint%20Finish)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. actionId)
     * @param options Amplitude event options.
     */
    mintFinish(properties, options) {
        return this.track(new MintFinish(properties), options);
    }
    /**
     * Mint Start
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Mint%20Start)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. actionId)
     * @param options Amplitude event options.
     */
    mintStart(properties, options) {
        return this.track(new MintStart(properties), options);
    }
    /**
     * Popover Click
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Popover%20Click)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. artist)
     * @param options Amplitude event options.
     */
    popoverClick(properties, options) {
        return this.track(new PopoverClick(properties), options);
    }
    /**
     * Read More Highlighted
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Read%20More%20Highlighted)
     *
     * User clicks the "Read more" link in Highlight_Ed. sections
     *
     * @param properties The event's properties (e.g. artist)
     * @param options Amplitude event options.
     */
    readMoreHighlighted(properties, options) {
        return this.track(new ReadMoreHighlighted(properties), options);
    }
    /**
     * Refer Earn
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Refer%20Earn)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. projectName)
     * @param options Amplitude event options.
     */
    referEarn(properties, options) {
        return this.track(new ReferEarn(properties), options);
    }
    /**
     * Related Project Click
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Related%20Project%20Click)
     *
     * When a user clicks a related project from the mint page.
     *
     * @param properties The event's properties (e.g. projectName)
     * @param options Amplitude event options.
     */
    relatedProjectClick(properties, options) {
        return this.track(new RelatedProjectClick(properties), options);
    }
    /**
     * Resources Click
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Resources%20Click)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. linkText)
     * @param options Amplitude event options.
     */
    resourcesClick(properties, options) {
        return this.track(new ResourcesClick(properties), options);
    }
    /**
     * Search
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Search)
     *
     * Debounced collection of users' search bar inputs. Note that this is different from a search select.
     *
     * @param properties The event's properties (e.g. searchValue)
     * @param options Amplitude event options.
     */
    search(properties, options) {
        return this.track(new Search(properties), options);
    }
    /**
     * Search Select
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Search%20Select)
     *
     * Whenever a user performs a search by clicking on a search result
     *
     * @param properties The event's properties (e.g. itemClicked)
     * @param options Amplitude event options.
     */
    searchSelect(properties, options) {
        return this.track(new SearchSelect(properties), options);
    }
    /**
     * Sponsor Mints
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/Sponsor%20Mints)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. amountSponsored)
     * @param options Amplitude event options.
     */
    sponsorMints(properties, options) {
        return this.track(new SponsorMints(properties), options);
    }
    /**
     * View Activity
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/View%20Activity)
     *
     * When a user clicks the "Activity" tab on the project page
     *
     * @param properties The event's properties (e.g. projectName)
     * @param options Amplitude event options.
     */
    viewActivity(properties, options) {
        return this.track(new ViewActivity(properties), options);
    }
    /**
     * View On Marketplace
     *
     * [View in Tracking Plan](https://data.amplitude.com/xyzhighlight/Production%20-%20V1/events/main/latest/View%20On%20Marketplace)
     *
     * Event has no description in tracking plan.
     *
     * @param properties The event's properties (e.g. collectionId)
     * @param options Amplitude event options.
     */
    viewOnMarketplace(properties, options) {
        return this.track(new ViewOnMarketplace(properties), options);
    }
}
export const ampli = new Ampli();

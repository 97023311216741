import { useEffect, useState } from "react";

import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import {
  TransactionStateType,
  useTransactionsDispatch,
  useTransactionState,
} from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { logError } from "@hl/shared-features/lib/services/logger";

import { _CollectionType, TransactionType } from "apollo/graphql.generated";
import { useRebateCollectorArgsLazyQuery } from "~features/MintPage/queries.graphql.generated";

const useRebateCollector = ({
  mintVectorId,
  collectionId,
  collectionType,
  refetch,
  onFinish,
}: {
  mintVectorId: string;
  collectionId: string;
  collectionType: _CollectionType;
  refetch: () => void;
  onFinish: () => void;
}) => {
  const [error, setError] = useState<string>("");
  const getNewTxnId = () => `txn-${Date.now()}`;
  const [txnId, setTxnId] = useState<string>(getNewTxnId());
  const transactionDispatch = useTransactionsDispatch();
  const transactionState = useTransactionState(txnId);
  const [generateTxArgs] = useRebateCollectorArgsLazyQuery();
  const { walletAddress } = useAuth();
  const [buttonLabel, setButtonLabel] = useState<string>("Claim");
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const startRebate = async () => {
    setError("");
    setButtonDisabled(true);
    try {
      if (!walletAddress) {
        return;
      }
      const args = await generateTxArgs({
        variables: {
          vectorId: mintVectorId,
        },
      });
      const txnId = getNewTxnId();

      transactionDispatch?.({
        type: "START_TRANSACTION",
        payload: {
          id: txnId,
          entityId: mintVectorId,
          transactionType: TransactionType.EVM_DUTCH_AUCTION_REBATE,
          collectionType,
          args: args.data?.dutchAuctionRebateTxArgs,
          metadata: {
            collectionId,
            nftContractAddress: walletAddress,
            txType: TransactionType.EVM_DUTCH_AUCTION_REBATE,
          },
        },
      });
      setTxnId(txnId);
    } catch (e) {
      setButtonDisabled(false);
      setError("Failed submit transaction.");
      logError({
        e,
        message: "[RebateCollector] Failed to rebate collector",
      });
    }
  };

  useEffect(() => {
    let buttonLabel = "";
    let buttonDisabled = false;
    if (
      transactionState &&
      !transactionState.error &&
      !!transactionState.args
    ) {
      if (transactionState.type === TransactionStateType.WaitingSignedTx) {
        buttonLabel = "Waiting for user signature...";
        buttonDisabled = true;
      } else if (transactionState.type !== TransactionStateType.Done) {
        buttonLabel = "Collecting rebate...";
        buttonDisabled = true;
      }
      setButtonLabel(buttonLabel);
      setButtonDisabled(buttonDisabled);
    }

    if (
      transactionState?.type === TransactionStateType.Done &&
      // having receipt ensures that `updateTx` is called already, that would trigger sync
      transactionState.receipt
    ) {
      setButtonLabel("Done");
      setButtonDisabled(true);
      const timeout = setTimeout(() => {
        refetch();
        onFinish();
      }, 1000);

      return () => clearTimeout(timeout);
    }

    if (transactionState?.error) {
      setButtonLabel("Claim");
      setButtonDisabled(false);
      const errMsg =
        transactionState.type === TransactionStateType.SignTxRejected
          ? "Please confirm in your wallet"
          : transactionState?.error?.shortMessage ?? "Unknown error";
      setError(errMsg);
    }
  }, [transactionState]);

  return {
    error,
    startRebate,
    buttonLabel,
    buttonDisabled,
    transactionState,
  };
};

export default useRebateCollector;

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const GetSupportedChainsDocument = gql `
  query GetSupportedChains {
    getSupportedChains {
      id
      chainId
      name
      network
      networkUrl
      enabled
      testnet
      supportedFeatures
      supportedPaymentCurrencies {
        address
        symbol
        type
      }
    }
  }
`;
/**
 * __useGetSupportedChainsQuery__
 *
 * To run a query within a React component, call `useGetSupportedChainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportedChainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportedChainsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupportedChainsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetSupportedChainsDocument, options);
}
export function useGetSupportedChainsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetSupportedChainsDocument, options);
}

import Cookies from "js-cookie";
import { STORAGE_KEYS } from "../config";
export const setLastRefresh = async () => {
    Cookies.set(STORAGE_KEYS.clientVersionLastFetch, Date.now().toString());
};
// Get the last time we refetched the static client code
export const getLastRefresh = () => {
    var _a;
    return parseInt((_a = Cookies.get(STORAGE_KEYS.clientVersionLastFetch)) !== null && _a !== void 0 ? _a : Date.now().toString());
};

import { GetSalePageAuctionsQuery } from "../queries.graphql.generated";

import { CardStatsProps } from "./AuctionCardStats";

export type SalePageAuctionQueryResult =
  GetSalePageAuctionsQuery["getAuctionsByCollection"][0];

export type AuctionCardProps = {
  hideBidButton?: boolean;
  statsStyles?: CardStatsProps["styles"];
};

export enum SaleStatusAuction {
  ACTIVE,
  ACTIVE_WITH_BID,
  ENDED,
  ENDED_WITH_WIN,
  ENDED_WITH_LOSS,
  NOT_STARTED,
}

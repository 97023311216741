export const PRIMARY_COLOR = "primaryButton";
export const SECONDARY_COLOR = "secondaryButton";
export const OUTLINE_COLOR = "outlineButton";
export const SHADOW_COLOR = "shadowButton";
export const DESTRUCTIVE_COLOR = "destructiveButton";
export const ACCENT_COLOR = "accentButton";
export const CLEAR_COLOR = "transparent";
export const AFFIRMATIVE_COLOR = "affirmativeButton";
export const VIOLET_COLOR = "violetButton";
export const GREEN_COLOR = "greenButton";
export const BLUE_COLOR = "blueButton";
export const buttonsTheme = {
    Button: {
        defaultProps: {
            color: PRIMARY_COLOR,
            size: "md",
        },
        styles: (theme, { color, size, withLeftIcon, withRightIcon }) => ({
            root: {
                fontWeight: 500,
                letterSpacing: "0.02em",
                ...sizeStyles(size),
                ...backgroundStyles(theme, color),
                ...colorStyles(theme, color),
                ...borderStyles(theme, color),
                ...loadingStyles(theme, color),
                ...disabledStyles(theme, color),
                ...hoverStyles(theme, color),
                ...shadowStyles(theme, color),
            },
            leftIcon: {
                ...iconStyles(size, withLeftIcon, false, color),
            },
            rightIcon: {
                ...iconStyles(size, false, withRightIcon, color),
            },
        }),
    },
    ActionIcon: {
        defaultProps: {
            size: 32,
        },
        styles: (theme, { color }) => ({
            root: {
                backgroundColor: theme.colors.outlineButton[0],
                ...backgroundStyles(theme, color),
                ...colorStyles(theme, color),
                ...borderStyles(theme, color),
                ...hoverStyles(theme, color),
                ...shadowStyles(theme, color),
            },
        }),
    },
};
const shadowStyles = (theme, color) => ({
    ...(color === SHADOW_COLOR && { boxShadow: theme.shadows.xs }),
});
const borderStyles = (theme, color) => ({
    ...(color === OUTLINE_COLOR && {
        borderColor: theme.colors.divider[0],
    }),
});
const backgroundStyles = (theme, color) => ({
    ...(color === PRIMARY_COLOR && {
        backgroundColor: theme.colors.primaryButton[0],
    }),
    ...(color === SECONDARY_COLOR && {
        backgroundColor: theme.colors.secondaryButton[0],
    }),
    ...(color === VIOLET_COLOR && {
        backgroundColor: theme.colors.violetButton[0],
    }),
    ...(color === GREEN_COLOR && {
        backgroundColor: theme.colors.greenButton[0],
    }),
    ...(color === BLUE_COLOR && {
        backgroundColor: theme.colors.blueButton[0],
    }),
    ...(color === OUTLINE_COLOR && {
        backgroundColor: theme.colors.outlineButton[0],
    }),
    ...(color === DESTRUCTIVE_COLOR && {
        backgroundColor: theme.colors.destructiveButton[0],
    }),
    ...(color === ACCENT_COLOR && {
        backgroundColor: theme.colors.accentButton[0],
    }),
    ...(color === AFFIRMATIVE_COLOR && {
        backgroundColor: theme.colors.affirmativeButton[0],
    }),
    ...(color === CLEAR_COLOR && {
        backgroundColor: CLEAR_COLOR,
    }),
});
const loadingStyles = (theme, color) => ({
    "&[data-loading]": {
        ...(color === PRIMARY_COLOR && {
            backgroundColor: theme.colors.primaryButton[2],
            color: theme.colors.invertedPrimaryText[0],
        }),
        ...(color === SECONDARY_COLOR && {
            backgroundColor: theme.colors.secondaryButton[2],
            color: theme.colors.primaryText[0],
        }),
        ...(color === VIOLET_COLOR && {
            backgroundColor: theme.colors.violetButton[0],
            color: theme.colors.invertedPrimaryText[0],
            opacity: 0.5,
        }),
        ...(color === GREEN_COLOR && {
            backgroundColor: theme.colors.greenButton[0],
            color: theme.colors.invertedPrimaryText[0],
            opacity: 0.5,
        }),
        ...(color === BLUE_COLOR && {
            backgroundColor: theme.colors.blueButton[0],
            color: theme.colors.invertedPrimaryText[0],
            opacity: 0.5,
        }),
        ...(color === OUTLINE_COLOR && {
            backgroundColor: theme.colors.outlineButton[2],
            color: theme.colors.primaryText[0],
        }),
        ...(color === DESTRUCTIVE_COLOR && {
            backgroundColor: theme.colors.destructiveButton[2],
        }),
        ...(color === ACCENT_COLOR && {
            backgroundColor: theme.colors.accentButton[2],
            color: theme.colors.tertiaryText[0],
        }),
        ...(color === AFFIRMATIVE_COLOR && {
            opacity: 0.9,
            backgroundColor: theme.colors.affirmativeButton[0],
            color: theme.colors.successStatus[0],
        }),
        ...(color === CLEAR_COLOR && {
            backgroundColor: CLEAR_COLOR,
            color: "inherit",
            opacity: 0.3,
        }),
    },
    "&[data-loading]::before": {
        ...(color === DESTRUCTIVE_COLOR && {
            backgroundColor: "unset",
        }),
    },
});
const disabledStyles = (theme, color) => ({
    "&[disabled]": {
        ...(color === PRIMARY_COLOR && {
            backgroundColor: theme.colors.primaryButton[2],
            color: theme.colors.invertedPrimaryText[0],
        }),
        ...(color === SECONDARY_COLOR && {
            backgroundColor: theme.colors.secondaryButton[2],
            color: theme.colors.primaryText[0],
            opacity: "0.3",
        }),
        ...(color === VIOLET_COLOR && {
            backgroundColor: theme.colors.violetButton[0],
            color: theme.colors.invertedPrimaryText[0],
            opacity: 0.5,
        }),
        ...(color === GREEN_COLOR && {
            backgroundColor: theme.colors.greenButton[0],
            color: theme.colors.invertedPrimaryText[0],
            opacity: 0.5,
        }),
        ...(color === BLUE_COLOR && {
            backgroundColor: theme.colors.blueButton[0],
            color: theme.colors.invertedPrimaryText[0],
            opacity: 0.5,
        }),
        ...(color === OUTLINE_COLOR && {
            backgroundColor: theme.colors.outlineButton[2],
            color: theme.colors.primaryText[0],
        }),
        ...(color === DESTRUCTIVE_COLOR && {
            backgroundColor: theme.colors.destructiveButton[2],
        }),
        ...(color === ACCENT_COLOR && {
            backgroundColor: theme.colors.accentButton[2],
            color: theme.colors.tertiaryText[0],
        }),
        ...(color === AFFIRMATIVE_COLOR && {
            backgroundColor: theme.colors.affirmativeButton[0],
            opacity: 0.75,
            color: theme.colors.successStatus[0],
        }),
        ...(color === CLEAR_COLOR && {
            backgroundColor: CLEAR_COLOR,
            color: "inherit",
            opacity: 0.3,
        }),
    },
});
const hoverStyles = (theme, color) => ({
    "&:hover": {
        ...(color === PRIMARY_COLOR && {
            backgroundColor: theme.colors.primaryButton[1],
            color: theme.colors.invertedPrimaryText[0],
        }),
        ...(color === SECONDARY_COLOR && {
            backgroundColor: theme.colors.secondaryButton[1],
            color: theme.colors.primaryText[0],
        }),
        ...(color === VIOLET_COLOR && {
            backgroundColor: theme.colors.violetButton[1],
            color: theme.colors.invertedPrimaryText[0],
        }),
        ...(color === GREEN_COLOR && {
            backgroundColor: theme.colors.greenButton[1],
            color: theme.colors.invertedPrimaryText[0],
        }),
        ...(color === BLUE_COLOR && {
            backgroundColor: theme.colors.blueButton[1],
            color: theme.colors.invertedPrimaryText[0],
        }),
        ...(color === OUTLINE_COLOR && {
            backgroundColor: theme.colors.outlineButton[1],
            color: theme.colors.primaryText[0],
        }),
        ...(color === DESTRUCTIVE_COLOR && {
            backgroundColor: theme.colors.destructiveButton[1],
        }),
        ...(color === ACCENT_COLOR && {
            backgroundColor: theme.colors.accentButton[1],
            color: theme.colors.primaryText[0],
        }),
        ...(color === AFFIRMATIVE_COLOR && {
            backgroundColor: theme.colors.affirmativeButton[1],
            color: theme.colors.successStatus[0],
        }),
        ...(color === CLEAR_COLOR && {
            backgroundColor: CLEAR_COLOR,
            color: "inherit",
        }),
        ...(color === SHADOW_COLOR && {
            boxShadow: theme.shadows.sm,
        }),
    },
});
const colorStyles = (theme, color) => {
    if (color === PRIMARY_COLOR) {
        return {
            color: theme.colors.invertedPrimaryText[0],
        };
    }
    if (color === VIOLET_COLOR) {
        return {
            color: theme.colors.invertedPrimaryText[0],
        };
    }
    if (color === GREEN_COLOR) {
        return {
            color: theme.colors.invertedPrimaryText[0],
        };
    }
    if (color === BLUE_COLOR) {
        return {
            color: theme.colors.invertedPrimaryText[0],
        };
    }
    if (color === DESTRUCTIVE_COLOR) {
        return {
            color: theme.colors.errorStatus[0],
        };
    }
    if (color === AFFIRMATIVE_COLOR) {
        return {
            color: theme.colors.successStatus[0],
        };
    }
    if (color === CLEAR_COLOR) {
        return {
            color: "inherit",
        };
    }
    return {
        color: theme.colors.primaryText[0],
    };
};
const sizeStyles = (size) => sizeConfig[size];
const iconStyles = (size, withLeftIcon, withRightIcon, color) => {
    const { size: iconSize, margin } = iconConfig[size];
    return {
        ...(color === OUTLINE_COLOR && {
            "& svg": {
                stroke: "currentColor",
            },
        }),
        width: `${iconSize}px`,
        height: `${iconSize}px`,
        ...(withLeftIcon && { marginRight: margin }),
        ...(withRightIcon && { marginLeft: margin }),
    };
};
const sizeConfig = {
    xs: {
        height: "28px",
        fontSize: "12px",
    },
    sm: {
        height: "32px",
        fontSize: "14px",
    },
    md: {
        height: "36px",
        fontSize: "14px",
    },
    lg: {
        height: "40px",
        fontSize: "14px",
    },
    xl: {
        height: "48px",
        fontSize: "14px",
    },
};
const iconConfig = {
    xs: {
        size: 14,
        margin: 6,
    },
    sm: {
        size: 16,
        margin: 8,
    },
    md: {
        size: 16,
        margin: 8,
    },
    lg: {
        size: 16,
        margin: 8,
    },
    xl: {
        size: 20,
        margin: 8,
    },
};

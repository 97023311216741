import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment } from "react";
import PulsatingStatusIndicator from "@hl/base-components/lib/PulsatingStatusIndicator";
import { BrandHighlightLight, BrandHighlightSmall, BrandJustLogo, Menu as MenuIcon, } from "@hl/base-components/lib/assets/icons.generated";
import { CLEAR_COLOR } from "@hl/base-components/lib/theme/button";
import { STATUS_COLOR, TEXT_COLOR, UTILITY_COLOR, } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Box, Button, Group, Menu, Modal, Text, CloseButton, Stack, Anchor, UnstyledButton, createStyles, } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { ViewElsewhereIcon } from "@hl/shared-features/lib/assets/icons.generated";
import { ampli } from "../../ampli";
import { envConfig, EXTERNAL_URL } from "../../config";
import { FEATURE_FLAGS, useEmbedMode, useFeatureFlags } from "../auth/hooks";
import { GlobalSearch } from "../search/GlobalSearch";
import { NavbarSearchMobile } from "../search/MobileSearch";
import CreateCollectionButton from "./CreateCollectionButton";
const exclusivePaths = ["/why-highlight", "/tools/", "/discover"];
const TextLink = ({ link, label, icon, newTab = true }) => {
    const isExclusive = !!exclusivePaths.find((el) => el === location.pathname);
    const isActive = (isExclusive ? location.href : location.origin) === link;
    const textColor = isActive ? TEXT_COLOR.PRIMARY : TEXT_COLOR.PLACEHOLDER;
    return (_jsx(Button, { component: "a", ...(newTab && {
            target: "_blank",
            rel: "noopener noreferrer",
        }), href: link, color: CLEAR_COLOR, compact: true, size: "sm", p: 0, rightIcon: icon, styles: (theme) => ({
            root: {
                "&:hover": {
                    color: theme.fn.themeColor(TEXT_COLOR.PRIMARY),
                },
                "&:not(hover)": {
                    color: theme.fn.themeColor(textColor),
                },
            },
            icon: {
                width: 14,
                color: theme.fn.themeColor(textColor),
            },
        }), children: label }));
};
const useDesktopStyles = createStyles((theme) => ({
    root: {
        gap: 30,
        [theme.fn.largerThan("md")]: {
            gap: 56,
        },
    },
    logo: {
        width: 130,
        [theme.fn.largerThan("md")]: {
            width: 160,
        },
        color: theme.colors.primaryText,
    },
}));
export const NavbarMenuDesktop = ({ menuItems, hideBrand, }) => {
    const { classes } = useDesktopStyles();
    const { isEmbedMode } = useEmbedMode();
    return (_jsxs(Group, { className: classes.root, align: "center", h: "100%", noWrap: true, children: [!isEmbedMode && !hideBrand && (_jsx(Box, { sx: {
                    justifySelf: "center",
                }, children: _jsx(Anchor, { href: envConfig.home.baseUrl, display: "flex", target: isEmbedMode ? "_blank" : "_self", children: _jsx(BrandHighlightLight, { height: 15, className: classes.logo }) }) })), !isEmbedMode && (_jsx(Group, { spacing: 28, align: "center", noWrap: true, children: menuItems.map((item, index) => {
                    if (!item.subMenu) {
                        return _jsx(TextLink, { ...item }, index);
                    }
                    return (_jsxs(Menu, { trigger: "hover", position: "bottom-start", children: [_jsx(Menu.Target, { children: _jsx(Text, { size: "sm", fw: WEIGHT_BOLD, sx: (theme) => ({
                                        cursor: "pointer",
                                        color: theme.fn.themeColor(TEXT_COLOR.PLACEHOLDER),
                                        "&[data-expanded]": {
                                            color: theme.fn.themeColor(TEXT_COLOR.PRIMARY),
                                        },
                                    }), children: item.label }) }), _jsx(Menu.Dropdown, { style: {
                                    border: 0,
                                }, children: item.subMenu.map((subItem, index) => (_jsxs(Fragment, { children: [index == 0 && (_jsx(Menu.Label, { sx: { paddingTop: 10 }, children: "How to" })), index == 8 && (_jsxs(_Fragment, { children: [_jsx(Menu.Divider, { sx: { marginRight: 10, marginLeft: 10 } }), _jsx(Menu.Label, { sx: { paddingTop: 10 }, children: "Resources" })] })), _jsx(Menu.Item, { component: "a", href: subItem.link, target: "_blank", rel: "noopener noreferrer", onClick: () => {
                                                if (!ampli.isLoaded) {
                                                    return;
                                                }
                                                ampli.learnItemClick({
                                                    itemClicked: JSON.stringify(subItem.label),
                                                });
                                            }, children: subItem.label })] }, index))) })] }, index));
                }) }))] }));
};
const NavbarMenuMobile = ({ children, menuItems, enableSearch }) => {
    const isJustLogo = useMediaQuery("(max-width: 410px)");
    const [opened, { open, close }] = useDisclosure(false);
    const { isEmbedMode } = useEmbedMode();
    return (_jsxs(_Fragment, { children: [_jsxs(Modal, { opened: opened, onClose: close, centered: true, withCloseButton: false, radius: 0, fullScreen: true, styles: {
                    body: {
                        maxHeight: "100vh",
                    },
                    root: {
                        zIndex: 10002,
                    },
                }, children: [_jsx(Stack, { align: "flex-end", mb: 12, children: _jsx(CloseButton, { onClick: close }) }), !isEmbedMode && (_jsx(Anchor, { href: envConfig.home.baseUrl, children: _jsx(BrandHighlightLight, {}) })), _jsx(Stack, { spacing: 28, mt: 28, align: "flex-start", children: menuItems.map((item, index) => (_jsxs(Fragment, { children: [index == 3 && (_jsx(Text, { size: "xs", fw: WEIGHT_BOLD, sx: (theme) => ({
                                        borderTop: "1px solid " + theme.fn.themeColor(UTILITY_COLOR.DIVIDER),
                                        width: "100%",
                                        paddingTop: 24,
                                        marginTop: -12,
                                        marginBottom: -8,
                                    }), children: "How to" })), _jsx(Box, { onClick: () => {
                                        if (!ampli.isLoaded) {
                                            return;
                                        }
                                        ampli.learnItemClick({
                                            itemClicked: JSON.stringify(item.label),
                                        });
                                    }, children: _jsx(TextLink, { ...item }) })] }, index))) })] }), _jsxs(Group, { spacing: 12, position: "apart", align: "center", w: "100%", children: [!isEmbedMode && (_jsx(Anchor, { href: envConfig.home.baseUrl, display: "flex", children: isJustLogo ? _jsx(BrandJustLogo, {}) : _jsx(BrandHighlightSmall, {}) })), _jsxs(Group, { position: "right", children: [children, enableSearch && _jsx(NavbarSearchMobile, {}), !isEmbedMode && (_jsx(UnstyledButton, { onClick: open, sx: { display: "flex", alignItems: "center" }, children: _jsx(MenuIcon, {}) }))] })] })] }));
};
export const Navbar = ({ children }) => {
    const isMobile = useMediaQuery(`(max-width: 1050px)`);
    const enableCalendar = useFeatureFlags(FEATURE_FLAGS.CALENDAR_WATCH);
    const { isEmbedMode } = useEmbedMode();
    const enableSearch = useFeatureFlags(FEATURE_FLAGS.ENABLE_NAVBAR_SEARCH) && !isEmbedMode;
    // const toolsNewTab = !envConfig.tools.baseUrl.startsWith(
    //   envConfig.home.baseUrl
    // );
    // const collect = {
    //   label: "Collect",
    //   link: envConfig.home.baseUrl,
    //   newTab: toolsNewTab,
    // };
    const explore = {
        label: "Explore",
        link: `${envConfig.home.baseUrl}/explore/trending`,
        newTab: false,
    };
    const calendar = {
        label: "Calendar",
        link: `${envConfig.home.baseUrl}/calendar`,
        newTab: false,
    };
    const live = {
        label: (_jsxs(Group, { spacing: 6, pr: 4, children: [_jsx(Text, { unstyled: true, children: "Live" }), _jsx(PulsatingStatusIndicator, { color: STATUS_COLOR.SUCCESS, size: 6 })] })),
        link: `${envConfig.home.baseUrl}/live`,
        newTab: false,
    };
    // const create = {
    //   label: "Create",
    //   link: `${envConfig.tools.baseUrl}/`,
    //   newTab: toolsNewTab,
    // };
    const knowledgeBase = {
        label: "Help center & documentation",
        link: EXTERNAL_URL.KNOWLEDGE_BASE,
    };
    const openEditionUseCase = {
        label: "Launch an open edition",
        link: "https://support.highlight.xyz/knowledge-base/for-creators/project-quick-start-guides/launch-an-open-edition",
    };
    const generativeUseCase = {
        label: "Launch a generative art project",
        link: "https://support.highlight.xyz/knowledge-base/for-creators/project-quick-start-guides/launch-a-generative-artwork",
    };
    const pfpUseCase = {
        label: "Launch a PFP project",
        link: "https://support.highlight.xyz/knowledge-base/for-creators/project-quick-start-guides/launch-a-pfp-project",
    };
    const onchainUseCase = {
        label: "Launch an onchain project",
        link: "https://support.highlight.xyz/knowledge-base/for-creators/project-quick-start-guides/launch-an-onchain-project",
    };
    const collectorsChoiceUseCase = {
        label: "Launch a collector's choice project",
        link: "https://support.highlight.xyz/knowledge-base/for-creators/project-quick-start-guides/launch-a-collectors-choice-project",
    };
    const createCustomSite = {
        label: "Create a custom mint site",
        link: "https://support.highlight.xyz/knowledge-base/for-creators/selling-your-nfts/sell-on-your-own-site-using-embeds",
    };
    const saleMethods = {
        label: "Sell with auctions, fixed price & more",
        link: "https://support.highlight.xyz/knowledge-base/for-creators/selling-your-nfts/available-sale-methods",
    };
    const viewAllQuickStartGuides = {
        label: "View all quick start guides",
        link: "https://support.highlight.xyz/knowledge-base/for-creators/project-quick-start-guides",
    };
    // const videos = {
    //   label: "YouTube",
    //   link: EXTERNAL_URL.YOUTUBE,
    // };
    // const generative = {
    //   label: "Generative docs",
    //   link: EXTERNAL_URL.HL_GITHUB,
    // };
    // const protocol = {
    //   label: "Protocol docs",
    //   link: EXTERNAL_URL.PROTOCOL_DOCS,
    // };
    // const changelog = {
    //   label: "Changelog",
    //   link: `${envConfig.home.baseUrl}/changelog`,
    // };
    const whyHighlight = {
        label: "Why Highlight",
        link: `${envConfig.home.baseUrl}/why-highlight`,
    };
    const mainNavItems = enableCalendar
        ? [live, explore, calendar]
        : [live, explore];
    if (isMobile) {
        return (_jsx(NavbarMenuMobile, { menuItems: [
                ...mainNavItems,
                { ...openEditionUseCase, icon: _jsx(ViewElsewhereIcon, {}) },
                { ...generativeUseCase, icon: _jsx(ViewElsewhereIcon, {}) },
                { ...pfpUseCase, icon: _jsx(ViewElsewhereIcon, {}) },
                { ...onchainUseCase, icon: _jsx(ViewElsewhereIcon, {}) },
                { ...collectorsChoiceUseCase, icon: _jsx(ViewElsewhereIcon, {}) },
                { ...createCustomSite, icon: _jsx(ViewElsewhereIcon, {}) },
                { ...saleMethods, icon: _jsx(ViewElsewhereIcon, {}) },
                { ...viewAllQuickStartGuides, icon: _jsx(ViewElsewhereIcon, {}) },
                { ...knowledgeBase, icon: _jsx(ViewElsewhereIcon, {}) },
                { ...whyHighlight, icon: _jsx(ViewElsewhereIcon, {}) },
            ], enableSearch: enableSearch, children: _jsxs(Box, { sx: { display: "inline-flex", gap: 4 }, children: [_jsx(CreateCollectionButton, { isMobile: true }), children] }) }));
    }
    return (_jsxs(Group, { sx: { flex: 1 }, position: "apart", spacing: 32, children: [_jsx(NavbarMenuDesktop, { menuItems: mainNavItems }), enableSearch && (_jsx(Group, { sx: { flex: 1 }, position: "center", children: _jsx(GlobalSearch, {}) })), _jsxs(Box, { sx: { display: "inline-flex", gap: 4, alignItems: "center" }, children: [_jsx(NavbarMenuDesktop, { hideBrand: true, menuItems: [
                            {
                                label: "Learn",
                                subMenu: [
                                    openEditionUseCase,
                                    generativeUseCase,
                                    pfpUseCase,
                                    onchainUseCase,
                                    collectorsChoiceUseCase,
                                    createCustomSite,
                                    saleMethods,
                                    viewAllQuickStartGuides,
                                    knowledgeBase,
                                    whyHighlight,
                                ],
                                link: "",
                            },
                        ] }), _jsx(Box, { w: 16 }), _jsx(CreateCollectionButton, { isMobile: false }), children] })] }));
};

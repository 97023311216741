import { ethers } from "ethers";
import { maskAddress } from "./content";
export const getProfileDisplayName = (displayName, walletAddresses, fallback = "", shouldShortenDisplayName) => {
    const defaultNameShort = maskWallet(getProfileDefaultDisplayName(walletAddresses, fallback));
    const finalDefaultNameShort = shouldShortenDisplayName
        ? shortenDisplayName(defaultNameShort)
        : defaultNameShort;
    const finalDisplayName = shouldShortenDisplayName
        ? shortenDisplayName(displayName)
        : displayName;
    return finalDisplayName || finalDefaultNameShort;
};
export const getProfileDefaultDisplayName = (walletAddresses, fallback = "") => (walletAddresses ? walletAddresses[0] : fallback);
export const maskWallet = (wallet) => ethers.utils.isAddress(wallet) ? maskAddress(wallet || "", 4, 4) : wallet;
const shortenDisplayName = (str) => {
    if (!str)
        return;
    const max = 11;
    return str.length > max ? str.substring(0, max) + "..." : str;
};

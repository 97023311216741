import { forwardRef, Ref } from "react";

import { useTransactionState } from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { Box, Button, ButtonProps, Stack } from "@mantine/core";

import ErrorBox from "~features/MintPage/MintVector/ErrorBox";
import { useMintCardButtonStyles } from "~features/MintPage/MintVector/MintCardButton";
import MintFee from "~features/MintPage/MintVector/MintFee";
import { AppendNumMintsInput } from "~features/MintPage/MintVector/NumMintsInput";
import { UserStatus } from "~features/MintPage/utils/types";
import { useApproveErc20 } from "~hooks/useApproveErc20";
import useMintState, { MintStateRequired } from "~hooks/useMintState";

import { transactionStateErrorMsg } from "./tx-state";

export type CardButtonProps = {
  onMintClick: () => void;
  initialButtonLabel: string;
  showMintFee?: boolean;
  showWarningModals: boolean;
  hasEnoughMoney: boolean;
  showErrorIcon: boolean;
  disabledMint?: boolean | null;
  showNumberMintsInput?: boolean;
} & Partial<ButtonProps>;

const ApproveErc20Button = forwardRef(
  (
    {
      onMintClick,
      initialButtonLabel,
      showMintFee,
      showWarningModals,
      hasEnoughMoney,
      showErrorIcon,
      disabledMint,
      showNumberMintsInput = false,
      ...rest
    }: CardButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const {
      collection,
      mintVector,
      currencyAmountToAllow,
      numTokensToMint,
      chain,
      userStatus,
      mintFeeWaived,
      refetchAllowance,
    } = useMintState() as MintStateRequired;
    const { buttonLoading, buttonLabel, error, txnId, approve } =
      useApproveErc20(
        collection.id,
        collection.collectionType,
        mintVector,
        currencyAmountToAllow,
        async () => {
          await refetchAllowance({
            throwOnError: false,
          });
          onMintClick();
        }
      );
    const transactionState = useTransactionState(txnId);
    const errorMsg = transactionStateErrorMsg(transactionState);

    const { classes } = useMintCardButtonStyles({});

    const disableMintButton =
      disabledMint ||
      !hasEnoughMoney ||
      (!showWarningModals && userStatus === UserStatus.LIMIT_REACHED);

    return (
      <Stack spacing={8} w="100%" align="center">
        <Stack
          className={classes.buttonContainer}
          spacing={12}
          align="center"
          w="100%"
        >
          <AppendNumMintsInput show={showNumberMintsInput}>
            <Button
              ref={ref}
              {...rest}
              onClick={approve}
              loading={buttonLoading && !errorMsg}
              disabled={disableMintButton}
            >
              {buttonLabel || initialButtonLabel}
            </Button>
          </AppendNumMintsInput>
          {showMintFee && chain?.mintFee && !mintFeeWaived && (
            <MintFee numTokensToMint={numTokensToMint} />
          )}
        </Stack>
        <Box w="100%">
          {errorMsg ? (
            <ErrorBox message={errorMsg} small={!showErrorIcon} />
          ) : error ? (
            <ErrorBox
              message="There was an error, please try again later"
              small={!showErrorIcon}
            />
          ) : (
            !showWarningModals &&
            !hasEnoughMoney && (
              <ErrorBox message="Insufficient funds" small={!showErrorIcon} />
            )
          )}
        </Box>
      </Stack>
    );
  }
);

export default ApproveErc20Button;

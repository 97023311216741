import { EventType } from "../../apollo/graphql.generated";
import { IN_DEVELOPMENT } from "../../config";
import { logError } from "../../services/logger";
import { useEmitAnalyticsEventMutation } from "./analytics.graphql.generated";
const useAnalytics = () => {
    const [emitAnalyticsEvent] = useEmitAnalyticsEventMutation();
    const trackEvent = async (eventName, properties = {}) => {
        IN_DEVELOPMENT && console.log(`emitAnalyticsEvent: ${eventName} `);
        emitAnalyticsEvent({
            variables: {
                event: {
                    eventType: EventType[eventName],
                    eventDetails: JSON.stringify(properties),
                },
            },
        }).catch((err) => {
            logError({
                ...err,
                message: `emitAnalyticsEvent error: ${err.message}`,
            });
        });
    };
    return {
        trackEvent,
        EventType,
    };
};
export default useAnalytics;

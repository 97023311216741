import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { formatNumber } from "@hl/shared-features/lib/utils/format";
import { isUnlimitedSize } from "@hl/shared-features/lib/utils/unlimitedSize";
import { Divider, Group, Progress, Space, Text } from "@mantine/core";

import useMintState from "~hooks/useMintState";

const CollectorsStats = () => {
  const { isAnySeriesMint, totalSoldCount, totalSize, remainingSupply } =
    useMintState();
  return (
    <>
      <Space h="sm" />
      <Divider mx="-md" />
      <Space h="sm" />
      <Group position="apart">
        {totalSoldCount > 0 && (
          <Text size="xs">{formatNumber(totalSoldCount)} minted</Text>
        )}
        <Text size="xs" color={TEXT_COLOR.PRIMARY}>
          {isUnlimitedSize(totalSize)
            ? "Open" + (isAnySeriesMint ? " series" : " edition")
            : `${formatNumber(remainingSupply)} remaining`}
        </Text>
      </Group>
      {!isUnlimitedSize(totalSize) && (
        <Progress
          styles={{
            bar: {
              height: 6,
            },
          }}
          color={TEXT_COLOR.PRIMARY}
          mt={8}
          value={(100 * (totalSize - remainingSupply)) / totalSize}
        />
      )}
    </>
  );
};

export default CollectorsStats;

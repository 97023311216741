import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Box } from "@mantine/core";
import { STATUS_COLOR } from "./theme/colors";
const BoxContainer = ({ border = true, radius = "sm", error = false, fluid = false, p = "md", sx, onClick = undefined, children, ...rest }) => (_jsx(Box, { sx: [
        (theme) => ({
            ...(border && { border: `1px solid ${theme.colors.divider[0]}` }),
            borderRadius: typeof radius === "number" ? radius : theme.radius[radius],
            ...(error && { borderColor: theme.fn.themeColor(STATUS_COLOR.ERROR) }),
            ...(fluid && { width: "100%" }),
        }),
        sx,
    ], p: p, ...rest, onClick: onClick, children: children }));
export default BoxContainer;

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { Group, Select, Text, useMantineTheme } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import dayjs, { extend } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
extend(timezone);
extend(advancedFormat);
extend(customParseFormat);
const ExpirationOptionSelect = ({ expirationOptions, expirationOption, setExpirationOption, label, }) => {
    const now = new Date();
    const monthFromNow = dayjs(now).add(30, "days").toDate();
    const [date, setDate] = useState(monthFromNow);
    const theme = useMantineTheme();
    const checkValidityAndSetTime = (d, t) => {
        const defaultDate = dayjs().add(1, "minute").toDate();
        if (!d || !t) {
            return setDate(defaultDate);
        }
        const minute = d.getMinutes();
        const hour = d.getHours();
        const newDateTime = dayjs(t).minute(minute).hour(hour);
        if (newDateTime.unix() < dayjs().unix()) {
            setDate(defaultDate);
        }
        else {
            setDate(newDateTime.toDate());
        }
    };
    const isCustom = useMemo(() => expirationOption.value === "custom", [expirationOption.value]);
    const isXsAndBelow = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);
    useEffect(() => {
        if (!date || !isCustom) {
            return;
        }
        const relativeTime = dayjs(date).unix();
        if (relativeTime !== expirationOption.relativeTime) {
            setExpirationOption({ ...expirationOption, relativeTime });
        }
    }, [date, expirationOption, isCustom]);
    return (_jsxs(Group, { align: "flex-end", spacing: "md", w: "100%", children: [_jsx(Select, { styles: { root: { flexGrow: isCustom && !isXsAndBelow ? 0 : 1 } }, label: label, placeholder: `Pick the ${label.toLowerCase()}`, data: expirationOptions.map((el) => ({
                    label: el.text,
                    value: el.value,
                })), value: expirationOption.value, onChange: (value) => {
                    const selectedOption = expirationOptions.find((eo) => eo.value === value);
                    setExpirationOption(selectedOption);
                } }), isCustom && (_jsxs(_Fragment, { children: [_jsx(DatePicker, { placeholder: "Pick date", value: date, onChange: (d) => checkValidityAndSetTime(date, d), minDate: new Date(), sx: { flexGrow: 1 }, clearable: false }), _jsx(TimeInput, { styles: {
                            input: {
                                padding: "0 60px 0 16px",
                                display: "flex",
                                alignItems: "center",
                            },
                            rightSection: {
                                justifyContent: "flex-end",
                                paddingRight: 10,
                            },
                        }, rightSection: _jsx(Text, { size: "sm", color: TEXT_COLOR.SECONDARY, children: dayjs().format("z") }), sx: { flexGrow: 1, marginTop: 0 }, rightSectionWidth: 100, format: "12", value: date, onChange: (d) => checkValidityAndSetTime(d, date) })] }))] }));
};
export default ExpirationOptionSelect;

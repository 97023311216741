import { defaultTheme } from "..";
import { lightColors } from "./colors";
import { typographyTheme } from "./typography";
export const createLightTheme = (lightColors) => ({
    ...defaultTheme,
    colorScheme: "light",
    colors: {
        ...lightColors,
        primaryText: [lightColors.black[9]],
        secondaryText: [lightColors.black[5]],
        tertiaryText: [lightColors.black[2]],
        placeholderText: [lightColors.black[4]],
        baseBackground: [lightColors.white[9]],
        primaryBackground: [lightColors.white[9]],
        secondaryBackground: [lightColors.gray[2]],
        tertiaryBackground: [lightColors.gray[3]],
        quaternaryBackground: [lightColors.gray[4]],
        primaryButton: [
            lightColors.black[9],
            lightColors.gray[8],
            lightColors.black[2], // inactive
        ],
        secondaryButton: [
            lightColors.gray[2],
            lightColors.gray[3],
            lightColors.gray[2],
        ],
        outlineButton: [
            lightColors.white[9],
            lightColors.gray[1],
            lightColors.white[9],
        ],
        shadowButton: [
            lightColors.white[9],
            lightColors.gray[1],
            lightColors.white[9],
        ],
        destructiveButton: [
            lightColors.red[1],
            lightColors.red[2],
            lightColors.red[0],
        ],
        accentButton: [
            lightColors.cyan[5],
            lightColors.cyan[6],
            lightColors.cyan[2],
        ],
        affirmativeButton: [
            lightColors.green[1],
            lightColors.green[2],
            lightColors.green[0],
        ],
        violetButton: [
            lightColors.violet[4],
            lightColors.violet[5],
            lightColors.violet[2],
        ],
        greenButton: [
            lightColors.green[7],
            lightColors.green[9],
            lightColors.green[7],
        ],
        blueButton: [
            lightColors.blue[5],
            lightColors.blue[4],
            lightColors.blue[5],
        ],
        invertedPrimaryText: [lightColors.white[9]],
        invertedSecondaryText: [lightColors.white[5]],
        invertedTertiaryText: [lightColors.white[2]],
        invertedPlaceholderText: [lightColors.white[4]],
        divider: [lightColors.black[0]],
        overlay: [lightColors.black[5]],
        strongOverlay: [lightColors.black[8]],
        invertedDivider: [lightColors.white[2]],
        invertedOverlay: [lightColors.white[5]],
        invertedStrongOverlay: [lightColors.white[8]],
        errorStatus: [lightColors.red[6]],
        errorWashStatus: [lightColors.red[1]],
        affirmativeStatus: [lightColors.green[5]],
        affirmativeWashStatus: [lightColors.green[0]],
        successStatus: [lightColors.green[6]],
        successWashStatus: [lightColors.green[1]],
        warningStatus: [lightColors.orange[6]],
        warningWashStatus: [lightColors.orange[1]],
        informativeStatus: [lightColors.blue[6]],
        informativeWashStatus: [lightColors.blue[1]],
        tableRowBackground: [lightColors.gray[1]],
        accent: [lightColors.cyan[5]],
        accentWash: [lightColors.cyan[0]],
        primaryBackgroundHighlight101: [lightColors.cyan[0]],
        secondaryBackgroundHighlight101: [lightColors.cyan[2]],
        textHighlight101: [lightColors.cyan[9]],
        borderHighlight101: [lightColors.cyan[2]],
        invertedBaseBackground: [lightColors.black[9]],
        invertedPrimaryBackground: [lightColors.black[9]],
        invertedSecondaryBackground: [lightColors.gray[9]],
        invertedTertiaryBackground: [lightColors.gray[8]],
        invertedQuaternaryBackground: [lightColors.gray[7]],
        heroImageBackground: [lightColors.white[2]],
        codeSnippetContainerBackground: [lightColors.gray[0]],
        codeSnippetLinesOfCode: [lightColors.gray[2]],
        warningDeprecation: [lightColors.red[6]],
    },
    primaryColor: "black",
    primaryShade: 0,
    components: {
        ...defaultTheme.components,
        ...typographyTheme,
        Paper: {
            defaultProps: {
                shadow: "xs",
            },
            styles: (theme) => ({
                root: {
                    borderColor: theme.colors.divider[0],
                    backgroundColor: theme.colors.primaryBackground[0],
                },
            }),
        },
    },
});
export const lightTheme = createLightTheme(lightColors);

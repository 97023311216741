import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
import { Image } from "@mantine/core";
import { EXTERNAL_URL } from "../../config";
import { isDFSUri, isDFSContentAvailable, constructDFSGatewayUrl, } from "../../utils/dfs";
export const DFSFallbackImage = ({ src, imageRef, ...props }) => {
    var _a;
    const normalizedSrc = (_a = constructDFSGatewayUrl(src !== null && src !== void 0 ? src : "", true)) !== null && _a !== void 0 ? _a : "";
    const [resolvedSrc, setResolvedSrc] = useState(normalizedSrc);
    useEffect(() => {
        if (!normalizedSrc) {
            setResolvedSrc(EXTERNAL_URL.DFS_FALLBACK_IMAGE_URL);
        }
        else if (isDFSUri(resolvedSrc)) {
            isDFSContentAvailable(normalizedSrc)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((available) => {
                setResolvedSrc(available
                    ? constructDFSGatewayUrl(normalizedSrc)
                    : EXTERNAL_URL.DFS_FALLBACK_IMAGE_URL);
            })
                // eslint-disable-next-line unused-imports/no-unused-vars
                .catch((error) => {
                setResolvedSrc(EXTERNAL_URL.DFS_FALLBACK_IMAGE_URL);
            });
        }
    });
    return _jsx(Image, { src: resolvedSrc, ...props, ref: imageRef });
};

import { load } from "@fingerprintjs/fingerprintjs";
import Cookies from "js-cookie";
import { STORAGE_KEYS } from "../config";
import { logError } from "../services/logger";
// Initialize an agent at application startup.
export const setDeviceFingerprint = async () => {
    try {
        const fp = await load().then((fp) => fp.get());
        Cookies.set(STORAGE_KEYS.analyticsDevice, fp.visitorId);
    }
    catch (error) {
        logError({ error, message: "Couldn't generate device fingerprint" });
    }
};
// Get the visitor identifier when you need it.
export const getDeviceFingerprint = () => {
    var _a;
    return (_a = Cookies.get(STORAGE_KEYS.analyticsDevice)) !== null && _a !== void 0 ? _a : "";
};

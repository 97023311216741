import {
  Text,
  Box,
  Anchor,
  Image,
  ChevronIcon,
  Menu,
  Button,
} from "@mantine/core";

import strabismusTokenLinks from "./strabismusTokenLinks.json";

export const StrabismusCopySection = () => {
  return (
    <Box sx={{ paddingTop: 18 }}>
      <Text sx={{ fontStyle: "italic" }}>
        The top 10 bids will each receive an additional limited-edition artwork
        from Sarah’s archive.
      </Text>
      <br />
      <Text sx={{ fontStyle: "italic" }}>
        A collection of 100 looping video artworks by Sarah Zucker. Launching
        May 1st, 2024 at 9am PT via 24 hour ranked auction with a .3 ETH
        starting price. Tokens will be distributed randomly amongst winners.
        Powered by Highlight.
      </Text>
      <br />
      <iframe
        width="100%"
        style={{ aspectRatio: "16/9" }}
        allowFullScreen
        src="https://www.youtube.com/embed/758zyLrrWeE?si=90PE2EmMSEdaq77t"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
      ></iframe>
      <Text size="xl" sx={{ paddingTop: 18 }}>
        “That’s 3D Art. Computers generate ‘em. BIG computers.”
      </Text>
      <br />
      <Text>
        <div>
          - Cosmo Kramer,{" "}
          <Anchor
            sx={{ textDecoration: "underline" }}
            href="https://www.youtube.com/watch?v=Uy9D0lO_0y0"
            target="_blank"
          >
            Seinfeld Season 6, Episode 6
          </Anchor>
        </div>
      </Text>
      <br />
      <div>
        I was born with a magic eye. Thanks to a condition known as
        “Strabismus,” my left eye marches very much to the beat of its own drum.
        You could say that it is “crossed,” but I like to think of it as a bit
        of a maverick.
      </div>
      <br />
      <div>
        Beyond a charming appearance, this has affected my vision my entire
        life. While most people perceive depth physically through the
        stereoscopic coordination of both eyes, I have adapted to calculate
        depth algorithmically in my mind. This often leads to double vision and
        the illusion of depth where there is none (and also causes me to bump
        into the furniture a lot when I’m tired).
      </div>
      <br />
      {/* <Image src="https://highlight-creator-assets.highlight.xyz/custom/strabismus/SarahWork1.jpg" /> */}
      <Image
        src="https://highlight-creator-assets.highlight.xyz/custom/strabismus/Happy.jpg"
        height="200"
      />
      <br />
      <div>
        Ironically, this made it impossible for me to perceive the images hidden
        in the “Magic Eye” series that became popular in my childhood
        (immortalized, of course, on a 1994 episode of Seinfeld.) The
        “unfocused” or “blurry” vision required to witness the illusion was my
        everyday reality, and I would give my tiny self a headache staring at
        those psychedelic color fields and willing them to take form.
      </div>
      <br />
      <div>
        It’s an experience that has stuck with me as I’ve developed my art
        practice working with computer graphics and analog video techniques. My
        new series, Strabismus, explores illusions of depth generated through
        digital video synthesis and analog time displacement.
      </div>
      <br />
      <div>
        Each work in the series aims to convey my own unique experience of depth
        – no crossed eyes required. I approached the creation of the work as a
        generative system, with my own personal optical algorithm and internal
        artistic feedback loop (a BIG computer) serving as the essential spice
        of randomness. The aesthetics emerged, no doubt, thanks to a year
        investigating and luxuriating in the work of Op Art pioneers like
        Bridget Riley, Yaacov Agam and Victor Vasarely.
      </div>
      <br />
      <Image src="https://highlight-creator-assets.highlight.xyz/custom/strabismus/install.jpg" />
      <br />
      <div>
        This work evolved directly out of Temporale, my first long-form series
        editioned on the blockchain. Both projects distort the interlacing of
        analog video signal, where alternating bands of information yield
        motion. By directly manipulating this native aspect of the medium, I am
        able to weave together multiple timelines into a potent fusion of the
        temporal and the spatial.
      </div>
      <br />
      <div>
        Where Temporale channeled textural material video through this
        distortion to capture Time in a still image, Strabismus channels flat
        color motion through the distortion to conjure Depth out of Time.
      </div>
      <br />
      <div>
        I see each of these two series as independent yet undeniably related. I
        have always loved my own fluidity in moving between modes in my work,
        from the figurative to the abstract, the narrative to the
        expressionistic. But these two series reflect a new maturity, a desire
        to dig deeper and manifest my ideas on a greater scale than ever before.
        I remain as playful as ever, but am delighting in flexing my creative
        muscles.
      </div>
    </Box>
  );
};

export const StrabismusSlideMenu = ({
  index,
  name,
}: {
  index?: number;
  name?: string;
}) => {
  const names = [
    "#1 Stratoneiros",
    "#2 Cryocirculus",
    "#3 Serpencarta",
    "#4 Spherovision",
    "#5 Spiracarta",
    "#6 Thermosharpia",
    "#7 Thermocirculus",
    "#8 Glaciacortex",
    "#9 Enigmoptix",
    "#10 Spectrolypse",
    "#11 Noctivelum",
    "#12 Nucleotex",
    "#13 Depthagon",
    "#14 Percepticon",
    "#15 Quadracanalis",
    "#16 Mesmeraether",
    "#17 Orthogonios",
    "#18 Spectravelum",
    "#19 Boaboreal",
    "#20 Stratospectrus",
  ];
  // Must include either name or index
  const finalName = name ? name : names[index!];
  const assetPrefix = finalName + "/" + finalName?.split(" ")[1];
  const links = strabismusTokenLinks as Record<string, { URL: string }>;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      mt={16}
    >
      <Menu position="bottom">
        <Menu.Target>
          <Button color="secondary" size="xs" rightIcon={<ChevronIcon />}>
            Download assets
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            component="a"
            href={links[assetPrefix + "TWITTER.gif"]?.URL}
            target="_blank"
          >
            Twitter optimized GIF
          </Menu.Item>
          <Menu.Item
            component="a"
            href={links[assetPrefix + "WARPCAST.gif"]?.URL}
            target="_blank"
          >
            Farcaster optimized GIF
          </Menu.Item>
          <Menu.Item
            component="a"
            href={links[assetPrefix + "WEB.gif"]?.URL}
            target="_blank"
          >
            Web optimized GIF
          </Menu.Item>
          <Menu.Item
            component="a"
            href={links[assetPrefix + "TOKEN.gif"]?.URL}
            target="_blank"
          >
            Full size GIF
          </Menu.Item>
          <Menu.Item
            component="a"
            href={links[assetPrefix + "_SOCIAL.mp4"]?.URL}
            target="_blank"
          >
            Social share mp4
          </Menu.Item>
          <Menu.Item
            component="a"
            href={links[assetPrefix + "_TOKEN.mp4"]?.URL}
            target="_blank"
          >
            Full size mp4
          </Menu.Item>
          <Menu.Item
            component="a"
            href={links[assetPrefix + "STILL.jpg"]?.URL}
            target="_blank"
          >
            JPEG
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Box>
  );
};

import { defaultTheme } from "../index";
import { darkColors } from "./colors";
import { typographyTheme } from "./typography";
export const darkTheme = {
    ...defaultTheme,
    colorScheme: "dark",
    colors: {
        ...darkColors,
        primaryText: [darkColors.white[9]],
        secondaryText: [darkColors.white[5]],
        tertiaryText: [darkColors.white[2]],
        placeholderText: [darkColors.white[4]],
        baseBackground: [darkColors.gray[9]],
        // Following colors are the result of composing 2 core colors which cannot be specified in here. Check HDS for specific details: https://www.figma.com/file/08c0rHEN4HhY8WHYUWtSLO/HDS?node-id=1618-37616&t=FDuhOzQAnLUjPDrv-0
        primaryBackground: [darkColors.gray[7]],
        secondaryBackground: [darkColors.gray[6]],
        tertiaryBackground: [darkColors.gray[5]],
        quaternaryBackground: [darkColors.gray[4]],
        primaryButton: [
            darkColors.gray[4],
            darkColors.gray[5],
            darkColors.gray[4], // inactive
        ],
        secondaryButton: [
            darkColors.gray[5],
            darkColors.gray[6],
            darkColors.gray[5],
        ],
        outlineButton: [
            darkColors.gray[7],
            darkColors.gray[8],
            darkColors.gray[7],
        ],
        shadowButton: [
            darkColors.gray[5],
            darkColors.gray[6],
            darkColors.gray[5],
        ],
        destructiveButton: [
            darkColors.red[8],
            darkColors.red[7],
            darkColors.red[9],
        ],
        accentButton: [
            darkColors.green[8],
            darkColors.green[7],
            darkColors.green[9],
        ],
        affirmativeButton: [
            darkColors.green[8],
            darkColors.green[7],
            darkColors.green[9],
        ],
        violetButton: [
            darkColors.violet[4],
            darkColors.violet[5],
            darkColors.violet[4],
        ],
        greenButton: [
            darkColors.green[4],
            darkColors.green[5],
            darkColors.green[4],
        ],
        blueButton: [
            darkColors.blue[1],
            darkColors.blue[0],
            darkColors.blue[1],
        ],
        invertedPrimaryText: [darkColors.white[9]],
        invertedSecondaryText: [darkColors.white[5]],
        invertedTertiaryText: [darkColors.white[2]],
        invertedPlaceholderText: [darkColors.white[4]],
        divider: [darkColors.white[0]],
        overlay: [darkColors.black[7]],
        strongOverlay: [darkColors.black[8]],
        errorStatus: [darkColors.red[0]],
        errorWashStatus: [darkColors.red[6]],
        affirmativeStatus: [darkColors.green[0]],
        affirmativeWashStatus: [darkColors.green[6]],
        successStatus: [darkColors.green[0]],
        successWashStatus: [darkColors.green[6]],
        warningStatus: [darkColors.orange[0]],
        warningWashStatus: [darkColors.orange[6]],
        informativeStatus: [darkColors.blue[0]],
        informativeWashStatus: [darkColors.blue[6]],
        tableRowBackground: [darkColors.black[3]],
        accent: [darkColors.cyan[9]],
        accentWash: ["#283C40"],
        primaryBackgroundHighlight101: [darkColors.white[0]],
        secondaryBackgroundHighlight101: [darkColors.white[2]],
        textHighlight101: [darkColors.white[9]],
        borderHighlight101: [darkColors.white[2]],
        invertedBaseBackground: [darkColors.white[9]],
        invertedPrimaryBackground: [darkColors.white[0]],
        invertedSecondaryBackground: [darkColors.gray[2]],
        invertedTertiaryBackground: [darkColors.gray[3]],
        invertedQuaternaryBackground: [darkColors.gray[4]],
        heroImageBackground: [darkColors.black[2]],
        invertedDivider: [darkColors.black[2]],
        invertedOverlay: [darkColors.black[5]],
        invertedStrongOverlay: [darkColors.black[8]],
        codeSnippetContainerBackground: [darkColors.gray[9]],
        codeSnippetLinesOfCode: [darkColors.white[2]],
        warningDeprecation: [darkColors.red[1]],
    },
    primaryColor: "white",
    primaryShade: 0,
    components: {
        ...defaultTheme.components,
        ...typographyTheme,
        Paper: {
            defaultProps: {
                shadow: "xs",
            },
            styles: (theme) => ({
                root: {
                    backgroundColor: theme.colors.primaryBackground[0],
                },
            }),
        },
    },
};

import { useCallback, useRef, useState } from "react";

import ModalSection from "@hl/base-components/lib/modal/ModalSection";
import {
  BACKGROUND_COLOR,
  STATUS_COLOR,
  TEXT_COLOR,
} from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import CryptoCurrencyInput from "@hl/shared-features/lib/features/input/CryptoCurrencyInput";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import { Button, Group, Modal, Stack, Tabs, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { formatEther, parseEther } from "viem";

import {
  InsufficientFundsError,
  RaBidButton,
  useInsuficientFunds,
} from "./BidButton";
import { ClaimFundsButton } from "./claim";
import { useRankedAuction } from "./hooks";
import { RankedAuctionQuery } from "./ra-queries.graphql.generated";

type UserBids = NonNullable<RankedAuctionQuery["rankedAuction"]["userBids"]>;
type UserBid = UserBids[number];

const EditBidForm = ({
  bid,
  onClose,
}: {
  bid: UserBid;
  onClose: () => void;
}) => {
  const { rankedAuction, mintVector } = useRankedAuction();
  const ref = useRef(null);

  const [bidAmount, setBidAmount] = useState("");
  const handleFinish = useCallback(() => {
    onClose();
  }, [onClose]);

  const bidAmountDiff =
    parseEther(bidAmount || "0") - parseEther(bid.bidAmount);
  const { hasEnoughMoney } = useInsuficientFunds(
    formatEther(bidAmountDiff < 0n ? 0n : bidAmountDiff)
  );

  if (!rankedAuction) {
    return null;
  }

  const { lowestBidRequired } = rankedAuction;
  const { chainId } = mintVector;
  const currencySymbol = getCurrencySymbol(
    mintVector.chainId!,
    mintVector.paymentCurrency?.symbol
  );

  return (
    <>
      <Group position="apart" mt={16} align="start">
        <Stack spacing={0}>
          <Text color={TEXT_COLOR.SECONDARY} size="xs" fw={WEIGHT_BOLD}>
            YOUR BID
          </Text>
          <Text size={29} fw={WEIGHT_BOLD}>
            {bid.bidAmount}{" "}
            <Text span size={16}>
              {currencySymbol}
            </Text>
          </Text>
        </Stack>
        <Stack align="flex-end" spacing={0}>
          <Text color={TEXT_COLOR.SECONDARY} size="xs" fw={WEIGHT_BOLD}>
            LOWEST BID REQUIRED
          </Text>
          <Text size={29} fw={WEIGHT_BOLD}>
            {lowestBidRequired}{" "}
            <Text span size={16}>
              {currencySymbol}
            </Text>
          </Text>
        </Stack>
      </Group>
      <Group position="apart" align="start">
        <Stack spacing={0} sx={{ flexGrow: 1 }}>
          <CryptoCurrencyInput
            mt={16}
            missingChainIdPlaceholder=""
            chainId={chainId}
            sx={{ flexGrow: 1 }}
            styles={{
              input: { marginTop: 0 },
            }}
            defaultValue={parseFloat(bid.bidAmount)}
            onChange={(val: number) => {
              setBidAmount((val < 0.000001 ? 0.000001 : val).toString() ?? "0");
            }}
          />
          <InsufficientFundsError hasEnoughMoney={hasEnoughMoney} />
        </Stack>
        {!bid.currentlyValid && (
          <Stack w="40%" spacing={0}>
            <ClaimFundsButton
              label="Reclaim funds"
              bidId={bid.id}
              secondary
              onFinish={onClose}
            />
          </Stack>
        )}
      </Group>
      {!bid.currentlyValid && (
        <Text size="xs" mt="xs">
          Invalid bid; increase bid or reclaim funds to remove bid.
        </Text>
      )}
      <ModalSection>
        <Group noWrap position="apart" mt={16} align="flex-start">
          <Button
            onClick={onClose}
            color={BACKGROUND_COLOR.SECONDARY}
            fullWidth
            size="xl"
            mt={8}
          >
            Cancel
          </Button>
          <RaBidButton
            ref={ref}
            fullWidth
            hasEnoughMoney={hasEnoughMoney}
            // loading={loadingClaimMint || mintDataLoading}
            size="xl"
            initialButtonLabel="Update bid"
            bidAmount={bidAmount}
            bidId={bid.id}
            onFinish={handleFinish}
            disabled={parseFloat(bidAmount) < parseFloat(bid.bidAmount)}
          />
        </Group>
      </ModalSection>
    </>
  );
};

const EditBidsModalBody = ({ onClose }: { onClose: () => void }) => {
  const { rankedAuction } = useRankedAuction();
  const bids = rankedAuction?.userBids ?? [];
  const [activeBid, setActiveBid] = useState<UserBid | null>(bids[0] ?? null);

  const setActiveBidId = useCallback(
    (bidId: string) => {
      const bid = bids.find((x) => x.id === bidId);
      if (bid) {
        setActiveBid(bid);
      }
    },
    [bids]
  );

  if (!rankedAuction) {
    return null;
  }

  if (!bids.length) {
    return <div>error: no bids</div>;
  }

  return (
    <>
      <Tabs value={activeBid?.id} onTabChange={setActiveBidId}>
        <Tabs.List>
          {bids.map((bid) => (
            <Tabs.Tab key={bid.id} value={bid.id}>
              <Text
                size="sm"
                sx={(theme) => ({
                  color: bid.currentlyValid
                    ? theme.fn.themeColor(STATUS_COLOR.AFFIRMATIVE)
                    : theme.fn.themeColor(STATUS_COLOR.ERROR),
                })}
              >
                Bid #{bid.id}
              </Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {bids.map((bid) => (
          <Tabs.Panel key={bid.id} value={bid.id}>
            <EditBidForm bid={bid} onClose={onClose} />
          </Tabs.Panel>
        ))}
      </Tabs>
    </>
  );
};
export const EditBidsButton = () => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Modal opened={opened} onClose={close} title="Edit Bids">
        <EditBidsModalBody onClose={close} />
      </Modal>
      <Button
        color={BACKGROUND_COLOR.SECONDARY}
        fullWidth
        size="xl"
        onClick={open}
      >
        Edit bids
      </Button>
    </>
  );
};

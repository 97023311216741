import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { STATUS_COLOR, TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Box, Center, Group, Text } from "@mantine/core";
import { getProfileDisplayName } from "../../utils/profile";
import { User } from "./User";
export const UserMenuTitle = ({ avatarUrl, displayName, wallet, pendingTxsCount, hideAvatar = false, }) => {
    const walletAddresses = wallet ? [wallet] : undefined;
    if (pendingTxsCount > 0) {
        return (_jsxs(Group, { align: "center", spacing: 8, noWrap: true, children: [_jsx(Box, { sx: (theme) => ({
                        borderRadius: 999,
                        backgroundColor: theme.fn.themeColor(STATUS_COLOR.WARNING),
                        width: 20,
                        height: 20,
                    }), children: _jsx(Center, { h: "100%", w: "100%", children: _jsx(Text, { color: TEXT_COLOR.INVERTED_PRIMARY, size: "xs", weight: WEIGHT_BOLD, children: pendingTxsCount }) }) }), _jsx(Text, { lh: 1, size: "sm", fw: WEIGHT_BOLD, children: getProfileDisplayName(displayName, walletAddresses) })] }));
    }
    return (_jsx(User, { bold: true, avatarUrl: avatarUrl, displayName: displayName, walletAddresses: walletAddresses, enableLink: false, small: hideAvatar }));
};

import { createContext } from "react";
export var ColorSchemeMode;
(function (ColorSchemeMode) {
    ColorSchemeMode["DARK"] = "dark";
    ColorSchemeMode["LIGHT"] = "light";
})(ColorSchemeMode || (ColorSchemeMode = {}));
export var UserColorSchemeSettings;
(function (UserColorSchemeSettings) {
    UserColorSchemeSettings["DARK"] = "dark";
    UserColorSchemeSettings["LIGHT"] = "light";
    UserColorSchemeSettings["SYSTEM"] = "system";
})(UserColorSchemeSettings || (UserColorSchemeSettings = {}));
export default createContext(null);

export const lightColors = {
    black: [
        "rgba(0, 0, 0, 0.1)",
        "rgba(0, 0, 0, 0.2)",
        "rgba(0, 0, 0, 0.3)",
        "rgba(0, 0, 0, 0.4)",
        "rgba(0, 0, 0, 0.45)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0.7)",
        "rgba(0, 0, 0, 0.8)",
        "rgba(0, 0, 0, 0.9)",
        "rgba(0, 0, 0, 1)",
    ],
    white: [
        "rgba(255, 255, 255, 0.1)",
        "rgba(255, 255, 255, 0.2)",
        "rgba(255, 255, 255, 0.3)",
        "rgba(255, 255, 255, 0.4)",
        "rgba(255, 255, 255, 0.45)",
        "rgba(255, 255, 255, 0.6)",
        "rgba(255, 255, 255, 0.7)",
        "rgba(255, 255, 255, 0.8)",
        "rgba(255, 255, 255, 0.9)",
        "rgba(255, 255, 255, 1)",
    ],
    gray: [
        "#FDFDFD",
        "#FAFAFA",
        "#F5F5F5",
        "#EDEDED",
        "#D7D7D7",
        "#A5A5A5",
        "#767676",
        "#575757",
        "#444444",
        "#242424",
    ],
    cyan: [
        "#F0FDFF",
        "#D5FAFF",
        "#BCF5FF",
        "#A4F0FF",
        "#8EE9FC",
        "#79E2F7",
        "#62B8C9",
        "#4C8D9A",
        "#35636C",
        "#1E393E",
    ],
    green: [
        "#F6FEF9",
        "#ECFDF3",
        "#DCFAE6",
        "#ABEFC6",
        "#75E0A7",
        "#47CD89",
        "#17B26A",
        "#018A08",
        "#067647",
        "#015605",
    ],
    blue: [
        "#F5FAFF",
        "#EFF8FF",
        "#D1E9FF",
        "#B2DDFF",
        "#84CAFF",
        "#00C7FF",
        "#2E90FA",
        "#1570EF",
        "#175CD3",
        "#1849A9",
    ],
    orange: [
        "#FEFAF5",
        "#FEF6EE",
        "#FDEAD7",
        "#F9D4AF",
        "#F7B27A",
        "#F38744",
        "#EF6820",
        "#E04F16",
        "#B93815",
        "#932F19",
    ],
    red: [
        "#FFFBFA",
        "#FEF3F2",
        "#FEE4E2",
        "#FECDCA",
        "#FDA29B",
        "#FE6C61",
        "#F04438",
        "#D92D20",
        "#B42318",
        "#912018",
    ],
    violet: [
        "#E3B8FC",
        "#D18AFA",
        "#BF5CF8",
        "#BF5CF8",
        "#8B5CF6",
        "#9B00F4",
        "#7C01C3",
        "#5D0192",
        "#3E0062",
        "#1F0031",
    ],
};

import { createContext, useContext } from "react";

import { MintTokensState, useMintPageTokens } from "~hooks/useMintPageTokens";

import { WithoutNullableKeys } from "../features/MintPage/utils/type-helpers";

export const MintTokensContext = createContext<MintTokensState | null>(null);

export default function useMintTokensState() {
  const context = useContext(MintTokensContext);

  if (context === null) {
    throw new Error(
      "useMintTokensState hook must be used within a MintTokensContextProvider"
    );
  }

  return context;
}

export type MintTokensStateRequired = WithoutNullableKeys<
  ReturnType<typeof useMintPageTokens>
>;

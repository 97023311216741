import { useEffect, useRef } from "react";

import { GaslessClaimStatus } from "@hl/shared-features/lib/apollo/graphql.generated";
import {
  TransactionState,
  TransactionStateType,
  useTransactionsDispatch,
} from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { useGetGaslessTransactionStatusQuery } from "@hl/shared-features/lib/features/evm-tx/queries.graphql.generated";
import { Hash } from "viem";
import { useWaitForTransaction } from "wagmi";

import { TransactionType } from "../apollo/graphql.generated";

const useRedeemRelayerTaskTx = (
  id: string,
  transactionState?: TransactionState
) => {
  const type = transactionState?.transactionType;
  const redeemRelayerTaskId = transactionState?.relayerTaskId;
  let chainId;
  try {
    if (
      transactionState?.transactionType ===
        TransactionType.EVM_CROSSCHAIN_BURN &&
      transactionState?.args?.args
    ) {
      chainId = parseInt(JSON.parse(transactionState?.args?.args ?? "[]")[6]);
    }
  } catch (error) {
    console.error(error);
  }
  const dispatch = useTransactionsDispatch();

  const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>(
    undefined
  );
  const skip =
    type !== TransactionType.EVM_CROSSCHAIN_BURN ||
    !redeemRelayerTaskId ||
    !chainId;

  const { data: gaslessTransactionStatusData, refetch } =
    useGetGaslessTransactionStatusQuery({
      variables: {
        getGaslessTransactionStatusId: redeemRelayerTaskId ?? "",
        chainId: chainId as number,
        type: "Gelato",
      },
      skip,
    });

  const transactionStatus =
    gaslessTransactionStatusData?.getGaslessTransactionStatus;
  const hash = transactionStatus?.hash as `0x${string}`;

  useEffect(() => {
    if (!skip) {
      clearInterval(intervalRef?.current as unknown as number);
      intervalRef.current = setInterval(refetch, 10000);

      return () => clearInterval(intervalRef?.current as unknown as number);
    }
  }, [skip]);

  useEffect(() => {
    if (transactionStatus && transactionStatus.hash) {
      dispatch?.({
        type: "UPDATE_TX_STATUS",
        payload: {
          id,
          redeemHash: transactionStatus.hash as Hash,
        },
      });
      if (transactionStatus.status === GaslessClaimStatus.TX_COMPLETE) {
        clearInterval(intervalRef?.current as unknown as number);
      }
      return;
    }

    if (transactionStatus?.status === GaslessClaimStatus.TX_CANCELLED) {
      dispatch?.({
        type: "UPDATE_TX_STATUS",
        payload: {
          id,
          error: new Error("Crosschain Mint Transaction cancelled"),
        },
      });
      clearInterval(intervalRef?.current as unknown as number);
    }
  }, [transactionStatus]);

  const { data } = useWaitForTransaction({
    hash,
    chainId,
    confirmations: 1,
    enabled: true,
  });

  useEffect(() => {
    if (data) {
      dispatch?.({
        type: "UPDATE_TX_STATE",
        payload: {
          id,
          type: TransactionStateType.Done,
          redeemReceipt: data,
        },
      });
    }
  }, [data]);
};

export default useRedeemRelayerTaskTx;

import { EditionToken } from "../../../apollo/graphql.generated";

export const emptyEdition: EditionToken = {
  name: "",
  description: "",
  image: "",
  onChainImage: "",
  animation: "",
  onChainAnimation: "",
  size: 0,
  onChainMetadata: "",
  remainingSupply: 0,
  nonTransferable: false,
};

import { ApolloCache } from "@apollo/client";

export const setCacheTokenMinted = (cache: ApolloCache<object>, id: string) => {
  cache.modify({
    id: `SeriesToken:${id}`,
    fields: {
      minted: () => true,
      tokenId: () => id,
    },
  });
};

import { useEffect, useState } from "react";
import { useWindowScroll } from "@mantine/hooks";
const useIsScrollTop = (isDisabled = false) => {
    const [isScrollTop, setIsScrollTop] = useState(false);
    const [scroll] = useWindowScroll();
    useEffect(() => {
        if (!isDisabled) {
            setIsScrollTop(!scroll.y);
        }
    }, [scroll]);
    return {
        isScrollTop,
    };
};
export default useIsScrollTop;

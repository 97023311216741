import * as Types from "../../../apollo/graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type RankedAuctionQueryVariables = Types.Exact<{
  mintVectorId: Types.Scalars["String"];
  bidsLimit: Types.Scalars["Int"];
}>;

export type RankedAuctionQuery = {
  rankedAuction: {
    __typename: "RankedAuction";
    id: string;
    size: number;
    rebateWinningBids: boolean;
    minBid: string;
    lowestWinningBid: string;
    lowestBidRequired: string;
    end: string;
    isFinished: boolean;
    userNumTokensLeftToClaim: number | null;
    bids: {
      __typename: "RaBidsPaginated";
      edges: Array<{
        __typename: "RaBid";
        id: string;
        rank: number;
        bidAmount: string;
        bidderAddress: string;
        bidderEnsName: string | null;
        bidderProfile: {
          __typename: "BidderProfile";
          displayName: string | null;
          logoUrl: string | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "CursorPaginatedInfo";
        totalCount: number;
        hasNextPage: boolean;
      };
    };
    userBids: Array<{
      __typename: "RaBid";
      id: string;
      rank: number;
      bidAmount: string;
      bidderAddress: string;
      bidderEnsName: string | null;
      currentlyValid: boolean;
      bidderProfile: {
        __typename: "BidderProfile";
        displayName: string | null;
        logoUrl: string | null;
      } | null;
    }> | null;
  };
};

export type RankedAuctionHistoryQueryVariables = Types.Exact<{
  mintVectorId: Types.Scalars["String"];
  limit: Types.Scalars["Int"];
  offset: Types.Scalars["Int"];
}>;

export type RankedAuctionHistoryQuery = {
  rankedAuction: {
    __typename: "RankedAuction";
    id: string;
    bids: {
      __typename: "RaBidsPaginated";
      edges: Array<{
        __typename: "RaBid";
        id: string;
        rank: number;
        bidAmount: string;
        bidderAddress: string;
        currentlyValid: boolean;
        bidderEnsName: string | null;
        bidderProfile: {
          __typename: "BidderProfile";
          displayName: string | null;
          logoUrl: string | null;
        } | null;
      }>;
      pageInfo: {
        __typename: "CursorPaginatedInfo";
        totalCount: number;
        hasNextPage: boolean;
      };
    };
  };
};

export type RankedAuctionBidTxArgsQueryVariables = Types.Exact<{
  mintVectorId: Types.Scalars["String"];
  input: Types.RaBidInput;
}>;

export type RankedAuctionBidTxArgsQuery = {
  rankedAuctionBidTxArgs: {
    __typename: "TransactionArgs";
    chainId: number;
    address: string | null;
    args: string;
    method: string | null;
    value: string | null;
  };
};

export type ReclaimBidFundsTxArgsQueryVariables = Types.Exact<{
  mintVectorId: Types.Scalars["String"];
  bidId: Types.Scalars["String"];
}>;

export type ReclaimBidFundsTxArgsQuery = {
  rankedAuctionReclaimBidTxArgs: {
    __typename: "TransactionArgs";
    chainId: number;
    address: string | null;
    args: string;
    method: string | null;
    value: string | null;
  };
};

export const RankedAuctionDocument = gql`
  query RankedAuction($mintVectorId: String!, $bidsLimit: Int!) {
    rankedAuction(mintVectorId: $mintVectorId) {
      id
      size
      rebateWinningBids
      minBid
      lowestWinningBid
      lowestBidRequired
      end
      isFinished
      bids(isWinning: true, limit: $bidsLimit) {
        edges {
          id
          rank
          bidAmount
          bidderAddress
          bidderEnsName
          bidderProfile {
            displayName
            logoUrl
          }
        }
        pageInfo {
          totalCount
          hasNextPage
        }
      }
      userNumTokensLeftToClaim
      userBids {
        id
        rank
        bidAmount
        bidderAddress
        bidderEnsName
        bidderProfile {
          displayName
          logoUrl
        }
        currentlyValid
      }
    }
  }
`;

/**
 * __useRankedAuctionQuery__
 *
 * To run a query within a React component, call `useRankedAuctionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRankedAuctionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRankedAuctionQuery({
 *   variables: {
 *      mintVectorId: // value for 'mintVectorId'
 *      bidsLimit: // value for 'bidsLimit'
 *   },
 * });
 */
export function useRankedAuctionQuery(
  baseOptions: Apollo.QueryHookOptions<
    RankedAuctionQuery,
    RankedAuctionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RankedAuctionQuery, RankedAuctionQueryVariables>(
    RankedAuctionDocument,
    options
  );
}
export function useRankedAuctionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RankedAuctionQuery,
    RankedAuctionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RankedAuctionQuery, RankedAuctionQueryVariables>(
    RankedAuctionDocument,
    options
  );
}
export type RankedAuctionQueryHookResult = ReturnType<
  typeof useRankedAuctionQuery
>;
export type RankedAuctionLazyQueryHookResult = ReturnType<
  typeof useRankedAuctionLazyQuery
>;
export type RankedAuctionQueryResult = Apollo.QueryResult<
  RankedAuctionQuery,
  RankedAuctionQueryVariables
>;
export const RankedAuctionHistoryDocument = gql`
  query RankedAuctionHistory(
    $mintVectorId: String!
    $limit: Int!
    $offset: Int!
  ) {
    rankedAuction(mintVectorId: $mintVectorId) {
      id
      bids(isWinning: true, limit: $limit, offset: $offset) {
        edges {
          id
          rank
          bidAmount
          bidderAddress
          currentlyValid
          bidderEnsName
          bidderProfile {
            displayName
            logoUrl
          }
        }
        pageInfo {
          totalCount
          hasNextPage
        }
      }
    }
  }
`;

/**
 * __useRankedAuctionHistoryQuery__
 *
 * To run a query within a React component, call `useRankedAuctionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRankedAuctionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRankedAuctionHistoryQuery({
 *   variables: {
 *      mintVectorId: // value for 'mintVectorId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useRankedAuctionHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    RankedAuctionHistoryQuery,
    RankedAuctionHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RankedAuctionHistoryQuery,
    RankedAuctionHistoryQueryVariables
  >(RankedAuctionHistoryDocument, options);
}
export function useRankedAuctionHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RankedAuctionHistoryQuery,
    RankedAuctionHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RankedAuctionHistoryQuery,
    RankedAuctionHistoryQueryVariables
  >(RankedAuctionHistoryDocument, options);
}
export type RankedAuctionHistoryQueryHookResult = ReturnType<
  typeof useRankedAuctionHistoryQuery
>;
export type RankedAuctionHistoryLazyQueryHookResult = ReturnType<
  typeof useRankedAuctionHistoryLazyQuery
>;
export type RankedAuctionHistoryQueryResult = Apollo.QueryResult<
  RankedAuctionHistoryQuery,
  RankedAuctionHistoryQueryVariables
>;
export const RankedAuctionBidTxArgsDocument = gql`
  query RankedAuctionBidTxArgs($mintVectorId: String!, $input: RaBidInput!) {
    rankedAuctionBidTxArgs(mintVectorId: $mintVectorId, input: $input) {
      chainId
      address
      args
      method
      value
    }
  }
`;

/**
 * __useRankedAuctionBidTxArgsQuery__
 *
 * To run a query within a React component, call `useRankedAuctionBidTxArgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRankedAuctionBidTxArgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRankedAuctionBidTxArgsQuery({
 *   variables: {
 *      mintVectorId: // value for 'mintVectorId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRankedAuctionBidTxArgsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RankedAuctionBidTxArgsQuery,
    RankedAuctionBidTxArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RankedAuctionBidTxArgsQuery,
    RankedAuctionBidTxArgsQueryVariables
  >(RankedAuctionBidTxArgsDocument, options);
}
export function useRankedAuctionBidTxArgsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RankedAuctionBidTxArgsQuery,
    RankedAuctionBidTxArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RankedAuctionBidTxArgsQuery,
    RankedAuctionBidTxArgsQueryVariables
  >(RankedAuctionBidTxArgsDocument, options);
}
export type RankedAuctionBidTxArgsQueryHookResult = ReturnType<
  typeof useRankedAuctionBidTxArgsQuery
>;
export type RankedAuctionBidTxArgsLazyQueryHookResult = ReturnType<
  typeof useRankedAuctionBidTxArgsLazyQuery
>;
export type RankedAuctionBidTxArgsQueryResult = Apollo.QueryResult<
  RankedAuctionBidTxArgsQuery,
  RankedAuctionBidTxArgsQueryVariables
>;
export const ReclaimBidFundsTxArgsDocument = gql`
  query ReclaimBidFundsTxArgs($mintVectorId: String!, $bidId: String!) {
    rankedAuctionReclaimBidTxArgs(mintVectorId: $mintVectorId, bidId: $bidId) {
      chainId
      address
      args
      method
      value
    }
  }
`;

/**
 * __useReclaimBidFundsTxArgsQuery__
 *
 * To run a query within a React component, call `useReclaimBidFundsTxArgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReclaimBidFundsTxArgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReclaimBidFundsTxArgsQuery({
 *   variables: {
 *      mintVectorId: // value for 'mintVectorId'
 *      bidId: // value for 'bidId'
 *   },
 * });
 */
export function useReclaimBidFundsTxArgsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReclaimBidFundsTxArgsQuery,
    ReclaimBidFundsTxArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReclaimBidFundsTxArgsQuery,
    ReclaimBidFundsTxArgsQueryVariables
  >(ReclaimBidFundsTxArgsDocument, options);
}
export function useReclaimBidFundsTxArgsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReclaimBidFundsTxArgsQuery,
    ReclaimBidFundsTxArgsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReclaimBidFundsTxArgsQuery,
    ReclaimBidFundsTxArgsQueryVariables
  >(ReclaimBidFundsTxArgsDocument, options);
}
export type ReclaimBidFundsTxArgsQueryHookResult = ReturnType<
  typeof useReclaimBidFundsTxArgsQuery
>;
export type ReclaimBidFundsTxArgsLazyQueryHookResult = ReturnType<
  typeof useReclaimBidFundsTxArgsLazyQuery
>;
export type ReclaimBidFundsTxArgsQueryResult = Apollo.QueryResult<
  ReclaimBidFundsTxArgsQuery,
  ReclaimBidFundsTxArgsQueryVariables
>;

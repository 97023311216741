import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { createStyles, Space, Stack } from "@mantine/core";
import TxHistoryItem from "./TxHistoryItem";
const useStyles = createStyles(() => ({
    item: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        "&:last-of-type": {
            borderBottom: "0",
        },
    },
}));
const OperationList = ({ operations, manageCollectionUrl, mintTokenUrl, }) => {
    const { classes } = useStyles();
    const sorted = operations.sort((a, b) => {
        if (a.status === "PENDING" && b.status !== "PENDING") {
            return -1;
        }
        else if (a.status !== "PENDING" && b.status === "PENDING") {
            return 1;
        }
        else {
            return (Date.parse(b.updatedAt).valueOf() - Date.parse(a.updatedAt).valueOf());
        }
    });
    return (_jsx(Stack, { children: sorted.map((operation, i, arr) => (_jsxs("div", { className: classes.item, children: [_jsx(TxHistoryItem, { operation: operation, manageCollectionUrl: manageCollectionUrl, mintTokenUrl: mintTokenUrl }), i != arr.length - 1 && _jsx(Space, { h: "md" })] }, operation.txHash))) }));
};
export default OperationList;

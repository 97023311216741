import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Box, keyframes, useMantineTheme } from "@mantine/core";
import { TEXT_COLOR } from "./theme/colors";
const pulsate = keyframes({
    "0%": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 0 var(--var-pulse-color, rgba(0, 0, 0, 0.7))",
    },
    "70%": {
        transform: "scale(1)",
        boxShadow: "0 0 0 var(--var-pulse-size, 10px) rgba(0, 0, 0, 0)",
    },
    "100%": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
    },
});
const PulsatingStatusIndicator = (props) => {
    const { size = 24, color = TEXT_COLOR.PRIMARY, paused = false } = props;
    const theme = useMantineTheme();
    const resolvedColor = theme.fn.rgba(theme.fn.themeColor(color), 1.0);
    const resolvedPulseColor = theme.fn.rgba(theme.fn.themeColor(color), 0.7);
    return (_jsx(Box, { sx: {
            "--var-pulse-color": resolvedPulseColor,
            "--var-pulse-size": Math.max(size / 3, 4) + "px",
            width: size,
            height: size,
            background: resolvedColor,
            border: "1px solid transparent",
            borderRadius: "100%",
            transform: "scale(1)",
            animation: paused ? "" : `${pulsate} 2s infinite`,
        } }));
};
export default PulsatingStatusIndicator;

import React, { memo } from "react";

import { ArrowUpRight } from "@hl/base-components/lib/assets/icons.generated";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { useAuth } from "@hl/shared-features/lib/features/auth";
import { ResizedImage } from "@hl/shared-features/lib/features/image";
import {
  buildContractTokenUrl,
  networkLookup,
} from "@hl/shared-features/lib/utils/blockExplorer";
import { maskAddress } from "@hl/shared-features/lib/utils/content";
import { Anchor, createStyles, Group, Stack, Text } from "@mantine/core";
import { useTokens } from "@reservoir0x/reservoir-kit-ui";
import { IconCircleCheck } from "@tabler/icons";

import useMintState from "~hooks/useMintState";

const useStyle = createStyles(() => ({
  requirement: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    fontSize: 12,
    fontWeight: WEIGHT_BOLD,
    textTransform: "uppercase",
  },
  link: {
    textDecoration: "none",
  },
}));

const BurnAndMint = () => {
  const { classes, theme } = useStyle();
  const { chainId, mintVector, hasEnoughToBurnAndRedeem, userBurnBalance1155 } =
    useMintState();
  const { walletAddress } = useAuth();
  const burnRedeem = mintVector?.customProjectData?.burnRedeem;
  const { data: tokensData } = useTokens({
    tokens: [`${burnRedeem?.burn1155Address}:${burnRedeem?.burnTokenId}`],
  });
  const token = tokensData?.[0]?.token;
  const blockchain = networkLookup(chainId);
  const url = buildContractTokenUrl(
    blockchain.type,
    burnRedeem?.burn1155Address ?? ""
  );
  const maskedAddress = maskAddress(burnRedeem?.burn1155Address, 4, 4);
  const balanceFormatted = userBurnBalance1155?.toString();

  return (
    <Stack spacing={8}>
      <Text size="lg" fw={WEIGHT_BOLD}>
        Burn to redeem this mint
      </Text>
      <Text size="sm">
        {hasEnoughToBurnAndRedeem
          ? "To mint, you must burn the following tokens. This may prompt two transactions, one to grant permissions and one to burn & mint:"
          : "To unlock it, you must meet all of the following requirements:"}
      </Text>
      <Group pt={12} pb={8} mb={8}>
        <ResizedImage height={68} width={68} src={token?.imageSmall} />
        <Stack spacing={2}>
          <Text
            className={classes.requirement}
            color={
              hasEnoughToBurnAndRedeem
                ? theme.colors.successStatus[0]
                : undefined
            }
          >
            {hasEnoughToBurnAndRedeem && (
              <IconCircleCheck width={14} height={14} />
            )}
            Burn {burnRedeem?.numToBurnPerToken} tokens from
          </Text>
          <Text size="sm" fw={WEIGHT_BOLD}>
            {token?.collection?.name}
          </Text>
          <Anchor
            style={{ textDecoration: "none" }}
            href={url}
            target="_blank"
            rel="noreferrer"
          >
            {walletAddress && (
              <Text size="sm" sx={{ alignItems: "center", display: "flex" }}>
                You hold {balanceFormatted} tokens · {maskedAddress}
                <ArrowUpRight />
              </Text>
            )}
          </Anchor>
        </Stack>
      </Group>
    </Stack>
  );
};

export default memo(BurnAndMint);

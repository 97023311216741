import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { memo } from "react";
import { useTransactions } from "./TransactionContext";
import TransactionLifecycle from "./TransactionLifecycle";
const TransactionsManager = () => {
    var _a;
    const transactions = (_a = useTransactions()) !== null && _a !== void 0 ? _a : {};
    const ids = Object.keys(transactions);
    const transactionManagers = ids.map((id) => {
        return _jsx(TransactionLifecycle, { id: id, state: transactions[id] }, id);
    });
    return _jsx(_Fragment, { children: transactionManagers });
};
export default memo(TransactionsManager);

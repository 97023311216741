import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { SlashCircle } from "@hl/base-components/lib/assets/icons.generated";
import { DESTRUCTIVE_COLOR, SECONDARY_COLOR, } from "@hl/base-components/lib/theme/button";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Button, Center, Grid, Loader, Space, Stack, Text, useMantineTheme, } from "@mantine/core";
import { CancelListingModal, CancelListingStep, } from "@reservoir0x/reservoir-kit-ui";
import { USER_ROUTES } from "../../../config";
import { useModalStack } from "../../modal";
import MarketplaceConnectButton, { useMarketplaceAuth, } from "../components/MarketplaceConnectButton";
import MarketplaceSummaryCard from "../components/MarketplaceSummaryCard";
import { useGetTokensStatsQuery } from "../queries/marketplace.graphql.generated";
import ListModalHighlight from "./ListModal";
export const CancelListingModalTitle = (isDone) => (_jsx(Text, { fw: WEIGHT_BOLD, sx: { whiteSpace: "pre" }, children: isDone ? "Listing cancelled" : "Cancel listing" }));
const CancelListingModalHighlight = (props) => (_jsx(Stack, { pt: 16, spacing: 40, children: _jsx(CancelListingModal.Custom, { chainId: props.chainId, open: true, listingId: props.id, children: (cancelProps) => _jsx(CancelListingModalInner, { ...props, ...cancelProps }) }) }));
const CancelListingModalInner = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { listing, cancelStep, transactionError, cancelOrder, imageUrl, chainId, tokenId, collectionName, onClose, floorPrice, id, collectionId, onChainId, } = props;
    const [error, setError] = useState();
    const price = (_b = (_a = listing === null || listing === void 0 ? void 0 : listing.price) === null || _a === void 0 ? void 0 : _a.amount) === null || _b === void 0 ? void 0 : _b.native;
    const symbol = (_d = (_c = listing === null || listing === void 0 ? void 0 : listing.price) === null || _c === void 0 ? void 0 : _c.currency) === null || _d === void 0 ? void 0 : _d.symbol;
    const theme = useMantineTheme();
    const { isFullyAuthenticated, walletAddress } = useMarketplaceAuth();
    const { popModal, setModalProps, pushModal } = useModalStack();
    const canCancel = !(listing === null || listing === void 0 ? void 0 : listing.status) || ((_e = listing === null || listing === void 0 ? void 0 : listing.status) === null || _e === void 0 ? void 0 : _e.includes("active"));
    const listingsUrl = USER_ROUTES.user.marketplace.listings.replace(":slug", walletAddress !== null && walletAddress !== void 0 ? walletAddress : "");
    const quantity = (listing === null || listing === void 0 ? void 0 : listing.quantityRemaining) || 1;
    const { data, loading } = useGetTokensStatsQuery({
        variables: {
            onChainIds: [onChainId],
        },
        skip: !onChainId || !!floorPrice,
    });
    const actionButton = useMemo(() => {
        var _a;
        if (!isFullyAuthenticated)
            return _jsx(MarketplaceConnectButton, {});
        if (listing &&
            (walletAddress === null || walletAddress === void 0 ? void 0 : walletAddress.toLowerCase()) !== ((_a = listing === null || listing === void 0 ? void 0 : listing.maker) === null || _a === void 0 ? void 0 : _a.toLowerCase())) {
            return (_jsx(Button, { size: "xl", disabled: true, children: "You're not the owner" }));
        }
        return (_jsxs(Grid, { children: [_jsx(Grid.Col, { sm: 6, children: _jsx(Button, { component: "a", disabled: !walletAddress, href: listingsUrl, target: "_blank", onClick: () => {
                            popModal();
                            if (cancelStep === CancelListingStep.Complete)
                                onClose === null || onClose === void 0 ? void 0 : onClose();
                        }, size: "xl", color: SECONDARY_COLOR, w: "100%", children: "Manage listings" }) }), _jsx(Grid.Col, { sm: 6, children: cancelStep !== CancelListingStep.Complete ? (_jsxs(Button, { size: "xl", onClick: () => {
                            setError("");
                            cancelOrder();
                        }, loading: !listing || cancelStep === CancelListingStep.Approving, color: DESTRUCTIVE_COLOR, w: "100%", disabled: !listing ||
                            cancelStep === CancelListingStep.Approving ||
                            !canCancel, children: [listing && cancelStep !== CancelListingStep.Approving && (_jsx(SlashCircle, { style: { marginRight: 8 } })), canCancel ? "Cancel listing" : "Already cancelled"] })) : (_jsx(Button, { size: "xl", onClick: () => {
                            if ((listing === null || listing === void 0 ? void 0 : listing.contract) && chainId) {
                                onClose === null || onClose === void 0 ? void 0 : onClose();
                                popModal();
                                pushModal(_jsx(ListModalHighlight, { src: imageUrl, tokenId: tokenId, collectionData: {
                                        name: collectionName,
                                        address: listing === null || listing === void 0 ? void 0 : listing.contract,
                                        chainId,
                                        id: collectionId,
                                        // TODO: get onchainId
                                        onchainId: null,
                                    } }), {
                                    size: "lg",
                                    title: _jsx(Text, { fw: WEIGHT_BOLD, children: "Create a listing" }),
                                });
                            }
                        }, w: "100%", children: "Create listing" })) })] }));
    }, [
        isFullyAuthenticated,
        transactionError,
        cancelStep,
        canCancel,
        listing,
        walletAddress,
        chainId,
        tokenId,
        id,
        chainId,
        collectionName,
        imageUrl,
        cancelOrder,
    ]);
    useEffect(() => {
        if (!transactionError) {
            setError("");
        }
        else {
            setError(transactionError
                .shortMessage || "Ooops, something went wrong");
        }
    }, [transactionError]);
    useEffect(() => {
        var _a;
        if (cancelStep === CancelListingStep.Complete) {
            setModalProps({ title: CancelListingModalTitle(true) });
            (_a = props.onCancel) === null || _a === void 0 ? void 0 : _a.call(props);
        }
    }, [cancelStep]);
    if (loading) {
        return (_jsx(Center, { children: _jsx(Loader, {}) }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(MarketplaceSummaryCard, { symbol: symbol, price: price, imageUrl: imageUrl, tokenId: tokenId, collectionName: collectionName, validUntil: (listing === null || listing === void 0 ? void 0 : listing.expiration) ? listing.expiration * 1000 : undefined, floorPrice: floorPrice ||
                    ((_g = (_f = data === null || data === void 0 ? void 0 : data.getTokensStats[0]) === null || _f === void 0 ? void 0 : _f.floorListing) === null || _g === void 0 ? void 0 : _g.price.amount.native), quantity: quantity, isListing: true, subtext: cancelStep === CancelListingStep.Complete ? (_jsx(Text, { color: theme.colors.errorStatus[0], size: "sm", children: "Listing cancelled" })) : undefined }), _jsx(Stack, { children: _jsxs(Stack, { spacing: 8, children: [_jsx(Space, { h: 8, mx: -16, sx: {
                                borderTop: `1px solid ${theme.colors.divider[0]}`,
                                width: "auto !important",
                            } }), _jsx(Stack, { sx: { overflow: "hidden" }, children: actionButton }), !!error && (_jsx(Text, { size: "xs", align: "center", color: theme.colors.errorStatus[0], children: error }))] }) })] }));
};
export default CancelListingModalHighlight;

import { getDetailPageUrl, getMintPageUrl, TOOLS_ROUTES, } from "../../../config";
import { useGetPendingEvmTransactionsQuery, useGetRecentEvmTransactionsQuery, } from "../../tx-history/queries.graphql.generated";
const RECENT_HOURS = 24;
export const useTxHistory = () => {
    var _a, _b, _c;
    const { data: recent, loading: isRecentLoading } = useGetRecentEvmTransactionsQuery({
        variables: { hours: RECENT_HOURS },
    });
    const { data: pending, loading: isPendingLoading } = useGetPendingEvmTransactionsQuery();
    const loading = isRecentLoading || isPendingLoading;
    const txSet = new Set();
    ((_a = recent === null || recent === void 0 ? void 0 : recent.getRecentEvmTransactions) !== null && _a !== void 0 ? _a : []).forEach((tx) => txSet.add(tx.txHash));
    ((_b = pending === null || pending === void 0 ? void 0 : pending.getPendingEvmTransactions) !== null && _b !== void 0 ? _b : []).forEach((tx) => txSet.add(tx.txHash));
    const txs = [];
    for (const txHash of txSet) {
        const tx = (_c = recent === null || recent === void 0 ? void 0 : recent.getRecentEvmTransactions.find((tx) => tx.txHash === txHash)) !== null && _c !== void 0 ? _c : pending === null || pending === void 0 ? void 0 : pending.getPendingEvmTransactions.find((tx) => tx.txHash === txHash);
        if (tx) {
            txs.push(tx);
        }
    }
    const pendingTxs = txs.filter((tx) => tx.status === "PENDING");
    const manageCollectionUrl = (collectionId) => TOOLS_ROUTES.collections.manage.replace(":collectionId", collectionId);
    const mintTokenUrl = (collectionId, tokenIds) => {
        if (!(tokenIds === null || tokenIds === void 0 ? void 0 : tokenIds.length)) {
            return getMintPageUrl({ id: collectionId, onchainId: null });
        }
        return getDetailPageUrl({
            id: collectionId,
            onchainId: null,
        }, tokenIds[0]);
    };
    return {
        loading,
        pendingTxs,
        txs,
        manageCollectionUrl,
        mintTokenUrl,
    };
};

import { forwardRef, memo, ReactNode, Ref } from "react";

import { STATUS_COLOR } from "@hl/base-components/lib/theme/colors";
import { Button, ButtonProps, Menu, Text, Box, Stack } from "@mantine/core";

import {
  ChainOption,
  useCrossChainMint,
} from "~features/MintPage/hooks/useCrossChainMint";
import { UserStatus } from "~features/MintPage/utils/types";

export type CrossChainMintButtonProps = {
  userStatus: UserStatus;
  showWarningModals?: boolean;
  disabledMint: boolean;
  hasEnoughMoney: boolean;
  chainId: number;
  label: ReactNode;
  tokenId?: string | null;
  onClickHandler: (chainId: number, isRetry?: boolean) => void;
} & Partial<ButtonProps>;

const CrossChainMintButton = forwardRef(
  (
    {
      userStatus,
      showWarningModals,
      disabledMint,
      hasEnoughMoney,
      chainId,
      label,
      onClickHandler,
      tokenId,
      ...rest
    }: CrossChainMintButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const {
      options,
      currentChain,
      supported,
      loading: supportedLoading,
    } = useCrossChainMint(chainId);

    const getChainOptionComponent = (
      chainOption: ChainOption,
      hasEnoughMoney = true
    ) => {
      return (
        <Menu.Item
          key={chainOption.id}
          icon={chainOption.icon}
          onClick={
            hasEnoughMoney
              ? () => {
                  onClickHandler(chainOption.id);
                }
              : undefined
          }
          maw={300}
          rightSection={chainOption.balance}
          disabled={!hasEnoughMoney}
        >
          <Text size="sm" mr={10}>
            {chainOption.label}
          </Text>
          {!hasEnoughMoney && (
            <Text size="xs" color={STATUS_COLOR.ERROR}>
              Insufficient Funds
            </Text>
          )}
        </Menu.Item>
      );
    };

    const defaultOption = options.find((o) => o.default);
    const otherOptions = options.filter((o) => !o.default);
    const noCrosschainNoFunds =
      !supportedLoading && !supported && !hasEnoughMoney;
    return (
      <Menu
        width={tokenId != null ? undefined : "target"}
        disabled={!supported || (hasEnoughMoney && currentChain === chainId)}
      >
        <Menu.Target>
          <Box w="100%">
            <Button
              {...rest}
              ref={ref}
              onClick={
                !supported || (hasEnoughMoney && currentChain === chainId)
                  ? () => {
                      onClickHandler(chainId);
                    }
                  : undefined
              }
              disabled={
                noCrosschainNoFunds ||
                disabledMint ||
                (!showWarningModals && userStatus === UserStatus.LIMIT_REACHED)
              }
            >
              {label}
            </Button>
            {noCrosschainNoFunds && (
              <Text size="sm" color={STATUS_COLOR.ERROR} pt="sm">
                Insufficient Funds
              </Text>
            )}
          </Box>
        </Menu.Target>
        <Menu.Dropdown miw={250}>
          {options && options.length && (
            <Stack spacing={0}>
              <Menu.Label>Default</Menu.Label>
              {defaultOption &&
                getChainOptionComponent(defaultOption, hasEnoughMoney)}
              <Menu.Label>Other Options</Menu.Label>
              {otherOptions.map((o) => getChainOptionComponent(o))}
            </Stack>
          )}
        </Menu.Dropdown>
      </Menu>
    );
  }
);

export default memo(CrossChainMintButton);

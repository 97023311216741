import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const GlobalSearchDocument = gql `
  query GlobalSearch($query: String!, $limit: Int!) {
    globalSearch(query: $query, limit: $limit) {
      collections {
        id
        onchainId
        name
        logoUrl
        importData {
          logoUrl
        }
        creatorAccountSettings(withEns: true) {
          verified
          imported
          displayAvatar
          displayName
          walletAddresses
        }
      }
      accountSettings {
        id
        displayName
        verified
        imported
        displayAvatar
        wallet {
          address
          ensName
        }
      }
    }
  }
`;
/**
 * __useGlobalSearchQuery__
 *
 * To run a query within a React component, call `useGlobalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGlobalSearchQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GlobalSearchDocument, options);
}
export function useGlobalSearchLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GlobalSearchDocument, options);
}

import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { BrandHighlightLight, BrandJustLogo, } from "@hl/base-components/lib/assets/icons.generated";
import { BackgroundImage, Button, Space, Container, Text, Stack, useMantineTheme, Box, } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { UserNavbar } from "../auth/UserNavbar";
const fillWhiteStyle = css `
  path {
    fill: white;
  }
`;
export const Error = ({ redirectUrl, buttonLabel, code, description, loadLargeImage, loadSmallImage, inner, }) => {
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs - 1}px)`, undefined, { getInitialValueInEffect: false });
    const [image, setImage] = useState("");
    useEffect(() => {
        if (isMobile) {
            loadSmallImage(setImage);
        }
        else {
            loadLargeImage(setImage);
        }
    }, [isMobile]);
    const codeTextSize = isMobile ? 150 : 360;
    const descriptionTextSize = isMobile ? 20 : 56;
    return (_jsx(Container, { fluid: true, sx: { height: "100vh", width: "100%" }, p: 0, children: _jsx(BackgroundImage, { src: image, style: { height: "100%" }, children: _jsxs(Stack, { align: "center", justify: "space-between", style: { height: "100%" }, pb: "lg", pt: "xl", children: [_jsxs(Box, { sx: { position: "relative", textAlign: "center" }, w: "100%", children: [_jsx(BrandHighlightLight, { css: fillWhiteStyle, height: 14 }), !inner && (_jsx(Box, { sx: { position: "absolute", right: 10, top: -10 }, children: _jsx(UserNavbar, {}) }))] }), _jsxs(Stack, { align: "center", justify: "center", children: [code && (_jsx(Text, { color: "#FFFFFF", size: codeTextSize, lh: `${codeTextSize}px`, children: code })), _jsx(Text, { color: "#FFFFFF", size: descriptionTextSize, children: description }), _jsx(Space, { h: "xl" }), _jsx(Button, { variant: "white", color: "dark", component: "a", href: redirectUrl, children: buttonLabel })] }), _jsx(BrandJustLogo, { css: fillWhiteStyle, height: 15 })] }) }) }));
};

import React, { useEffect, useLayoutEffect, useState } from "react";

import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { Carousel, Embla } from "@mantine/carousel";
import { Box, Loader } from "@mantine/core";
import { useIntersection, useViewportSize } from "@mantine/hooks";
import { EmblaCarouselType } from "embla-carousel-react";

import { useSlideStyles } from "../../features/MintPage/header/MintCarouselCommon";
import MintCarouselSlide from "../../features/MintPage/header/MintCarouselSlide";

export type SlideProps = {
  imageUrl?: string | null;
  animationUrl?: string;
  selected: boolean;
  minted?: boolean | null;
  imagePlaceholder?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onImageError?: (object: any) => void;
  name?: string;
  index?: number;
  fullHeight?: boolean;
  slideSize: number;
  onAspectRatio?: (aspectRatio: number) => void;
};

type Token = {
  imageUrl?: string | null;
  tokenId?: string | null;
  minted?: boolean | null;
};

type PropType = {
  tokens: Token[];
  isLoading: boolean;
  hasMoreToLoad: boolean;
  onFetchMore: () => void;
  getEmblaApi?: (embla: EmblaCarouselType) => void;
  onSlideChange: (index: number) => void;
  imagePlaceholder?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onImageError?: (object: any) => void;
  fullHeight?: boolean;
  containerWidth?: number;
  containerHeight?: number;
};

export const InfiniteCarousel: React.FC<PropType> = (props) => {
  const {
    tokens,
    isLoading,
    hasMoreToLoad,
    onFetchMore,
    getEmblaApi,
    onSlideChange,
    imagePlaceholder,
    onImageError,
    fullHeight,
    containerWidth,
    containerHeight,
  } = props;
  const { ref, entry } = useIntersection({ threshold: 0 });
  const { isMobile, isExtraLarge } = useMantineMediaQueries({
    withInitialValues: true,
  });
  const { width: viewportWidth, height: viewportHeight } = useViewportSize();
  // approximate containerWidth via viewport: 516px is sidebar width + artwork container padding
  const viewportWidthNormalized = viewportWidth - 516;
  // approximate containerWidth via viewport: 136px is navbar height + artwork container padding + token info section height
  const viewportHeightNormalized = viewportHeight - 136;
  const width = containerWidth || viewportWidthNormalized;
  // height of the token info section is 90px
  const height = containerHeight
    ? containerHeight - 90
    : viewportHeightNormalized;
  const viewportAspectRatio = width / height;
  const [smallestAspectRatio, setSmallestAspectRatio] = useState(5);
  const [largestAspectRatio, setLargestAspectRatio] = useState(0);
  const makeSlideSmaller = smallestAspectRatio * 2.5 < largestAspectRatio;

  // create as big a slide as possible.
  // If the aspect ratio of the slide is bigger than the viewport aspect ratio,
  // use viewport width as a basis for slideSize, else take viewport height
  // If artworks have different aspect ratios, make the slide smaller to prevent blank space between artworks
  // If artwork is vertical, shrink slides by a factor of the smallest aspect ratio
  const slideSize = isMobile
    ? 270
    : isExtraLarge
    ? smallestAspectRatio >= viewportAspectRatio
      ? makeSlideSmaller
        ? width / 2
        : width - 180
      : makeSlideSmaller
      ? height / 2
      : height * smallestAspectRatio
    : 360;

  const { classes } = useSlideStyles({
    selected: false,
    isMobile,
    imagesCount: Infinity,
    fullHeight,
    slideSize,
  });

  const [startingIndex] = useState(
    tokens.length > 2 ? Math.floor(tokens.length / 2) - 1 : 0
  );
  const [selectedIndex, setSelectedIndex] = useState(startingIndex);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    setLoadingMore(false);
  }, [tokens, setLoadingMore]);

  const [emblaApi, setEmbla] = useState<Embla | null>(null);

  useLayoutEffect(() => {
    if (emblaApi && slideSize) {
      emblaApi.reInit();
    }
  }, [slideSize, emblaApi]);

  useLayoutEffect(() => {
    if (getEmblaApi && emblaApi) {
      getEmblaApi(emblaApi);
      emblaApi.scrollTo(startingIndex, true);
    }
  }, [getEmblaApi, emblaApi, startingIndex]);

  useEffect(() => {
    onSlideChange(selectedIndex);
  }, [onSlideChange, selectedIndex]);

  useEffect(() => {
    if (isLoading) return;
    if (!entry?.isIntersecting || !hasMoreToLoad) return;
    onFetchMore();
  }, [loadingMore, entry?.isIntersecting, hasMoreToLoad]);

  return (
    <Carousel
      initialSlide={startingIndex}
      getEmblaApi={setEmbla}
      align="center"
      slideSize={slideSize}
      w="100%"
      draggable
      withKeyboardEvents={false}
      withControls={false}
      inViewThreshold={0}
      containScroll="keepSnaps"
      onSlideChange={setSelectedIndex}
      classNames={{
        viewport: classes.viewport,
        root: classes.rootInfinite,
        slide: classes.slide,
        container: classes.container,
      }}
    >
      {tokens.map((token, index) => (
        <MintCarouselSlide
          key={index}
          {...token}
          selected={selectedIndex === index}
          imagePlaceholder={imagePlaceholder}
          onImageError={onImageError}
          index={index}
          fullHeight={fullHeight}
          slideSize={slideSize}
          onAspectRatio={(aspectRatio) => {
            if (aspectRatio < smallestAspectRatio) {
              setSmallestAspectRatio(aspectRatio);
            }
            if (aspectRatio > largestAspectRatio) {
              setLargestAspectRatio(aspectRatio);
            }
          }}
        />
      ))}
      {hasMoreToLoad && (
        <Carousel.Slide
          display="flex"
          sx={{ alignItems: "center", justifyContent: "center" }}
        >
          <Box ref={ref}>
            <Loader />
          </Box>
        </Carousel.Slide>
      )}
    </Carousel>
  );
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { forwardRef, useCallback, useMemo, useState } from "react";
import { Minimize as MinimizeIcon } from "@hl/base-components/lib/assets/icons.generated";
import AppleLogo from "@hl/base-components/lib/assets/icons.generated/AppleLogo";
import Calendar from "@hl/base-components/lib/assets/icons.generated/Calendar";
import GoogleLogo from "@hl/base-components/lib/assets/icons.generated/GoogleLogo";
import OutlookLogo from "@hl/base-components/lib/assets/icons.generated/OutlookLogo";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { ActionIcon, Anchor, Box, Button, createStyles, Flex, Group, Image, Modal, Stack, Text, Title, useMantineTheme, } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import { ampli } from "../../ampli";
import { _CollectionType } from "../../apollo/graphql.generated";
import { envConfig } from "../../config";
import { generateCalendarLinks } from "../../utils/calendar";
import { useEmbedMode } from "../auth/hooks";
import { GenArtIFrame } from "../gen-art/GenArtIFrame";
import { useGenParams } from "../gen-art/gen-art-utils";
import GenerativeDisplayToolbar from "./GenerativeDisplayToolbar";
const useStyles = createStyles({
    modalHeader: {
        position: "absolute",
        right: 16,
        border: "none",
    },
    fullHeight: {
        height: "100%",
    },
    wrapper: {
        height: "100%",
        display: "flex",
    },
    image: {
        maxHeight: "100%",
        width: "unset !important",
        maxWidth: "100%",
        margin: "auto",
    },
    expanded: {
        height: `calc(100vh - 30px)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    closeBtn: {
        position: "absolute",
        top: 10,
        right: 10,
    },
});
var CalendarType;
(function (CalendarType) {
    CalendarType["Google"] = "Google";
    CalendarType["Apple"] = "Apple";
    CalendarType["Outlook"] = "Outlook";
})(CalendarType || (CalendarType = {}));
const AddToCalendarButton = forwardRef(({ collection, name, description, date, location, collectionId, imageUrl, animationUrl, creator, visualElement, buttonInnerElement, modalTitle, modalSubtitle, hideImage, calendarEvent: customCalendarEvent, ...btnProps }, ref) => {
    var _a, _b, _c;
    const spacing = btnProps.size === "xl" ? 10 : 8;
    const [openedModal, setOpenedModal] = useState(false);
    const theme = useMantineTheme();
    const { isEmbedMode } = useEmbedMode();
    const isSmallAndUp = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px`);
    const { classes } = useStyles();
    const details = collection === null || collection === void 0 ? void 0 : collection.generativeDetails;
    const captureSettings = details === null || details === void 0 ? void 0 : details.captureSettings;
    const [opened, { open: openExpandArtwork, close: closeExpandArtwork }] = useDisclosure(false);
    const { params, refreshIframe, randomizeAll } = useGenParams({
        allowedHashes: details === null || details === void 0 ? void 0 : details.allowedHashes,
        size: (collection === null || collection === void 0 ? void 0 : collection.size) || undefined,
        fixEditionSize: true,
        fixedChainId: collection === null || collection === void 0 ? void 0 : collection.chainId,
        fixedContractAddress: collection === null || collection === void 0 ? void 0 : collection.address,
        isCurated: !!((_a = collection === null || collection === void 0 ? void 0 : collection.generativeDetails) === null || _a === void 0 ? void 0 : _a.curateOutput),
    });
    const getMintPageUrl = (collectionId) => {
        return isEmbedMode
            ? window.location.href
            : `${envConfig.mint.baseUrl}/${collectionId}`;
    };
    const calendarEvent = customCalendarEvent !== null && customCalendarEvent !== void 0 ? customCalendarEvent : {
        title: `${name}${creator ? ` by ${creator}` : ""}${!isEmbedMode ? " on Highlight" : ""} `,
        description: description || "",
        location: (location !== null && location !== void 0 ? location : collectionId)
            ? getMintPageUrl(collectionId)
            : envConfig.home.baseUrl,
        startDate: dayjs(date).toISOString(),
        endDate: dayjs(date).add(30, "m").toISOString(),
    };
    const links = generateCalendarLinks(calendarEvent);
    const calendars = [
        {
            label: "Add to Google Calendar",
            icon: _jsx(GoogleLogo, {}),
            href: links.google,
            type: CalendarType.Google,
        },
        {
            label: "Add to Apple Calendar",
            icon: _jsx(AppleLogo, {}),
            href: links.apple,
            isDownload: true,
            type: CalendarType.Apple,
        },
        {
            label: "Add to Outlook",
            icon: _jsx(OutlookLogo, {}),
            href: links.outlook,
            type: CalendarType.Outlook,
        },
    ];
    const artworkAspectRatio = useMemo(() => {
        if (captureSettings == null)
            return 1;
        if (captureSettings.viewPort == null)
            return 1;
        return captureSettings.viewPort.width / captureSettings.viewPort.height;
    }, [captureSettings]);
    const trackUsage = useCallback((calendarType) => {
        if (!ampli.isLoaded) {
            return;
        }
        ampli.addToCalendar({
            calendarType,
            collectionId,
            artist: creator !== null && creator !== void 0 ? creator : "",
            projectName: name,
            pagePath: window.location.pathname,
        });
    }, [collectionId, creator, name]);
    const modalSize = isSmallAndUp ? (hideImage ? "lg" : "xl") : "100%";
    const stackWidth = isSmallAndUp ? (hideImage ? "100%" : "50%") : "100%";
    const isArtworkGenerative = (collection === null || collection === void 0 ? void 0 : collection.collectionType) === _CollectionType.GenerativeSeries &&
        !!details;
    const mediaUrl = (_c = (_b = collection === null || collection === void 0 ? void 0 : collection.baseUri) !== null && _b !== void 0 ? _b : details === null || details === void 0 ? void 0 : details.generativeCodeUri) !== null && _c !== void 0 ? _c : animationUrl;
    const generativeContent = isArtworkGenerative && (_jsxs(Stack, { sx: {
            ...(opened
                ? {
                    width: "100%",
                    maxWidth: "calc(100vmin - 4rem)",
                    height: "100vh",
                    maxHeight: "calc(100vmin - 4rem)",
                }
                : {
                    height: "auto",
                    margin: "auto",
                    maxHeight: "100%",
                    maxWidth: "100%",
                }),
            alignItems: "center",
            aspectRatio: "" + artworkAspectRatio,
            position: "relative",
        }, children: [_jsx(GenArtIFrame, { mediaUrl: mediaUrl !== null && mediaUrl !== void 0 ? mediaUrl : "", ...params, bordered: false, aspectRatio: "" + artworkAspectRatio }), _jsx(Flex, { sx: {
                    ...(opened ? { position: "absolute", bottom: 4 } : undefined),
                    margin: "auto",
                }, children: _jsx(GenerativeDisplayToolbar, { showRefresh: true, showRandomize: true, showExpand: true, showFullscreen: false, isExpanded: opened, refreshIframe: refreshIframe, randomizeAll: randomizeAll, toggleExpand: opened ? closeExpandArtwork : openExpandArtwork }) })] }));
    const nonGenerativeContent = (_jsx(Image, { src: imageUrl, alt: `Preview image for collection ${name}`, classNames: {
            imageWrapper: classes.wrapper,
            figure: classes.fullHeight,
            root: classes.fullHeight,
            image: classes.image,
        } }));
    if (opened) {
        return (_jsxs(Modal, { opened: true, onClose: closeExpandArtwork, fullScreen: true, withCloseButton: false, centered: true, zIndex: 1001, styles: {
                modal: {
                    maxHeight: "100%",
                    width: "100%",
                    height: "100%",
                },
                body: {
                    background: "transparent",
                    overflow: "visible",
                },
            }, children: [_jsx(Box, { className: classes.expanded, children: generativeContent }), _jsx(ActionIcon, { className: classes.closeBtn, onClick: closeExpandArtwork, children: _jsx(MinimizeIcon, {}) })] }));
    }
    const size = btnProps.size;
    const iconWidth = size === "sm" || size === "xs" ? 14 : 20;
    return (_jsxs(_Fragment, { children: [_jsx(Button, { ref: ref, ...btnProps, onClick: () => setOpenedModal(true), children: buttonInnerElement !== null && buttonInnerElement !== void 0 ? buttonInnerElement : (_jsxs(Group, { spacing: spacing, noWrap: true, children: [_jsx(Calendar, { width: iconWidth }), " Add to calendar"] })) }), _jsx(Modal, { opened: openedModal, onClose: () => setOpenedModal(false), zIndex: 1001, size: modalSize, classNames: { header: classes.modalHeader }, children: _jsxs(Flex, { align: "center", gap: 80, p: isSmallAndUp ? 24 : 4, pt: 24, h: isSmallAndUp ? 480 : "auto", children: [isSmallAndUp && !hideImage && (_jsx(Box, { w: "50%", sx: {
                                ...(isArtworkGenerative
                                    ? {
                                        height: "auto",
                                        padding: "24px 0",
                                    }
                                    : { height: "100%" }),
                                maxHeight: "100%",
                                maxWidth: "100%",
                                aspectRatio: isArtworkGenerative
                                    ? "" + artworkAspectRatio
                                    : "auto",
                            }, children: visualElement
                                ? visualElement
                                : isArtworkGenerative
                                    ? generativeContent
                                    : nonGenerativeContent })), _jsxs(Stack, { w: stackWidth, spacing: 0, children: [_jsx(Title, { size: "x-large", mb: "sm", weight: 500, align: "center", children: modalTitle !== null && modalTitle !== void 0 ? modalTitle : `Don’t miss the release of ${name}` }), _jsx(Text, { size: "sm", align: "center", mb: 32, children: modalSubtitle !== null && modalSubtitle !== void 0 ? modalSubtitle : `Add the drop to your calendar and make sure you’re one of the
                first to mint.` }), _jsx(Stack, { spacing: 8, children: calendars.map((calendar) => (_jsx(Anchor, { href: calendar.href, onClick: () => trackUsage(calendar.type), target: "_blank", rel: "noreferrer", w: "100%", download: calendar.isDownload ? `${name}_drop.ics` : undefined, children: _jsx(Button, { size: "xl", color: SECONDARY_COLOR, w: "100%", children: _jsxs(Group, { spacing: "xs", children: [calendar.icon, calendar.label] }) }) }, calendar.label))) })] })] }) })] }));
});
export default AddToCalendarButton;

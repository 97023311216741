import { forwardRef, ReactNode, Ref, useMemo } from "react";

import { useTransactionState } from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { Box, Button, ButtonProps, Group, Stack } from "@mantine/core";
import { parseEther } from "viem";

import ErrorBox from "~features/MintPage/MintVector/ErrorBox";
import { useMintCardButtonStyles } from "~features/MintPage/MintVector/MintCardButton";
import { UserStatus } from "~features/MintPage/utils/types";
import useMintState, { MintStateRequired } from "~hooks/useMintState";

import { transactionStateErrorMsg } from "../MintVector/tx-state";
import useCardSaleData from "../hooks/useCardSaleData";

import { useRankedAuction } from "./hooks";
import { useRankedAuctionTx } from "./tx";

export type CardButtonProps = {
  bidAmount: string;
  bidId?: string;
  initialButtonLabel: string;
  showWarningModals?: boolean;
  showErrorIcon?: boolean;
  disabledMint?: boolean | null;
  rightButton?: ReactNode;
  onFinish: () => void;
  hasEnoughMoney: boolean;
} & Partial<ButtonProps>;

export const RaBidButton = forwardRef(
  (
    {
      bidAmount,
      bidId,
      initialButtonLabel,
      showWarningModals,
      showErrorIcon,
      disabledMint,
      rightButton,
      onFinish,
      hasEnoughMoney,
      ...rest
    }: CardButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const { mintVector, collection, userStatus } =
      useMintState() as MintStateRequired;

    const { buttonLoading, buttonLabel, error, txnId, approve } =
      useRankedAuctionTx({
        collectionId: collection.id,
        collectionType: collection.collectionType,
        mintVector: mintVector!,
        bidAmount,
        bidId,
        onFinish,
      });

    const transactionState = useTransactionState(txnId);
    const errorMsg = transactionStateErrorMsg(transactionState);

    const { rankedAuction } = useRankedAuction();
    const { lowestBidRequired, userBids } = rankedAuction ?? {};
    const isBidTooLow = useMemo(() => {
      if (!lowestBidRequired) {
        return false;
      }
      if (!bidAmount) {
        return true;
      }
      return parseEther(lowestBidRequired) > parseEther(bidAmount || "0");
    }, [bidAmount, lowestBidRequired]);

    const { classes } = useMintCardButtonStyles({});

    const disableMintButton =
      disabledMint ||
      !hasEnoughMoney ||
      isBidTooLow ||
      (!showWarningModals && userStatus === UserStatus.LIMIT_REACHED);

    return (
      <Stack spacing={8} w="100%" align="center">
        <Stack
          className={classes.buttonContainer}
          spacing={12}
          align="center"
          w="100%"
        >
          <Group mt={8} position="apart" noWrap w="100%">
            {!(
              userBids?.length &&
              mintVector.maxPerUser &&
              userBids.length >= mintVector.maxPerUser &&
              ["New bid", "Make bid"].includes(initialButtonLabel)
            ) && (
              <Button
                ref={ref}
                {...rest}
                onClick={approve}
                loading={buttonLoading && !errorMsg}
                disabled={disableMintButton}
              >
                {buttonLabel || initialButtonLabel}
              </Button>
            )}
            {rightButton}
          </Group>
        </Stack>
        <Box w="100%">
          {errorMsg ? (
            <ErrorBox message={errorMsg} small={!showErrorIcon} />
          ) : error ? (
            <ErrorBox
              message="There was an error, please try again later"
              small={!showErrorIcon}
            />
          ) : null}
        </Box>
      </Stack>
    );
  }
);

export const useInsuficientFunds = (bidAmount: string) => {
  const { mintVector, numTokensToMint } = useMintState() as MintStateRequired;
  const { hasEnoughMoney } = useCardSaleData({
    mintVector,
    numTokens: numTokensToMint || 1,
    bidAmount: bidAmount || "0",
  });

  return { hasEnoughMoney };
};

export const InsufficientFundsError = ({
  hasEnoughMoney,
}: {
  hasEnoughMoney: boolean;
}) => {
  if (hasEnoughMoney) {
    return null;
  }
  return <ErrorBox message="Insufficient funds" small />;
};

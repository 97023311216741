import { isEmpty, max } from "lodash";
import { IN_DEVELOPMENT, LOGGER_API } from "../config";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logError = async (error, m) => {
    const message = m !== null && m !== void 0 ? m : "<no message>";
    let payload = JSON.stringify({ message });
    if (LOGGER_API) {
        try {
            // most raw Error's are parsed as {}
            const parsedError = JSON.parse(JSON.stringify(error));
            const finalError = isEmpty(parsedError)
                ? { message, stack: error === null || error === void 0 ? void 0 : error.stack }
                : parsedError;
            payload = JSON.stringify({
                error: finalError,
                message,
                level: "error",
                context: "userError",
                url: window.location.href,
                hlVersion: 1,
            });
            await fetch(LOGGER_API, {
                method: "post",
                body: payload,
                headers: { "Content-Type": "application/json" },
            });
            IN_DEVELOPMENT &&
                console.log("🚨 Error | sent to logger API: " + message, { e: error });
        }
        catch (e) {
            IN_DEVELOPMENT &&
                console.log("🚨 Error | failed to send error to logger API:", {
                    rawErrorData: error,
                    payload,
                    loggerApiError: e,
                });
        }
    }
    else {
        IN_DEVELOPMENT && console.log("🚨 Error: " + message, error);
    }
};
const getMessage = (error, statusCode) => {
    var _a, _b, _c, _d, _e;
    let message = "";
    if (error.networkError) {
        // https://stackoverflow.com/a/60334473
        // interfaces and types aren't exported from typescript to javascript,
        // cannot do instanceof (and typeof is for primitives, not custom types)
        if (error.networkError.bodyText) {
            message =
                (_a = error.networkError.bodyText) !== null && _a !== void 0 ? _a : "unknown network error - type ServerParseError";
        }
        else if (error.networkError.result) {
            message =
                (_b = error.networkError.result.errors[0].message) !== null && _b !== void 0 ? _b : "unknown network error - type ServerError";
        }
        else {
            message =
                (_d = (_c = error.networkError) === null || _c === void 0 ? void 0 : _c.message) !== null && _d !== void 0 ? _d : "unknown network error - type Error";
        }
    }
    else if (error.graphQLErrors) {
        const messages = ((_e = error.graphQLErrors) !== null && _e !== void 0 ? _e : []).filter(({ extensions }) => extensions.status === statusCode);
        // for now, return first message as top-level message,
        // will still have all details in log-analytics / opensearch
        message = !messages ? "unknown graphql error" : messages[0].message;
    }
    else {
        message = "unknown error - missing graphql and network error data!";
    }
    return message;
};
const DEFAULT_ERROR = 500;
const getStatusCode = (error) => {
    var _a, _b;
    let statusCode = DEFAULT_ERROR;
    if (error.networkError) {
        if (error.networkError.statusCode) {
            statusCode = error.networkError.statusCode;
        }
        else if (error.networkError.statusCode) {
            statusCode = error.networkError.statusCode;
        }
    }
    else if (error.graphQLErrors) {
        const codes = ((_a = error.graphQLErrors) !== null && _a !== void 0 ? _a : []).map(({ extensions }) => extensions.status);
        statusCode = !codes ? DEFAULT_ERROR : (_b = max(codes)) !== null && _b !== void 0 ? _b : DEFAULT_ERROR;
    }
    return statusCode;
};
const prepareErrorPayload = (error) => {
    const statusCode = getStatusCode(error);
    const message = getMessage(error, statusCode);
    return {
        context: "apolloClient",
        level: statusCode >= DEFAULT_ERROR ? "error" : "warn",
        hlVersion: 1,
        statusCode,
        message,
        graphqlOperationName: error.operation.operationName,
        highlightTraceId: error.highlightTraceId,
        url: window.location.href,
        error: {
            //  - don't log the full graphql error, it's already visible on web-graphql logs
            //  - validation / parsing graphql syntax errors will not log on web-graphql,
            //    but codegen should prevent those from happening
            //  - log full network error, as that never hits web-graphql
            graphQLErrorMessage: !error.networkError ? message : undefined,
            networkError: error.networkError,
        },
    };
};
/**
 * If there was a network error - set that as the top level error code
 * If there were graphql error(s) - set the highest status code (503 > 400) as the top level error code
 *
 * Log the entire error object returned by the apollo client
 *
 * @param error - from apollo client onError() handler
 */
export const logApolloError = async (error) => {
    let payload = { message: "no message" };
    if (LOGGER_API) {
        try {
            payload = prepareErrorPayload(error);
            await fetch(LOGGER_API, {
                method: "post",
                body: JSON.stringify(payload),
                headers: { "Content-Type": "application/json" },
            });
            IN_DEVELOPMENT &&
                console.log(`🚨 Error | sent to logger API: ${payload.message}`, {
                    rawErrorData: error,
                    payload,
                });
        }
        catch (e) {
            IN_DEVELOPMENT &&
                console.log("🚨 Error | failed to send apollo error to logger API:", {
                    rawErrorData: error,
                    payload,
                    loggerApiError: e,
                });
        }
    }
    else {
        IN_DEVELOPMENT && console.log("🚨 Error | apollo error was:", error);
    }
};

import { useState } from "react";

import { ChevronLeft } from "@hl/base-components/lib/assets/icons/HDS";
import { BACKGROUND_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import {
  Box,
  Button,
  Center,
  Group,
  Loader,
  Modal,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { Bidder } from "./Bidder";
import { useRankedAuction } from "./hooks";
import { useRankedAuctionHistoryQuery } from "./ra-queries.graphql.generated";

const BidsHistoryModalBody = () => {
  const { mintVector } = useRankedAuction();
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const { data, loading } = useRankedAuctionHistoryQuery({
    variables: {
      mintVectorId: mintVector?.id ?? "0",
      limit,
      offset,
    },
  });

  if (loading) {
    return (
      <Center p={30} mih={350}>
        <Loader />
      </Center>
    );
  }
  if (!data?.rankedAuction || !mintVector) {
    return <div>error</div>;
  }
  const chainId = mintVector.chainId!;
  const currencySymbol = getCurrencySymbol(
    chainId,
    mintVector.paymentCurrency?.symbol
  );
  const { bids } = data?.rankedAuction;
  const prevPage = () => setOffset(Math.max(offset - limit, 0));
  const nextPage = () => {
    const nextOffset = offset + limit;
    if (bids.pageInfo.totalCount < nextOffset) {
      return;
    }
    setOffset(nextOffset);
  };

  return (
    <Box>
      <Box mih={310}>
        {bids.edges.map((bid) => (
          <Group position="apart" mt={12} key={bid.id}>
            <Text size="sm">
              <Text span fw={WEIGHT_BOLD} mr={10} inherit>
                {bid.rank}.
              </Text>
              <Bidder {...bid} chainId={chainId} />
            </Text>
            <Text size="sm">
              {bid.bidAmount} {currencySymbol}
            </Text>
          </Group>
        ))}
      </Box>
      <Group mt={16}>
        <UnstyledButton onClick={prevPage}>
          <ChevronLeft width={20} height={20} />
        </UnstyledButton>
        <UnstyledButton onClick={nextPage}>
          <ChevronLeft
            width={20}
            height={20}
            style={{ transform: "rotate(180deg)" }}
          />
        </UnstyledButton>
      </Group>
    </Box>
  );
};

export const AuctionHistoryBtn = () => {
  const { rankedAuction, bidsLimit } = useRankedAuction();
  const [opened, { open, close }] = useDisclosure(false);

  if (!rankedAuction) {
    return null;
  }
  const { bids } = rankedAuction;
  const { pageInfo } = bids;

  return (
    <>
      <Modal opened={opened} onClose={close} title="Auction ranking">
        <BidsHistoryModalBody />
      </Modal>
      <Button
        size="sm"
        color={BACKGROUND_COLOR.SECONDARY}
        fullWidth
        mt={6}
        onClick={open}
      >
        + {pageInfo.totalCount - bidsLimit} winning bids
      </Button>
    </>
  );
};

import { useEffect, useLayoutEffect, useRef, useState } from "react";

import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { Carousel, Embla } from "@mantine/carousel";
import { useViewportSize } from "@mantine/hooks";
import { EmblaCarouselType } from "embla-carousel-react";

import { useSlideStyles } from "../../features/MintPage/header/MintCarouselCommon";
import MintCarouselSlide from "../../features/MintPage/header/MintCarouselSlide";

type Props = {
  images: string[];
  onSlideChange: (index: number) => void;
  getEmblaApi?: (embla: EmblaCarouselType) => void;
  fullHeight?: boolean;
  containerWidth?: number;
  containerHeight?: number;
};

export const StaticCarousel = ({
  images,
  onSlideChange,
  getEmblaApi,
  fullHeight,
  containerWidth,
  containerHeight,
}: Props) => {
  const { isMobile, isExtraLarge } = useMantineMediaQueries({
    withInitialValues: true,
  });
  const { width: viewportWidth, height: viewportHeight } = useViewportSize();
  // approximate containerWidth via viewport: 516px is sidebar width + artwork container padding
  const viewportWidthNormalized = viewportWidth - 516;
  // approximate containerWidth via viewport: 136px is navbar height + artwork container padding + token info section height
  const viewportHeightNormalized = viewportHeight - 136;
  const width = containerWidth || viewportWidthNormalized;
  const height = containerHeight || viewportHeightNormalized;
  const viewportAspectRatio = width / height;
  const [smallestAspectRatio, setSmallestAspectRatio] = useState(5);
  const smallestAspectRatioRef = useRef(5);
  const largestAspectRatioRef = useRef(0);
  const [largestAspectRatio, setLargestAspectRatio] = useState(0);
  const makeSlideSmaller = smallestAspectRatio * 2.5 < largestAspectRatio;

  // create as big a slide as possible.
  // If the aspect ratio of the slide is bigger than the viewport aspect ratio,
  // use viewport width as a basis for slideSize, else take viewport height
  // If artworks have different aspect ratios, make the slide smaller to prevent blank space between artworks
  // If artwork is vertical, shrink slides by a factor of the smallest aspect ratio
  const slideSize = isMobile
    ? 270
    : isExtraLarge
    ? smallestAspectRatio >= viewportAspectRatio
      ? makeSlideSmaller
        ? width / 2
        : width - 180
      : makeSlideSmaller
      ? height / 2
      : height * smallestAspectRatio
    : 360;

  const { classes } = useSlideStyles({
    selected: false,
    isMobile,
    imagesCount: images.length,
    fullHeight,
    slideSize,
  });

  const [startingIndex] = useState(
    images.length > 2 ? Math.floor(images.length / 2) - 1 : 0
  );
  const [selectedIndex, setSelectedIndex] = useState(startingIndex);
  const [emblaApi, setEmbla] = useState<Embla | null>(null);

  useLayoutEffect(() => {
    if (emblaApi && slideSize) {
      emblaApi.reInit();
    }
  }, [slideSize, emblaApi]);

  useEffect(() => {
    onSlideChange(selectedIndex);
  }, [onSlideChange, selectedIndex]);

  useLayoutEffect(() => {
    if (getEmblaApi && emblaApi) {
      getEmblaApi(emblaApi);
      emblaApi.scrollTo(startingIndex, true);
    }
  }, [getEmblaApi, emblaApi, startingIndex]);

  return (
    <Carousel
      initialSlide={startingIndex}
      getEmblaApi={setEmbla}
      align="center"
      slideSize={slideSize}
      w="100%"
      draggable
      withKeyboardEvents={false}
      withControls
      inViewThreshold={0}
      onSlideChange={setSelectedIndex}
      containScroll="keepSnaps"
      classNames={{
        controls: classes.controls,
        viewport: classes.viewport,
        root: classes.root,
        slide: classes.slide,
        container: classes.container,
      }}
      style={{
        overflow: "hidden",
      }}
    >
      {images.map((image, index) => (
        <MintCarouselSlide
          key={index}
          imageUrl={image}
          selected={index === selectedIndex}
          minted={false}
          index={index}
          fullHeight={fullHeight}
          slideSize={slideSize}
          onAspectRatio={(aspectRatio) => {
            if (aspectRatio && aspectRatio < smallestAspectRatioRef.current) {
              setSmallestAspectRatio(aspectRatio);
              smallestAspectRatioRef.current = aspectRatio;
            }
            if (aspectRatio && aspectRatio > largestAspectRatioRef.current) {
              setLargestAspectRatio(aspectRatio);
              largestAspectRatioRef.current = aspectRatio;
            }
          }}
        />
      ))}
    </Carousel>
  );
};

export function noExponents(potentialExponent) {
    const data = String(potentialExponent).split(/[eE]/);
    if (data.length == 1)
        return data[0];
    let z = "", mag = Number(data[1]) + 1;
    const sign = potentialExponent < 0 ? "-" : "", str = data[0].replace(".", "");
    if (mag < 0) {
        z = sign + "0.";
        while (mag++)
            z += "0";
        return z + str.replace(/^\-/, "");
    }
    mag -= str.length;
    while (mag--)
        z += "0";
    return str + z;
}

import { useEffect, useState } from "react";

import {
  TransactionStateType,
  TransactionState,
  useTransactionState,
} from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { networkLookup } from "@hl/shared-features/lib/utils/blockExplorer";

import { stateLabels } from "./MintCardButton";

export const transactionStateErrorMsg = (
  transactionState?: TransactionState
) => {
  if (!transactionState) {
    return;
  }
  const txType = transactionState.type;

  if (txType === TransactionStateType.ChainSwitchError) {
    return `Failed to switch to chain ${switchToChain(transactionState)}`;
  }
};

const switchToChain = (transactionState: TransactionState) => {
  const { chainId } = transactionState;
  if (!chainId) return "";
  return `${networkLookup(chainId)?.displayName} (chain ID ${chainId})`;
};

const END_TX_STATE = [
  TransactionStateType.Done,
  TransactionStateType.ChainSwitchError,
  TransactionStateType.SignTxRejected,
];
export const useTxButtonState = (txnId: string) => {
  const transactionState = useTransactionState(txnId);
  const txType = transactionState?.type;

  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [isMinting, setIsMinting] = useState(false);

  useEffect(() => {
    setErrorMsg(null);
  }, [txnId]);

  useEffect(() => {
    if (!txType) {
      return;
    }
    if (txType === TransactionStateType.SignTxRejected) {
      setErrorMsg("Please confirm in your wallet");
      setIsMinting(false);
    } else if (END_TX_STATE.includes(txType)) {
      setIsMinting(false);
    } else {
      setIsMinting(true);
    }
  }, [txType]);

  let txBtnLabel = null;
  const stateError = transactionStateErrorMsg(transactionState);

  if (txType && !END_TX_STATE.includes(txType)) {
    txBtnLabel = stateLabels[txType] ?? "Processing...";
  }

  return {
    isMinting,
    txBtnLabel,
    errorMsg: errorMsg || stateError,
  };
};

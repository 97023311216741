export const darkColors = {
    black: [
        "rgba(18, 18, 18, 0.1)",
        "rgba(18, 18, 18, 0.2)",
        "rgba(18, 18, 18, 0.3)",
        "rgba(18, 18, 18, 0.4)",
        "rgba(18, 18, 18, 0.45)",
        "rgba(18, 18, 18, 0.6)",
        "rgba(18, 18, 18, 0.7)",
        "rgba(18, 18, 18, 0.8)",
        "rgba(18, 18, 18, 0.9)",
        "rgba(18, 18, 18, 1)",
    ],
    white: [
        "rgba(237, 237, 237, 0.1)",
        "rgba(237, 237, 237, 0.2)",
        "rgba(237, 237, 237, 0.3)",
        "rgba(237, 237, 237, 0.4)",
        "rgba(237, 237, 237, 0.45)",
        "rgba(237, 237, 237, 0.6)",
        "rgba(237, 237, 237, 0.7)",
        "rgba(237, 237, 237, 0.8)",
        "rgba(237, 237, 237, 0.9)",
        "rgba(237, 237, 237, 1)",
    ],
    gray: [
        "#D7D7D7",
        "#A5A5A5",
        "#767676",
        "#575757",
        "#444444",
        "#242424",
        "#1C1C1C",
        "#151515",
        "#121212",
        "#0F0F0F",
    ],
    cyan: [
        "rgba(121, 226, 247, 0.1)",
        "rgba(121, 226, 247, 0.2)",
        "rgba(121, 226, 247, 0.3)",
        "rgba(121, 226, 247, 0.4)",
        "rgba(121, 226, 247, 0.45)",
        "rgba(121, 226, 247, 0.6)",
        "rgba(121, 226, 247, 0.7)",
        "rgba(121, 226, 247, 0.8)",
        "rgba(121, 226, 247, 0.9)",
        "rgba(121, 226, 247, 1)",
    ],
    green: [
        "#75E0A7",
        "#47CD89",
        "#17B26A",
        "#079455",
        "#067647",
        "#085D3A",
        "#074D31",
        "#053321",
        "#042415",
        "#031A0F",
    ],
    blue: [
        "#84CAFF",
        "#53B1FD",
        "#2E90FA",
        "#1570EF",
        "#175CD3",
        "#1849A9",
        "#194185",
        "#102A56",
        "#081F3F",
        "#051326",
    ],
    orange: [
        "#F7B27A",
        "#F38744",
        "#EF6820",
        "#E04F16",
        "#B93815",
        "#932F19",
        "#772917",
        "#511C10",
        "#3B130A",
        "#260A05",
    ],
    red: [
        "#FE6C61",
        "#F04438",
        "#D92D20",
        "#B42318",
        "#912018",
        "#7A271A",
        "#55160C",
        "#3F0E0A",
        "#2A0608",
        "#1B0504",
    ],
    violet: [
        "rgba(173, 46, 246, 0.1)",
        "rgba(173, 46, 246, 0.2)",
        "rgba(173, 46, 246, 0.3)",
        "rgba(173, 46, 246, 0.4)",
        "rgba(173, 46, 246, 0.45)",
        "rgba(173, 46, 246, 0.6)",
        "rgba(173, 46, 246, 0.7)",
        "rgba(173, 46, 246, 0.8)",
        "rgba(173, 46, 246, 0.9)",
        "rgba(173, 46, 246, 1)",
    ],
};

import { CSSProperties, HTMLAttributes, ReactNode } from "react";

import { useEmbedMode } from "@hl/shared-features/lib/features/auth/hooks";
import { createStyles } from "@mantine/core";
import { Link } from "react-router-dom";

import { MINT_BASE_URL } from "../../../config";

type InternalLinkProps = {
  to: string;
  style?: CSSProperties;
  children?: ReactNode;
} & HTMLAttributes<HTMLAnchorElement>;

export const useLinkStyles = createStyles({
  grow: {
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
  },
});

// safe link  that works in embed mode (without router)
export const InternalLink = ({ to, children, ...rest }: InternalLinkProps) => {
  const { isEmbedMode } = useEmbedMode();
  return isEmbedMode ? (
    <a
      href={`${MINT_BASE_URL}${to}`}
      target="_blank"
      rel="noreferrer"
      {...rest}
    >
      {children}
    </a>
  ) : (
    <Link to={to} {...rest}>
      {children}
    </Link>
  );
};

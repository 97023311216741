import { abi as Erc20ABI } from "@hl/shared-features/lib/assets/web3/ERC20.json";
import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import { ethers } from "ethers";
import { parseEther, formatEther } from "ethers/lib/utils";
import { useContractRead } from "wagmi";

import { MintVector } from "~features/MintPage";
import { useGetCheckCurrencyAllowanceTxArgsQuery } from "~features/MintPage/queries.graphql.generated";

import { CurrencyType } from "../apollo/graphql.generated";
export const useAllowanceErc20 = (
  numberTokensToMint: number,
  mintVector?: MintVector,
  is1155?: boolean
) => {
  const { walletAddress } = useAuth();

  const {
    data: argsData,
    loading: loadingArgs,
    error: errorArgs,
  } = useGetCheckCurrencyAllowanceTxArgsQuery({
    variables: {
      chainId: mintVector?.chainId || 0,
      currency: mintVector?.currency || "",
    },
    skip:
      mintVector?.paymentCurrency?.type !== CurrencyType.ERC20 ||
      !mintVector?.chainId ||
      !mintVector?.currency ||
      !walletAddress,
  });

  const args = argsData?.checkCurrencyAllowanceTxArgs;

  const { data, isLoading, error, refetch } = useContractRead({
    address: args?.address as `0x${string}`,
    abi: Erc20ABI,
    chainId: mintVector?.chainId || 0,
    functionName: args?.method || "",
    args: JSON.parse(args?.args || "[]"),
    enabled: !!argsData && !errorArgs,
  });
  // @ts-ignore
  const currencyAllowance = formatEther(data || 0n);

  const currencyAmountToAllow = parseEther(mintVector?.price || "0")
    .add(
      parseEther(mintVector?.paymentCurrency?.mintFee || "0").div(
        is1155 ? 2 : 1
      )
    )
    .mul(numberTokensToMint || 1);

  const amountPendingToAllow = currencyAmountToAllow.sub(
    parseEther(currencyAllowance)
  );

  return {
    currencyAmountToAllow,
    hasSufficientAllowance: amountPendingToAllow.lte(ethers.constants.Zero),
    loading: isLoading || loadingArgs,
    error: errorArgs || error,
    refetch,
  };
};

export enum UserStatus {
  NO_LIMIT,
  LIMIT_REACHED,
  LIMIT_MINTED,
  LIMIT_NOT_MINTED,
}

export enum SaleStatus {
  ACTIVE_WITH_END,
  ACTIVE_WITH_NO_END,
  ENDED,
  SOLD_OUT,
  PAUSED,
  NOT_STARTED,
}

import * as Types from "../../../apollo/graphql.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export type MarketplaceBrowseCollectionQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
  minted: Types.Maybe<Types.Scalars["Boolean"]>;
}>;

export type MarketplaceBrowseCollectionQuery = {
  getPublicCollection: {
    __typename: "Collection";
    id: string;
    name: string;
    description: string | null;
    address: string;
    chainId: number;
    reveal: boolean | null;
    seriesAttributes: Array<{
      __typename: "AttributeSummary";
      name: string;
      variants: Array<{
        __typename: "AttributeVariant";
        value: string;
        count: number;
      }>;
    }>;
    seriesDetails: {
      __typename: "SeriesDetails";
      showAllMetadata: boolean | null;
    } | null;
  };
};

export type MarketplaceBrowseTokensQueryVariables = Types.Exact<{
  collectionId: Types.Scalars["String"];
  limit: Types.Scalars["Float"];
  cursor: Types.Maybe<Types.Scalars["String"]>;
  minted: Types.Maybe<Types.Scalars["Boolean"]>;
  attributes: Types.Maybe<Array<Types.NftAttribute> | Types.NftAttribute>;
}>;

export type MarketplaceBrowseTokensQuery = {
  getPublicCollection: {
    __typename: "Collection";
    id: string;
    seriesTokens: {
      __typename: "SeriesTokensPaginated";
      edges: Array<{
        __typename: "SeriesToken";
        id: string;
        tokenId: string | null;
        name: string;
        imageUrl: string | null;
        animationUrl: string | null;
        minted: boolean | null;
        ownerAddress: string | null;
        ownerEns: string | null;
      }>;
      pageInfo: {
        __typename: "CursorPaginatedInfo";
        endCursor: string | null;
        totalCount: number;
      };
    } | null;
  };
};

export type CollectionAttributesQueryVariables = Types.Exact<{
  chainId: Types.Scalars["Float"];
  tokenAddress: Types.Scalars["String"];
  minted: Types.Maybe<Types.Scalars["Boolean"]>;
  withOffers: Types.Maybe<Types.Scalars["Boolean"]>;
  withListings: Types.Maybe<Types.Scalars["Boolean"]>;
  tokenNameOrId: Types.Maybe<Types.Scalars["String"]>;
}>;

export type CollectionAttributesQuery = {
  getCollectionAttributes: Array<{
    __typename: "MarketplaceCollectionAttribute";
    name: string;
    variants: Array<{
      __typename: "MarketplaceTokenVariant";
      value: string;
      count: number;
    }>;
  }>;
};

export const MarketplaceBrowseCollectionDocument = gql`
  query MarketplaceBrowseCollection($collectionId: String!, $minted: Boolean) {
    getPublicCollection(collectionId: $collectionId) {
      id
      name
      description
      address
      chainId
      seriesAttributes(minted: $minted) {
        name
        variants {
          value
          count
        }
      }
      reveal
      seriesDetails {
        showAllMetadata
      }
    }
  }
`;

/**
 * __useMarketplaceBrowseCollectionQuery__
 *
 * To run a query within a React component, call `useMarketplaceBrowseCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceBrowseCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceBrowseCollectionQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      minted: // value for 'minted'
 *   },
 * });
 */
export function useMarketplaceBrowseCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    MarketplaceBrowseCollectionQuery,
    MarketplaceBrowseCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MarketplaceBrowseCollectionQuery,
    MarketplaceBrowseCollectionQueryVariables
  >(MarketplaceBrowseCollectionDocument, options);
}
export function useMarketplaceBrowseCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketplaceBrowseCollectionQuery,
    MarketplaceBrowseCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MarketplaceBrowseCollectionQuery,
    MarketplaceBrowseCollectionQueryVariables
  >(MarketplaceBrowseCollectionDocument, options);
}
export type MarketplaceBrowseCollectionQueryHookResult = ReturnType<
  typeof useMarketplaceBrowseCollectionQuery
>;
export type MarketplaceBrowseCollectionLazyQueryHookResult = ReturnType<
  typeof useMarketplaceBrowseCollectionLazyQuery
>;
export type MarketplaceBrowseCollectionQueryResult = Apollo.QueryResult<
  MarketplaceBrowseCollectionQuery,
  MarketplaceBrowseCollectionQueryVariables
>;
export const MarketplaceBrowseTokensDocument = gql`
  query MarketplaceBrowseTokens(
    $collectionId: String!
    $limit: Float!
    $cursor: String
    $minted: Boolean
    $attributes: [NftAttribute!]
  ) {
    getPublicCollection(collectionId: $collectionId) {
      id
      seriesTokens(
        first: $limit
        after: $cursor
        minted: $minted
        attributes: $attributes
      ) {
        edges {
          id
          tokenId
          name
          imageUrl
          animationUrl
          minted
          ownerAddress
          ownerEns
        }
        pageInfo {
          endCursor
          totalCount
        }
      }
    }
  }
`;

/**
 * __useMarketplaceBrowseTokensQuery__
 *
 * To run a query within a React component, call `useMarketplaceBrowseTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceBrowseTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceBrowseTokensQuery({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *      minted: // value for 'minted'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useMarketplaceBrowseTokensQuery(
  baseOptions: Apollo.QueryHookOptions<
    MarketplaceBrowseTokensQuery,
    MarketplaceBrowseTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MarketplaceBrowseTokensQuery,
    MarketplaceBrowseTokensQueryVariables
  >(MarketplaceBrowseTokensDocument, options);
}
export function useMarketplaceBrowseTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketplaceBrowseTokensQuery,
    MarketplaceBrowseTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MarketplaceBrowseTokensQuery,
    MarketplaceBrowseTokensQueryVariables
  >(MarketplaceBrowseTokensDocument, options);
}
export type MarketplaceBrowseTokensQueryHookResult = ReturnType<
  typeof useMarketplaceBrowseTokensQuery
>;
export type MarketplaceBrowseTokensLazyQueryHookResult = ReturnType<
  typeof useMarketplaceBrowseTokensLazyQuery
>;
export type MarketplaceBrowseTokensQueryResult = Apollo.QueryResult<
  MarketplaceBrowseTokensQuery,
  MarketplaceBrowseTokensQueryVariables
>;
export const CollectionAttributesDocument = gql`
  query CollectionAttributes(
    $chainId: Float!
    $tokenAddress: String!
    $minted: Boolean
    $withOffers: Boolean
    $withListings: Boolean
    $tokenNameOrId: String
  ) {
    getCollectionAttributes(
      chainId: $chainId
      tokenAddress: $tokenAddress
      minted: $minted
      withOffers: $withOffers
      withListings: $withListings
      tokenNameOrId: $tokenNameOrId
    ) {
      variants {
        value
        count
      }
      name
    }
  }
`;

/**
 * __useCollectionAttributesQuery__
 *
 * To run a query within a React component, call `useCollectionAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollectionAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollectionAttributesQuery({
 *   variables: {
 *      chainId: // value for 'chainId'
 *      tokenAddress: // value for 'tokenAddress'
 *      minted: // value for 'minted'
 *      withOffers: // value for 'withOffers'
 *      withListings: // value for 'withListings'
 *      tokenNameOrId: // value for 'tokenNameOrId'
 *   },
 * });
 */
export function useCollectionAttributesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollectionAttributesQuery,
    CollectionAttributesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CollectionAttributesQuery,
    CollectionAttributesQueryVariables
  >(CollectionAttributesDocument, options);
}
export function useCollectionAttributesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollectionAttributesQuery,
    CollectionAttributesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CollectionAttributesQuery,
    CollectionAttributesQueryVariables
  >(CollectionAttributesDocument, options);
}
export type CollectionAttributesQueryHookResult = ReturnType<
  typeof useCollectionAttributesQuery
>;
export type CollectionAttributesLazyQueryHookResult = ReturnType<
  typeof useCollectionAttributesLazyQuery
>;
export type CollectionAttributesQueryResult = Apollo.QueryResult<
  CollectionAttributesQuery,
  CollectionAttributesQueryVariables
>;

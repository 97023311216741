import { useEffect, useRef, useState } from "react";

import {
  BACKGROUND_COLOR,
  TEXT_COLOR,
} from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { useAuth } from "@hl/shared-features/lib/features/auth";
import { SignInButton } from "@hl/shared-features/lib/features/auth/SignInButton";
import { useEmbedMode } from "@hl/shared-features/lib/features/auth/hooks";
import {
  TransactionStateType,
  useTransactionState,
} from "@hl/shared-features/lib/features/evm-tx/TransactionContext";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import {
  Box,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { formatEther, parseEther } from "viem";

import useMintState, { MintStateRequired } from "../../../hooks/useMintState";
import {
  ClaimMinter,
  ClaimMinterWithNavigate,
} from "../../claim-mints/ClaimMinter";
import ErrorBox from "../MintVector/ErrorBox";
import { useTxButtonState } from "../MintVector/tx-state";

import { useRankedAuction } from "./hooks";
import { RankedAuctionQuery } from "./ra-queries.graphql.generated";
import { useClaimBidFundsTx } from "./tx";

export const ClaimCard = () => {
  const { rankedAuction, mintVector } = useRankedAuction();
  const ref = useRef(null);
  const { isEmbedMode } = useEmbedMode();

  const { authenticated } = useAuth();
  if (!rankedAuction) {
    return null;
  }
  const {
    userBids,
    userNumTokensLeftToClaim,
    rebateWinningBids,
    lowestWinningBid,
  } = rankedAuction;

  const tokensToClaim = userNumTokensLeftToClaim ?? 0;
  const wonBids = userBids?.filter((x) => x.currentlyValid) ?? [];

  const lostBids = userBids?.filter((x) => !x.currentlyValid) ?? [];

  const hasWonBids = wonBids.length !== 0;

  const amountPaidEth = wonBids.reduce(
    (total, bid) => total + parseEther(bid.bidAmount),
    0n
  );
  const amountRequiredEth =
    parseEther(lowestWinningBid) * BigInt(wonBids.length);

  const totalAmount = formatEther(
    rebateWinningBids ? amountRequiredEth : amountPaidEth
  );
  const rebateAmountEth = rebateWinningBids
    ? amountPaidEth - amountRequiredEth
    : 0n;
  const rebateAmount = formatEther(rebateAmountEth);
  const hasWinningRebate = rebateAmountEth > 0;

  const hasLostBids = lostBids.length !== 0;

  const currencySymbol = getCurrencySymbol(
    mintVector.chainId!,
    mintVector.paymentCurrency?.symbol
  );

  const endDate = new Date(rankedAuction.end).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  });

  return (
    <>
      {!hasWonBids && !hasLostBids && (
        <Box>
          <Text size={24} fw={WEIGHT_BOLD}>
            Auction ended
          </Text>
          <Text size="sm" color={TEXT_COLOR.SECONDARY} mt="md">
            This auction ended on {endDate}
          </Text>
        </Box>
      )}
      {!authenticated && <SignInButton ref={ref} size="xl" fullWidth mt={16} />}

      {hasWonBids && (
        <Box>
          <Text size={24} fw={WEIGHT_BOLD}>
            {wonBids.length === 1
              ? `Congrats! You won a token at ${totalAmount} ${currencySymbol}`
              : `Congrats! You won ${wonBids.length} tokens for a total of ${totalAmount} ${currencySymbol}`}
          </Text>
          <Text size="sm" color={TEXT_COLOR.SECONDARY} mt="md">
            This auction ended on {endDate}
            {lostBids.length !== 0 && (
              <>
                {". "}
                You also have {lostBids.length} losing bid
                {lostBids.length > 1 ? "s" : ""}.
              </>
            )}
          </Text>
          {tokensToClaim !== 0 && (
            <ClaimTokensButton
              numToClaim={tokensToClaim!}
              labelSuffix={
                hasWinningRebate
                  ? ` and a ${rebateAmount} ${currencySymbol} rebate`
                  : undefined
              }
            />
          )}
          {isEmbedMode ? <ClaimMinter /> : <ClaimMinterWithNavigate />}
        </Box>
      )}
      {hasLostBids && (
        <>
          {wonBids.length === 0 && (
            <>
              <Text size={24} fw={WEIGHT_BOLD}>
                Auction ended. Claim funds from escrow
              </Text>
              <Text size="sm" color={TEXT_COLOR.SECONDARY} mt="md">
                This auction ended on {endDate}
              </Text>
            </>
          )}
          {lostBids.length && (
            <ClaimFundsModalBtn
              secondary={tokensToClaim !== 0}
              lostBids={lostBids}
            />
          )}
        </>
      )}
    </>
  );
};

const ClaimTokensButton = ({
  numToClaim,
  labelSuffix,
}: {
  numToClaim: number;
  labelSuffix?: string;
}) => {
  const {
    handleMint,
    mintVector,
    loadingClaimMint,
    txnId,
    setNumTokensToMint,
  } = useMintState() as MintStateRequired;
  const [claimed, setClaimed] = useState(false);

  const handleClick = () => {
    handleMint(mintVector.chainId!);
  };
  const transactionState = useTransactionState(txnId);
  const txType = transactionState?.type;

  useEffect(() => {
    if (numToClaim) {
      setNumTokensToMint(numToClaim);
    }
  }, [numToClaim]);

  useEffect(() => {
    if (!txType) {
      return;
    }
    if (txType === TransactionStateType.Done) {
      setClaimed(true);
    }
  }, [txType]);

  const { isMinting, errorMsg, txBtnLabel } = useTxButtonState(txnId);
  const pluralSuffix = numToClaim > 1 ? "s" : "";

  return (
    <>
      {!claimed && (
        <Button
          size="xl"
          fullWidth
          mt="md"
          mb="sm"
          onClick={handleClick}
          loading={loadingClaimMint || isMinting}
        >
          {txBtnLabel ?? `Claim token${pluralSuffix}${labelSuffix ?? ""}`}
        </Button>
      )}
      {errorMsg && <ErrorBox message={errorMsg} />}
      {claimed && (
        <Text size="lg" fw={WEIGHT_BOLD} mt="lg">
          Token{pluralSuffix} claim processed successfully
        </Text>
      )}
    </>
  );
};

export const ClaimFundsButton = ({
  bidId,
  label,
  secondary = false,
  fullWidth,
  onFinish,
}: {
  hasUnclaimedTokens?: boolean;
  bidId: string;
  label?: string;
  fullWidth?: boolean;
  secondary?: boolean;
  onFinish?: () => void;
}) => {
  const { collection, mintVector } = useMintState() as MintStateRequired;
  const { buttonLoading, buttonLabel, error, approve } = useClaimBidFundsTx({
    collectionId: collection.id,
    collectionType: collection.collectionType,
    mintVector: mintVector,
    bidId,
    onFinish,
  });
  const theme = useMantineTheme();
  return (
    <>
      <Button
        size="xl"
        fullWidth={fullWidth}
        mt="md"
        mb="sm"
        color={secondary ? BACKGROUND_COLOR.SECONDARY : undefined}
        onClick={approve}
        loading={buttonLoading}
        loaderProps={{ stroke: theme.fn.themeColor(TEXT_COLOR.PRIMARY) }}
      >
        {buttonLabel || label || "Claim funds"}
      </Button>
      {error && <ErrorBox message={error} small />}
    </>
  );
};

type UserBid = NonNullable<
  RankedAuctionQuery["rankedAuction"]["userBids"]
>[number];

const ClaimFundsModalBtn = ({
  secondary,
  lostBids,
}: {
  secondary: boolean;
  lostBids: UserBid[];
}) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Modal opened={opened} onClose={close} title="Claim funds">
        <ClaimBidsFundsModalBody onClose={close} bids={lostBids} />
      </Modal>
      <Button
        size="xl"
        fullWidth
        mt="md"
        mb="sm"
        color={secondary ? BACKGROUND_COLOR.SECONDARY : undefined}
        onClick={open}
      >
        Claim funds from losing bids
      </Button>
    </>
  );
};

const ClaimBidsFundsModalBody = ({
  onClose,
  bids,
}: {
  bids: UserBid[];
  onClose: () => void;
}) => {
  const { mintVector } = useMintState() as MintStateRequired;
  const currencySymbol = getCurrencySymbol(
    mintVector.chainId!,
    mintVector.paymentCurrency?.symbol
  );
  useEffect(() => {
    if (!bids.length) {
      // close on last one
      onClose();
    }
  }, [bids, onClose]);
  return (
    <>
      {bids.map((bid) => (
        <Group position="apart" align="start" key={bid.id}>
          <TextInput
            mt={16}
            sx={{ flexGrow: 1 }}
            styles={{
              input: { marginTop: 0 },
            }}
            defaultValue={bid.bidAmount}
            disabled
            rightSection={
              <Text size="sm" color={TEXT_COLOR.SECONDARY}>
                {currencySymbol}
              </Text>
            }
            rightSectionWidth={150}
            label={`Bid #${bid.id}`}
          />
          <Stack w="40%" mt={22}>
            <ClaimFundsButton label="Reclaim funds" bidId={bid.id} secondary />
          </Stack>
        </Group>
      ))}
    </>
  );
};

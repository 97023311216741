import { useCallback, useEffect, useState } from "react";

import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import {
  FEATURE_FLAGS,
  useFeatureFlags,
} from "@hl/shared-features/lib/features/auth/hooks";
import loadable from "@loadable/component";
import { Button, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { ReactComponent as CreditCardIcon } from "~assets/icons/credit-card.svg";

import { PaymentStatus } from "./crossmint";
import type { CrossmintPaymentProps } from "./types";

const CrossmintCheckout = loadable(() => import("./crossmint"), {
  resolveComponent: (components) => components.CrossmintCheckout,
});

export const CreditCardPayButton = ({
  handleCreditCardClaim,
  claim,
  buttonProps,
  ...rest
}: CrossmintPaymentProps) => {
  const [claimId, setClaimId] = useState<string>();
  const [opened, { open, close }] = useDisclosure(false);
  const [canClose, setCanClose] = useState(true);
  const { authenticated } = useAuth();

  const [paymentStatus, setPaymentStatus] =
    useState<PaymentStatus>("not-started");
  const enableCrossmint = useFeatureFlags(
    FEATURE_FLAGS.ENABLE_CROSSMINT_PAYMENTS
  );
  const onPaymentStatusChange = useCallback(
    (status: PaymentStatus) => {
      setPaymentStatus(status);
      setCanClose(status !== "processing");
    },
    [setPaymentStatus, setCanClose]
  );

  const initiateCreditCardClaim = useCallback(() => {
    setClaimId(`cc-Date.now().toString()`);
    open();
  }, []);

  useEffect(() => {
    if (claimId && handleCreditCardClaim) {
      handleCreditCardClaim(claimId);
    }
  }, [claimId, handleCreditCardClaim]);

  if (!enableCrossmint || !authenticated) {
    return null;
  }

  return (
    <>
      <Modal
        size="1058px"
        styles={(theme) => ({
          inner: {
            [theme.fn.smallerThan("sm")]: {
              padding: 20,
            },
          },
          body: {
            padding: 0,
            [theme.fn.smallerThan("sm")]: {
              minHeight: "calc(100vh - 110px)", // FIXME: Find a cleaner way to increase modal's height
            },
            [theme.fn.largerThan("sm")]: {
              position: "relative",
              minHeight: 665,
            },
          },
          header: {
            marginBottom: 0,
          },
        })}
        opened={opened && claim?.claimId === claimId}
        onClose={close}
        closeOnEscape={canClose}
        closeOnClickOutside={canClose}
        withCloseButton={canClose}
        title="Buy with credit card"
        overflow="inside"
      >
        <CrossmintCheckout
          closeModal={close}
          onPaymentStatusChange={onPaymentStatusChange}
          paymentStatus={paymentStatus}
          handleCreditCardClaim={handleCreditCardClaim}
          claim={claim}
          {...rest}
        />
      </Modal>

      <Button
        leftIcon={<CreditCardIcon />}
        color={SECONDARY_COLOR}
        onClick={initiateCreditCardClaim}
        size="xl"
        fullWidth
        {...buttonProps}
      >
        Buy with credit card
      </Button>
    </>
  );
};

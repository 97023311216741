import { createContext, useContext } from "react";
import { apolloClient } from "../../services/apolloClient";
import { logError } from "../../services/logger";
/*
* this is Privy specific implementation
* TODO: remove legacy ./auth/context.tsx
* /

/**
 * SESSION
 * PRIVATE - top level Actions that other component can calls (these actions know how to safely manage state)
 */
export const resetSession = () => {
    // TODO - somehow check if currently logged in, would need access to current state,
    // reset any session data / switch users?
    try {
        apolloClient.resetStore();
        // segmentTrackEvent(ANALYTICS_EVENT.ACCOUNT_SIGN_OUT);
    }
    catch (error) {
        logError({ error, message: "auth-context: logout error" });
    }
};
export const EMPTY_AUTH_STATE = {
    authenticated: false,
    loading: true,
};
/**
 * CONTEXTS
 */
export const AuthContext = createContext(EMPTY_AUTH_STATE);
export const AuthDispatchContext = createContext(null);
/**
 * HOOKS
 */
export const useAuth = () => {
    return useContext(AuthContext);
};
export const useAuthDispatch = () => {
    return useContext(AuthDispatchContext);
};
/**
 * REDUCER
 */
export const authReducer = (auth, action) => {
    var _a;
    switch (action.type) {
        case "REQUEST_SIGNIN":
            return { loading: true, authenticated: false };
        case "SIGNOUT":
            return { loading: false, authenticated: false };
        case "SIGNIN_SUCCESS":
            return {
                highlightAccountId: action.payload.highlightAccountId,
                walletAddress: action.payload.walletAddress,
                email: (_a = action.payload.email) !== null && _a !== void 0 ? _a : undefined,
                authenticated: true,
                loading: false,
            };
        case "SIGNIN_ERROR":
            return {
                loading: false,
                error: action.payload.error,
                authenticated: false,
            };
        case "AUTH_FETCHED":
            return { ...auth, loading: false };
        default:
            throw new Error("unknown reducer-action type");
    }
};
/**
 * HELPERS
 */
export const initiateUserSignIn = (dispatch) => {
    dispatch === null || dispatch === void 0 ? void 0 : dispatch({
        type: "REQUEST_SIGNIN",
    });
};
export const userSignInSuccess = (walletAddress, highlightAccountId, email, dispatch) => {
    dispatch === null || dispatch === void 0 ? void 0 : dispatch({
        type: "SIGNIN_SUCCESS",
        payload: {
            highlightAccountId,
            walletAddress,
            email,
        },
    });
};
export const userSignInFailure = (dispatch) => {
    dispatch === null || dispatch === void 0 ? void 0 : dispatch({
        type: "SIGNIN_ERROR",
        payload: {
            error: "Failed to Sign In",
        },
    });
};
export const userSignOut = (dispatch) => {
    dispatch === null || dispatch === void 0 ? void 0 : dispatch({
        type: "SIGNOUT",
    });
};
export const userAuthFetched = (dispatch) => {
    dispatch === null || dispatch === void 0 ? void 0 : dispatch({
        type: "AUTH_FETCHED",
    });
};

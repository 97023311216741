import { createContext, useContext } from "react";

import { EmbedTemplate } from "@hl/shared-features/lib/features/mint/types";

export type RevealFlags = {
  hideRevealHelp?: boolean;
  hideViewOnMarketplace?: boolean;
  hideRevealCreatedBy?: boolean;
  showReturnToSalesPage?: boolean;
};

export type EmbedFlags = {
  hideMintCloseDate?: boolean;
  hideTitle?: boolean;
  imgOnlyWhenDisabled?: boolean; // hide buttons, text, links when closed/sold out
  hideArt?: boolean;
  hideDetails?: boolean;

  hidePrice?: boolean;
  hidePriceDutchAuction?: boolean;
  hideDaButtons?: boolean;
  hideRaButtons?: boolean;
  hideFpButtons?: boolean;
  hideAuctionButtons?: boolean;

  hidePriceFixed?: boolean;

  hideStats?: boolean;
  hideStatsDutchAuction?: boolean;
  hideStatsFixedPrice?: boolean;

  hideCollectionLinks?: boolean;

  useContentAsLoading?: boolean;
  enableDarkMode?: boolean;
  hideSuccessModal?: boolean;

  // Mint page templates
  strabismusTemplate?: boolean;
  v1Template?: boolean;

  isButtonOnly?: boolean;
} & RevealFlags;

const parseBool = (val: string | null) => (val ? val === "true" : undefined);

type Template = EmbedTemplate | string | null;

export const embedFlagsFromAttributes = (container: Element): EmbedFlags => {
  const template: Template = container.getAttribute("data-template") ?? null;

  const attrFlags = {
    useContentAsLoading: parseBool(
      container.getAttribute("data-use-content-as-loading")
    ),
    enableDarkMode: parseBool(container.getAttribute("data-dark-mode")),
    hideSuccessModal: parseBool(
      container.getAttribute("data-hide-success-modal")
    ),
  };
  if (!template) {
    return attrFlags;
  }

  // TODO: implement EmbedTemplate options

  if (template && /^foundation(?:@\d+\.\d+)?$/.test(template)) {
    const [, templateVer] = template.split("@");
    const flags: EmbedFlags = {
      hideMintCloseDate: true,
      hideTitle: true,
      imgOnlyWhenDisabled: true,

      hidePriceDutchAuction: true,
      hidePriceFixed: false,

      hideStatsDutchAuction: true,
      hideStatsFixedPrice: false,

      hideCollectionLinks: true,
      hideRevealCreatedBy: true,
      hideViewOnMarketplace: true,
      hideRevealHelp: true,
      showReturnToSalesPage: true,
      ...attrFlags,
    };
    if (templateVer === "1.1") {
      flags.hideDaButtons = true;
    }
    if (templateVer === "1.2") {
      flags.hideDaButtons = true;
      flags.hideFpButtons = true;
    }

    return flags;
  } else if (template === EmbedTemplate.Strabismus) {
    return {
      ...attrFlags,
      strabismusTemplate: true,
    };
  } else if (template == EmbedTemplate.NoArt) {
    const flags: EmbedFlags = {
      hideArt: true,
      hideTitle: true,
      hideDetails: true,
      hideCollectionLinks: true,
      ...attrFlags,
    };
    return flags;
  } else if (template == EmbedTemplate.ButtonOnly) {
    return {
      isButtonOnly: true,
      ...attrFlags,
    } as EmbedFlags;
  } else if (template == EmbedTemplate.ArtOnly) {
    return {
      hideMintCloseDate: true,
      hideTitle: true,
      imgOnlyWhenDisabled: true,
      hidePrice: true,
      hideStats: true,
      hideCollectionLinks: true,
      hideRevealCreatedBy: true,
      hideViewOnMarketplace: true,
      hideRevealHelp: true,
      hideDaButtons: true,
      hideRaButtons: true,
      hideFpButtons: true,
      hideAuctionButtons: true,
      ...attrFlags,
    } as EmbedFlags;
  } else if (template == EmbedTemplate.Compact) {
    return {
      hideTitle: true,
      imgOnlyWhenDisabled: true,
      hideViewOnMarketplace: true,
      hideCollectionLinks: true,
      hideRevealCreatedBy: true,
      hideRevealHelp: true,
      // hideStats: true,
      ...attrFlags,
    };
  } else if (template == EmbedTemplate.V1) {
    return {
      ...attrFlags,
      v1Template: true,
    };
  } else {
    console.warn("unknown template", template);
  }
  return attrFlags;
};

export const EmbedFlagsContext = createContext<EmbedFlags | null>(null);

export const useEmbedFlags = (): EmbedFlags => {
  const context = useContext(EmbedFlagsContext);
  if (!context) {
    return {};
  }
  return context;
};

import { FC, lazy, memo, PropsWithChildren, Suspense } from "react";

import { useReactiveVar } from "@apollo/client";

import type { SuccessModalData } from "../MintPage/SuccessModal";
import { useEmbedFlags } from "../MintPage/embed/customize";

import { modalVar, ModalType } from "./Modal/modal";

const MintSuccessModal = lazy(() => import("../MintPage/SuccessModal"));
const GatedMintAllPrereqsModal = lazy(
  () => import("./Modal/GatedMintAllPrereqsModal")
);

export type RootProps = {
  requiredAuth?: boolean;
};

const Root: FC<PropsWithChildren<RootProps>> = ({ children }) => {
  const { showModal } = useReactiveVar(modalVar);

  return (
    <>
      {children}
      <Suspense fallback={""}>
        {showModal === ModalType.MINT_SUCCESS && <MintSuccessModal />}
        {showModal === ModalType.GATED_ENTITY_ALL_PREREQS && (
          <GatedMintAllPrereqsModal />
        )}
      </Suspense>
    </>
  );
};

export const MintCardEmbedModals = ({
  collectionId,
}: {
  collectionId: string;
}) => {
  const { showModal, data } = useReactiveVar(modalVar);
  const flags = useEmbedFlags();
  const isMatchingCollection = () => {
    const successData = data as SuccessModalData;
    return successData.collectionId === collectionId;
  };
  if (showModal === ModalType.MINT_SUCCESS && isMatchingCollection()) {
    return <MintSuccessModal flags={flags} />;
  }
  return null;
};

export default memo(Root);

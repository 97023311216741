import { memo, ReactNode, useLayoutEffect, useState } from "react";
import * as React from "react";

import CopyButton from "@hl/shared-features/lib/features/widgets/CopyButton";
import { maskAddress } from "@hl/shared-features/lib/utils/content";
import { Text, TextProps, Flex } from "@mantine/core";
import { useElementSize, useViewportSize } from "@mantine/hooks";

export type EvmAddress = {
  address: string;
  name: string | null;
};

type CreatedByProps = {
  creatorAddresses?: EvmAddress[] | null;
  creatorEns?: string | null;
  copyableAddress?: boolean;
};
type CollectionInfoProps = CreatedByProps & {
  editionSize?: number;
  textProps?: TextProps;
  nonTransferable?: boolean | null;
  series?: boolean;
  codeGenSeries?: boolean;
  rightContent?: React.ReactNode;
};

export const CreatedBy = ({
  creatorEns,
  creatorAddresses,
  copyableAddress,
}: CreatedByProps) => {
  const displayAddress = creatorEns
    ? creatorEns
    : creatorAddresses
    ? maskAddress(creatorAddresses[0].address, 6, 4)
    : "";
  const fullAddress = creatorEns
    ? creatorEns
    : creatorAddresses
    ? creatorAddresses[0].address
    : "";

  return (
    <>
      <span>Created by {displayAddress}</span>
      {copyableAddress && <CopyButton copyValue={fullAddress} />}
    </>
  );
};

export const EditionOf = ({ editionSize }: { editionSize: number }) => {
  const editionSizeFormatted =
    editionSize !== 0
      ? editionSize % 1000 == 0
        ? `Edition of ${editionSize / 1000}K`
        : `Edition of ${editionSize}`
      : "Open Edition";

  return <span>{editionSizeFormatted}</span>;
};

const useIsWrapped = (showNodes: ReactNode[], gap = 0) => {
  const { ref, width } = useElementSize<HTMLDivElement>();
  const { width: viewportWidth } = useViewportSize();
  const [isRow, setIsRow] = useState(true);

  useLayoutEffect(() => {
    const parentWidth = ref.current.parentElement!.parentElement!.offsetWidth;
    const combinedWidth = Array.from(ref.current.children).reduce(
      (acc, child) => acc + (child as HTMLElement).offsetWidth,
      0
    );
    const dotWidth =
      (ref.current.children[1]?.children[0] as HTMLElement)?.offsetWidth || 0;

    const numberOfGaps = showNodes.length - 1;
    // gap accounts for additional gap due to <span>.</span> element
    if (combinedWidth + numberOfGaps * gap >= parentWidth) {
      setIsRow(false);
    }
    // when the content is wrapped in a column left gap, right gap and dot itself disappear
    if (combinedWidth + numberOfGaps * (2 * gap + dotWidth) < parentWidth) {
      setIsRow(true);
    }
  }, [width, viewportWidth]);

  return {
    isRow,
    ref,
  };
};

const CollectionInfo = ({
  editionSize,
  creatorAddresses,
  creatorEns,
  textProps,
  nonTransferable,
  copyableAddress,
  series,
  codeGenSeries,
  rightContent,
}: CollectionInfoProps) => {
  const showNodes: ReactNode[] = [
    ...(creatorAddresses?.length || creatorEns
      ? [
          <CreatedBy
            key={"created-by"}
            creatorAddresses={creatorAddresses}
            creatorEns={creatorEns}
            copyableAddress={copyableAddress}
          />,
        ]
      : []),
    ...(editionSize && !series
      ? [<EditionOf key={"edition-of"} editionSize={editionSize} />]
      : []),
    ...(series && !codeGenSeries ? [<span key={"series"}>Series</span>] : []),
    ...(codeGenSeries
      ? [<span key={"code-gen-series"}>Generative series</span>]
      : []),
    ...(nonTransferable
      ? [<span key={"non-transferable"}>Non-transferable</span>]
      : []),
    ...(rightContent ? [rightContent] : []),
  ];

  const { isRow, ref } = useIsWrapped(showNodes, 8);

  return (
    <Text size="sm" {...textProps}>
      <Flex
        columnGap={8}
        rowGap={4}
        gap={8}
        ref={ref}
        align="center"
        direction={isRow ? "row" : "column"}
      >
        {showNodes.map((node, index) => (
          <Flex columnGap={8} key={index}>
            {index > 0 && (
              <Text
                sx={{
                  lineHeight: "inherit",
                  color: "inherit",
                  position: isRow ? "static" : "absolute",
                  visibility: isRow ? "visible" : "hidden",
                }}
              >
                ·
              </Text>
            )}
            {node}
          </Flex>
        ))}
      </Flex>
    </Text>
  );
};

export default memo(CollectionInfo);

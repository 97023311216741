import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import FormatCrypto from "@hl/base-components/lib/FormatCrypto";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
const DepositFundsDescription = ({ amount, status, symbol }) => {
    const formatted = (_jsx(FormatCrypto, { display: "inline", weight: WEIGHT_BOLD, size: "sm", amount: amount, symbol: symbol }));
    if (status === "PENDING") {
        return _jsxs(_Fragment, { children: ["Wrapped ", formatted] });
    }
    if (status === "MINED") {
        return _jsxs(_Fragment, { children: ["Wrapped ", formatted] });
    }
    if (status === "REJECTED") {
        return _jsxs(_Fragment, { children: ["Failed to wrap ", formatted] });
    }
    return _jsx(_Fragment, { children: "Unknown transaction status." });
};
export default DepositFundsDescription;

import {
  ArtblocksEngineLogo,
  ArtblocksLogo,
  BrightMoments,
  VerseLogo,
  Data,
} from "@hl/base-components/lib/assets/icons.generated";

const importedCollectionPlatform = (platform?: string | null) => {
  if (platform === "Verse") {
    return {
      icon: VerseLogo,
      name: "Verse",
    };
  } else if (platform === "Artblocks") {
    return {
      icon: ArtblocksLogo,
      name: "ArtBlocks",
    };
  } else if (platform === "Artblocks Engine") {
    return {
      icon: ArtblocksEngineLogo,
      name: "ArtBlocks Engine",
    };
  } else if (platform === "Bright Moments") {
    return {
      icon: BrightMoments,
      name: "Bright Moments",
    };
  }
  return {
    icon: Data,
    name: "External release",
  };
};

export default importedCollectionPlatform;

import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import FormatCrypto from "@hl/base-components/lib/FormatCrypto";
import { FilledSquare } from "@hl/base-components/lib/assets/icons.generated";
import { TEXT_COLOR, UTILITY_COLOR, } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Card, Flex, Group, Stack, Text, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import dayjs, { extend } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import usePercentDifference from "../../../hooks/usePercentDifference";
import { ResizedImage } from "../../image";
extend(relativeTime);
extend(advancedFormat);
extend(utc);
extend(timezone);
const MarketplaceSummaryCard = ({ imageSize = 64, imageUrl, tokenId, collectionName, validUntil, price, floorPrice, symbol, isListing = false, subtext, quantity, }) => {
    const theme = useMantineTheme();
    const isSmAndUp = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);
    const { isDiffPositive, diffWording } = usePercentDifference(price, floorPrice);
    const unitPrice = parseFloat(price || "0");
    const totalPrice = (quantity || 1) * unitPrice;
    return (_jsx(Card, { children: _jsxs(Group, { noWrap: true, children: [_jsx(Flex, { p: 8, bg: UTILITY_COLOR.TABLE_ROW_BACKGROUND, justify: "center", align: "center", sx: { flexShrink: 0 }, w: imageSize + 16, h: imageSize + 16, children: imageUrl ? (_jsx(ResizedImage, { src: imageUrl, width: imageSize, height: imageSize, fit: "contain" })) : (_jsx(FilledSquare, {})) }), _jsxs(Flex, { justify: "space-between", align: { base: "flex-start", sm: "center" }, gap: "sm", direction: { base: "column", sm: "row" }, sx: { flexGrow: 1 }, children: [_jsxs(Stack, { spacing: 0, lh: 1.4, children: [_jsx(Text, { size: tokenId ? "lg" : "sm", fw: WEIGHT_BOLD, children: tokenId ? `#${tokenId}` : "any token from" }), _jsx(Text, { size: "sm", tt: "uppercase", children: collectionName }), subtext
                                    ? subtext
                                    : validUntil && (_jsxs(Text, { size: "sm", color: TEXT_COLOR.SECONDARY, children: [isListing ? "Listing" : "Offer", " expires", " ", dayjs(validUntil).format("MMM. DD @ h:mma z")] }))] }), price && (_jsxs(Stack, { spacing: 0, align: isSmAndUp ? "flex-end" : "flex-start", sx: { flexShrink: 0 }, children: [_jsx(FormatCrypto, { amount: totalPrice, symbol: symbol, size: "lg", fw: WEIGHT_BOLD, sx: { flexShrink: 0 }, align: isSmAndUp ? "right" : "left" }), !!quantity && quantity > 1 && (_jsxs(Text, { size: "xs", align: isSmAndUp ? "right" : "left", color: TEXT_COLOR.SECONDARY, children: [quantity, "x", " ", _jsx(FormatCrypto, { amount: unitPrice, symbol: symbol, onlyText: true })] })), _jsx(Text, { size: "sm", align: isSmAndUp ? "right" : "left", color: isDiffPositive
                                        ? theme.colors.affirmativeStatus[0]
                                        : theme.colors.warningStatus[0], children: diffWording })] }))] })] }) }));
};
export default MarketplaceSummaryCard;

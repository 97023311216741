import { forwardRef, memo, Ref, useCallback, useEffect, useState } from "react";

import { STATUS_COLOR, TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { useAuth } from "@hl/shared-features/lib/features/auth";
import { SignInButton } from "@hl/shared-features/lib/features/auth/SignInButton";
import AddToCalendarButton from "@hl/shared-features/lib/features/calendar/AddToCalendarButton";
import CryptoCurrencyInput from "@hl/shared-features/lib/features/input/CryptoCurrencyInput";
import { getCurrencySymbol } from "@hl/shared-features/lib/utils/currency";
import {
  Box,
  Center,
  createStyles,
  Group,
  List,
  Loader,
  Paper,
  PaperProps,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";

import { RaBid } from "../../../apollo/graphql.generated";
import useMintState, { MintStateRequired } from "../../../hooks/useMintState";
import { MintCardInnerProps, MintCardProps } from "../MintVector/MintCard";
import MintCardDate from "../MintVector/MintCardDate";
import MainCardSkeleton from "../MintVector/MintCardSkeleton";

import {
  InsufficientFundsError,
  RaBidButton,
  useInsuficientFunds,
} from "./BidButton";
import { Bidder } from "./Bidder";
import { ClaimCard } from "./claim";
import { EditBidsButton } from "./edit";
import { AuctionHistoryBtn } from "./history";
import { useRankedAuction, useRankedAuctionAutoRefresh } from "./hooks";

const BidCardInner = forwardRef<HTMLButtonElement, MintCardInnerProps>(
  (props, ref) => {
    const { buttonProps = {}, showErrorIcon, showWarningModals } = props;
    const {
      collection,
      mintVector,
      numTokensToMint,
      loadingClaimMint,
      mintDataLoading,
    } = useMintState() as MintStateRequired;
    const [bidAmount, setBidAmount] = useState<string>("");

    const { authenticated } = useAuth();

    const currency = getCurrencySymbol(
      mintVector.chainId!,
      mintVector.paymentCurrency?.symbol
    );

    const handleAddBidFinish = useCallback(() => {
      setBidAmount("");
    }, [setBidAmount]);
    const { hasEnoughMoney } = useInsuficientFunds(bidAmount);

    const { rankedAuction, isStarted } = useRankedAuction();
    if (!rankedAuction) {
      return null;
    }
    const { lowestBidRequired, minBid, userBids } = rankedAuction;
    const hasUserBids = userBids?.length ?? 0 !== 0;

    const editBidsBtn = hasUserBids ? <EditBidsButton /> : undefined;

    return (
      <Box>
        <Stack spacing={0}>
          <Text
            color={TEXT_COLOR.SECONDARY}
            fw={WEIGHT_BOLD}
            size={12}
            sx={{ textTransform: "uppercase" }}
          >
            {minBid === lowestBidRequired
              ? "Minimum bid"
              : "lowest bid required"}
          </Text>
          <Text size={29} fw={WEIGHT_BOLD}>
            {lowestBidRequired}{" "}
            <Text span fw={WEIGHT_BOLD} size={18}>
              {getCurrencySymbol(
                mintVector.chainId!,
                mintVector.paymentCurrency?.symbol
              )}
            </Text>
          </Text>
        </Stack>
        <Space h={16} />
        {!isStarted && (
          <AddToCalendarButton
            ref={ref}
            w="100%"
            size="xl"
            name={collection.name}
            date={mintVector.start}
            collectionId={collection.id}
            collection={collection}
            imageUrl={collection.collectionImage ?? ""}
          />
        )}
        {isStarted && !authenticated && (
          <SignInButton ref={ref} size="xl" fullWidth {...buttonProps} />
        )}
        {isStarted && authenticated && (
          <>
            <Group spacing={8} align="center">
              {userBids?.length &&
              userBids.length >= mintVector.maxPerUser &&
              mintVector.maxPerUser > 0 ? (
                <Text size="xs">Maximum number of bids submitted</Text>
              ) : (
                <CryptoCurrencyInput
                  missingChainIdPlaceholder=""
                  chainId={collection.chainId}
                  sx={{ flexGrow: 1 }}
                  styles={{
                    input: { marginTop: 0 },
                  }}
                  value={bidAmount !== "" ? parseFloat(bidAmount) : undefined}
                  onChange={(val) => setBidAmount((val ?? 0).toString())}
                />
              )}
            </Group>
            <InsufficientFundsError hasEnoughMoney={hasEnoughMoney} />

            <RaBidButton
              ref={ref}
              fullWidth
              loading={loadingClaimMint || mintDataLoading}
              showErrorIcon={showErrorIcon}
              size="xl"
              initialButtonLabel={hasUserBids ? "New bid" : "Make bid"}
              showWarningModals={showWarningModals}
              bidAmount={bidAmount}
              rightButton={editBidsBtn}
              onFinish={handleAddBidFinish}
              hasEnoughMoney={hasEnoughMoney}
              {...buttonProps}
            />
            {numTokensToMint > 1 && (
              <Text size="sm" color={TEXT_COLOR.SECONDARY}>
                You are placing {numTokensToMint} bids at {bidAmount} {currency}{" "}
                each
              </Text>
            )}
          </>
        )}
      </Box>
    );
  }
);

const useStyles = createStyles({
  inheritColor: {
    color: "inherit",
  },
});
const AuctionRanking = () => {
  const { classes } = useStyles();
  const { rankedAuction, loading, mintVector, bidsLimit } = useRankedAuction();
  if (loading) {
    return (
      <Center p="xl">
        <Loader size="md" />
      </Center>
    );
  }
  if (!rankedAuction) {
    return <div>error</div>;
  }

  const { bids, userBids } = rankedAuction;
  const { edges: winningBids, pageInfo } = bids;
  const chainId = mintVector.chainId!;
  const currencySymbol = getCurrencySymbol(
    chainId,
    mintVector.paymentCurrency?.symbol
  );
  let sortedUserBids: RaBid[] = [];
  if (userBids) {
    sortedUserBids = [...userBids].sort((a, b) => {
      if (a.currentlyValid && !b.currentlyValid) {
        return -1;
      } else if (!a.currentlyValid && b.currentlyValid) {
        return 1;
      }
      return a.rank - b.rank;
    });
  }

  return (
    <>
      {userBids && userBids.length !== 0 && (
        <>
          <Text size={18} fw={WEIGHT_BOLD}>
            Your bids
          </Text>
          <Box mb={12}>
            {sortedUserBids.map((bid) => (
              <Group
                position="apart"
                mt={12}
                key={bid.id}
                sx={(theme) => ({
                  color: bid.currentlyValid
                    ? theme.fn.themeColor(STATUS_COLOR.AFFIRMATIVE)
                    : theme.fn.themeColor(STATUS_COLOR.ERROR),
                })}
              >
                <Text size="sm" className={classes.inheritColor}>
                  <Text inherit span fw={WEIGHT_BOLD} mr={10}>
                    {bid.rank ? `${bid.rank}.` : " - "}
                  </Text>
                  <Bidder {...bid} chainId={chainId} />
                </Text>
                <Text size="sm" className={classes.inheritColor}>
                  {bid.bidAmount} {currencySymbol}
                </Text>
              </Group>
            ))}
          </Box>
        </>
      )}
      <Text size={18} fw={WEIGHT_BOLD}>
        Auction ranking
      </Text>
      <Box>
        {winningBids.map((bid) => (
          <Group position="apart" mt={12} key={bid.id}>
            <Text size="sm">
              <Text span fw={WEIGHT_BOLD} mr={10} inherit>
                {bid.rank}.
              </Text>
              <Bidder {...bid} chainId={chainId} />
            </Text>
            <Text size="sm">
              {bid.bidAmount} {currencySymbol}
            </Text>
          </Group>
        ))}
      </Box>
      {pageInfo.totalCount > bidsLimit && <AuctionHistoryBtn />}
    </>
  );
};

const InfoCard = () => {
  const { mintVector, rankedAuction } = useRankedAuction();
  return (
    <Text size="sm">
      <Text size={14} fw={WEIGHT_BOLD}>
        Details
      </Text>

      <List
        size="sm"
        mt={8}
        styles={{ item: { paddingLeft: 8, paddingRight: 12 } }}
        spacing={5}
      >
        <List.Item>
          The top {mintVector?.maxPerVector} bids will each win an artwork.
        </List.Item>
        {mintVector && mintVector.maxPerUser > 0 && (
          <List.Item>
            Bidders can place a maximum of {mintVector.maxPerUser} active bids.
            Bids can be increased over the course of the auction.
          </List.Item>
        )}
        <List.Item>
          In the final 5 minutes of the auction, a new bid will reset the
          remaining time to 5 minutes.
        </List.Item>
        <List.Item>
          Losing bids funds can be claimed from escrow at any time.
        </List.Item>
        {rankedAuction?.rebateWinningBids && (
          <List.Item>
            All winning bidders will pay the lowest winning bid amount and
            reclaim any extra amount as a rebate.
          </List.Item>
        )}
      </List>
    </Text>
  );
};

const RankedAuctionRefreshRules = () => {
  useRankedAuctionAutoRefresh(60);
  return null;
};

export const RankedAuctionCards = memo(
  forwardRef((props: MintCardProps, ref: Ref<HTMLButtonElement>) => {
    const { mintVector, disabledCardText } = useMintState();
    const { rankedAuction, isStarted, loading, error } = useRankedAuction();
    const isFinished = rankedAuction?.isFinished;
    const validBidsCount = rankedAuction?.bids?.edges?.length ?? 0;

    useEffect(() => {
      if (error) {
        console.log("rankedAuction error", { error });
      }
    }, [error]);

    const cardPaperProps: PaperProps = {
      shadow: props.isApollo ? "0" : "md",
      radius: props.isApollo ? 12 : "md",
      withBorder: props.isApollo,
      p: "md",
    };

    if (loading) {
      return <MainCardSkeleton isApollo={props.isApollo} />;
    }
    return (
      <>
        {rankedAuction && mintVector && <RankedAuctionRefreshRules />}
        <Paper {...cardPaperProps}>
          {error && (
            <Text size="sm" color={STATUS_COLOR.ERROR}>
              Something is wrong. Cannot load data
            </Text>
          )}
          {!isFinished && !disabledCardText && !error && (
            <>
              <MintCardDate showDivider type="auction" />
              <BidCardInner {...props} ref={ref} />
            </>
          )}
          {!isFinished && disabledCardText && (
            <Stack spacing="xs" mb="sm">
              <Title size="h5">{disabledCardText.title}</Title>
              <Text style={{ opacity: 0.6 }} size="sm">
                {disabledCardText.description}
              </Text>
            </Stack>
          )}
          {isFinished && <ClaimCard />}
        </Paper>
        {isStarted && validBidsCount !== 0 && !error && (
          <Paper {...cardPaperProps}>
            <AuctionRanking />
          </Paper>
        )}

        <Paper {...cardPaperProps} mb="md">
          <InfoCard />
        </Paper>
      </>
    );
  })
);

import { useCallback, useState } from "react";

import { ChevronDown } from "@hl/base-components/lib/assets/icons.generated";
import {
  Box,
  Checkbox,
  Flex,
  UnstyledButton,
  Text,
  Divider,
} from "@mantine/core";

type Props = {
  title: string;
  options: {
    value: string;
    count: number;
  }[];
  selectedOptions?: string[];
  onSelect: (attribute: string, value: string) => void;
  onUnselect: (attribute: string, value: string) => void;
  isLast: boolean;
};

const ChevronUp = () => <ChevronDown style={{ transform: "rotate(180deg)" }} />;

export const FilterGroup: React.FC<Props> = ({
  title,
  options,
  selectedOptions = [],
  onSelect,
  onUnselect,
  isLast,
}) => {
  const [isExpanded, setExpanded] = useState(selectedOptions.length !== 0);
  const toggleExpanded = useCallback(() => {
    setExpanded((state) => !state);
  }, [setExpanded]);

  return (
    <Box>
      <UnstyledButton onClick={toggleExpanded} w="100%" py={10}>
        <Flex justify={"space-between"}>
          <Text size={14}>{title}</Text>
          <div>{isExpanded ? <ChevronUp /> : <ChevronDown />}</div>
        </Flex>
      </UnstyledButton>
      {isExpanded && (
        <>
          <Divider />
          {options.map(({ value, count }) => (
            <Box key={value} ml={24} pt={10}>
              <Checkbox
                labelPosition="left"
                label={count ? `${value} (${count})` : value}
                checked={count !== 0 && selectedOptions.includes(value)}
                disabled={count === 0}
                styles={{
                  body: { width: "100%" },
                  labelWrapper: {
                    width: "100%",
                    maxWidth: "calc(100% - 20px)",
                  },
                  label: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                }}
                mb={10}
                onChange={(e) => {
                  const fn = e.currentTarget.checked ? onSelect : onUnselect;
                  fn(title, value);
                }}
              />
              <Divider />
            </Box>
          ))}
        </>
      )}
      {!isLast && !isExpanded && <Divider />}
    </Box>
  );
};

import { labelStyles } from "./typography";
const styles = (theme) => ({
    input: {
        padding: 16,
        height: 48,
        color: theme.colors.primaryText[0],
        "::placeholder": {
            color: theme.colors.placeholderText[0],
        },
        borderColor: theme.colors.divider[0],
        borderRadius: theme.radius.sm,
        marginTop: 8,
        backgroundColor: "inherit",
        "&[aria-invalid=true]": {
            borderColor: theme.colors.errorStatus[0],
            color: theme.colors.errorStatus[0],
        },
        "&[aria-invalid=true]::placeholder": {
            color: theme.colors.errorStatus[0],
        },
    },
    label: {
        ...labelStyles(theme),
    },
    description: {
        color: theme.colors.primaryText[0],
        lineHeight: 1.4,
    },
    rightSection: {
        justifyContent: "end",
        paddingRight: 16,
    },
    error: {
        color: theme.colors.errorStatus[0],
    },
});
export const inputsTheme = {
    Input: {
        styles,
    },
    InputWrapper: {
        defaultProps: {
            descriptionProps: {
                size: "md",
            },
        },
        styles,
    },
    NumberInput: {
        styles: (theme) => ({
            control: {
                borderColor: theme.colors.divider[0],
                color: theme.colors.primaryText[0],
            },
        }),
    },
};

import dayjs from "dayjs";
import { createEvent } from "ics";
const formatDateForGoogle = (isoDate) => {
    const [date, time] = isoDate.split("T");
    const [year, month, day] = date.split("-");
    const [hour, minute, second] = time.split(":");
    // remove milliseconds
    return `${year}${month}${day}T${hour}${minute}${second.slice(0, 2)}Z`;
};
const generateAppleCalendarLink = (event) => {
    const start = dayjs(event.startDate)
        .format("YYYY-M-D-H-m")
        .split("-")
        .map((num) => parseInt(num));
    const icsData = createEvent({
        title: event.title,
        description: event.description,
        busyStatus: "FREE",
        location: event.location,
        start,
        duration: { minutes: 30 },
    });
    return `data:text/calendar;charset=utf-8,${encodeURIComponent(icsData.value || "")}`;
};
const generateGoogleCalendarLink = (event) => {
    const url = new URL("https://calendar.google.com/calendar/render");
    url.searchParams.append("action", "TEMPLATE");
    url.searchParams.append("text", event.title);
    url.searchParams.append("details", event.description);
    url.searchParams.append("location", event.location);
    url.searchParams.append("dates", `${formatDateForGoogle(event.startDate)}/${formatDateForGoogle(event.endDate)}`);
    return url.toString();
};
const generateOutlookCalendarLink = (event) => {
    const url = new URL("https://outlook.live.com/calendar/0/deeplink/compose");
    url.searchParams.append("subject", event.title);
    url.searchParams.append("body", event.description);
    // url.searchParams.append('allday', String(event.isAllDay))
    url.searchParams.append("startdt", event.startDate);
    url.searchParams.append("enddt", event.endDate);
    url.searchParams.append("location", event.location);
    url.searchParams.append("rru", "addevent");
    return url.toString();
};
const generateYahooCalendarLink = (event) => {
    const url = new URL("https://calendar.yahoo.com/");
    url.searchParams.append("title", event.title);
    url.searchParams.append("desc", event.description);
    url.searchParams.append("in_loc", event.location);
    url.searchParams.append("dur", "");
    url.searchParams.append("st", formatDateForGoogle(event.startDate));
    url.searchParams.append("et", formatDateForGoogle(event.endDate));
    url.searchParams.append("v", "60");
    return url.toString();
};
export const generateCalendarLinks = (event) => {
    return {
        google: generateGoogleCalendarLink(event),
        outlook: generateOutlookCalendarLink(event),
        yahoo: generateYahooCalendarLink(event),
        apple: generateAppleCalendarLink(event),
    };
};

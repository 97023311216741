import { BrowseCollectionQuery } from "~features/browse/queries/browse.graphql.generated";
export enum MintStatus {
  All = "all",
  Minted = "minted",
  Available = "available",
}

export type BrowseCollection = Pick<
  BrowseCollectionQuery["getPublicCollection"],
  | "address"
  | "name"
  | "description"
  | "reveal"
  | "seriesDetails"
  | "chainId"
  | "seriesAttributes"
>;

import { Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Text } from "@mantine/core";
import { currencyCode, formatBN } from "./utils/numbers";
const FormatCrypto = ({ amount, maximumFractionDigits = 5, shorten = false, decimals = 18, symbol, onlyText = false, ...rest }) => {
    let value = amount ? formatBN(amount, maximumFractionDigits, decimals) : "—";
    if (shorten && Number(value) > 1) {
        value = value.substring(0, value.indexOf(".") + 3);
    }
    return onlyText ? (_jsxs(_Fragment, { children: [value, " ", currencyCode(symbol)] })) : (_jsxs(Text, { ...rest, children: [value, " ", currencyCode(symbol)] }));
};
export default FormatCrypto;

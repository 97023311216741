import { labelStyles, fontFeatureSettings, WEIGHT_NORMAL } from "../typography";
export const typographyTheme = {
    Text: {
        defaultProps: (theme) => ({
            color: theme.colors.primaryText[0],
            size: "md",
            weight: WEIGHT_NORMAL,
        }),
        styles: (theme, { size }) => ({
            root: {
                ...(size === "xl" && {
                    lineHeight: "1.3em",
                    letterSpacing: "0.015em",
                    fontFeatureSettings,
                }),
                ...(size === "lg" && {
                    lineHeight: "1.4em",
                    letterSpacing: "0.02em",
                    fontFeatureSettings,
                }),
                ...(size === "md" && {
                    lineHeight: "1.4em",
                    letterSpacing: "0.025em",
                    fontFeatureSettings,
                }),
                ...(size === "sm" && {
                    lineHeight: "1.4em",
                    letterSpacing: "0.03em",
                    fontFeatureSettings,
                }),
                ...(size === "xs" && {
                    lineHeight: "1.4em",
                    letterSpacing: "0.035em",
                    fontFeatureSettings,
                }),
                ":is(label)": {
                    ...labelStyles(theme),
                },
            },
        }),
    },
    Title: {
        defaultProps: (theme) => ({
            color: theme.colors.primaryText[0],
            weight: WEIGHT_NORMAL,
        }),
        styles: (theme, { size, element }) => ({
            root: {
                ...((size === "h1" || element === "h1") && {
                    lineHeight: "1em",
                    letterSpacing: "-0.005em",
                    fontFeatureSettings,
                }),
                ...((size === "h2" || element === "h2") && {
                    lineHeight: "1.05em",
                    letterSpacing: "0em",
                    fontFeatureSettings,
                }),
                ...((size === "h3" || element === "h3") && {
                    lineHeight: "1.1em",
                    letterSpacing: "0.005em",
                    fontFeatureSettings,
                }),
                ...((size === "h4" || element === "h4") && {
                    lineHeight: "1.15em",
                    letterSpacing: "0.01em",
                    fontFeatureSettings,
                }),
                ...((size === "h5" || element === "h5") && {
                    lineHeight: "1.2em",
                    letterSpacing: "0.015em",
                    fontFeatureSettings,
                }),
                ...((size === "h6" || element === "h6") && {
                    lineHeight: "1.25em",
                    letterSpacing: "0.02em",
                    fontFeatureSettings,
                }),
            },
        }),
    },
};

import { PropsWithChildren } from "react";

import { Box, Flex, NumberInput } from "@mantine/core";
import { NumberInputProps } from "@mantine/core/lib/NumberInput/NumberInput";

import useMintState from "../../../hooks/useMintState";

const NumMintsInput = ({
  height,
  ...rest
}: Omit<NumberInputProps, "data"> & {
  height?: number;
}) => {
  const { numTokensToMint, setNumTokensToMint, numMintsLeft, collection } =
    useMintState();
  const enableMintGenSeriesControls =
    collection?.flagVariations.enableMintGenSeriesControls;
  if (enableMintGenSeriesControls) return <></>;
  return (
    <NumberInput
      {...rest}
      onChange={(value: number) => setNumTokensToMint(parseInt(String(value)))}
      defaultValue={1}
      value={numTokensToMint}
      min={1}
      max={numMintsLeft}
      w={84}
      miw={84}
      styles={{
        input: {
          marginTop: 0,
          paddingRight: 24,
          height,
        },
        rightSection: {
          paddingRight: 0,
        },
      }}
    />
  );
};

export const AppendNumMintsInput: React.FC<
  PropsWithChildren<
    Omit<NumberInputProps, "data"> & {
      height?: number;
      show?: boolean;
    }
  >
> = ({ children, show = true, ...rest }) => {
  if (!show) return <>{children}</>;
  return (
    <Flex w="100%" align="center" justify="center" gap={4}>
      <Box style={{ maxWidth: "100%", flexGrow: 1, overflow: "hidden" }}>
        {children}
      </Box>
      <NumMintsInput {...rest} />
    </Flex>
  );
};

export default NumMintsInput;

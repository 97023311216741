import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";
import { IN_DEVELOPMENT } from "../config";
const useGaTracker = (googleAnalyticsId) => {
    const location = useLocation();
    const [GAInitialized, setGAInitialized] = useState(false);
    const startGoogleAnalytics = () => {
        if (!googleAnalyticsId)
            return;
        if (!window.location.href.includes("localhost")) {
            ReactGA.initialize(googleAnalyticsId);
            IN_DEVELOPMENT &&
                console.log(`🟢 Google Analytics Inited. Key: ${googleAnalyticsId}`);
        }
        setGAInitialized(true);
    };
    /**
     * Emit a web core vitals event to google analytics
     *
     * @param event - Google Analytics, web-core-vitals Event
     */
    const emitGaEvent = (event) => {
        IN_DEVELOPMENT &&
            console.log(`🟢 Google Analytics report web vital event: ${event}`);
        if (!googleAnalyticsId)
            return;
        if (GAInitialized) {
            if (!window.location.href.includes("localhost")) {
                ReactGA.gtag("event", event.name, {
                    // Built-in params:
                    value: event.delta,
                    // Custom params:
                    metric_id: event.id,
                    metric_value: event.value,
                    metric_delta: event.delta, // Optional.
                    // OPTIONAL: any additional params or debug info here.
                    // See: https://web.dev/debug-web-vitals-in-the-field/
                    // metric_rating: 'good' | 'ni' | 'poor',
                    // debug_info: '...',
                    // ...
                });
            }
            else {
                IN_DEVELOPMENT &&
                    console.log(`🟢 Google Analytics emit event skipped for local-dev: ${event}`);
            }
        }
        else {
            IN_DEVELOPMENT &&
                console.warn(`🟡 Google Analytics emit event skipped, library not initialized!`);
        }
    };
    useEffect(() => {
        if (GAInitialized) {
            ReactGA.send("pageview");
        }
    }, [GAInitialized, location]);
    return {
        startGoogleAnalytics,
        isInitialized: GAInitialized,
        emitGaEvent,
    };
};
export default useGaTracker;

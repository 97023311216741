import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Box } from "@mantine/core";
import { useHover } from "@mantine/hooks";
// video tag with controls list shown on hover
export const Video = ({ controlsList = "nodownload", containerStyles = {}, ...props }) => {
    var _a;
    const { ref, hovered } = useHover();
    const hoverAttrs = hovered ? { controlsList } : {};
    const showControls = (_a = props.controls) !== null && _a !== void 0 ? _a : hovered;
    return (_jsx(Box, { ref: ref, sx: containerStyles, children: _jsx("video", { ...props, controls: showControls, ...hoverAttrs }) }));
};

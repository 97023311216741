import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Text } from "@mantine/core";
const MultilineText = ({ children, numLines, ...rest }) => {
    return (_jsx(Text, { sx: {
            overflowWrap: "anywhere",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            ["@supports (-webkit-line-clamp: 2)"]: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "initial",
                display: "-webkit-box",
                WebkitLineClamp: +numLines,
                WebkitBoxOrient: "vertical",
            },
        }, ...rest, children: children }));
};
export default MultilineText;

import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo, useEffect, useRef } from "react";
import { GaslessClaimStatus, } from "../../apollo/graphql.generated";
import { TransactionStateType, useTransactionsDispatch, } from "./TransactionContext";
import { useGetGaslessTransactionStatusQuery } from "./queries.graphql.generated";
const RelayerTransactionExecution = ({ id, type, relayerTaskId, chainId, }) => {
    const dispatch = useTransactionsDispatch();
    const intervalRef = useRef(undefined);
    const { data: gaslessTransactionStatusData, refetch } = useGetGaslessTransactionStatusQuery({
        variables: {
            getGaslessTransactionStatusId: relayerTaskId,
            chainId,
            type: "Gelato",
        },
    });
    const transactionStatus = gaslessTransactionStatusData === null || gaslessTransactionStatusData === void 0 ? void 0 : gaslessTransactionStatusData.getGaslessTransactionStatus;
    useEffect(() => {
        if (type === TransactionStateType.WaitingPrepareTx) {
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_TX_STATE",
                payload: {
                    id,
                    type: TransactionStateType.WaitingConfirmedTx,
                },
            });
        }
    }, []);
    useEffect(() => {
        clearInterval(intervalRef === null || intervalRef === void 0 ? void 0 : intervalRef.current);
        intervalRef.current = setInterval(refetch, 10000);
        return () => clearInterval(intervalRef === null || intervalRef === void 0 ? void 0 : intervalRef.current);
    }, []);
    useEffect(() => {
        if (transactionStatus && transactionStatus.hash) {
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_TX_STATUS",
                payload: {
                    id,
                    hash: transactionStatus.hash,
                },
            });
            if (transactionStatus.status === GaslessClaimStatus.TX_COMPLETE) {
                clearInterval(intervalRef === null || intervalRef === void 0 ? void 0 : intervalRef.current);
            }
            return;
        }
        if ((transactionStatus === null || transactionStatus === void 0 ? void 0 : transactionStatus.status) === GaslessClaimStatus.TX_CANCELLED) {
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_TX_STATUS",
                payload: {
                    id,
                    error: new Error("Transaction cancelled"),
                },
            });
            clearInterval(intervalRef === null || intervalRef === void 0 ? void 0 : intervalRef.current);
        }
    }, [transactionStatus]);
    return _jsx(_Fragment, {});
};
export default memo(RelayerTransactionExecution);

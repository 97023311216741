import { useCallback, useEffect, useRef } from "react";

import { useAuthChangedCallback } from "@hl/shared-features/lib/features/auth/hooks";

import { CollectionStatus } from "../../../apollo/graphql.generated";
import { useMintPage } from "../../../hooks/useMintPage";
import useMintState, { MintStateRequired } from "../../../hooks/useMintState";
import { SaleStatus } from "../utils/types";

import { useRankedAuctionQuery } from "./ra-queries.graphql.generated";

const bidsLimit = 10;

export const useRankedAuction = () => {
  const { mintVector, collectionStatus, saleStatus } = useMintState();

  const { data, loading, refetch, error } = useRankedAuctionQuery({
    variables: {
      mintVectorId: mintVector?.id ?? "0",
      bidsLimit,
    },
    skip: !mintVector?.id,
  });

  if (!mintVector) {
    return {};
  }
  const isStarted = !(
    SaleStatus.NOT_STARTED === saleStatus ||
    collectionStatus !== CollectionStatus.LIVE
  );

  return {
    rankedAuction: data?.rankedAuction,
    loading,
    error: !!error?.graphQLErrors?.length,
    mintVector,
    bidsLimit,
    isStarted,
    refetchRankedAuction: refetch,
  };
};

const isPastDate = (endDate: string) => {
  const now = new Date();
  return new Date(endDate) < now;
};

export const useRankedAuctionAutoRefresh = (refreshPeriodSec = 60) => {
  const { mintVector, collection } = useMintState() as MintStateRequired;
  const { refetchMintVector } = useMintPage(collection?.id ?? "", {
    skipAuction: true,
  });

  const { rankedAuction, refetchRankedAuction } = useRankedAuction();
  const wasActive = useRef(false);
  useEffect(() => {
    if (
      !mintVector ||
      mintVector.paused ||
      !mintVector.end ||
      isPastDate(mintVector.end) // ended
    ) {
      return;
    }
    wasActive.current = true;
    const interval = setInterval(() => {
      refetchRankedAuction?.();

      //  stop when ended
      if (isPastDate(mintVector.end!)) {
        clearInterval(interval);
      }
    }, refreshPeriodSec * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [mintVector, refetchRankedAuction]);

  const endDate = rankedAuction?.end;

  useEffect(() => {
    if (!endDate) {
      return;
    }

    // checking it every 3 sec should help to distribute load
    // when auctions ends for all participants at the same time
    const END_CHECK_SEC = 3;

    const interval = setInterval(() => {
      if (wasActive.current && isPastDate(endDate)) {
        // ended after loading active, refresh
        refetchRankedAuction?.();
        wasActive.current = false;
      }
    }, END_CHECK_SEC * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [endDate, refetchRankedAuction]);

  const origEndDateRef = useRef(endDate);

  useEffect(() => {
    if (!origEndDateRef.current) {
      origEndDateRef.current = endDate;
    }
    if (origEndDateRef.current !== endDate) {
      // end date changed, auction was extended
      console.log("auction was extended", { endDate });
      refetchMintVector();
      origEndDateRef.current = endDate;
    }
  }, [refetchMintVector, endDate]);

  // refetch auction when authenticated state changes

  const handleRefetch = useCallback(() => {
    console.log("auth state changed, reload ranked auction");
    refetchRankedAuction?.();
  }, [refetchRankedAuction]);

  useAuthChangedCallback(handleRefetch);
};

import { useLocalStorage } from "@mantine/hooks";
import { ColorSchemeMode } from "./ColorSchemeContext";
export const useAppColorScheme = (props) => {
    const { initialColorScheme, forcedColorScheme } = props;
    const [colorSchemeLocalStore, setColorSchemeLocalStore] = useLocalStorage({
        key: "highlight-color-scheme",
        defaultValue: initialColorScheme !== null && initialColorScheme !== void 0 ? initialColorScheme : ColorSchemeMode.LIGHT,
        // If "false" gets it immediately, so for SSR the initialColorScheme
        // needs to be set
        getInitialValueInEffect: false,
    });
    const reconciledColorScheme = forcedColorScheme !== null && forcedColorScheme !== void 0 ? forcedColorScheme : colorSchemeLocalStore;
    const setColorScheme = (newColorScheme) => {
        if (forcedColorScheme != null)
            return;
        if (typeof window !== "undefined") {
            document.cookie = `colorScheme=${newColorScheme}; path=/; samesite=strict`;
        }
        setColorSchemeLocalStore(newColorScheme);
    };
    // This is derived from colorScheme. Right now we don't support "SYSTEM" as it clashes
    // with SSR, but we might (again) in the future. This setting is (was) used in the
    // dropdown where the user selects a theme.
    const userColorSchemeSettings = reconciledColorScheme;
    return {
        colorScheme: reconciledColorScheme,
        userColorSchemeSettings,
        setColorScheme,
    };
};

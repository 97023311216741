import { Radio, RadioProps } from "@mantine/core";

const RadioStyled = (props: RadioProps) => (
  <Radio
    labelPosition="left"
    styles={(theme) => ({
      root: {
        width: "100%",
        height: 40,
        display: "flex",
        alignItems: "center",
        borderBottom: `1px solid ${theme.colors.black[0]}`,
      },
      body: { width: "100%" },
      labelWrapper: { width: "100%" },
    })}
    {...props}
  />
);

export default RadioStyled;

import { createContext, useContext } from "react";

import { WithoutNullableKeys } from "../features/MintPage/utils/type-helpers";

import { MintState, useMintPage } from "./useMintPage";

export const MintContext = createContext<MintState | null>(null);

export default function useMintState() {
  const context = useContext(MintContext);

  if (context === null) {
    throw new Error(
      "useMintState hook must be used within a MintContext.Provider"
    );
  }

  return context;
}

export type MintStateRequired = WithoutNullableKeys<
  ReturnType<typeof useMintPage>
>;

import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo, useEffect } from "react";
import { useContractWrite, usePrepareContractWrite } from "wagmi";
import { ContractEntityType, TransactionType, } from "../../apollo/graphql.generated";
import { abi as AuctionManagerABI } from "../../assets/web3/AuctionManager.json";
import { abi as DiscreteDutchAuctionMechanicABI } from "../../assets/web3/DiscreteDutchAuctionMechanic.json";
import ERC1155ABI from "../../assets/web3/ERC1155.json";
import { abi as Erc20ABI } from "../../assets/web3/ERC20.json";
import { abi as ERC721EditionsABI } from "../../assets/web3/ERC721Editions.json";
import { abi as ERC721EditionsDFSABI } from "../../assets/web3/ERC721EditionsDFS.json";
import { abi as ERC721GeneralABI } from "../../assets/web3/ERC721General.json";
import { abi as GaslessMechanicABI } from "../../assets/web3/GaslessMechanic.json";
import { abi as HighlightFactoryABI } from "../../assets/web3/HighlightFactory.json";
import { abi as IEditionsMetadataRendererABI } from "../../assets/web3/IEditionsMetadataRenderer.json";
import { abi as MintManagerABI } from "../../assets/web3/MintManager.json";
import { abi as RankedAuctionMechanicABI } from "../../assets/web3/RankedAuctionMechanic.json";
import { abi as SeedBasedMintMechanicABI } from "../../assets/web3/SeedBasedMintMechanic.json";
import { TransactionStateType, useTransactionsDispatch, } from "./TransactionContext";
import { getMintingAbiFromMethodName } from "./types";
const FACTORY_DEPLOY_METHOD_NAMES = [
    "deployGenerativeSeries721",
    "deploySeries721",
    "deploySingleEdition721",
    "deployMultipleEditions721",
    "deployEditions1155",
];
const TransactionContractWriteExecution = ({ id, type, contractType, transactionType, args, }) => {
    const dispatch = useTransactionsDispatch();
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    let abi;
    if (transactionType === TransactionType.EVM_DEPLOY_COLLECTION) {
        if (FACTORY_DEPLOY_METHOD_NAMES.includes(args.method)) {
            abi = HighlightFactoryABI;
        }
        else {
            abi = ERC721EditionsDFSABI;
            if (contractType === ContractEntityType.MultipleEditions) {
                abi = ERC721EditionsABI;
            }
        }
    }
    else if (transactionType === TransactionType.EVM_721_BID ||
        transactionType === TransactionType.EVM_721_AUCTION) {
        abi = AuctionManagerABI;
    }
    else if ([
        TransactionType.EVM_RANKED_AUCTION_BID,
        TransactionType.EVM_721_RANKED_AUCTION_RECLAIM_BID_FUNDS,
        TransactionType.EVM_721_RANKED_AUCTION_EARNINGS,
        TransactionType.EVM_UPDATE_RANKED_AUCTION_VECTOR,
    ].includes(transactionType)) {
        if (args.method === "setPauseOnMechanicMintVector") {
            abi = MintManagerABI;
        }
        else {
            abi = RankedAuctionMechanicABI;
        }
    }
    else if (transactionType === TransactionType.EVM_721_MINT ||
        transactionType === TransactionType.EVM_UPDATE_MINT_VECTOR ||
        transactionType === TransactionType.EVM_CREATE_MINT_VECTOR ||
        transactionType === TransactionType.EVM_MINT_CREATOR_RESERVES ||
        transactionType === TransactionType.EVM_CREATE_MECHANIC_VECTOR) {
        abi = getMintingAbiFromMethodName(args.method);
    }
    else if (transactionType === TransactionType.EVM_UPDATE_DUTCH_AUCTION_VECTOR ||
        transactionType === TransactionType.EVM_DUTCH_AUCTION_REBATE ||
        transactionType === TransactionType.EVM_DUTCH_AUCTION_CLAIM_FUNDS) {
        if (args.method === "setPauseOnMechanicMintVector") {
            abi = MintManagerABI;
        }
        else {
            abi = DiscreteDutchAuctionMechanicABI;
        }
    }
    else if (transactionType === TransactionType.EVM_UPDATE_ROYALTY) {
        // if updating royalty on Series, Editions ABI works fine since it has setDefaultRoyalty
        abi = ERC721EditionsABI;
    }
    else if (transactionType === TransactionType.EVM_SET_BASE_URI) {
        abi = ERC721GeneralABI;
    }
    else if (transactionType === TransactionType.EVM_UPDATE_METADATA) {
        if (args.method === "setBaseURI") {
            abi = ERC721GeneralABI;
        }
        else if (args.method === "setEditionURI") {
            abi = ERC721EditionsDFSABI;
        }
        else {
            abi = IEditionsMetadataRendererABI;
        }
    }
    else if (transactionType === TransactionType.EVM_CURRENCY_ALLOWANCE) {
        abi = args.method === "setApprovalForAll" ? ERC1155ABI : Erc20ABI;
    }
    else if (transactionType === TransactionType.EVM_SPONSOR_MINT) {
        abi = GaslessMechanicABI;
    }
    else if (transactionType === TransactionType.EVM_CROSSCHAIN_BURN) {
        abi = SeedBasedMintMechanicABI;
    }
    else if (transactionType === TransactionType.EVM_TOKEN_TRANSFER) {
        try {
            if (JSON.parse(args.args).length === 5) {
                abi = ERC1155ABI;
            }
            else {
                abi = ERC721GeneralABI;
            }
        }
        catch (_) {
            abi = ERC721GeneralABI;
        }
    }
    const prepareArgs = {
        address: args.address,
        abi,
        functionName: args.method,
        args: JSON.parse(args.args),
        value: args.value && args.value !== "0" ? BigInt(args.value) : undefined,
        gas: args.gasLimit ? BigInt(args.gasLimit) : undefined,
    };
    const { config, error: configError } = usePrepareContractWrite(prepareArgs);
    const { data, write, error } = useContractWrite(config);
    useEffect(() => {
        if (type === TransactionStateType.WaitingPrepareTx && write) {
            write();
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_TX_STATE",
                payload: {
                    id,
                    type: TransactionStateType.WaitingSignedTx,
                },
            });
        }
    }, [write]);
    useEffect(() => {
        if (error) {
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_TX_STATUS",
                payload: {
                    id,
                    error,
                },
            });
        }
    }, [error]);
    useEffect(() => {
        if (data) {
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_TX_STATUS",
                payload: {
                    id,
                    hash: data.hash,
                },
            });
        }
    }, [data]);
    useEffect(() => {
        if (configError) {
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_TX_STATUS",
                payload: {
                    id,
                    error: configError,
                },
            });
        }
    }, [configError]);
    return _jsx(_Fragment, {});
};
export default memo(TransactionContractWriteExecution);

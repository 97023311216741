import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { DFSFallbackImage } from "./DFSFallbackImage";
const toNumber = (val) => {
    if (!val) {
        return 0;
    }
    return typeof val === "number" ? val : parseInt(val) || 0;
};
export const useResizedImageUrl = (imageUrl, fitSize) => {
    if (!imageUrl || !fitSize || !/^http/.test(imageUrl)) {
        return imageUrl;
    }
    return `${imageUrl}?d=${fitSize}x${fitSize}`;
};
export const ResizedImage = ({ imageProps = {}, fitSize, aspectRatio, src, imageRef, ...props }) => {
    const { width, height } = props;
    const size = Math.max(fitSize !== null && fitSize !== void 0 ? fitSize : 0, toNumber(width), toNumber(height));
    if (!src || !/^http/.test(src) || !size) {
        return (_jsx(DFSFallbackImage, { imageRef: imageRef, styles: { image: { aspectRatio } }, ...props, imageProps: imageProps, src: src }));
    }
    const dimension1x = `${size}x${size}`;
    const dimension2x = `${size * 2}x${size * 2}`;
    const url1x = new URL(src);
    url1x.searchParams.set("d", dimension1x);
    url1x.searchParams.set("width", dimension1x);
    const url2x = new URL(src);
    url2x.searchParams.set("d", dimension2x);
    url2x.searchParams.set("width", dimension2x);
    const imagePropsActual = {
        ...imageProps,
        srcSet: `${url1x}, ${url2x} 2x`,
    };
    return (_jsx(DFSFallbackImage, { styles: { image: { aspectRatio } }, ...props, imageRef: imageRef, imageProps: imagePropsActual }));
};

import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Expand as ExpandIcon, Screen as FullScreenIcon, Minimize as MinimizeIcon, Refresh2 as RefreshIcon, Shuffle as ShuffleIcon, } from "@hl/base-components/lib/assets/icons.generated";
import { ActionIcon, Box, Flex, Tooltip, useMantineTheme, } from "@mantine/core";
const GenerativeDisplayToolbar = (props) => {
    const { toolbarPosition = "bottom", showRefresh = false, showRandomize = false, showExpand = false, showFullscreen = false, isExpanded = false, refreshIframe, randomizeAll, toggleExpand, toggleFullscreen, sx, } = props;
    const theme = useMantineTheme();
    // Dont render the toolbar if there are no buttons to show
    if (!(showRefresh || showRandomize || showExpand || showFullscreen)) {
        return null;
    }
    const iconSize = { width: 16, height: 16 };
    return (_jsx(Box, { p: 4, sx: {
            boxShadow: theme.shadows.sm,
            borderRadius: 10,
            backgroundColor: theme.colors.baseBackground[0],
            button: {
                height: 36,
                width: 36,
            },
            alignSelf: "center",
            ...sx,
        }, children: _jsxs(Flex, { gap: 4, direction: toolbarPosition === "bottom" ? "row" : "column", children: [showRefresh && (_jsx(Tooltip, { label: "Reload", children: _jsx(ActionIcon, { onClick: refreshIframe, children: _jsx(RefreshIcon, { ...iconSize }) }) })), showRandomize && (_jsx(Tooltip, { label: "Randomize", children: _jsx(ActionIcon, { onClick: randomizeAll, children: _jsx(ShuffleIcon, { ...iconSize }) }) })), showExpand && (_jsx(Tooltip, { label: "Expand", children: _jsx(ActionIcon, { onClick: toggleExpand, children: isExpanded ? (_jsx(MinimizeIcon, { ...iconSize })) : (_jsx(ExpandIcon, { ...iconSize })) }) })), showFullscreen && (_jsx(Tooltip, { label: "Fullscreen", children: _jsx(ActionIcon, { onClick: toggleFullscreen, children: _jsx(FullScreenIcon, { ...iconSize }) }) }))] }) }));
};
export default GenerativeDisplayToolbar;

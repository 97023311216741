import { makeVar } from "@apollo/client";
export const initVar = makeVar(false);
export const identityVar = makeVar(null);
export const accountSettingsVar = makeVar({
    __typename: "AccountSettings",
    colorScheme: null,
    displayAvatar: null,
    displayName: null,
    id: "",
    loading: true,
    socials: { __typename: "LinkedSocials", email: null },
});
export const isEmbedVar = makeVar(false);
export const isFullyConnected = makeVar(false);

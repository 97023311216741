import React from "react";

import { ArrowLeft } from "@hl/base-components/lib/assets/icons.generated";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { Button, Image, Modal } from "@mantine/core";

import {
  shloms404OnImageError,
  SHLOMS_404_FALLBACK_IMG,
} from "~features/MintPage/custom/Shloms404Carousel";

type ImageFullscreenModalProps = {
  close: () => void;
  imageUrl?: string | null;
  shloms404UI?: boolean;
};

export const ImageFullscreenModal: React.FC<ImageFullscreenModalProps> = ({
  close,
  shloms404UI,
  imageUrl,
}) => {
  return (
    <Modal
      opened
      onClose={close}
      fullScreen
      withCloseButton={false}
      centered
      styles={{
        body: {
          maxHeight: "100%",
        },
      }}
    >
      <Image
        src={imageUrl}
        width="100%"
        fit="contain"
        styles={{
          image: {
            margin: "auto",
          },
        }}
        height={`calc(100vh - 30px)`}
        placeholder={shloms404UI ? SHLOMS_404_FALLBACK_IMG : undefined}
        withPlaceholder={shloms404UI}
        onError={shloms404UI ? shloms404OnImageError : undefined}
      />
      <Button
        color={SECONDARY_COLOR}
        leftIcon={<ArrowLeft />}
        style={{
          position: "absolute",
          top: 10,
          left: 10,
        }}
        onClick={close}
      >
        Back
      </Button>
    </Modal>
  );
};

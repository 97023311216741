export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const getChainLabel = (platform) => {
    const chain = platform.toLowerCase();
    switch (chain) {
        case "polygon":
            return "Polygon";
        case "mumbai":
            return "Mumbai";
        case "ethereum":
            return "Ethereum";
        case "goerli":
            return "Goerli";
        case "sepolia":
            return "Sepolia";
        case "arbitrum":
            return "Arbitrum";
        case "arbitrum_goerli":
            return "Arbitrum Goerli";
        case "optimism":
            return "Optimism";
        case "optimism_goerli":
            return "Optimism Goerli";
        case "base":
            return "Base";
        case "base_goerli":
            return "Base Goerli";
        case "base_sepolia":
            return "Base Sepolia";
        case "zora":
            return "Zora";
        case "zora_goerli":
            return "Zora Goerli";
        case "forma":
            return "Forma";
        case "mantle":
            return "Mantle";
        case "scroll":
            return "Scroll";
        case "zk_sync_era":
            return "ZKsync Era";
        case "shape":
            return "Shape";
        default:
            return "Unknown";
    }
};

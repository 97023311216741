import { useReactiveVar } from "@apollo/client";

import { ModalType, modalVar } from "../layout/Modal/modal";

import { SuccessModalData } from "./SuccessModal";

export const useSuccessModalData = () => {
  const { showModal, data } = useReactiveVar(modalVar);

  if (showModal !== ModalType.MINT_SUCCESS) {
    return null;
  }

  return data as SuccessModalData;
};

import { useEffect, useState } from "react";
const useGenArtAspectRatio = (viewPort, imageUrl) => {
    const [aspectRatio, setAspectRatio] = useState("1/1");
    const [isLoaded, setIsLoaded] = useState(false);
    const [isVertical, setIsVertical] = useState(false);
    const [ratio, setRatio] = useState(1);
    useEffect(() => {
        if (imageUrl) {
            const img = new Image();
            img.onload = () => {
                const aspect = img.width + "/" + img.height;
                if (aspect !== aspectRatio) {
                    setIsLoaded(true);
                    setIsVertical(img.height > img.width);
                    setAspectRatio(aspect);
                    setRatio(img.width / img.height);
                }
            };
            img.src = imageUrl;
        }
        else if (viewPort) {
            const aspect = viewPort.width + "/" + viewPort.height;
            if (aspect !== aspectRatio) {
                setIsLoaded(true);
                setIsVertical(viewPort.height > viewPort.width);
                setAspectRatio(aspect);
                setRatio(viewPort.width / viewPort.height);
            }
        }
    }, [imageUrl, aspectRatio]);
    return { aspectRatio, isLoaded, isVertical, ratio };
};
export default useGenArtAspectRatio;

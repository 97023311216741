import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { memo, useEffect, useState } from "react";
import { TransactionType } from "../../../apollo/graphql.generated";
import { maskAddress } from "../../../utils/content";
import ChainName from "../../chain/ChainName";
import { useCollectionForHistoryDropdownQuery } from "../queries.graphql.generated";
const UpdateMetadataDescription = ({ collectionId, status, chainId, type, }) => {
    const { data } = useCollectionForHistoryDropdownQuery({
        variables: { collectionId },
    });
    const [displayName, setDisplayName] = useState("");
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.getPublicCollection) {
            const { name, address: rawAddress } = data.getPublicCollection;
            const address = maskAddress(rawAddress, 6, 4);
            setDisplayName(`${name} (${address})`);
        }
    }, [data === null || data === void 0 ? void 0 : data.getPublicCollection]);
    const resource = type === TransactionType.EVM_UPDATE_MINT_VECTOR ||
        type === TransactionType.EVM_UPDATE_DUTCH_AUCTION_VECTOR
        ? "mint"
        : "metadata";
    const isRebateCollect = type === TransactionType.EVM_DUTCH_AUCTION_REBATE;
    if (status === "PENDING") {
        return (_jsxs(_Fragment, { children: [isRebateCollect ? "Collecting rebate" : "Updating " + resource, " for", " ", displayName, " on ", _jsx(ChainName, { chainId: chainId }), "."] }));
    }
    if (status === "MINED") {
        return (_jsxs(_Fragment, { children: ["Successfully", " ", isRebateCollect ? "collected rebate" : "updated " + resource, " for", " ", displayName, " on ", _jsx(ChainName, { chainId: chainId }), "."] }));
    }
    if (status === "REJECTED") {
        return (_jsxs(_Fragment, { children: ["Failed to ", isRebateCollect ? "collect rebate" : "updated " + resource, " ", "for ", displayName, " on ", _jsx(ChainName, { chainId: chainId }), "."] }));
    }
    return _jsx(_Fragment, { children: "Unknown transaction status." });
};
export default memo(UpdateMetadataDescription);

import { memo } from "react";

import {
  Group,
  Skeleton,
  Paper,
  Stack,
  Space,
  Divider,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const MainCardSkeleton = ({ isApollo }: { isApollo?: boolean }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);

  return (
    <Paper
      shadow="md"
      radius="md"
      p="md"
      w={isMobile || isApollo ? "100%" : 380}
    >
      <Group spacing={8} position="apart">
        <Stack spacing={4}>
          <Group spacing={2} align="end">
            <Skeleton height={40} width={40} />
            <Skeleton height={20} width={40} />
          </Group>
          <Skeleton height={15} width={120} />
        </Stack>
        <Skeleton height={48} width={84} />
      </Group>
      <Space h={8} />
      <Skeleton height={48} />
      <Divider mx={-16} my="sm" />
      <Group spacing={8} position="apart">
        <Skeleton height={15} width={50} />
        <Skeleton height={15} width={50} />
      </Group>
    </Paper>
  );
};

export default memo(MainCardSkeleton);

import { useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { ampli } from "../../ampli";
import { envConfig } from "../../config";
import { useAuth } from "../auth/AuthContext";
import { accountSettingsVar, identityVar } from "../auth/vars";
const isAmpliEnabled = !!envConfig.analytics.amplitudeKey;
export const useAmpliSetup = () => {
    const { walletAddress } = useAuth();
    useEffect(() => {
        if (envConfig.analytics.amplitudeKey && !ampli.isLoaded) {
            ampli.load({
                client: {
                    apiKey: envConfig.analytics.amplitudeKey,
                    configuration: {
                        defaultTracking: true,
                    },
                },
            });
        }
    }, []);
    const identity = useReactiveVar(identityVar);
    const accountSettings = useReactiveVar(accountSettingsVar);
    useEffect(() => {
        var _a, _b;
        if (isAmpliEnabled && (identity === null || identity === void 0 ? void 0 : identity.account) && accountSettings) {
            ampli.identify(identity.account.id, {
                walletAddress: walletAddress !== null && walletAddress !== void 0 ? walletAddress : "",
                displayName: (_a = accountSettings.displayName) !== null && _a !== void 0 ? _a : "",
                colorScheme: (_b = accountSettings.colorScheme) !== null && _b !== void 0 ? _b : "system",
            });
        }
    }, [identity, accountSettings]);
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Box, Divider } from "@mantine/core";
const ModalSection = ({ children }) => {
    return (_jsxs(_Fragment, { children: [_jsx(Divider, { mt: 16, mx: -16 }), _jsx(Box, { mt: 16, children: children })] }));
};
export const HeaderModalSection = ({ children }) => {
    return (_jsxs(Box, { sx: (theme) => ({
            position: "sticky",
            marginBottom: theme.spacing.md,
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            marginLeft: -theme.spacing.md,
            marginRight: -theme.spacing.md,
            paddingLeft: theme.spacing.md,
            paddingRight: theme.spacing.md,
            backgroundColor: theme.colors.primaryBackground[0],
        }), children: [children, _jsx(Divider, { mt: "md", mx: "-md" })] }));
};
export const BottomModalSection = ({ children }) => {
    return (_jsxs(Box, { sx: (theme) => ({
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            marginLeft: -theme.spacing.md,
            marginRight: -theme.spacing.md,
            paddingLeft: theme.spacing.md,
            paddingRight: theme.spacing.md,
            backgroundColor: theme.colors.primaryBackground[0],
        }), children: [_jsx(Divider, { mt: "md", mx: "-md" }), _jsx(Box, { mt: "sm", children: children })] }));
};
export default ModalSection;

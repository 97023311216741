import { USER_ROUTES } from "@hl/shared-features/lib/config";
import {
  buildContractUrl,
  networkLookup,
} from "@hl/shared-features/lib/utils/blockExplorer";
import { maskAddress } from "@hl/shared-features/lib/utils/content";
import { Anchor } from "@mantine/core";

export const Bidder = ({
  bidderAddress: address,
  bidderEnsName: ens,
  bidderProfile: profile,
  chainId,
}: {
  chainId: number;
  bidderAddress: string;
  bidderEnsName?: string | null;
  bidderProfile?: {
    displayName?: string | null;
    logoUrl?: string | null;
  } | null;
}) => {
  let profileLink;
  if (profile) {
    profileLink = USER_ROUTES.user.profile.replace(":slug", ens ?? address);
  } else {
    const networkData = networkLookup(chainId);
    profileLink = buildContractUrl(networkData.type, address);
  }
  return (
    <>
      {/* {profile.logoUrl && <Avatar src={profile.logoUrl} size={16} />} */}
      <Anchor target="_blank" href={profileLink} size="sm">
        {formatName(profile?.displayName || ens) || maskAddress(address, 6, 4)}
      </Anchor>
    </>
  );
};

const formatName = (ens: string | null | undefined, maxLen = 25) => {
  if (!ens || ens.length <= maxLen) {
    return ens;
  }
  return maskAddress(ens, maxLen - 4, 4);
};

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useEmbedMode } from "../features/auth/hooks";
export const useNavigateSafe = () => {
    const { isEmbedMode } = useEmbedMode();
    const changeUrl = useCallback((url) => {
        window.location.href = url;
    }, []);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const navigate = isEmbedMode ? changeUrl : useNavigate();
    return navigate;
};

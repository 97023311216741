import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useAccount } from "wagmi";
import { useAuth } from "../../auth/AuthContext";
import { SignInButton } from "../../auth/SignInButton";
const MarketplaceConnectButton = () => {
    const { authenticated } = useMarketplaceAuth();
    return !authenticated ? _jsx(SignInButton, { size: "xl" }) : null;
};
export default MarketplaceConnectButton;
export const useMarketplaceAuth = () => {
    const { isConnected } = useAccount();
    const { walletAddress, authenticated } = useAuth();
    return {
        authenticated,
        isConnected,
        hasJwt: authenticated,
        walletAddress,
        isFullyAuthenticated: authenticated,
    };
};

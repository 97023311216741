import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createStyles } from "@mantine/core";
import { useMergedRef } from "@mantine/hooks";
import { constructDFSGatewayUrl } from "../../utils/dfs";
export const toQueryString = (obj) => {
    return Object.entries(obj)
        .map(([key, val]) => `${key}=${val}`)
        .join("&");
};
const useStyles = createStyles((theme, { bordered, aspectRatio, pointerEvents }) => ({
    iframe: {
        height: "100%",
        width: "100%",
        aspectRatio,
        position: "relative",
        border: bordered
            ? `1px solid ${theme.colors.divider[0]}`
            : `0px solid ${theme.colors.divider[0]}`,
        pointerEvents,
    },
}));
export const generateParams = (genProps) => {
    const params = {
        h: genProps.txHash,
        t: genProps.timestamp,
        wa: genProps.walletAddress,
        tid: genProps.tokenId,
        s: genProps.editionSize,
        a: genProps.address,
        bh: genProps.blockHash,
        bn: genProps.blockNumber,
        c: genProps.chainId,
        gp: genProps.gasPrice,
        gu: genProps.gasUsed,
        ms: genProps.mintSize,
        mi: genProps.mintIteration,
        ic: genProps.isCurated ? "1" : "0",
        loadVer: genProps.loadVer,
    };
    const urlPath = genProps.defaultParams
        ? genProps.defaultParams
        : toQueryString(params);
    return `${genProps.mediaUrl}/index.html?${urlPath}`;
};
export const GenArtIFrame = ({ mediaUrl, txHash, timestamp, walletAddress, tokenId, editionSize, address, blockHash, blockNumber, chainId, gasPrice, gasUsed, mintSize, mintIteration, defaultParams, isCurated, loadVer, ...props }) => {
    const url = generateParams({
        mediaUrl,
        txHash,
        timestamp,
        walletAddress,
        tokenId,
        editionSize,
        address,
        blockHash,
        blockNumber,
        chainId,
        gasPrice,
        gasUsed,
        mintSize,
        mintIteration,
        defaultParams,
        isCurated,
        loadVer,
    });
    return _jsx(GenArtUrlIFrame, { url: url, ...props });
};
const BASE_64_HTML_ENCODING_PREFIX = "data:text/html;base64,";
export const GenArtUrlIFrame = ({ url, bordered = true, aspectRatio = "1", iframeRef, iframeElementRef, pointerEvents, extraClass, enableRestrictiveFlags, allowIframeSameOrigin, }) => {
    var _a;
    const ref = useMergedRef(iframeElementRef || null, iframeRef || null);
    const { classes, cx } = useStyles({ bordered, aspectRatio, pointerEvents });
    if (url.indexOf(BASE_64_HTML_ENCODING_PREFIX) === 0) {
        url = url.substring(BASE_64_HTML_ENCODING_PREFIX.length);
        // create object url, which handles more efficiently
        url = URL.createObjectURL(new Blob([Buffer.from(url, "base64").toString("utf-8")], {
            type: "text/html",
        }));
    }
    return (_jsx("iframe", { ref: ref, sandbox: `allow-scripts${enableRestrictiveFlags ? " allow-downloads allow-modals" : ""} ${allowIframeSameOrigin ? " allow-same-origin" : ""}`, className: cx(classes.iframe, extraClass), allow: "accelerometer; camera; gyroscope; microphone; xr-spatial-tracking;", src: (_a = constructDFSGatewayUrl(url, true)) !== null && _a !== void 0 ? _a : "" }));
};

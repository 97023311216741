import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { SearchIcon } from "@hl/base-components/lib/assets/icons.generated";
import { Modal, UnstyledButton, Text, useMantineTheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { GlobalSearch } from "./GlobalSearch";
export const NavbarSearchMobile = () => {
    const [opened, { open, close }] = useDisclosure(false);
    const theme = useMantineTheme();
    const cancelBtn = (_jsx(UnstyledButton, { onClick: close, ml: 8, mr: 20, sx: {
            textWrap: "nowrap",
        }, children: _jsx(Text, { size: "sm", children: "Cancel" }) }));
    return (_jsxs(_Fragment, { children: [_jsx(Modal, { opened: opened, onClose: close, centered: true, withCloseButton: false, radius: 0, fullScreen: true, styles: {
                    root: {
                        zIndex: 10002,
                    },
                    modal: {
                        padding: `0 !important`,
                    },
                    body: {
                        borderBottom: `1px solid ${theme.colors.divider[0]}`,
                    },
                }, children: _jsx(GlobalSearch, { mobile: true, cancelBtn: cancelBtn }) }), _jsx(UnstyledButton, { onClick: open, sx: { display: "flex", alignItems: "center" }, children: _jsx(SearchIcon, { width: 24, height: 24 }) })] }));
};

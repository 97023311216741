import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState, } from "react";
import FormatCrypto from "@hl/base-components/lib/FormatCrypto";
import { ArrowLeft, CheckCircle, CursorBox, } from "@hl/base-components/lib/assets/icons.generated";
import { SECONDARY_COLOR, AFFIRMATIVE_COLOR, } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { ActionIcon, Button, Card, Divider, Flex, Grid, Group, NumberInput, Space, Stack, Text, useMantineTheme, } from "@mantine/core";
import dayjs from "dayjs";
import { parseUnits } from "ethers/lib/utils";
import ReactTimeAgo from "react-time-ago";
import { useWalletClient } from "wagmi";
import { _CollectionType, EvmTxSaleKind, NftContractStandard, TransactionType, } from "../../../apollo/graphql.generated";
import { EXTERNAL_URL, getDetailPageUrl, getMintPageUrl, USER_ROUTES, } from "../../../config";
import { reactTimeAgoStyle } from "../../../utils/format";
import { User } from "../../auth/User";
import { useTransactionsDispatch } from "../../evm-tx/TransactionContext";
import { useModalStack } from "../../modal";
import MarketplaceConnectButton, { useMarketplaceAuth, } from "../components/MarketplaceConnectButton";
import MarketplacePopover from "../components/MarketplacePopover";
import MarketplaceSummaryCard from "../components/MarketplaceSummaryCard";
import RecentSales from "../components/RecentSales";
import RoyaltySettings from "../components/RoyaltySettings";
import SelectToken from "../components/SelectToken";
import { marketplaceFeeBps } from "../fees";
import { useGetCollectionRoyaltyInfoQuery } from "../queries/marketplace.graphql.generated";
import { AcceptBidModalRenderer, AcceptBidStep, } from "./custom-accept-bid-modal/AcceptBidModalRenderer";
export const AcceptBidModalTitle = ({ address, isCollection, step = AcceptBidModalStep.Default, onBack, }) => step === AcceptBidModalStep.SelectToken ? (_jsxs(Flex, { gap: 8, align: "center", children: [_jsx(ActionIcon, { size: 24, onClick: () => onBack === null || onBack === void 0 ? void 0 : onBack(), children: _jsx(ArrowLeft, { width: 20, height: 20 }) }), _jsx(Text, { fw: WEIGHT_BOLD, children: "Choose token" })] })) : (_jsxs(Flex, { align: "center", children: [_jsx(User, { walletAddresses: [address], enableLink: false, textSize: "md", bold: true }), _jsxs(Text, { fw: WEIGHT_BOLD, sx: { whiteSpace: "pre" }, children: [" made ", isCollection ? "a collection offer" : "you an offer"] })] }));
const AcceptBidModalHighlight = (props) => {
    var _a, _b;
    const { bid, collectionData } = props;
    const token = (_a = props.bid.tokens) === null || _a === void 0 ? void 0 : _a[0];
    const { data: royaltyQuery } = useGetCollectionRoyaltyInfoQuery({
        variables: { collectionId: collectionData.id },
    });
    const royalty = royaltyQuery === null || royaltyQuery === void 0 ? void 0 : royaltyQuery.getPublicCollection.royalty;
    const royaltyAddress = royalty === null || royalty === void 0 ? void 0 : royalty.royaltyRecipient;
    const royaltyPercentage = royalty === null || royalty === void 0 ? void 0 : royalty.royaltyPercentageString;
    const royaltyBps = royaltyPercentage
        ? parseFloat(royaltyPercentage) * 100
        : 0;
    const [tokenId, setTokenId] = useState((_b = token === null || token === void 0 ? void 0 : token.id) !== null && _b !== void 0 ? _b : "");
    const { data: walletClient } = useWalletClient();
    const [customRoyalty, setCustomRoyalty] = useState(royaltyBps || 0);
    useEffect(() => {
        if (royaltyBps) {
            setCustomRoyalty(royaltyBps);
        }
    }, [royaltyBps]);
    if (!bid.contractAddress) {
        return null;
    }
    // TODO: Make sure to only ever pass in token array of length ONE, because quantity is applied to all passed in tokens
    const tokens = [
        {
            collectionId: bid.contractAddress,
            tokenId,
            bidIds: [bid.reservoirId],
        },
    ];
    return (_jsx(Stack, { pt: 16, spacing: 40, children: _jsx(AcceptBidModalRenderer, { chainId: props.bid.chainId, open: true, tokens: [...tokens], normalizeRoyalties: false, feesOnTopCustom: (order) => {
                const rawWeiPrice = (order === null || order === void 0 ? void 0 : order[0].totalRawPrice) || "0";
                const weiRoyalty = parseUnits(rawWeiPrice, 0)
                    .mul(customRoyalty)
                    .div(10000);
                if (!royaltyAddress) {
                    return [];
                }
                return [`${royaltyAddress}:${weiRoyalty.toString()}`];
            }, walletClient: walletClient, children: (acceptBidProps) => (_jsx(AcceptBidModalInner, { ...acceptBidProps, ...props, royaltyBps: customRoyalty, setRoyaltyBps: setCustomRoyalty, defaultBps: royaltyBps, tokenId: tokenId, setTokenId: setTokenId })) }) }));
};
export var AcceptBidModalStep;
(function (AcceptBidModalStep) {
    AcceptBidModalStep[AcceptBidModalStep["SelectToken"] = 0] = "SelectToken";
    AcceptBidModalStep[AcceptBidModalStep["Default"] = 1] = "Default";
})(AcceptBidModalStep || (AcceptBidModalStep = {}));
const AcceptBidModalInner = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const { acceptBidStep, stepData, transactionError, acceptBid, bid, onCompleted, tokenId, setTokenId, prices, defaultBps, setRoyaltyBps, royaltyBps, step = AcceptBidModalStep.Default, collectionData, quantity, setQuantity, } = props;
    const quantityAvailable = bid.quantityRemaining;
    const is1155 = (collectionData === null || collectionData === void 0 ? void 0 : collectionData.standard) === NftContractStandard.ERC1155;
    const txHash = (_b = (_a = stepData === null || stepData === void 0 ? void 0 : stepData.currentStepItem) === null || _a === void 0 ? void 0 : _a.txHashes) === null || _b === void 0 ? void 0 : _b[0].txHash;
    const getNewTxnId = () => `txn-${Date.now()}`;
    const [txnId, setTxnId] = useState(getNewTxnId());
    const transactionDispatch = useTransactionsDispatch();
    const [error, setError] = useState();
    const [highlightModalStep, setHighlightModalStep] = useState(step);
    const priceData = prices[0] || {};
    const { amount, marketplaceFee, netAmount, royalty, feesOnTop } = priceData;
    const netAmountQuantityAdjusted = netAmount * quantity;
    const amountQuantityAdjusted = amount * quantity;
    const marketplaceFeeQuantityAdjusted = marketplaceFee * quantity;
    const royaltyQuantityAdjusted = royalty * quantity;
    const feesOnTopQuantityAdjusted = feesOnTop * quantity;
    // const percentage =
    //   marketplaceFee && amount ? (marketplaceFee / amount) * 100 : null;
    const symbol = (_d = (_c = bid === null || bid === void 0 ? void 0 : bid.price) === null || _c === void 0 ? void 0 : _c.currency) === null || _d === void 0 ? void 0 : _d.symbol;
    const isCollectionBid = bid.criteriaKind !== "token";
    const theme = useMantineTheme();
    const { isFullyAuthenticated, walletAddress } = useMarketplaceAuth();
    const { clearModals, setModalProps } = useModalStack();
    const token = (_e = bid === null || bid === void 0 ? void 0 : bid.tokens) === null || _e === void 0 ? void 0 : _e[0];
    const [tokenImageUrl, setTokenImageUrl] = useState((_f = token === null || token === void 0 ? void 0 : token.imageUrl) !== null && _f !== void 0 ? _f : "");
    const linkToResource = bid.collection
        ? isCollectionBid
            ? getMintPageUrl(bid.collection)
            : getDetailPageUrl(bid.collection, (_g = token === null || token === void 0 ? void 0 : token.id) !== null && _g !== void 0 ? _g : "")
        : "";
    const acceptBidAndCreateTx = useCallback(() => {
        var _a;
        setError("");
        if (!txnId) {
            return;
        }
        transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
            type: "START_TRANSACTION",
            payload: {
                id: txnId,
                entityId: (_a = collectionData === null || collectionData === void 0 ? void 0 : collectionData.id) !== null && _a !== void 0 ? _a : "",
                transactionType: TransactionType.EVM_TOKEN_SALE,
                collectionType: _CollectionType.GenerativeSeries,
            },
        });
        acceptBid();
    }, [acceptBid, txnId]);
    useEffect(() => {
        var _a, _b;
        if (txHash && txnId) {
            transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
                type: "UPDATE_TX_ARGS",
                payload: {
                    id: txnId,
                    args: (_a = stepData === null || stepData === void 0 ? void 0 : stepData.currentStepItem) === null || _a === void 0 ? void 0 : _a.data,
                    metadata: {
                        collectionId: collectionData.id,
                        nftContractAddress: (_b = collectionData.address) !== null && _b !== void 0 ? _b : "",
                        reservoirOrderId: bid.reservoirId,
                        saleKind: EvmTxSaleKind.OFFER,
                        tokenId: tokenId !== null && tokenId !== void 0 ? tokenId : "",
                    },
                },
            });
            transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
                type: "UPDATE_TX_STATUS",
                payload: {
                    id: txnId,
                    hash: txHash,
                },
            });
            setTxnId(null);
        }
    }, [stepData]);
    const actionButton = useMemo(() => {
        if (!isFullyAuthenticated)
            return _jsx(MarketplaceConnectButton, {});
        if (acceptBidStep === AcceptBidStep.Complete) {
            return (_jsxs(Grid, { children: [_jsx(Grid.Col, { span: 6, children: _jsxs(Button, { component: "a", href: linkToResource, onClick: () => clearModals(), w: "100%", px: "md", color: SECONDARY_COLOR, size: "xl", children: ["View ", isCollectionBid ? "collection" : "token"] }) }), _jsx(Grid.Col, { span: 6, children: _jsx(Button, { component: "a", size: "xl", w: "100%", px: "md", href: USER_ROUTES.user.marketplace.receivedOffers.replace(":slug", walletAddress !== null && walletAddress !== void 0 ? walletAddress : ""), target: "_blank", children: "Manage offers" }) })] }));
        }
        const isExecuting = acceptBidStep === AcceptBidStep.Finalizing ||
            acceptBidStep === AcceptBidStep.ApproveMarketplace;
        return highlightModalStep === AcceptBidModalStep.Default &&
            isCollectionBid ? (_jsx(Button, { size: "xl", onClick: () => setHighlightModalStep(AcceptBidModalStep.SelectToken), color: AFFIRMATIVE_COLOR, w: "100%", children: _jsxs(Group, { spacing: "xs", children: [_jsx(CursorBox, {}), " Choose token"] }) })) : (_jsx(Button, { size: "xl", onClick: () => {
                acceptBidAndCreateTx();
                setError("");
            }, loading: isExecuting, disabled: isExecuting || !tokenId, color: AFFIRMATIVE_COLOR, w: "100%", children: _jsxs(Group, { spacing: "xs", children: [!isExecuting && _jsx(CheckCircle, {}), " Accept offer"] }) }));
    }, [
        isFullyAuthenticated,
        transactionError,
        acceptBidStep,
        isCollectionBid,
        highlightModalStep,
        acceptBidAndCreateTx,
    ]);
    useEffect(() => {
        setModalProps({
            title: (_jsx(AcceptBidModalTitle, { address: bid.makerAddress, isCollection: bid.criteriaKind !== "token", step: highlightModalStep, onBack: () => setHighlightModalStep(AcceptBidModalStep.Default) })),
        });
    }, [highlightModalStep]);
    useEffect(() => {
        if (acceptBidStep === AcceptBidStep.Complete) {
            setModalProps({ title: _jsx(Text, { fw: WEIGHT_BOLD, children: "Offer accepted" }) });
            onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted();
        }
    }, [acceptBidStep]);
    useEffect(() => {
        var _a;
        if (!transactionError) {
            setError("");
        }
        else {
            if ((_a = transactionError === null || transactionError === void 0 ? void 0 : transactionError.message) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes("balance too low")) {
                setError("Insufficient balance, add funds then try again");
            }
            else {
                setError(transactionError
                    .shortMessage || "Ooops, something went wrong");
            }
        }
    }, [transactionError]);
    const imageUrl = isCollectionBid
        ? highlightModalStep === AcceptBidModalStep.Default
            ? (_h = bid.collection) === null || _h === void 0 ? void 0 : _h.imageUrl
            : tokenImageUrl
        : tokenImageUrl;
    const isShowingRoyalty = !isCollectionBid || highlightModalStep === AcceptBidModalStep.SelectToken;
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, { children: [_jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: "Offer" }), _jsx(MarketplaceSummaryCard, { imageSize: 80, symbol: symbol, price: amount, quantity: quantity, imageUrl: imageUrl, tokenId: token === null || token === void 0 ? void 0 : token.id, collectionName: (_j = bid === null || bid === void 0 ? void 0 : bid.collection) === null || _j === void 0 ? void 0 : _j.name, floorPrice: bid.floorPrice, validUntil: (_k = bid === null || bid === void 0 ? void 0 : bid.expiredAt) !== null && _k !== void 0 ? _k : undefined })] }), acceptBidStep !== AcceptBidStep.Complete && (_jsxs(_Fragment, { children: [_jsxs(Stack, { children: [_jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: "Expires" }), _jsx(Card, { px: 16, py: 12, children: _jsxs(Flex, { justify: "space-between", children: [_jsx(Text, { size: "sm", children: bid.expiredAt
                                                ? `${dayjs(bid.expiredAt).format("MMM. DD @ hh:mma")} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`
                                                : "N/A" }), _jsxs(Text, { color: theme.colors.secondaryText[0], size: "sm", children: ["in", " ", _jsx(ReactTimeAgo, { date: dayjs(bid.expiredAt).toDate(), timeStyle: reactTimeAgoStyle(), future: true, locale: "en-US" })] })] }) })] }), _jsx(_Fragment, { children: is1155 && (_jsxs(Stack, { spacing: 4, children: [_jsx(NumberInput, { label: "Number of tokens to buy", placeholder: "1", onChange: (value) => setQuantity(parseInt(String(value))), defaultValue: 1, value: quantity, min: 1, max: parseInt(String(quantityAvailable)), styles: {
                                        input: {
                                            paddingRight: 24,
                                        },
                                        rightSection: {
                                            paddingRight: 0,
                                        },
                                    } }), _jsxs(Text, { size: "xs", color: theme.colors.secondaryText[0], children: [parseInt(String(quantityAvailable)), " available"] })] })) }), highlightModalStep === AcceptBidModalStep.SelectToken &&
                        bid.collection && (_jsx(SelectToken, { address: (_l = bid.collection) === null || _l === void 0 ? void 0 : _l.address, tokenId: tokenId, selectToken: (token) => {
                            var _a;
                            setTokenId(token.tokenId);
                            setTokenImageUrl((_a = token.imageSrc) !== null && _a !== void 0 ? _a : "");
                        }, chainId: collectionData.chainId })), isShowingRoyalty && (_jsx(RoyaltySettings, { royaltyBps: royaltyBps, setRoyaltyBps: setRoyaltyBps, defaultBps: defaultBps })), highlightModalStep === AcceptBidModalStep.Default &&
                        bid.collection && (_jsx(RecentSales, { name: bid.collection.name || "", isCollectionBid: isCollectionBid, address: bid.collection.address })), _jsxs(Stack, { spacing: 0, children: [_jsxs(Flex, { justify: "space-between", align: "center", children: [_jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "Offer price" }), _jsx(FormatCrypto, { size: "xs", color: TEXT_COLOR.SECONDARY, amount: amountQuantityAdjusted, symbol: symbol })] }), _jsxs(Flex, { justify: "space-between", align: "center", children: [_jsx(MarketplacePopover, { text: `Highlight charges a ${(marketplaceFeeBps / 100).toFixed(1)}% fee for all marketplace listings.`, url: EXTERNAL_URL.MARKETPLACE_FEE_EXPLANATION, children: _jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "Marketplace fee" }) }), _jsx(FormatCrypto, { size: "xs", color: TEXT_COLOR.SECONDARY, amount: marketplaceFeeQuantityAdjusted, symbol: symbol })] }), _jsxs(Flex, { justify: "space-between", align: "center", children: [_jsx(MarketplacePopover, { text: feesOnTopQuantityAdjusted
                                            ? "100% of creator royalties are paid to the creator of this collection."
                                            : "This creator hasn't enabled creator royalties.", url: royaltyQuantityAdjusted
                                            ? EXTERNAL_URL.ROYALTY_FEE_EXPLANATION
                                            : undefined, children: _jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "Creator royalties" }) }), defaultBps ? (_jsx(FormatCrypto, { size: "xs", color: TEXT_COLOR.SECONDARY, amount: feesOnTopQuantityAdjusted, symbol: symbol })) : (_jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "0%" }))] }), _jsx(Divider, { my: "sm", size: 0.5 }), _jsxs(Flex, { justify: "space-between", align: "center", children: [_jsx(Text, { size: "lg", fw: WEIGHT_BOLD, children: "Total earnings" }), _jsx(FormatCrypto, { size: "lg", fw: WEIGHT_BOLD, amount: netAmountQuantityAdjusted, symbol: symbol })] })] })] })), _jsxs(Stack, { spacing: 8, sx: { overflow: "hidden" }, children: [_jsx(Space, { h: 8, mx: -16, sx: {
                            borderTop: `1px solid ${theme.colors.divider[0]}`,
                            width: "auto !important",
                        } }), actionButton, error && (_jsx(Text, { size: "xs", align: "center", color: theme.colors.errorStatus[0], children: error }))] })] }));
};
export default AcceptBidModalHighlight;

import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo, useEffect } from "react";
import { useCrossChainTransaction } from "../cross-chain-tx/CrossChainTransactionContext";
import { TransactionStateType, useTransactionsDispatch, } from "./TransactionContext";
const CrossChainTransactionExecution = ({ id, type, args, walletClient, fromChain, }) => {
    const dispatch = useTransactionsDispatch();
    const { startCrossChainMintTransaction, crossChainId, txStatus, destinationTx, txError, } = useCrossChainTransaction();
    useEffect(() => {
        if (type === TransactionStateType.WaitingPrepareTx) {
            startCrossChainMintTransaction(fromChain, args, walletClient);
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_TX_STATE",
                payload: {
                    id,
                    type: TransactionStateType.WaitingSignedTx,
                },
            });
        }
    }, []);
    useEffect(() => {
        if (txError) {
            const type = txError.includes("User rejected the request")
                ? TransactionStateType.SignTxRejected
                : TransactionStateType.Web2TxCreateError;
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_TX_STATE",
                payload: {
                    id,
                    type,
                },
            });
        }
    }, [txError]);
    useEffect(() => {
        console.log("Destination TX Status", { txStatus });
        if (txStatus === "complete" && destinationTx && crossChainId) {
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_TX_STATUS",
                payload: {
                    id,
                    hash: destinationTx,
                    crossChainId,
                },
            });
        }
    }, [txStatus, crossChainId]);
    return _jsx(_Fragment, {});
};
export default memo(CrossChainTransactionExecution);
